import React, { Component } from 'react';
import Thumbnail from './DocumentThumbnail.js';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { FeatureDetector } from 'eyeson';

class Thumbnails extends Component {
  state = {
    thumbnails: [],
    scrollLeft: 0,
  };

  componentDidMount() {
    this.setState({ thumbnails: this.range(1, this.props.numPages) });
  }

  componentDidUpdate(prevProps) {
    if (this.props.numPages !== prevProps.numPages) {
      this.setState({ thumbnails: this.range(1, this.props.numPages) });
    }
    if (this.props.page !== prevProps.page) {
      this.scrollIntoView();
    }
  }

  // https://stackoverflow.com/a/10050831/980524
  range(startAt = 1, size) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  setRef = (node) => {
    if (node) {
      this.thumbnailsEl = node;
    }
  };

  triggerThumbnailsUpdate = () => {
    this.setState({ scrollLeft: this.thumbnailsEl.scrollLeft });
  };

  scrollIntoView = () => {
    if (this.props.expanded) {
      const activeEl = document.getElementById(`thumbnail-${this.props.page}`);
      activeEl.scrollIntoView();
    }
  };

  // Firefox has a very small deltaY, therfore we increase it
  handleWheel = (event) => {
    let { deltaY } = event;

    if (!deltaY) {
      return;
    }

    if (deltaY < 10 && FeatureDetector.platform().name === 'Firefox') {
      deltaY = deltaY * 10;
    }

    this.thumbnailsEl.scrollLeft = this.thumbnailsEl.scrollLeft + deltaY;
  };

  // Wait for 250ms and see if the scroll state has changed,
  // we only update if that's the case. I.e scrolling has "settled".
  // The idea is to be less resource intensive.
  handleScroll = () => {
    if (!this.thumbnailsEl) {
      return;
    }

    const recordedScrollLeft = this.thumbnailsEl.scrollLeft;
    this.recorderTimeout = window.setTimeout(() => {
      if (this.thumbnailsEl.scrollLeft === recordedScrollLeft) {
        this.triggerThumbnailsUpdate();
      }
    }, 250);
  };

  render() {
    return (
      <section
        ref={this.setRef}
        role="list"
        aria-label={I18n.t('aria:label:thumbnails', {
          defaultValue: 'Thumbnails',
        })}
        onWheel={this.handleWheel}
        onScroll={this.handleScroll}
        className="thumbnails"
        style={{ maxWidth: `calc(100vw - ${this.props.borderOffset})` }}
      >
        {this.state.thumbnails.map((pageNum) => (
          <Thumbnail
            {...this.props}
            key={pageNum}
            active={this.props.page === pageNum}
            pageNum={pageNum}
            onClick={this.props.onThumbnailClick}
            scrollLeft={this.state.scrollLeft}
          />
        ))}
      </section>
    );
  }
}

Thumbnail.propTypes = {
  pdf: PropTypes.object.isRequired,
  file: PropTypes.object.isRequired,
  onThumbnailClick: PropTypes.func.isRequired,
  borderOffset: PropTypes.string.isRequired,
};

Thumbnail.defaultProps = {};

export default Thumbnails;
