import React from 'react';
import Icon from '../generic/Icon.js';

const VoiceActivityIndicator = ({ active }) => {
  if (!active) {
    return null;
  }

  return (
    <div className="voice-activity-indicator">
      <Icon type="mic" />
    </div>
  );
};

export default VoiceActivityIndicator;
