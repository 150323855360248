import React, { useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Box from '@material-ui/core/Box';
import CustomIcon from '../generic/Icon.js';
import Icon from '@material-ui/core/Icon';
import YoutubeIcon from '@material-ui/icons/YouTube';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import supports from '../../utils/SupportHelper.js';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    minHeight: 'auto',
    lineHeight: 'unset',
  },
}))(MenuItem);

const MenuEntry = forwardRef(
  (
    { action, label, customIcon, icon, onClick, ariaControls, ariaHasPopup },
    itemRef
  ) => (
    <StyledMenuItem
      ref={itemRef}
      onClick={() => onClick(action)}
      aria-haspopup={ariaHasPopup}
      aria-labelledby={'menu-label-' + action}
      aria-controls={ariaControls}
    >
      <ListItemIcon>
        {customIcon ? (
          <CustomIcon type={customIcon} title={label} />
        ) : (
          <Icon>{icon}</Icon>
        )}
      </ListItemIcon>
      <ListItemText
        primary={label}
        primaryTypographyProps={{ id: 'menu-label-' + action }}
      />
    </StyledMenuItem>
  )
);

MenuEntry.propTypes = {
  action: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  customIcon: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  ariaControls: PropTypes.string,
};

const ToolbarExtras = ({
  theme,
  canPip,
  options = {},
  onEvent,
  isGuest,
  minified,
  feedback,
  disableSettings,
  activeRecording,
  fullscreenActive,
  guestTokenAvailable,
  isLocked,
  tooltip,
  hideRecording,
  disableRecording,
  hideGuestLogin,
  hideScreenshare,
  hideLayoutSettings,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const menuEl = useRef(null);

  const handleButtonClick = (event) => {
    if (menuOpen) {
      handleClose();
      return;
    }
    let menuHeight = 0;
    if (menuEl.current) {
      const menu = menuEl.current.querySelector('#actions-menu');
      if (menu) {
        menuHeight = menu.clientHeight;
      }
    }
    const { top, left, height } = event.currentTarget.getBoundingClientRect();
    setPosition({ top: top + height - menuHeight, left: left + 80 });
  };
  const handleClose = () => setPosition({ top: 0, left: 0 });

  const handleAction = (type) => {
    onEvent({ type });
    handleClose();
  };

  let menuOpen = position.top !== 0 && position.left !== 0;

  return (
    <div>
      <Box py={1} px={1.5} pb={0}>
        <Tooltip title={tooltip} placement="right">
          <IconButton
            edge="end"
            aria-label={I18n.t('aria:label:actions_menu', {
              defaultValue: 'Actions menu',
            })}
            aria-haspopup="menu"
            aria-controls="actions-menu"
            aria-pressed={menuOpen}
            onClick={handleButtonClick}
            tabIndex={null}
          >
            <Icon>more_vert</Icon>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        keepMounted
        ref={menuEl}
        open={menuOpen}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={position}
        MenuListProps={{
          id: 'actions-menu',
          'aria-label': I18n.t('aria:label:actions_menu', {
            defaultValue: 'Actions menu',
          }),
        }}
        TransitionProps={{
          onEntered: (menu) => {
            const firstEntry =
              menu.querySelector('li[role="menuitem"]') || menu.firstChild;
            if (firstEntry) {
              firstEntry.focus();
            }
          },
        }}
      >
        {options.lock_available && !isGuest && !isLocked && (
          <MenuEntry
            action="lock_meeting_prompt"
            label={I18n.t('label:lock_meeting', {
              defaultValue: 'Lock meeting',
            })}
            icon="lock"
            onClick={handleAction}
            ariaControls="lock-meeting-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {!disableSettings && (
          <MenuEntry
            action="settings"
            label={I18n.t('label:settings', {
              defaultValue: 'Device settings',
            })}
            icon="settings"
            onClick={handleAction}
            ariaControls="device-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {minified && !hideScreenshare && (
          <MenuEntry
            action="presentation"
            label={I18n.t('label:presentation', {
              defaultValue: 'Presentation',
            })}
            customIcon="presentation"
            onClick={handleAction}
            ariaControls="share-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {minified && canPip && (
          <MenuEntry
            action="toggle_pip"
            label={I18n.t('label:pip', { defaultValue: 'Picture in Picture' })}
            customIcon="pip_on"
            onClick={handleAction}
          />
        )}
        {minified && guestTokenAvailable && !hideGuestLogin && (
          <MenuEntry
            action="guest_link"
            label={I18n.t('label:quick_join', { defaultValue: 'Invite guest' })}
            icon="person_add"
            onClick={handleAction}
            ariaControls="quick-join-dialog"
            ariaHasPopup="dialog"
          />
        )}
        <MenuEntry
          action="toggle_theme"
          label={
            theme === 'dark'
              ? I18n.t('label:light', { defaultValue: 'Light mode' })
              : I18n.t('label:dark', { defaultValue: 'Dark mode' })
          }
          icon={theme === 'dark' ? 'brightness_7' : 'brightness_4'}
          onClick={handleAction}
        />
        {options.layout_available && !hideLayoutSettings && (
          <MenuEntry
            action="layout_selection"
            label={I18n.t('label:layout', { defaultValue: 'Layout' })}
            icon="apps"
            onClick={handleAction}
            ariaControls="layout-selection-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {options.recording_available && !hideRecording && !disableRecording && (
          <MenuEntry
            action={activeRecording ? 'recording_stop' : 'recording'}
            label={I18n.t('label:recording', { defaultValue: 'Recording' })}
            customIcon="recording"
            onClick={handleAction}
            ariaControls="recording-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {options.broadcast_available && (
          <MenuEntry
            action="live_stream"
            label={I18n.t('label:live_stream', { defaultValue: 'Live stream' })}
            icon="settings_input_antenna"
            onClick={handleAction}
            ariaControls="live-stream-dialog"
            ariaHasPopup="dialog"
          />
        )}
        {supports.YoutubePopup && guestTokenAvailable && !isLocked && (
          <MenuEntry
            action="show_youtube_popup"
            icon={<YoutubeIcon />}
            label={I18n.t('popup:youtube:title', {
              defaultValue: 'YouTube player',
            })}
            onClick={handleAction}
          />
        )}
        <MenuEntry
          action="full_screen"
          icon={fullscreenActive ? 'fullscreen_exit' : 'fullscreen'}
          label={
            fullscreenActive
              ? I18n.t('label:full_screen_exit', {
                  defaultValue: 'Exit full screen',
                })
              : I18n.t('label:full_screen', { defaultValue: 'Full screen' })
          }
          onClick={handleAction}
        />
        <MenuEntry
          action="toggle_help"
          icon="help"
          label={I18n.t('label:help', { defaultValue: 'Help' })}
          onClick={handleAction}
          ariaControls="help-dialog"
          ariaHasPopup="dialog"
        />
        {feedback && (
          <MenuEntry
            action="feedback"
            icon="feedback"
            label={I18n.t('label:feedback', { defaultValue: 'Feedback' })}
            onClick={handleAction}
            ariaControls="feedback-dialog"
            ariaHasPopup="dialog"
          />
        )}
      </Menu>
    </div>
  );
};

ToolbarExtras.propTypes = {
  disableSettings: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isLocked: PropTypes.bool,
  isGuest: PropTypes.bool,
  guestTokenAvailable: PropTypes.bool,
  feedback: PropTypes.bool.isRequired,
  options: PropTypes.object,
  onEvent: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
};

ToolbarExtras.defaultProps = {
  disableSettings: false,
  feedback: false,
  options: {},
  isFullscreen: false,
  isLocked: false,
  guestTokenAvailable: false,
  isGuest: false,
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.theme === nextProps.theme &&
    prevProps.canPip === nextProps.canPip &&
    prevProps.isLocked === nextProps.isLocked &&
    prevProps.isGuest === nextProps.isGuest &&
    prevProps.minified === nextProps.minified &&
    prevProps.options === nextProps.options &&
    prevProps.disableSettings === nextProps.disableSettings &&
    prevProps.fullscreenActive === nextProps.fullscreenActive &&
    prevProps.activeRecording === nextProps.activeRecording
  );
}

export default React.memo(ToolbarExtras, areEqual);
