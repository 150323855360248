import React from 'react';
import PropTypes from 'prop-types';
import VoiceActivityIndicator from './VoiceActivityIndicator.js';

const FloatingNameInsert = ({
  visible,
  collapsed,
  participants,
  voiceActivity,
}) => {
  if (!visible) {
    return null;
  }

  const speaker =
    participants.find((p) => p.apiId === voiceActivity.user.apiId) ||
    participants[0];

  if (!speaker) {
    return null;
  }

  return (
    <div
      key={speaker.name}
      className={`name-insert float ${collapsed ? 'collapsed' : ''}`}
    >
      <VoiceActivityIndicator active={true} />
      <div className="name">{speaker.name}</div>
    </div>
  );
};

FloatingNameInsert.propTypes = {
  voiceActivity: PropTypes.object,
};

export default FloatingNameInsert;
