import Logger from './Logger.js';
import PodiumFacade from './PodiumFacade.js';

/**
 * SipMessageTransformer
 **/
class SigMessageTransformer {
  constructor(clientId) {
    this.clientId = clientId;
    this.stamp = this.stamp.bind(this);
    this.default = this.default.bind(this);
    this.process = this.process.bind(this);
    this.sourceUpdate = this.sourceUpdate.bind(this);
    this.voiceActivity = this.voiceActivity.bind(this);
  }

  /**
   * Put a stamp with _src on the message.
   **/
  stamp(message) {
    return Object.assign({ _src: 'sig' }, message);
  }

  process(message) {
    const transformation =
      {
        source_update: this.sourceUpdate,
        voice_activity: this.voiceActivity
      }[message.type] || this.default;

    return transformation(this.stamp(message));
  }

  default(message) {
    Logger.debug('SipMessageTransformer::default', message);
    return message;
  }

  sourceUpdate(message) {
    Logger.debug('SipMessageTransformer::sourceUpdate', message);

    const podium = new PodiumFacade(message, this.clientId);

    const transformed = {
      type: 'podium',
      solo: podium.isSolo,
      audio: podium.hasAudioPosition,
      video: podium.hasVideoPosition,
      media: podium.hasMedia,
      layers: podium.layers,
      sources: podium.sources,
      isSource: podium.isSource,
      presenter: podium.presenter,
      isPresenter: podium.isPresenter,
      hasPresenter: podium.hasPresenter,
      videoSources: podium.videoSources,
      userDimensions: podium.userDimensions,
      hasVideoSources: podium.hasVideoSources,
      hasDesktopSources: podium.hasDesktopSources,
      allUserDimensions: podium.allUserDimensions,
      hasMutedVideoPeers: podium.hasMutedVideoPeers
    };

    return transformed;
  }

  voiceActivity(message) {
    Logger.debug('SipMessageTransformer::voiceActivity', message);

    // we rename the type here so we can use 'voice_activity' for passing to the
    // ui
    const transformed = Object.assign({
      type: 'voice_activity_raw',
      on: message.on,
      userId: message.cid
    });

    return transformed;
  }
}

export default SigMessageTransformer;
