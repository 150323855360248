import React from 'react';
import Button from '@material-ui/core/Button';

const DownloadLink = ({ href, label = 'Download' }) => {
  return (
    <Button href={href} target="_blank" color="secondary" variant="contained">
      {label}
    </Button>
  );
};

export default DownloadLink;
