import I18n from 'i18n-js';
import React, { Fragment } from 'react';
import CopyInput from './../layout/CopyInput.js';
import DialogContentText from '@material-ui/core/DialogContentText';

const ShareDialog = ({ url }) => (
  <Fragment>
    <DialogContentText>{I18n.t('dialog:share:live')}</DialogContentText>
    <CopyInput value={url} />
  </Fragment>
);

export default ShareDialog;
