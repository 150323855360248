import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import BroadcastsFacade from '../../utils/BroadcastsFacade.js';

class UpdateBroadcasts extends BaseAction {
  process(state, props) {
    const broadcasts = new BroadcastsFacade(
      this.message.broadcasts,
      props.eyeson,
      state.liveStream
    );

    let dialog = '';
    if (broadcasts.dialogVisible) {
      dialog = 'live_stream';
    }
    if (state.visible.dialog === 'error_dialog') {
      dialog = 'error_dialog';
    }

    return {
      broadcasts: this.message.broadcasts,
      liveStream: update(state.liveStream, {
        phase: { $set: broadcasts.liveStreamPhase },
        user: { $set: broadcasts.broadcastUser },
      }),
      active: update(state.active, {
        live_stream: { $set: broadcasts.hasBroadcasts },
      }),
      visible: update(state.visible, {
        dialog: { $set: dialog },
      }),
    };
  }
}

class StopLiveStream extends BaseAction {
  process(state, props) {
    props.eyeson.send({ type: 'stop_broadcasts' });
    props.eyeson.send({ type: 'clear_front_layer' });
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
      liveStream: update(state.liveStream, {
        user: { $set: null },
      }),
    };
  }
}

class LiveStreamActivated extends BaseAction {
  add(array, element) {
    return array.includes(element) ? array : array.concat([element]);
  }

  process(state, props) {
    const { platform } = this.message;
    const activated = Object.assign({}, state.liveStream.activated);
    activated[platform] = true;

    return {
      liveStream: update(state.liveStream, {
        user: { $set: props.eyeson.user.id },
        activated: { $set: activated },
      }),
    };
  }
}

class LiveStreamCancelled extends BaseAction {
  process(state, props) {
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
      liveStream: update(state.liveStream, {
        user: { $set: null },
      }),
    };
  }
}

class HandleStartBroadcastError extends BaseAction {
  process(state, props) {
    props.enqueueSnackbar(
      I18n.t('message:broadcast_error:' + this.message.platform),
      {
        variant: 'error',
      }
    );

    state.broadcastHelper.deactivate(this.message.platform);

    return {
      liveStream: update(state.liveStream, {
        user: { $set: null },
        phase: { $set: 'initial' },
        activated: { $set: {} },
      }),
    };
  }
}

export {
  UpdateBroadcasts,
  StopLiveStream,
  LiveStreamActivated,
  LiveStreamCancelled,
  HandleStartBroadcastError,
};
