import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import classnames from 'classnames';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    height: '100%',
  },
};

const ExpansionPanelContainer = ({
  classes,
  children,
  className,
  ...props
}) => {
  return (
    <Box className={classnames(classes.root, className)} {...props}>
      {children}
    </Box>
  );
};

export default withStyles(styles)(ExpansionPanelContainer);
