import I18n from 'i18n-js';
import UserPool from './UserPool.js';
import DialogActions from '@material-ui/core/DialogActions';
import PodiumPreview from './PodiumPreview.js';
import DragAndDropHint from './DragAndDropHint.js';
import { FeatureDetector } from 'eyeson';
import React, { useState, useEffect, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import Button from '../../generic/Button.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import defaultAvatarUrl from './../../../assets/default-avatar.png';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

let DndBackend = HTML5Backend;
let dndOptions = null;
if (FeatureDetector.isTouchDevice()) {
  DndBackend = TouchBackend;
  dndOptions = { enableMouseEvents: true };
}

const useStyles = makeStyles((theme) => ({
  preview: {
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'column',
  },
  radioGroupContainer: {
    display: 'flex',
  },
  radioGroup: {
    display: 'block',
  },
  radioGroupLabel: {
    padding: '11px 0',
    flexShrink: '0',
    marginRight: theme.spacing(2),
  },
}));

const layoutLookup = {
  auto: { spots: 0, passive: 0, name: 'auto', group: 'normal' },
  one: {
    spots: 1,
    passive: 0,
    name: 'one',
    fitContent: {
      zoom: 'one',
      contain: 'aspect-fit',
    },
    group: 'normal',
  },
  two: { spots: 2, passive: 0, name: 'two', group: 'normal' },
  four: { spots: 4, passive: 0, name: 'four', group: 'normal' },
  nine: { spots: 9, passive: 0, name: 'nine', group: 'normal' },
  one_plus_six: {
    spots: 1,
    passive: 6,
    name: 'present-upper-6',
    fitContent: {
      zoom: 'present-upper-6',
      contain: 'present-upper-6-aspect-fit',
    },
    group: 'speaker',
  },
  two_plus_six: {
    spots: 2,
    passive: 6,
    name: 'present-two-upper-6',
    fitContent: {
      zoom: 'present-two-upper-6',
      contain: 'present-two-upper-6-aspect-fit',
    },
    group: 'speaker',
  },
  widescreen_one_plus_six: {
    spots: 1,
    passive: 6,
    name: 'present-one-6',
    fitContent: {
      zoom: 'present-one-6',
      contain: 'present-one-6-aspect-fit',
    },
    group: 'speaker',
  },
};

const getFitContentType = (layout, activeLayout) => {
  let type = 'zoom';
  if (Boolean(layout.fitContent) === false) {
    return type;
  }
  for (let key in layout.fitContent) {
    if (layout.fitContent[key] === activeLayout) {
      type = key;
    }
  }
  return type;
};

const LayoutDialog = ({
  type,
  users = [
    { id: 1, name: 'Bob Ross', avatar: defaultAvatarUrl },
    { id: 2, name: 'Markus Prinz', avatar: defaultAvatarUrl },
    { id: 3, name: 'Philipp Weißensteiner', avatar: defaultAvatarUrl },
    { id: 4, name: 'Michael Wolfgang', avatar: defaultAvatarUrl },
  ],
  propPodium = [],
  layoutType,
  layoutName = 'auto',
  voiceActivation,
  onEvent,
  onClose = () => onEvent({ type: 'hide_dialog' }),
  onBack = () => {
    onClose();
    onEvent({ type: 'layout_selection' });
  },
}) => {
  const classes = useStyles();
  const groupRef = useRef();

  // Pool is users "minus" whoever is on the podium
  const buildPool = (podium) => users.filter((u) => podium.indexOf(u) === -1);
  const isEmpty = (podium) => podium.every((position) => position === null);
  const isFilled = (podium) => podium.every((position) => position !== null);

  const buildCurrentPodium = (typeAsInt) => {
    let currentPodium = new Array(typeAsInt).fill(null);
    if (!propPodium) return currentPodium;

    for (let i = 0; i < currentPodium.length; i++) {
      currentPodium[i] = users.find((u) => u.apiId === propPodium[i]) || null;
    }
    return currentPodium;
  };

  const initialPodium = buildCurrentPodium(layoutLookup[type].spots);
  const isSpeakerLayout = layoutLookup[type].group === 'speaker';
  const hasFitContentOption = Boolean(layoutLookup[type].fitContent);

  const [state, setState] = useState({
    pool: buildPool(initialPodium),
    podium: initialPodium,
  });
  const [useVAD, setUseVAD] = useState(isSpeakerLayout ? true : false);
  const [useVADBackup, setUseVADBackup] = useState(true);
  const [autoFill, setAutoFill] = useState(
    isSpeakerLayout ? true : type === 'auto'
  );
  const [fitContent, setFitContent] = useState(
    getFitContentType(layoutLookup[type], layoutName)
  );

  // Build the podium array according to the users's selection
  const buildPodiumPreview = (user, position) => {
    let podium = state.podium.slice();
    if (podium.indexOf(user) !== -1) {
      podium[podium.indexOf(user)] = null;
    }
    if (position >= 0) {
      podium[position] = user;
    }
    return podium;
  };

  const handleDrop = (user, position) => {
    const podium = buildPodiumPreview(user, position);
    setState({
      pool: buildPool(podium),
      podium: podium,
    });
  };

  const handleDoubleClick = (user) => {
    const emptySlot = state.podium.indexOf(null);
    // if no empty slot, default to first position.
    const position = emptySlot === -1 ? 0 : emptySlot;
    handleDrop(user, position);
  };

  // Build a params object in the expected api format:
  // [ user1@id.com, '', user2@id.com ]
  // NOTE: `layoutType` supplied for client logger information
  // `layout` would not work with api.
  const handlePrimaryClick = () => {
    const params = { layout: 'auto' };
    if (type !== 'auto') {
      params.layout = autoFill ? 'auto' : 'custom';
      params.name = layoutLookup[type].name;
      if (hasFitContentOption) {
        params.name = layoutLookup[type].fitContent[fitContent];
      }
      params.users = state.podium.map((user) => (user && user.apiId) || '');
      if (layoutLookup[type].passive > 0) {
        params.users.length += layoutLookup[type].passive;
        params.users.fill('', state.podium.length);
      }
    }
    if (autoFill) {
      params.voice_activation = useVAD;
    }
    onEvent({ type: 'set_layout', params });
  };

  useEffect(() => {
    if (groupRef.current) {
      groupRef.current.focus();
    }
  }, [state.podium]);

  useEffect(() => {
    if (isSpeakerLayout) return;
    const autoFill =
      type === 'auto' || (layoutName !== 'auto' && layoutType === 'auto');
    const vad = !autoFill ? false : voiceActivation;
    setAutoFill(autoFill);
    setUseVAD(vad);
    setUseVADBackup(!autoFill ? true : vad);
  }, [type, layoutType, voiceActivation, layoutName, isSpeakerLayout]);

  const toggleAutofill = (event) => {
    const enabled = event.target.checked;
    setAutoFill(enabled);
    if (!enabled) {
      setUseVADBackup(useVAD);
      setUseVAD(false);
    } else {
      setUseVAD(useVADBackup);
    }
  };

  const toggleVAD = (event) => {
    setUseVAD(event.target.checked);
  };

  const suffix = FeatureDetector.isTouchDevice() ? '_touch' : '';
  const disableApply = (() => {
    if (isSpeakerLayout) {
      return !isFilled(state.podium);
    }
    return type !== 'auto' && isEmpty(state.podium) && !autoFill;
  })();

  return (
    <DndProvider backend={DndBackend} options={dndOptions}>
      <DialogContentText tabIndex={0}>
        {I18n.t(`layout_description_${type}${suffix}`)}
      </DialogContentText>
      {type !== 'auto' && (
        <>
          <div
            className={classes.preview}
            tabIndex={0}
            role="group"
            ref={groupRef}
          >
            <PodiumPreview
              type={type}
              onDrop={handleDrop}
              podium={state.podium}
              onDoubleClick={(user) => handleDrop(user, -1)}
              onKeyDown={(user) => handleDrop(user, -1)}
            />
            <DragAndDropHint />
            <UserPool
              type={type}
              pool={state.pool}
              onDrop={handleDrop}
              onDoubleClick={handleDoubleClick}
              onKeyDown={handleDoubleClick}
            />
          </div>
          {hasFitContentOption && (
            <div className={classes.radioGroupContainer}>
              <Typography
                variant="body2"
                component="div"
                className={classes.radioGroupLabel}
              >
                {I18n.t('label:fitcontent:description')}
              </Typography>
              <RadioGroup
                aria-label={I18n.t('aria:label:fitcontent')}
                name="fitContent"
                value={fitContent}
                onChange={({ target }) => setFitContent(target.value)}
                className={classes.radioGroup}
              >
                {Object.keys(layoutLookup[type].fitContent).map((key) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={
                      <Typography variant="body2">
                        {I18n.t('label:fitcontent:' + key)}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </div>
          )}
          <div>
            <label htmlFor="dialog-layout-fill-switch">
              {I18n.t('label:layout:autofill')}
            </label>
            <Switch
              id="dialog-layout-fill-switch"
              disabled={isSpeakerLayout}
              checked={autoFill}
              onChange={toggleAutofill}
            />
          </div>
        </>
      )}
      <div>
        <label htmlFor="dialog-layout-vad-switch">
          {I18n.t('label:layout:arrange_by_voice')}
        </label>
        <Switch
          id="dialog-layout-vad-switch"
          checked={useVAD}
          disabled={isSpeakerLayout || !autoFill}
          onChange={toggleVAD}
        />
      </div>
      <DialogActions>
        <Button
          onClick={onBack}
          type="secondary"
          style={{ marginRight: 'auto' }}
        >
          {I18n.t('label:back')}
        </Button>
        <Button onClick={onClose} type="secondary">
          {I18n.t('label:close')}
        </Button>
        <Button onClick={handlePrimaryClick} disabled={disableApply}>
          {I18n.t('btn:apply')}
        </Button>
      </DialogActions>
    </DndProvider>
  );
};

/**
 * Sadly, 'react-dnd' doesn't support touch events with the HTML5 backend. The
 * 'react-dnd-touch-backend' doesn't really support click events:
 *
 * > NOTE: This is buggy due to the difference in touchstart/touchend...
 * https://github.com/yahoo/react-dnd-touch-backend
 *
 * Therefore, something like this is the "recommended" solution. May the gods
 * forgive us.
 **/
export default LayoutDialog;
