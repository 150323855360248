import immediate from './immediate.js';

const throttledAnimationFrame = (fn, fps) => {
  let raf = null;
  let focus = true;
  let abort = false;
  let animationFrameThen = 0;
  const frameRate = 1000 / fps;
  const throttledRAF = () => {
    const now = Date.now();
    const elapsed = now - animationFrameThen;
    if (elapsed <= frameRate) {
      raf = requestAnimationFrame(throttledRAF);
      return;
    }
    animationFrameThen = now - (elapsed % frameRate);
    fn();
  };
  const throttledImmediate = () => {
    const now = Date.now();
    const elapsed = now - animationFrameThen;
    if (elapsed <= frameRate) {
      immediate(throttledImmediate);
      return;
    }
    animationFrameThen = now - (elapsed % frameRate);
    if (!abort) {
      fn();
    }
  };
  const result = {
    requestAnimationFrame: () => {
      if (focus) {
        raf = requestAnimationFrame(throttledRAF);
      } else {
        if (abort) {
          abort = false;
        }
        immediate(throttledImmediate);
      }
    },
    cancelAnimationFrame: () => {
      abort = true;
      if (raf) {
        cancelAnimationFrame(raf);
        raf = null;
      }
    }
  };
  document.addEventListener('visibilitychange', () => {
    focus = document.visibilityState === 'visible';
    if (!focus && raf) {
      result.cancelAnimationFrame();
      result.requestAnimationFrame();
    }
  });
  return result;
};

export default throttledAnimationFrame;
