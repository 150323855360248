import Logger from './Logger.js';
import ConnectionStatistics from './utils/ConnectionStatistics.js';
/**
 * Connection Monitor informs about on and offline and ice state changes.
 * I.e. when the connection to the conf server (from a webRTC point of view)
 * is breaking up or degrading.
 **/
class ConnectionMonitor {
  constructor() {
    this.pc = null;
    this.statistics = new ConnectionStatistics();
    this.listeners = [];
    this.disconnectTimer = null;
    this.prevIceState = 'initial';
    this.onOffline = this.onOffline.bind(this);
    this.handleIceStateChange = this.handleIceStateChange.bind(this);
    this.handleConnectionStateChange =
      this.handleConnectionStateChange.bind(this);
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener('offline', this.onOffline);
  }

  onOffline() {
    this.emit({ type: 'offline' });
  }

  observe(peerConnection) {
    if (this.pc) {
      return;
    }
    this.pc = peerConnection;
    this.pc.oniceconnectionstatechange = this.handleIceStateChange;
    this.pc.onconnectionstatechange = this.handleConnectionStateChange;
    this.statistics.init(peerConnection);
    this.emit({ type: 'statistics_ready', statistics: this.statistics });
  }

  destroy() {
    const { pc, statistics } = this;
    clearTimeout(this.disconnectTimer);
    if (statistics) {
      statistics.destroy();
      this.statistics = null;
    }
    if (pc) {
      pc.onconnectionstatechange = null;
      pc.oniceconnectionstatechange = null;
      this.pc = null;
    }
    window.removeEventListener('offline', this.onOffline);
  }

  onEvent(listener) {
    this.listeners.push(listener);
  }

  emit(msg) {
    this.listeners.forEach(listener => listener(msg));
  }

  handleConnectionStateChange(event) {
    if (event.currentTarget) {
      Logger.warn(
        'connectionStateChange: ',
        event.currentTarget.connectionState
      );
    }
  }

  /* eslint-disable max-statements */
  handleIceStateChange(event) {
    // event doesn't have a current target in EDGE
    if (!event.currentTarget) {
      return;
    }

    const { iceConnectionState: currentIceState } = event.currentTarget;

    if (currentIceState === 'disconnected') {
      this.emit({ type: 'warning', name: 'ice_disconnected' });
      // As of Chrome 76 failed is no longer emitted
      // https://bugs.chromium.org/p/chromium/issues/detail?id=982793&q=ice%20failed&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Component%20Status%20Owner%20Summary%20OS%20Modified
      //
      // We use a 2 seconds delay to re-check the connection.
      clearTimeout(this.disconnectTimer);
      this.disconnectTimer = window.setTimeout(() => {
        if (this.pc && this.pc.iceConnectionState !== 'connected') {
          this.emit({ type: 'error', name: 'ice_failed', code: 'Goldfinger' });
        }
      }, 2000);
    }

    if (currentIceState === 'failed') {
      this.emit({ type: 'error', name: 'ice_failed', code: 'Thunderball' });
    }

    if (
      this.prevIceState === 'disconnected' &&
      ['completed', 'connected'].includes(currentIceState)
    ) {
      this.emit({ type: 'clear_warning', name: 'ice_disconnected' });
    }

    this.prevIceState = currentIceState;
  }
  /* eslint-enable max-statements */
}

export default ConnectionMonitor;
