import Logger from './Logger.js';
import { stopStream, stopTrack } from './utils/StreamHelpers.js';

class MicMixer {
  constructor() {
    this.active = false;
    this.audioContext = null;
    this.acOut = null;
    this.micOnlyStream = null;
    this.originalStream = null;
  }

  setMicOnlyStream(audioStream, videoStream) {
    this.micOnlyStream = new MediaStream();
    const audioTracks = audioStream.getAudioTracks();
    const videoTracks = videoStream.getVideoTracks();
    if (audioTracks.length > 0) {
      this.micOnlyStream.addTrack(audioTracks[0]);
    }
    if (videoTracks.length > 0) {
      this.micOnlyStream.addTrack(videoTracks[0]);
    }
    return this.micOnlyStream;
  }

  getMicOnlyStream() {
    return this.micOnlyStream;
  }

  getOriginalStream() {
    return this.originalStream;
  }

  stopOriginalStream() {
    stopStream(this.originalStream);
  }

  noMix(userMediaStream, outStream) {
    const audioTracks = userMediaStream.getAudioTracks();
    if (audioTracks.length > 0) {
      outStream.addTrack(audioTracks[0]);
    }
  }

  initMixScreenshareMix(userMediaStream, displayStream, outStream) {
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    this.acOut = this.audioContext.createMediaStreamDestination();
    this.audioContext
      .createMediaStreamSource(displayStream)
      .connect(this.acOut);
    this.audioContext
      .createMediaStreamSource(userMediaStream)
      .connect(this.acOut);
    outStream.addTrack(this.acOut.stream.getAudioTracks()[0]);
  }

  mixScreenshareAudio(userMediaStream, displayStream, outStream) {
    this.originalStream = displayStream;
    const displayAudio = displayStream.getAudioTracks();
    if (displayAudio.length === 0) {
      this.noMix(userMediaStream, outStream);
    } else {
      try {
        this.initMixScreenshareMix(userMediaStream, displayStream, outStream);
        this.active = true;
      } catch (exc) {
        Logger.error('MicMixer::mixScreenshareAudio', exc);
        stopTrack(displayAudio[0]);
        this.noMix(userMediaStream, outStream);
      }
    }
  }

  destroy() {
    this.stopOriginalStream();
    this.micOnlyStream = null;
    this.originalStream = null;
    if (this.audioContext) {
      stopStream(this.acOut.stream);
      this.audioContext.close().then(() => {
        this.audioContext = null;
        this.acOut = null;
        this.active = false;
      });
      return;
    }
    this.active = false;
  }
}

export default MicMixer;
