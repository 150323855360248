import { Logger } from 'eyeson';

export default class ActivityMonitor {
  constructor(minutesToWarning) {
    this.listeners = [];
    this.debugActive = false;
    this.warningTimeout = null;
    this.minutesToWarning = minutesToWarning || 15;
  }

  start() {
    this.debug('ActivityMonitor:: minutesToWarning:', this.minutesToWarning);
    this.warningTimeout = setTimeout(() => {
      Logger.warn('ActivityMonitor::inactive');
      this.emit({ type: 'inactive' });
      this.stop();
    }, this.minutesToWarning * 1000 * 60);
  }

  stop() {
    this.debug('ActivityMonitor::stop');
    clearTimeout(this.warningTimeout);
    this.warningTimeout = null;
  }

  reset() {
    this.debug('ActivityMonitor::reset');
    this.stop();
    this.start();
  }

  debug() {
    if (this.debugActive) {
      Logger.debug(...arguments);
    }
  }

  onInactivity(callback) {
    this.listeners.push(callback);
  }

  emit(msg) {
    this.listeners.forEach((listener) => listener(msg));
  }
}
