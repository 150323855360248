import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import classnames from 'classnames';
import { withStyles } from '@material-ui/styles';

// Handle full-height collapse containers.
const StyledCollapse = withStyles((theme) => ({
  hidden: {
    display: 'none',
  },
  entered: {
    boxShadow: theme.shadows[1],
    height: '100% !important',
  },
  wrapper: { height: '100%' },
  wrapperInner: {
    height: '100%',
    background:
      theme.palette.type === 'light' ? theme.palette.grey[50] : '#191919',
  },
}))(Collapse);

const ExpansionPanel = ({
  id,
  open,
  icon,
  headerTitle,
  children,
  onClick,
  disabled,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClick(event);
    }
  };

  if (disabled) {
    return null;
  }

  return (
    <section
      className={classnames('eyeson-expansion-panel', {
        'eyeson-expansion-panel--open': open,
      })}
    >
      <Paper
        id={id + '-header'}
        elevation={2}
        className="eyeson-expansion-panel__header"
        onClick={onClick}
        onKeyPress={handleKeyPress}
        tabIndex="0"
        role="button"
        aria-label={headerTitle}
        aria-expanded={open}
        aria-controls={id}
      >
        <Box className="eyeson-expansion-panel__icon">{icon}</Box>
        <h2 className="eyeson-expansion-panel__title">{headerTitle}</h2>
        <Icon
          color="action"
          className={classnames('animation-rotate', {
            'animation-rotate--180': open,
          })}
        >
          keyboard_arrow_down
        </Icon>
      </Paper>
      <StyledCollapse
        id={id}
        className="eyeson-expansion-panel__content"
        in={open}
        timeout={0}
        role="region"
        aria-labelledby={id + '-header'}
      >
        {children}
      </StyledCollapse>
    </section>
  );
};

ExpansionPanel.propTypes = {
  open: PropTypes.bool,
  icon: PropTypes.element,
  headerTitle: PropTypes.string,
  children: PropTypes.element,
  onClick: PropTypes.func,
};

export default ExpansionPanel;
