import React, { Component } from 'react';
import { Geolocation } from 'eyeson';
import Avatar from '../../../generic/Avatar.js';
import dateHelper from '../../../../utils/dateHelper.js';
import Linkify from 'react-linkify';
import LinkifyDecorator from './LinkifyDecorator.js';
import GeolocationHelper from '../../../../utils/GeolocationHelper.js';
import { Box } from '@material-ui/core';

class LocationMessage extends Component {
  static test(props) {
    return Geolocation.linkContainsCoordinates(props.content);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { user, me, skipHead, timestamp, content } = this.props;
    const isMeMessage = user.clientId === me.clientId;
    const { latitude, longitude } =
      Geolocation.parseCoordinatesFromLink(content);
    const id = Math.random().toString(36).substring(2, 12);
    GeolocationHelper.addMap(id, latitude, longitude, 13);

    return (
      <div
        className={`eyeson-chat-message ${isMeMessage ? 'me' : ''} ${
          skipHead ? 'chat-message__followup' : ''
        }`}
      >
        {!isMeMessage &&
          (skipHead ? (
            <span className="eyeson-avatar eyeson-avatar__placeholder"></span>
          ) : (
            <Avatar url={user.avatar} label={user.name} />
          ))}
        <div className="content">
          {!skipHead && (
            <span className="details">
              <strong className="username">{user.name}</strong>
              <span className="date">{dateHelper.formatTime(timestamp)}</span>
            </span>
          )}
          <Box marginBottom={2}>
            <Linkify className="body" componentDecorator={LinkifyDecorator}>
              {content}
            </Linkify>
          </Box>
          <div
            className="location-map"
            id={id}
            style={{ height: '10rem' }}
          ></div>
        </div>
        {isMeMessage &&
          (skipHead ? (
            <span className="eyeson-avatar eyeson-avatar__placeholder"></span>
          ) : (
            <Avatar url={user.avatar} label={user.name} />
          ))}
      </div>
    );
  }
}

export default LocationMessage;
