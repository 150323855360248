import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';

/**
 * VoiceActivityEvent
 **/
class VoiceActivityEvent extends BaseEvent {
  constructor(context) {
    super(context);
    this.getUser = this.getUser.bind(this);
  }

  getUser(id) {
    return new Promise(resolve => {
      this.context._api.getUser(id, resolve);
    });
  }

  handle(msg) {
    this.getUser(msg.userId).then(
      user => {
        this.context.send({ type: 'voice_activity', user: user, on: msg.on });
      },
      error => {
        Logger.error('VoiceActivityEvent::handle', error);
      }
    );
  }
}

export default VoiceActivityEvent;
