import ImagePresentation from './Image.js';
import MediaPresentation from './Media.js';
import Screen from './Screen.js';
import Document from './Document.js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const IMAGE_TYPE = 'image/';
const DOCUMENT_TYPE = 'application/pdf';

class Presentation extends Component {
  start = () => {
    this.props.onEvent({ type: 'set_presenting', state: true });
  };
  stop = () => {
    this.props.onEvent({ type: 'set_presenting', state: false });
  };

  componentDidMount() {
    this.stop();
  }

  render() {
    const { mediaPresentation: media, canvasPresentation: presentation } =
      this.props;

    if (media.file !== null) {
      return (
        <MediaPresentation
          {...this.props}
          file={media.file}
          onStop={this.stop}
          onStart={this.start}
        />
      );
    }

    if (presentation.files.length === 0) {
      return (
        <Screen
          {...this.props}
          screen={true}
          onStop={this.stop}
          onStart={this.start}
        />
      );
    }

    if (presentation.files.some((file) => file.type === DOCUMENT_TYPE)) {
      const file = presentation.files.find(
        (file) => file.type === DOCUMENT_TYPE
      );
      return (
        <Document
          {...this.props}
          file={file}
          onStop={this.stop}
          onStart={this.start}
        />
      );
    }

    if (presentation.files.some((file) => file.type.startsWith(IMAGE_TYPE))) {
      const files = presentation.files.filter((file) =>
        file.type.startsWith(IMAGE_TYPE)
      );
      const [file] = files;
      return (
        <ImagePresentation
          {...this.props}
          file={file}
          onStop={this.stop}
          onStart={this.start}
          presentationFiles={files}
        />
      );
    }

    return null;
  }
}

Presentation.propTypes = {
  presentation: PropTypes.object,
};

export default Presentation;
