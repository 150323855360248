import I18n from 'i18n-js';
import React, { useState } from 'react';
import { FeatureDetector } from 'eyeson';
import NavigationTools from './toolbar/NavigationTools.js';
import AnnotationTools from './toolbar/AnnotationTools.js';
import ImageThumbnails from './ImageThumbnails.js';
import DocumentThumbnails from './DocumentThumbnails.js';
import PresentationActions from './toolbar/PresentationActions.js';
import CameraOverlayActions from './toolbar/CameraOverlayActions.js';
import ArrowUp from '@material-ui/icons/KeyboardArrowUp';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
    height: (collapsed) => (collapsed ? '3.5rem' : '12rem'),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const canDrawCamera = FeatureDetector.canDrawCamera();

const PresentationToolbar = ({
  pdf,
  file,
  page,
  panel = true,
  onEvent,
  numPages,
  allFiles,
  collapsed,
  onCollapse,
  pageChange,
  stopStream,
  startStream,
  markerColor,
  markerActive,
  onColorClick,
  onMarkerClick,
  onEraserClick,
  onRotationClick,
  onUndoClick,
  onRedoClick,
  undoActive,
  redoActive,
  rotationActive,
  presentationStream,
  active: {
    hasPresenter,
    isPresenter,
    screen_capture,
    video: hasCamera,
    isPresenting,
  },
}) => {
  const hasFile = Object.keys(file).length > 0;
  const classes = useStyles(collapsed);
  // NOTE: A bit of "extra" state we keep track of, because tooltip would
  // otherwise keep showing in mid-air when collapsing.
  const [tooltip, setTooltip] = useState(false);
  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <section
      className={classes.root}
      role="region"
      aria-label={I18n.t('aria:label:presentation_toolbar', {
        defaultValue: 'Presentation toolbar',
      })}
    >
      <div className={classes.row}>
        <NavigationTools
          page={page}
          numPages={numPages}
          pageFlip={(kind) => pageChange({ type: 'flip', details: kind })}
          available={hasFile}
          handleChange={(event) =>
            pageChange({ type: 'input', details: event })
          }
        />
        {/* In FF it can take a while until we get the presentationStream,
        since the gum dialog is asking user every time. So we don't want
        the user to be able to startStream with their current, regular
        stream  */}
        <PresentationActions
          disabled={(hasPresenter && !isPresenter) || !presentationStream}
          started={isPresenting || screen_capture}
          stopStream={stopStream}
          startStream={startStream}
          inSelfPreview={!(hasPresenter && !isPresenter) && !presentationStream}
        />
        <div className={classes.row}>
          {canDrawCamera && (
            <CameraOverlayActions disabled={!hasCamera} onEvent={onEvent} />
          )}
          <AnnotationTools
            available={hasFile}
            markerColor={markerColor}
            markerActive={markerActive}
            markerDisabled={!isPresenting}
            eraserDisabled={!isPresenting}
            rotationAvailable={!pdf}
            onColorClick={onColorClick}
            onMarkerClick={onMarkerClick}
            onEraserClick={onEraserClick}
            onRotationClick={onRotationClick}
            onUndoClick={onUndoClick}
            onRedoClick={onRedoClick}
            undoActive={undoActive}
            redoActive={redoActive}
            rotationActive={rotationActive}
          />
          <Tooltip
            open={tooltip}
            onOpen={() => setTooltip(true)}
            onClose={() => setTooltip(false)}
            title={
              collapsed ? I18n.t('label:expand') : I18n.t('label:collapse')
            }
            placement="top"
          >
            <IconButton
              onClick={() => {
                setTooltip(false);
                onCollapse();
              }}
              aria-label={
                collapsed
                  ? I18n.t('aria:label:thumbnails:expand', {
                      defaultValue: 'Expand thumbnails',
                    })
                  : I18n.t('aria:label:thumbnails:collapse', {
                      defaultValue: 'Collapse thumbnails',
                    })
              }
            >
              {collapsed ? <ArrowUp /> : <ArrowDown />}
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <Thumbnails
        pdf={pdf}
        file={file}
        page={page}
        allFiles={allFiles}
        numPages={numPages}
        expanded={!collapsed}
        pageChange={pageChange}
        borderOffset={panel ? '32rem' : '14rem'}
      />
    </section>
  );
};

const Thumbnails = ({
  pdf,
  file,
  page,
  allFiles,
  numPages,
  expanded,
  pageChange,
  borderOffset,
}) => {
  return (
    <>
      {pdf ? (
        <DocumentThumbnails
          pdf={pdf}
          file={file}
          page={page}
          numPages={numPages}
          expanded={expanded}
          borderOffset={borderOffset}
          onThumbnailClick={(page) =>
            pageChange({ type: 'thumbnail', details: page })
          }
        />
      ) : (
        <ImageThumbnails
          file={file}
          files={allFiles}
          expanded={expanded}
          borderOffset={borderOffset}
          onThumbnailClick={(page) =>
            pageChange({ type: 'thumbnail', details: page })
          }
        />
      )}
    </>
  );
};

export default PresentationToolbar;
