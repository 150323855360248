/**
 * Podium Facade.
 **/
class PodiumFacade {
  constructor(message, clientId) {
    const { src = [], psrc, asrc, vsrc, dims } = message;
    this.clientId = clientId;
    this.sources = src;
    this.presenterIdx = psrc;
    this.audioSources = asrc;
    this.videoSources = vsrc;
    this.dimensions = dims;
    this.userSourceIndex = src.indexOf(clientId);
    this.layers = this.parseLayer(message);
  }

  get isSolo() {
    const uniqueSources = Array.from(new Set(this.sources));
    return uniqueSources.length === 1 && this.sources[0] === this.clientId;
  }

  get isSource() {
    return this.sources.includes(this.clientId);
  }

  get isPresenter() {
    return this.hasPresenter && this.presenter === this.clientId;
  }

  get presenter() {
    return this.hasPresenter && this.sources[this.presenterIdx];
  }

  get hasMedia() {
    return this.sources.some(source => source.startsWith('media-'));
  }

  get hasPresenter() {
    return Number.isInteger(this.presenterIdx) && this.presenterIdx > -1;
  }

  get hasVideoSources() {
    return Boolean(this.videoSources);
  }

  get hasAudioPosition() {
    return this.audioSources.includes(this.userSourceIndex);
  }

  get hasVideoPosition() {
    return this.videoSources.includes(this.userSourceIndex);
  }

  get hasMutedVideoPeers() {
    return (
      !this.hasPresenter &&
      this.videoSources.filter(source => source !== this.userSourceIndex)
        .length === 0
    );
  }

  get userDimensions() {
    const userVideoIndex = this.videoSources.indexOf(this.userSourceIndex);
    if (userVideoIndex > -1) {
      return this.dimensions[userVideoIndex];
    }
    return null;
  }

  // eslint-disable-next-line max-statements
  get allUserDimensions() {
    const { dimensions, videoSources, sources } = this;
    const { length } = dimensions;
    const dimsCopy = new Array(length);
    for (let index = 0; index < length; index++) {
      const place = {};
      const userIndex = videoSources[index];
      const sourceId = userIndex > -1 ? sources[userIndex] : '';
      const isMedia = sourceId.startsWith('media-');
      place.clientId = isMedia ? '' : sourceId;
      place.playId = isMedia ? sourceId.substring(6) : '';
      dimsCopy[index] = Object.assign(place, dimensions[index]);
    }
    return dimsCopy;
  }

  parseLayer(data) {
    const layers = [];
    if (Reflect.has(data, 'fg_img_id')) {
      layers.push({ zIndex: 1, id: data.fg_img_id });
    }
    if (Reflect.has(data, 'bg_img_id')) {
      layers.push({ zIndex: -1, id: data.bg_img_id });
    }
    return layers;
  }
}

export default PodiumFacade;
