import React, { Component } from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import LinkifyDecorator from './LinkifyDecorator.js';

class FollowUpMessage extends Component {
  /**
   * Message is a follow-up if the header should be skipped.
   **/
  static test(props) {
    return !!props.skipHead;
  }

  /**
   * Render a message but do not display details like user or date.
   **/
  render() {
    const isMeMessage = this.props.user.clientId === this.props.me.clientId;

    return (
      <div
        className={
          isMeMessage
            ? 'eyeson-chat-message chat-message__followup me'
            : 'eyeson-chat-message chat-message__followup'
        }
      >
        {!isMeMessage && (
          <span className="eyeson-avatar eyeson-avatar__placeholder"></span>
        )}
        <div className="content">
          <Linkify className="body" componentDecorator={LinkifyDecorator}>
            {this.props.content}
          </Linkify>
        </div>
        {isMeMessage && (
          <span className="eyeson-avatar eyeson-avatar__placeholder"></span>
        )}
      </div>
    );
  }
}

FollowUpMessage.propTypes = {
  me: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
};

export default FollowUpMessage;
