/**
 * Ninja Stream
 **/
class NinjaStream {
  constructor() {
    this._stream = this.createStream();
  }

  get stream() {
    return this._stream;
  }

  /**
   * Create a dummy stream from a canvas element.
   **/
  // eslint-disable-next-line max-statements
  createStream() {
    const width = 0;
    const height = 0;
    let canvas = Object.assign(document.createElement('canvas'), {
      width,
      height
    });
    canvas.id = 'eyeson-ninja-stream';
    canvas.getContext('2d').fillRect(0, 0, width, height);
    let stream = canvas.captureStream();
    let [track] = stream.getVideoTracks();
    // adjust FF to identify NinjaStream track
    if (
      window.CanvasCaptureMediaStream &&
      stream instanceof window.CanvasCaptureMediaStream
    ) {
      track.type = 'canvas-track';
      track.canvas = canvas;
    }
    return stream;
  }
}

export default NinjaStream;
