// https://stackoverflow.com/a/30810322
const copyText = (text) => {
  let status = false;
  const textArea = document.createElement('textarea');
  Object.assign(textArea.style, {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '2em',
    height: '2em',
    padding: 0,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    background: 'transparent',
  });

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    status = document.execCommand('copy');
  } catch (err) {}

  document.body.removeChild(textArea);
  return status;
};

export { copyText };
