import BaseEvent from './BaseEvent.js';

class PublicEvent extends BaseEvent {
  handle(msg) {
    if (msg._src === 'client') {
      return;
    }

    Reflect.deleteProperty(msg, '_src');
    this.context.listeners.forEach(callback => callback(msg));
  }
}

export default PublicEvent;
