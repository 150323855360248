import React from 'react';
import PropTypes from 'prop-types';
import ParticipantList from './ParticipantList.js';
import ParticipantActions from './ParticipantActions.js';

const Participants = (props) => [
  <ParticipantList key="list" onEvent={props.onEvent} {...props} />,
  <ParticipantActions
    key="action"
    onEvent={props.onEvent}
    quickJoin={props.quickJoin}
    isLocked={props.isLocked}
    hideGuestLogin={props.hideGuestLogin}
  />,
];

Participants.propTypes = {
  podium: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  onEvent: PropTypes.func.isRequired,
};

export default Participants;
