import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ScrollContainer from '../../generic/ScrollContainer.js';
import ParticipantEntry from './ParticipantEntry.js';

const ParticipantList = ({
  podium,
  participants,
  order,
  voiceActivity,
  isGuest,
  me,
  isSFU,
  onEvent,
  options,
}) => {
  /**
   * If no sort order is given, the participants will be ordered by their id.
   * Otherwise the sort order is parsed and the users are shown as they appear
   * in the order or afterwards if the sort-order does not contain their id.
   **/
  const sort = (a, b) => {
    if (!order || order.length === 0) {
      return a.id < b.id ? -1 : 1;
    }
    const orderA = order.indexOf(a.id);
    const orderB = order.indexOf(b.id);
    if (orderA === -1 && orderB === -1) {
      return a.id < b.id ? -1 : 1;
    }
    if (orderA === -1) {
      return 1;
    }
    if (orderB === -1) {
      return -1;
    }
    return orderA - orderB;
  };

  const defaultProps = {
    podium,
    voiceActivity,
    isGuest,
    me,
    isSFU,
    onEvent,
    options,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      style={{ overflow: 'hidden', height: '100%' }}
    >
      <ScrollContainer>
        <List>
          {participants.sort(sort).map((user) => (
            <ParticipantEntry key={user.id} user={user} {...defaultProps} />
          ))}
        </List>
      </ScrollContainer>
    </Box>
  );
};

ParticipantList.propTypes = {
  order: PropTypes.array,
  participants: PropTypes.array.isRequired,
  voiceActivity: PropTypes.object,
};

ParticipantList.defaultProps = {
  voiceActivity: { user: null, on: false },
};

export default ParticipantList;
