const SnackbarConfig = () => ({
  preventDuplicate: true,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  autoHideDuration: 2000,
  TransitionProps: {
    onEnter: (contentEl) => {
      // prevent screenreaders from reading twice
      contentEl.removeAttribute('aria-describedby');
    },
  },
});

export default SnackbarConfig;
