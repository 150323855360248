import I18n from 'i18n-js';
import React from 'react';
import Connecting from './status/Connecting.js';
import AudioStream from './streams/AudioStream.js';
import VideoStream from './streams/VideoStream.js';
import Presentation from './presentation/Presentation.js';
import PodiumOutline from './generic/PodiumOutline.js';
import MainAudioStream from './streams/MainAudioStream.js';
import { FeatureDetector } from 'eyeson';
import supports from '../utils/SupportHelper.js';

/**
 * MainView - represents the "main" view inside a room.
 * So in MCU mode that's the MCU stream
 * In SFU it will be the remote peer stream if video is enabled, otherwise
 * the remote audio view with avatar.
 * Or if in case of solo: the local stream.
 **/
const MainView = (props) => {
  const {
    eco,
    user,
    peer,
    sinkId,
    active,
    stream,
    onEvent,
    isPhone,
    sources,
    playback,
    sortOrder,
    connecting,
    localStream,
    videoSources,
    participants,
    showBranding,
    voiceActivity,
    showLocalStream,
    mediaPresentation,
    canvasPresentation,
    forwardedVideoMuted,
    options,
    virtualBackground,
    usePersistentAudio,
  } = props;

  const sfuDisabled = options && options.sfu_mode === 'disabled';
  const showNames = options ? options.show_names : false;

  const localPlayback = playback.playing.find(
    (p) => p.replacement_id === user.id
  );

  const remotePlayback = playback.playing.find(
    (p) => p.replacement_id !== user.id
  );

  const onVideoStreamClick = () => {
    // HEADS-UP! click and double click at the same element
    if (FeatureDetector.isIOSDevice()) {
      onEvent({ type: 'revitalize_stream_dialog' });
    }
  };

  const onVideoStreamDoubleClick = () => {
    // HEADS-UP! click and double click at the same element
    if (!FeatureDetector.hasMobileDevice()) {
      onEvent({ type: 'full_screen' });
    }
  };

  return [
    {
      condition: () =>
        active.screen_capture ||
        canvasPresentation.files.length > 0 ||
        mediaPresentation.file !== null,
      component: () => <Presentation {...props} />,
    },
    {
      condition: () => connecting && !isPhone,
      component: () => (
        <Connecting
          withFunFact={false}
          initialDelay={true}
          withAnimation={showBranding}
        />
      ),
    },
    {
      condition: () => !connecting && eco,
      component: () => [
        <PodiumOutline
          key="podium-outline"
          active={active}
          onEvent={onEvent}
          sortOrder={sortOrder}
          videoSources={videoSources}
          participants={participants}
          withDisableOption={!isPhone}
        />,
        <AudioStream
          key={stream && stream.id}
          stream={stream}
          sinkId={sinkId}
          muted={usePersistentAudio}
        />,
      ],
    },
    {
      condition: () => showLocalStream && active.video && !sfuDisabled,
      component: () => (
        <VideoStream
          {...props}
          visible={true} // because props also contain "visible" from room...
          stream={localStream}
          playback={localPlayback}
          className="local-stream"
          pipEnabled={false}
          withNameInsert={showNames}
          useCanvas={
            supports.playCanvasStreamBug && virtualBackground !== 'off'
          }
          onClick={onVideoStreamClick}
          onDoubleClick={onVideoStreamDoubleClick}
        />
      ),
    },
    {
      condition: () => showLocalStream && !sfuDisabled,
      component: () => (
        <MainAudioStream
          user={user}
          sinkId={sinkId}
          stream={stream}
          voiceActivity={voiceActivity}
          muted={usePersistentAudio}
        />
      ),
    },
    {
      condition: () => forwardedVideoMuted,
      component: () => (
        <MainAudioStream
          user={peer}
          sinkId={sinkId}
          stream={stream}
          onEvent={onEvent}
          playback={remotePlayback}
          voiceActivity={voiceActivity}
          muted={usePersistentAudio}
        />
      ),
    },
    {
      condition: () =>
        !connecting &&
        !active.video &&
        sources.length > 2 &&
        videoSources.length === 0,
      component: () => [
        <PodiumOutline
          key="podium-outline"
          title={I18n.t('title:empty_video_podium')}
          subtitle={I18n.t('subtitle:empty_video_podium')}
          active={active}
          onEvent={onEvent}
          sortOrder={sortOrder}
          videoSources={videoSources}
          participants={participants}
          withDisableOption={false}
        />,
        <AudioStream
          key={stream && stream.id}
          stream={stream}
          sinkId={sinkId}
          muted={usePersistentAudio}
        />,
      ],
    },
    {
      condition: () => true,
      component: () => (
        <VideoStream
          {...props}
          muted={usePersistentAudio}
          stream={stream}
          sinkId={sinkId}
          visible={Boolean(!eco || (isPhone && !active.sfu))}
          onEvent={onEvent}
          playback={remotePlayback}
          withNameInsert={showNames}
          onClick={onVideoStreamClick}
          onDoubleClick={onVideoStreamDoubleClick}
        />
      ),
    },
  ]
    .find((component) => component.condition())
    .component();
};

export default MainView;
