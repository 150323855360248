/* eslint-disable max-lines */
import Logger from '../Logger.js';

const Status = {
  NO_CONNECTION: 3,
  BAD: 2,
  OK: 1,
  GOOD: 0
};

const calculateStatus = (
  packetLossRate = 0,
  jitter = 0,
  roundTripTime = 0,
  nackRate = 0
) => {
  if (
    roundTripTime > 1 ||
    packetLossRate > 0.2 ||
    jitter > 0.5 ||
    nackRate > 0.2
  ) {
    return Status.BAD;
  }
  if (
    roundTripTime > 0.3 ||
    packetLossRate > 0.1 ||
    jitter > 0.3 ||
    nackRate > 0.1
  ) {
    return Status.OK;
  }
  return Status.GOOD;
};

const getOldestHistoryEntry = history => {
  let [result] = history;
  const { length } = history;
  for (let index = 1; index < length; index++) {
    if (history[index].time < result.time) {
      result = history[index];
    }
  }
  return result;
};

const getNewestHistoryEntry = history => {
  let [result] = history;
  const { length } = history;
  for (let index = 1; index < length; index++) {
    if (history[index].time > result.time) {
      result = history[index];
    }
  }
  return result;
};

const addHistory = (history, entry) => {
  if (history.length < 3) {
    history.push(Object.assign({}, entry));
    return;
  }
  Object.assign(getOldestHistoryEntry(history), entry);
};

// eslint-disable-next-line max-statements
const getHistoryAverage = history => {
  const { length } = history;
  const result = {
    jitter: 0,
    packetLoss: 0,
    packetLossRecv: 0,
    roundTripTime: 0,
    nack: 0,
    bitrateSend: 0,
    bitrateRecv: 0,
    status: Status.GOOD
  };
  const newest = getNewestHistoryEntry(history);
  for (const entry of history) {
    result.jitter += entry.jitter;
    result.packetLoss += entry.packetLoss;
    result.packetLossRecv += entry.packetLossRecv;
    result.roundTripTime += entry.roundTripTime;
    result.nack += entry.nack;
    result.bitrateSend += entry.bitrateSend;
    result.bitrateRecv += entry.bitrateRecv;
  }
  result.jitter = newest.jitter === null ? null : result.jitter / length;
  result.packetLoss =
    newest.packetLoss === null ? null : result.packetLoss / length;
  result.packetLossRecv =
    newest.packetLossRecv === null ? null : result.packetLossRecv / length;
  result.roundTripTime =
    newest.roundTripTime === null ? null : result.roundTripTime / length;
  result.nack = newest.nack === null ? null : result.nack / length;
  result.bitrateSend /= length;
  result.bitrateRecv /= length;
  result.status = calculateStatus(
    result.packetLoss,
    result.jitter,
    result.roundTripTime,
    result.nack
  );
  return result;
};

class ConnectionStatistics {
  constructor() {
    this.peerConnection = null;
    this.timer = null;
    this.listeners = [];
    this.history = [];
    this.entry = {
      jitter: 0,
      packetLoss: 0,
      packetLossRecv: 0,
      roundTripTime: 0,
      nack: 0,
      time: 0,
      bitrateSend: 0,
      bitrateRecv: 0
    };
    this.lastStat = {
      time: null,
      bytesSent: null,
      bytesReceived: null,
      audio: {
        out: {
          packetsSent: null,
          packetsLost: null,
          bytesSent: null
        },
        in: {
          bytesReceived: null,
          packetsReceived: null,
          packetsLost: null
        }
      },
      video: {
        out: {
          packetsSent: null,
          packetsLost: null,
          bytesSent: null,
          nackCount: null
        },
        in: {
          bytesReceived: null,
          packetsReceived: null,
          packetsLost: null
        }
      }
    };
    this.boundPrintStats = this.printStats.bind(this);
  }

  init(peerConnection) {
    this.peerConnection = peerConnection;
  }

  // eslint-disable-next-line max-statements
  async printStats() {
    const { peerConnection, entry, lastStat, history } = this;
    const stats = await peerConnection.getStats();
    const now = Date.now();
    let statsCurrentRoundTripTime = null;
    Object.keys(entry).forEach(key => {
      entry[key] = 0;
    });
    entry.jitter = null;
    entry.packetLoss = null;
    entry.packetLossRecv = null;
    entry.roundTripTime = null;
    entry.nack = null;
    entry.time = now;

    // eslint-disable-next-line max-statements, complexity
    stats.forEach(report => {
      if (report.type === 'inbound-rtp') {
        const kind = report.kind || report.mediaType;
        if (lastStat.time) {
          const diff = (now - lastStat.time) / 1000.0;
          const received = Math.max(
            report.bytesReceived - lastStat[kind].in.bytesReceived,
            0
          );
          entry.bitrateRecv += (received * 8) / diff;
        }
        if (lastStat[kind]) {
          const lost = report.packetsLost - lastStat[kind].in.packetsLost;
          const packets =
            report.packetsReceived - lastStat[kind].in.packetsReceived;
          const packetLossRate = packets > 0 ? lost / packets : 0;
          entry.packetLossRecv = Math.max(entry.packetLossRecv, packetLossRate);
        }
        lastStat[kind].in.bytesReceived = report.bytesReceived;
        lastStat[kind].in.packetsLost = report.packetsLost;
        lastStat[kind].in.packetsReceived = report.packetsReceived;
      } else if (report.type === 'outbound-rtp') {
        const kind = report.kind || report.mediaType;
        if (lastStat.time) {
          const diff = (now - lastStat.time) / 1000.0;
          const sent = report.bytesSent - lastStat[kind].out.bytesSent;
          entry.bitrateSend += (sent * 8) / diff;
        }
        lastStat[kind].out.bytesSent = report.bytesSent;
      } else if (report.type === 'remote-inbound-rtp') {
        const kind = report.kind || report.mediaType;
        if (lastStat[kind]) {
          const outbound = stats.get(report.localId);
          const hasRTT = typeof report.roundTripTime !== 'undefined';
          const hasJitter = typeof report.jitter !== 'undefined';
          const hasPacketLoss = typeof report.packetsLost !== 'undefined';
          const hasNackCount = typeof outbound.nackCount !== 'undefined';
          if (lastStat.time) {
            const lost = report.packetsLost - lastStat[kind].out.packetsLost;
            const packets =
              outbound.packetsSent - lastStat[kind].out.packetsSent;
            const packetLossRate = packets > 0 ? lost / packets : 0;
            entry.jitter = hasJitter
              ? Math.max(entry.jitter, packets > 0 ? report.jitter : 0)
              : null;
            entry.packetLoss = hasPacketLoss
              ? Math.max(entry.packetLoss, packetLossRate)
              : null;
            entry.roundTripTime = hasRTT
              ? Math.max(entry.roundTripTime, report.roundTripTime)
              : null;
            if (kind === 'video') {
              const nack = outbound.nackCount - lastStat[kind].out.nackCount;
              const nackRate = packets > 0 ? nack / packets : 0;
              entry.nack = hasNackCount ? Math.max(entry.nack, nackRate) : null;
            }
          }
          if (kind === 'video') {
            lastStat[kind].out.nackCount = outbound.nackCount;
          }
          lastStat[kind].out.packetsSent = outbound.packetsSent;
          lastStat[kind].out.packetsLost = report.packetsLost;
        }
      } else if (
        report.type === 'candidate-pair' &&
        report.state === 'succeeded' &&
        'currentRoundTripTime' in report
      ) {
        statsCurrentRoundTripTime = report.currentRoundTripTime;
      }
    });
    if (entry.roundTripTime === null && statsCurrentRoundTripTime !== null) {
      entry.roundTripTime = statsCurrentRoundTripTime;
    }
    addHistory(history, entry);
    const average = getHistoryAverage(history);
    if (navigator.onLine === false) {
      average.status = Status.BAD;
    }
    if (
      ['disconnected', 'failed', 'closed'].includes(
        peerConnection.iceConnectionState
      )
    ) {
      average.status = Status.NO_CONNECTION;
    }
    this.emit(average);
    lastStat.time = now;
  }

  getCurrentStatistics() {
    return getHistoryAverage(this.history);
  }

  onUpdate(listener) {
    const { listeners, timer } = this;
    if (listeners.length === 0 && timer === null) {
      this.timer = setInterval(this.boundPrintStats, 1000);
      this.peerConnection.addEventListener(
        'iceconnectionstatechange',
        this.boundPrintStats
      );
      window.addEventListener('offline', this.boundPrintStats);
    }
    listeners.push(listener);
  }

  emit(statistics) {
    this.listeners.forEach(listener => {
      try {
        listener(statistics);
      } catch (err) {
        Logger.error('ConnectionStatistics::emit', err);
      }
    });
  }

  destroy() {
    clearInterval(this.timer);
    window.removeEventListener('offline', this.boundPrintStats);
    if (this.peerConnection) {
      this.peerConnection.removeEventListener(
        'iceconnectionstatechange',
        this.boundPrintStats
      );
      this.peerConnection = null;
    }
    this.timer = null;
    this.history.length = 0;
    this.listeners.length = 0;
  }
}

ConnectionStatistics.Status = Status;

export default ConnectionStatistics;
