import I18n from 'i18n-js';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import MicCheck from '../device_settings/MicCheck.js';
import DeviceTile from './DeviceTile.js';
import AudioPreview from '../device_settings/AudioPreview.js';
import { FeatureDetector, StreamHelpers } from 'eyeson';
import StreamdeckHelper from '../../utils/StreamdeckHelper.js';

const DeviceTiles = ({
  eco,
  sinkId,
  stream,
  cameras,
  children,
  selected,
  speakers,
  microphones,
  toggleVideo,
  playerMuted,
  toggleAudio,
  handleMicCheck,
  handlePlayPreviewAudio,
  handleAudioInputChange,
  handleVideoInputChange,
  handleAudioOutputChange,
}) => {
  const hasAudio = StreamHelpers.hasAudio(stream);
  const hasVideo = StreamHelpers.hasVideo(stream);
  const hasCamera = cameras.length > 0;
  const hasMic = microphones.length > 0;
  const audioState = hasAudio;
  const audioEnabled = hasMic;
  const videoState = hasVideo;
  const videoEnabled = hasCamera && hasMic && !eco;

  useEffect(() => {
    StreamdeckHelper.setActions({
      audiotoggle: { enabled: audioEnabled, state: audioState },
      videotoggle: { enabled: videoEnabled, state: videoState },
    });
  }, [audioState, audioEnabled, videoState, videoEnabled]);

  return (
    <div className="tiles">
      <DeviceTile
        id="preview-device-cam"
        off={!hasVideo || eco}
        icon={hasVideo ? 'videocam' : 'videocam_off'}
        label={I18n.t('devices:camera')}
        pickerLabel={I18n.t('label:select_camera')}
        stream={stream}
        devices={cameras}
        onChange={handleVideoInputChange}
        disabled={!FeatureDetector.canChangeCamera() || eco}
        selected={selected.camera}
      >
        <Switch
          checked={videoState}
          onChange={toggleVideo}
          disabled={!videoEnabled}
          inputProps={{
            'aria-label': I18n.t('label:toggle_video', {
              defaultValue: 'Toggle video',
            }),
          }}
        />
      </DeviceTile>
      <DeviceTile
        id="preview-device-mic"
        off={!hasAudio}
        icon={hasAudio ? 'mic' : 'mic_off'}
        label={I18n.t('devices:microphone')}
        pickerLabel={I18n.t('label:select_microphone')}
        stream={stream}
        devices={microphones}
        onChange={handleAudioInputChange}
        disabled={!FeatureDetector.canChangeMicrophone()}
        selected={selected.microphone}
      >
        <Switch
          checked={audioState}
          onChange={toggleAudio}
          disabled={!audioEnabled}
          inputProps={{
            'aria-label': I18n.t('label:toggle_audio', {
              defaultValue: 'Toggle audio',
            }),
          }}
        />
      </DeviceTile>
      <DeviceTile
        id="preview-device-speaker"
        icon="volume_up"
        label={I18n.t('devices:speaker')}
        pickerLabel={I18n.t('label:select_speaker')}
        sinkId={sinkId}
        stream={stream}
        devices={speakers}
        onChange={handleAudioOutputChange}
      >
        <span className="tile-group">
          <AudioPreview
            sinkId={sinkId}
            tooltip={I18n.t('tooltip:sound')}
            onClick={handlePlayPreviewAudio}
          />
          <MicCheck onClick={handleMicCheck} playerMuted={playerMuted} />
        </span>
      </DeviceTile>
      {children}
    </div>
  );
};

DeviceTiles.propTypes = {
  hasMic: PropTypes.bool,
  sinkId: PropTypes.string,
  stream: PropTypes.object,
  cameras: PropTypes.array,
  speakers: PropTypes.array,
  selected: PropTypes.object,
  hasAudio: PropTypes.bool,
  hasVideo: PropTypes.bool,
  hasCamera: PropTypes.bool,
  microphones: PropTypes.array,
  toggleAudio: PropTypes.func.isRequired,
  toggleVideo: PropTypes.func.isRequired,
  handleVideoInputChange: PropTypes.func.isRequired,
  handleAudioInputChange: PropTypes.func.isRequired,
  handlePlayPreviewAudio: PropTypes.func.isRequired,
  handleAudioOutputChange: PropTypes.func.isRequired,
};

DeviceTiles.defaultProps = {
  hasMic: false,
  sinkId: '',
  cameras: [],
  selected: {},
  speakers: [],
  hasAudio: false,
  hasVideo: false,
  hasCamera: false,
  microphones: [],
};

export default DeviceTiles;
