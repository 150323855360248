import I18n from 'i18n-js';
import BaseAction from './BaseActions.js';
import supports from '../SupportHelper.js';
import { copyText } from '../SimpleClipboard.js';

/**
 * Copy guest link to clipboard
 **/
class CopyGuestLink extends BaseAction {
  process(state, props) {
    const { options } = state;
    const { guest_token } = props.eyeson.room;
    let url = `${window.location.href.split('?')[0]}?guest=${guest_token}`;
    if (props.hideGuestLogin) {
      return;
    }
    if (options.suggest_guest_names === false) {
      url += '&suggest=false';
    }
    const tryCopy = () => {
      if (copyText(url)) {
        props.enqueueSnackbar(I18n.t('message:clipboard_success'));
      } else {
        props.enqueueSnackbar(I18n.t('label:copy_error'), {
          variant: 'warning',
        });
      }
    };
    if (supports.ClipboardAPI) {
      navigator.clipboard
        .writeText(url)
        .then(() => props.enqueueSnackbar(I18n.t('message:clipboard_success')))
        .catch(() => tryCopy());
    } else {
      tryCopy();
    }
    return {};
  }
}

class SessionInfo extends BaseAction {
  process(_state, props) {
    try {
      const { user, room } = props.eyeson;
      const options = props.eyeson.core.eventHandler._connection.uaOptions;
      const info = {
        now: new Date().toISOString(),
        user: {
          name: user.name,
          id: user.id,
          apiId: user.apiId,
          clientId: options.client_id,
        },
        room: {
          id: room.id,
          confId: options.conf_id,
          started_at: room.started_at,
        },
      };
      navigator.clipboard
        .writeText(JSON.stringify(info, null, 2))
        .catch(() => {});
    } catch (error) {}
    return null;
  }
}

export { CopyGuestLink, SessionInfo };
