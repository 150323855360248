import React from 'react';
import I18n from 'i18n-js';
import { withSnackbar } from 'notistack';
import eyeson, {
  Logger,
  FeatureDetector,
  StreamHelpers,
  debounce,
} from 'eyeson';
import {
  MenuItem,
  Select,
  TextField,
  Button,
  CircularProgress,
  DialogContentText,
  InputLabel,
  FormLabel,
} from '@material-ui/core';

const _captureHandleSupport =
  window.MediaStreamTrack && 'getCaptureHandle' in MediaStreamTrack.prototype;
const _canStereo = FeatureDetector.canStereo();

const _ownTabId =
  'eyeson-youtube-player_' + String(Math.random()).substring(2, 8);
let _YTLoaded = false;
let _vimeoLoaded = false;

// https://developers.google.com/youtube/iframe_api_reference
const importYTScript = () => {
  if (_YTLoaded) {
    window.onYouTubeIframeAPIReady();
    return;
  }
  const script = document.createElement('script');
  script.src = 'https://www.youtube.com/iframe_api';
  script.onload = () => {
    _YTLoaded = true;
    script.remove();
  };
  script.onerror = () => {
    script.remove();
    window.onYouTubeIframeAPIError();
  };
  document.head.append(script);
};

// https://developer.vimeo.com/player/sdk
const importVimeoScript = () => {
  if (_vimeoLoaded) {
    return;
  }
  const script = document.createElement('script');
  script.src = 'https://player.vimeo.com/api/player.js';
  script.onload = () => {
    _vimeoLoaded = true;
    script.remove();
  };
  script.onerror = () => {
    script.remove();
  };
  document.head.append(script);
};

const setCaptureHandle = () => {
  // https://developer.chrome.com/docs/web-platform/capture-handle/
  if (_captureHandleSupport) {
    navigator.mediaDevices.setCaptureHandleConfig({
      handle: _ownTabId,
      exposeOrigin: true,
      permittedOrigins: [window.location.origin],
    });
  }
};

const startScreenshare = async () => {
  const controller = new window.CaptureController();
  const stream = await navigator.mediaDevices.getDisplayMedia({
    controller,
    audio: {
      channelCount: _canStereo ? 2 : 1,
      echoCancellation: _canStereo ? false : true,
      suppressLocalAudioPlayback: true,
    },
    video: {
      height: { max: 1080 },
      frameRate: { max: 15 },
      displaySurface: 'browser',
    },
    preferCurrentTab: true,
    systemAudio: 'exclude',
    surfaceSwitching: 'exclude',
    selfBrowserSurface: 'include',
    monitorTypeSurfaces: 'exclude',
  });
  // exchange in future with "focus-capturing-application"
  // https://chromestatus.com/feature/5119529898475520
  controller.setFocusBehavior('no-focus-change');
  const [vTrack] = stream.getVideoTracks();
  if (!vTrack) {
    StreamHelpers.stopStream(stream);
    Logger.error('YoutubePopup::startScreenshare', 'Missing video track');
    throw new TypeError('Missing video track');
  }
  if (_captureHandleSupport) {
    const captureHandle = vTrack.getCaptureHandle();
    if (
      !(
        captureHandle &&
        captureHandle.handle === _ownTabId &&
        captureHandle.origin === window.location.origin
      )
    ) {
      StreamHelpers.stopStream(stream);
      Logger.error('YoutubePopup::startScreenshare', 'Invalid screen source');
      throw new TypeError('Invalid screen source');
    }
  }
  return stream;
};

// https://gist.github.com/jschr/e98030e4e51c9fc243abf7052c762188
const parseYoutubeUrl = (url) => {
  const [domain, querystring] = url.split('?');
  const options = {};
  const query = new URLSearchParams(querystring);

  if (query.has('list')) {
    options.playlistId = query.get('list');
  }
  if (query.has('v')) {
    options.videoId = query.get('v');
  }
  const shortVideo = new window.URLPattern(
    'http{s}?://{www.}?youtu.be/:videoId'
  );
  const longVideo = new window.URLPattern(
    'http{s}?://{www.}?youtube.com/(v|live|embed)/:videoId'
  );

  let params = shortVideo.exec(domain);
  if (params) {
    options.videoId = params.pathname.groups.videoId;
  }
  params = longVideo.exec(domain);
  if (params) {
    options.videoId = params.pathname.groups.videoId;
  }

  if (options.videoId) {
    // https://regexr.com/3nsop
    if (/^[A-Za-z0-9_-]{11}$/.test(options.videoId) === false) {
      Reflect.deleteProperty(options, 'videoId');
      return options;
    }
    if (query.has('start')) {
      options.start = parseInt(query.get('start'), 10);
    } else if (query.has('t')) {
      options.start = parseInt(query.get('t'), 10);
    }
    if (query.has('end')) {
      options.end = parseInt(query.get('end'), 10);
    }
  }
  return options;
};

const isVimeoUrl = (url) => {
  const pattern = new window.URLPattern('http{s}?://{*.}?vimeo.com/*');
  return Boolean(pattern.exec(url));
};

const observeResize = () => {
  const updateSize = () => {
    if (window.innerWidth !== 800 || window.innerHeight !== 450) {
      setWindowHeight('small');
    }
  };
  window.onresize = debounce(updateSize, 300);
  updateSize();
};

const setWindowHeight = (size) => {
  const height = size === 'big' ? 600 : 450;
  const heightDiff = window.outerHeight - window.innerHeight;
  window.resizeTo(800, height + heightDiff);
};

const registerGuest = (token, name, locale) => {
  return new Promise((resolve, reject) => {
    if (!token) {
      Logger.error('YoutubePopup::registerGuest', 'Missing guest token');
      throw new Error('error:msg:unknown');
    }
    const onEvent = (event) => {
      if (event.type === 'error') {
        eyeson.offEvent(onEvent);
        Logger.error('YoutubePopup::registerGuest', event);
        reject(new Error('error:msg:guest'));
      } else if (event.type === 'guest_user' && event.token) {
        eyeson.offEvent(onEvent);
        resolve(event.token);
      }
    };
    eyeson.onEvent(onEvent);
    eyeson.send({
      api: eyeson.config.api,
      name: '[YouTube]' + name,
      type: 'request_guest_user',
      token,
      locale,
    });
  });
};

class YoutubePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roomReady: false,
      init: true,
      error: null,
      loading: false,
      inputUrl: '',
      presentation: 'shared',
      options: null,
      token: null,
      ytReady: false,
      inputUrlError: null,
      isPresenter: false,
      hasPresenter: false,
      oldLayout: null,
      quality: null,
      checkPresenterTimer: null,
    };
    setCaptureHandle();
    window.onbeforeunload = () => {
      window.onresize = null;
      if (this.state.token && this.state.oldLayout) {
        // https://stackoverflow.com/a/67644011
        // keepalive is required to be reliably sent
        // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
        fetch(`${eyeson.config.api}/rooms/${this.state.token}/layout`, {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(this.state.oldLayout),
          keepalive: true,
        });
      }
    };
    document.title = '[YouTube player] ' + document.title;
    setWindowHeight('small');
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    if (params.has('guest')) {
      window.onYouTubeIframeAPIReady = () => {
        const guestToken = params.get('guest');
        const name = params.has('user') ? ' ' + params.get('user') : '';
        const locale = params.has('locale') ? params.get('locale') : null;
        registerGuest(guestToken, name, locale).then(
          (token) => {
            if (window.setYoutubeUserToken && window.opener) {
              window.setYoutubeUserToken(token);
            }
            this.setState({ init: false, token });
          },
          (error) => {
            this.setState({ error: error.message });
          }
        );
      };
    } else if (params.has('token')) {
      window.onYouTubeIframeAPIReady = () => {
        this.setState({ init: false, token: params.get('token') });
      };
    } else {
      Logger.error('YoutubePopup::onMount', 'Missing guest token or accessKey');
      this.setState({ error: 'error:msg:unknown' });
      return;
    }
    if (params.has('quality')) {
      this.setState({ quality: params.get('quality') });
    }
    window.onYouTubeIframeAPIError = () => {
      Logger.error('YoutubePopup::onMount', 'Unable to load YouTube script');
      this.setState({ error: 'error:msg:unknown' });
    };
    importYTScript();
    importVimeoScript();
  }

  onPlayerReady = ({ target }) => {
    if (this.state.ytReady === false && this.state.inputUrlError) {
      return;
    }
    setWindowHeight('big');
    startScreenshare().then(
      (stream) => {
        if (this.state.ytReady === false && this.state.inputUrlError) {
          StreamHelpers.stopStream(stream);
          setWindowHeight('small');
          return;
        }
        // resize after screenshare to prevent cropped screen share dialog
        // https://bugs.chromium.org/p/chromium/issues/detail?id=1505709
        observeResize();
        stream.getTracks().forEach((track) => {
          track.addEventListener('ended', () => {
            target.destroy();
            window.onbeforeunload = null;
            this.tryResetLayout();
            this.exitMeeting();
          });
        });
        eyeson.onEvent(this.handleEyesonEvent);
        const options = { stream, sendOnly: true };
        if (this.state.quality === 'low') {
          options.sendEncodings = {
            audio: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_AUDIO },
            video: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_VIDEO },
          };
        }
        if (this.state.quality === 'medium') {
          options.sendEncodings = {
            video: {
              maxBitrate: +process.env.REACT_APP_DATASAVER_MEDIUM_VIDEO,
            },
          };
        }
        eyeson.start(this.state.token, options);
      },
      (error) => {
        target.destroy();
        setWindowHeight('small');
        this.setState({ ytReady: false });
        this.showWarning(`error_Screen_${error.name}`);
      }
    );
  };

  onPlayerError = ({ target, data }) => {
    setWindowHeight('small');
    // https://developers.google.com/youtube/iframe_api_reference#Events
    const message =
      {
        2: 'error:youtube:parameter',
        5: 'error:youtube:unplayable',
        100: 'error:youtube:not_found',
        101: 'error:youtube:permission_denied',
        150: 'error:youtube:permission_denied',
      }[data] || 'error:youtube:unplayable';
    clearTimeout(this.state.checkPresenterTimer);
    window.onresize = null;
    this.tryResetLayout();
    target.destroy();
    eyeson.destroy();
    this.setState({ ytReady: false, inputUrlError: message, roomReady: false });
  };

  handleEyesonEvent = (event) => {
    const { type } = event;
    if (type === 'room_ready' && !this.state.roomReady) {
      this.setState({ roomReady: true });
      if (this.state.presentation === 'solo') {
        setTimeout(async () => {
          if (this.state.hasPresenter) {
            eyeson.send({ type: 'stop_presenting' });
            await new Promise((resolve) => setTimeout(resolve, 200));
          }
          eyeson.send({ type: 'start_presenting' });
          let timer = setTimeout(() => this.checkPresenter(), 1000);
          this.setState({ checkPresenterTimer: timer });
        }, 1000);
      } else if (this.state.presentation === 'shared') {
        const { options } = event.content;
        const oldLayout = {
          layout: options.layout,
          name: options.layout_name,
          users: options.layout_users,
          show_names: options.show_names,
          voice_activation: options.voice_activation,
        };
        if (options.layout_map) {
          oldLayout.map = JSON.stringify(options.layout_map);
        }
        this.setState({ oldLayout });
        eyeson.send({
          type: 'set_layout',
          params: {
            layout: 'auto',
            name: 'present-upper-6-aspect-fit',
            users: [event.content.user.id, '', '', '', '', '', ''],
            show_names: false,
            voice_activation: true,
          },
        });
      }
    } else if (type === 'podium') {
      if (event.isPresenter) {
        clearTimeout(this.state.checkPresenterTimer);
      }
      const { isPresenter, hasPresenter } = event;
      this.setState({ isPresenter, hasPresenter });
    } else if (type === 'presentation_ended') {
      if (this.state.isPresenter) {
        window.onbeforeunload = null;
        this.exitMeeting();
      }
    } else if (type === 'warning') {
      this.showWarning(event.name);
    } else if (type === 'error') {
      this.showWarning('error:page:msg:end_meeting');
      window.onbeforeunload = null;
      this.tryResetLayout();
      this.exitMeeting();
    } else if (type === 'exit') {
      this.showWarning('error:page:msg:end_meeting');
      window.onbeforeunload = null;
      this.cleanup();
    }
  };

  checkPresenter = () => {
    if (!this.state.isPresenter) {
      eyeson.send({ type: 'start_presenting' });
    }
  };

  exitMeeting = () => {
    eyeson.destroy();
    this.cleanup();
  };

  cleanup = () => {
    clearTimeout(this.state.checkPresenterTimer);
    setTimeout(() => this.onClose(), 500);
  };

  tryResetLayout = () => {
    if (this.state.isPresenter) {
      eyeson.send({ type: 'stop_presenting' });
    }
    if (this.state.oldLayout) {
      eyeson.send({ type: 'set_layout', params: this.state.oldLayout });
    }
  };

  changeInputUrl = ({ target }) => {
    this.setState({ inputUrl: target.value, inputUrlError: null });
  };

  changePresentation = ({ target }) => {
    this.setState({ presentation: target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (isVimeoUrl(this.state.inputUrl)) {
      this.setState({ ytReady: true, options: { vimeo: true } });
      return;
    }
    const options = parseYoutubeUrl(this.state.inputUrl);
    if (!(options.videoId || options.playlistId)) {
      this.setState({ inputUrlError: 'error:youtube:url' });
      return;
    }
    this.setState({ ytReady: true, options });
  };

  startVimeoPlayer = (node) => {
    const player = new window.Vimeo.Player(node, {
      url: this.state.inputUrl,
      width: 800,
      height: 450,
      dnt: true,
      pip: false,
    });
    player.ready().then(
      () => this.onPlayerReady({ target: player }),
      (error) => {
        Logger.error('Vimeo player', error.name, error.message);
        const data =
          {
            Error: 100,
            TypeError: 2,
            PrivacyError: 101,
            PasswordError: 101,
          }[error.name] || 100;
        this.onPlayerError({ target: player, data });
      }
    );
  };

  handleRef = (node) => {
    if (!node) {
      return;
    }
    this.setState({ loading: false });
    const { options } = this.state;
    if (options.vimeo) {
      this.startVimeoPlayer(node);
      return;
    }
    const playerVars = {
      fs: 0,
      rel: 0,
      autoplay: 0,
      playsinline: 1,
      modestbranding: 1,
    };
    if (Reflect.has(options, 'start')) {
      playerVars.start = options.start;
    }
    if (Reflect.has(options, 'end')) {
      playerVars.end = options.end;
    }
    if (Reflect.has(options, 'playlistId')) {
      playerVars.listType = 'playlist';
      playerVars.list = options.playlistId;
    }
    new window.YT.Player(node, {
      videoId: options.videoId,
      width: 800,
      height: 450,
      host: 'https://www.youtube-nocookie.com',
      playerVars,
      events: {
        onReady: (event) => setTimeout(() => this.onPlayerReady(event), 10),
        onError: this.onPlayerError,
      },
    });
  };

  onClose = () => {
    window.onresize = null;
    window.close();
  };

  showWarning = (message) => {
    this.props.enqueueSnackbar(I18n.t(message), {
      variant: 'warning',
      autoHideDuration: 5 * 1000,
    });
  };

  renderContent() {
    const { ytReady, error, init } = this.state;
    return [
      {
        condition: () => error,
        component: () => (
          <div id="youtube-popup">
            <h1>{I18n.t('error:page:header:unsupported')}</h1>
            <DialogContentText>
              {I18n.t('error:page:msg:description')}
            </DialogContentText>
            <FormLabel component="p" error>
              {I18n.t(error)}
            </FormLabel>
            <p>
              <Button variant="text" color="secondary" onClick={this.onClose}>
                {I18n.t('label:close')}
              </Button>
            </p>
          </div>
        ),
      },
      {
        condition: () => init,
        component: () => (
          <div id="youtube-popup">
            <h1>{I18n.t('popup:youtube:title')}</h1>
            <div className="spinner-wrap">
              <CircularProgress color="secondary" size="2rem" />
            </div>
            <DialogContentText>
              {I18n.t('popup:youtube:init')}
            </DialogContentText>
            <p>
              <Button variant="text" color="secondary" onClick={this.onClose}>
                {I18n.t('label:close')}
              </Button>
            </p>
          </div>
        ),
      },
      {
        condition: () => ytReady,
        component: () => <div id="youtube-player" ref={this.handleRef}></div>,
      },
      {
        condition: () => true,
        component: () => (
          <div id="youtube-popup">
            <h1>{I18n.t('popup:youtube:title')}</h1>
            <DialogContentText className="youtube-popup__description">
              {I18n.t('popup:youtube:description')}
            </DialogContentText>
            <form
              noValidate
              autoComplete="off"
              onSubmit={this.handleSubmit}
              className="youtube-popup__form"
            >
              <div className="row">
                <InputLabel htmlFor="input-url">
                  {I18n.t('label:youtube:url')}
                </InputLabel>
                <TextField
                  id="input-url"
                  type="url"
                  placeholder="https://www.youtube.com/watch?v=eteHciq0Ca0"
                  required
                  autoFocus
                  onChange={this.changeInputUrl}
                  value={this.state.inputUrl}
                  disabled={this.state.loading}
                  error={Boolean(this.state.inputUrlError)}
                  helperText={
                    this.state.inputUrlError
                      ? I18n.t(this.state.inputUrlError)
                      : null
                  }
                  inputProps={{ size: 50 }}
                />
              </div>
              <div className="row">
                <InputLabel
                  htmlFor="select-presentation"
                  id="label-select-presentation"
                >
                  {I18n.t('label:youtube:display')}
                </InputLabel>
                <Select
                  id="select-presentation"
                  labelId="label-select-presentation"
                  value={this.state.presentation}
                  onChange={this.changePresentation}
                  disabled={this.state.loading}
                >
                  <MenuItem value="shared">
                    {I18n.t('label:youtube:display:additional_user')}
                  </MenuItem>
                  <MenuItem value="solo">
                    {I18n.t('label:youtube:display:full_presentation')}
                  </MenuItem>
                </Select>
              </div>
              <div className="buttons">
                <Button variant="text" color="secondary" onClick={this.onClose}>
                  {I18n.t('label:close')}
                </Button>
                <Button type="submit" variant="contained" color="secondary">
                  {I18n.t('label:start')}
                </Button>
              </div>
            </form>
          </div>
        ),
      },
    ]
      .find((component) => component.condition())
      .component();
  }

  render() {
    return this.renderContent();
  }
}

export default withSnackbar(YoutubePopup);
