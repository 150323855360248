import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dateHelper from '../../../../utils/dateHelper.js';
import I18n from 'i18n-js';

class StatusMessage extends Component {
  /**
   * A status message starts with "/status ".
   **/
  static test(props) {
    return props.content.startsWith('/status ');
  }

  static parseContent(props) {
    const message = props.content.substr(8);
    try {
      const data = JSON.parse(message);
      return I18n.t(data.id, data);
    } catch (error) {
      return message;
    }
  }

  /**
   * Render a status message that is usually sent from GUI itself
   **/
  render() {
    return (
      <div className="eyeson-chat-message status">
        <span className="body">
          {StatusMessage.parseContent(this.props)
            .split(/(\*\*.+?\*\*)/g)
            .map((part, i) => {
              let match = part.match(/\*\*(.+?)\*\*/);
              if (match) {
                return <strong key={i}>{match[1]}</strong>;
              }
              return part;
            })}
        </span>
        <span className="date">
          &nbsp;| {dateHelper.formatTime(this.props.timestamp)}
        </span>
      </div>
    );
  }
}

StatusMessage.propTypes = {
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
};

export default StatusMessage;
