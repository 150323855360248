import React from 'react';
import PropTypes from 'prop-types';
import SnapshotList from './SnapshotList.js';
import SnapshotButton from './SnapshotButton.js';

const Snapshots = ({
  snapshots,
  hideSnapshotButton = false,
  onEvent,
  presentationActive,
  screenAsVideo,
}) => [
  <SnapshotList
    key="list"
    snapshots={snapshots}
    onEvent={onEvent}
    screenAsVideo={screenAsVideo}
    presentationActive={presentationActive}
  />,
  hideSnapshotButton ? null : <SnapshotButton key="action" onEvent={onEvent} />,
];

Snapshots.propTypes = {
  snapshots: PropTypes.array.isRequired,
  onEvent: PropTypes.func.isRequired,
  hideSnapshotButton: PropTypes.bool,
};

export default Snapshots;
