const toFixedNumber = number => Number(parseFloat(number).toFixed(2));

const scale = (width, height, max) => {
  const ratio = Math.min(max.width / width, max.height / height);
  const xScaled = toFixedNumber(width * ratio);
  const yScaled = toFixedNumber(height * ratio);

  return { width: xScaled, height: yScaled };
};

export default scale;
