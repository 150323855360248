import FeatureDetector from './FeatureDetector.js';
import Logger from './Logger.js';
import { isObject } from './utils/utils.js';

/**
 * Wrapper around localStorage API in order to prefix keys to be stored and
 * offer default values on demand. If no localStorage is provided by
 * the user-agent, we use a temporary fallbackStorage.
 */
const LocalStorage = (() => {
  const prefix = 'eyeson.';
  const fallbackStorage = {};
  const hasLocalStorage = FeatureDetector.hasLocalStorage();

  return {
    /**
     * Stores an object by key to local storage (or fallback storage)
     *
     * @param {string} key - Key
     * @param {object} value - Object to store
     * @return {boolean} - success or failed
     */
    store: (key, value) => {
      if (!hasLocalStorage) {
        fallbackStorage[key] = value;
        return true;
      }
      try {
        window.localStorage.setItem(prefix + key, JSON.stringify(value));
      } catch (error) {
        Logger.error('LocalStorage::store', error);
        return false;
      }
      return true;
    },
    /**
     * Loads an object by key from storage, returns defaultValue if key not
     * exists.
     *
     * @param {string} key - Key
     * @param {object} defaultValueParam - any desired default value if key not exists
     * @return {object} - Object from storage or defaultValueParam
     */
    // eslint-disable-next-line max-statements
    load: (key, defaultValueParam) => {
      let defaultValue = defaultValueParam;
      if (isObject(defaultValue) && !Array.isArray(defaultValue)) {
        defaultValue = Object.assign({}, defaultValue);
      }
      if (!hasLocalStorage) {
        return fallbackStorage[key] || defaultValue;
      }
      let storedValue = window.localStorage.getItem(prefix + key);
      if (!storedValue) {
        return defaultValue;
      }
      try {
        return JSON.parse(storedValue);
      } catch (error) {
        Logger.error('LocalStorage::load', key, error);
        return defaultValue;
      }
    }
  };
})();

export default LocalStorage;
