import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import PlaybackStore from '../PlaybackStore.js';
import PlaybackHelper from '../PlaybackHelper.js';

/**
 * PlaybackStart
 **/
class PlaybackStart extends BaseAction {
  process(state, props) {
    if (state.active.hasPresenter) {
      props.enqueueSnackbar(I18n.t('tooltip:reaction:off'));
      return {};
    }
    const playback = Object.assign(
      {
        audio: false,
        play_id: Date.now().toString(),
        replacement_id: state.user.id,
      },
      this.message.playback
    );

    props.eyeson.core.comApi.startPlayback(playback, ({ error }) => {
      if (error) {
        if (error.status !== 409) {
          props.enqueueSnackbar(I18n.t('error:comapi'), {
            variant: 'warning',
            autoHideDuration: 5 * 1000,
          });
        }
        return;
      }
      if (this.message.gif) {
        const helper = new PlaybackHelper(props.eyeson.links.self);
        helper.addRecent(this.message.gif);
      }
    });

    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
    };
  }
}

/**
 * PlaybackFav
 **/
class PlaybackFav extends BaseAction {
  process(state, props) {
    PlaybackStore.toggleFav(this.message.gif);
  }
}

/**
 * GifsUpdate
 **/
class GifsUpdate extends BaseAction {
  process(state, props) {
    let sortedByHeight = this.message.gifs.sort(
      (a, b) => a.images.fixed_width.height - b.images.fixed_width.height
    );

    return {
      playback: update(state.playback, { gifs: { $set: sortedByHeight } }),
    };
  }
}

/**
 * PlaybackUpdate
 **/
class PlaybackUpdate extends BaseAction {
  process(state) {
    const { playing } = this.message;

    if (playing.length === 0 || state.playback.playing.length > 0) {
      return {};
    }
    if (
      state.options.layout_users ||
      (state.active.sfu && !playing[0].replacement_id) ||
      (!state.active.sfu &&
        (!state.solo || (state.solo && !playing[0].replacement_id)))
    ) {
      return {};
    }

    return {
      playback: update(state.playback, {
        playing: { $push: playing },
      }),
    };
  }
}

/**
 * PlaybackEnded
 **/
class PlaybackEnded extends BaseAction {
  process(state, props) {
    return {
      playback: update(state.playback, { playing: { $set: [] } }),
    };
  }
}

/**
 * PlaybackStop
 **/
class PlaybackStop extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'stop_playback',
      playback: this.message.playback,
    });

    return {
      playback: update(state.playback, { playing: { $set: [] } }),
    };
  }
}

export {
  GifsUpdate,
  PlaybackFav,
  PlaybackStop,
  PlaybackStart,
  PlaybackEnded,
  PlaybackUpdate,
};
