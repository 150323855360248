import I18n from 'i18n-js';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PresentationActions from './toolbar/PresentationActions.js';
import MuiButton from '@material-ui/core/Button';
import MuiIconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Slider from '@material-ui/core/Slider';
import Utils from '../../utils/dateHelper.js';

const useSliderStyles = makeStyles((theme) => ({
  root: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.primary.dark
        : theme.palette.common.white,
  },
  thumb: {
    '&:hover': {
      boxShadow:
        theme.palette.type === 'light'
          ? '0px 0px 0px 8px rgba(0,0,0,.14)'
          : '0px 0px 0px 8px rgba(255,255,255,.16)',
    },
    '&.Mui-focusVisible': {
      boxShadow:
        theme.palette.type === 'light'
          ? '0px 0px 0px 14px rgba(0,0,0,.14)'
          : '0px 0px 0px 14px rgba(255,255,255,.16)',
    },
  },
  active: {
    '&:hover': {
      boxShadow:
        theme.palette.type === 'light'
          ? '0px 0px 0px 14px rgba(0,0,0,.14)'
          : '0px 0px 0px 14px rgba(255,255,255,.16)',
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    background:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
    height: '7rem',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowCenter: {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
  },
  volume: {
    flexGrow: 1,
    maxWidth: '170px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  time: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  timeSliderWrap: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  volumeSliderWrap: {
    flexGrow: 1,
    paddingRight: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  playButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const MediaToolbar = (props) => {
  const classes = useStyles();
  const sliderClasses = useSliderStyles();
  const {
    paused,
    active: { hasPresenter, isPresenter, isPresenting },
    ready,
    stopStream,
    startStream,
    onPlayPause,
    onMute,
  } = props;

  const fullVolume = 75;
  const volume = Math.round(props.volume * fullVolume);
  const currentTime = Math.round(props.currentTime);
  const duration = props.duration === null ? null : Math.round(props.duration);

  const setVolume = (event, value) => {
    props.setVolume(value / fullVolume);
  };

  const setCurrentTime = (event, value) => {
    props.setCurrentTime(value);
  };

  const timeDisabled = duration === null;

  return (
    // eslint-disable-next-line jsx-a11y/no-redundant-roles
    <section
      className={classes.root}
      role="region"
      aria-label={I18n.t('aria:label:presentation_toolbar', {
        defaultValue: 'Presentation toolbar',
      })}
    >
      <div className={classes.rowCenter}>
        <PresentationActions
          disabled={(hasPresenter && !isPresenter) || !ready}
          started={isPresenting}
          stopStream={stopStream}
          startStream={startStream}
          inSelfPreview={!(hasPresenter && !isPresenter) && !ready}
        />
      </div>
      <div className={classes.row}>
        <MuiButton
          onClick={onPlayPause}
          startIcon={<Icon>{paused ? 'play_arrow' : 'pause'}</Icon>}
          className={classes.playButton}
          disabled={!ready}
        >
          {paused ? I18n.t('label:play') : I18n.t('label:pause')}
        </MuiButton>
        <div className={classes.time}>
          <div>{Utils.displaySeconds(currentTime)}</div>
          <div className={classes.timeSliderWrap}>
            <Slider
              value={timeDisabled ? 0 : currentTime}
              max={timeDisabled ? 100 : duration}
              disabled={timeDisabled}
              onChange={setCurrentTime}
              aria-label={I18n.t('aria:label:current_time')}
              classes={sliderClasses}
            />
          </div>
          <div>{timeDisabled ? '0:00' : Utils.displaySeconds(duration)}</div>
        </div>
        <div className={classes.volume}>
          <MuiIconButton onClick={onMute} className={classes.button}>
            <Icon>
              {volume === 0
                ? 'volume_off'
                : volume > fullVolume
                ? 'flash_on'
                : 'volume_up'}
            </Icon>
          </MuiIconButton>
          <div className={classes.volumeSliderWrap}>
            <Slider
              value={volume}
              onChange={setVolume}
              marks={[{ value: fullVolume }]}
              aria-label={I18n.t('aria:label:volume')}
              classes={sliderClasses}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaToolbar;
