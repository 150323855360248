import React from 'react';
import PropTypes from 'prop-types';

const NameInsert = ({ visible, user, children }) => {
  if (!visible) {
    return null;
  }

  return (
    <div className="name-insert">
      <span className="name">{user && user.name}</span>
      {children}
    </div>
  );
};

NameInsert.propTypes = {
  visible: PropTypes.bool,
};

NameInsert.defaultProps = {
  visible: true,
};

export default NameInsert;
