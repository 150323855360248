import React from 'react';
import I18n from 'i18n-js';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import PresentationIcon from '@material-ui/icons/Slideshow';
import DownloadIcon from '@material-ui/icons/GetApp';
import eyeson, { Logger, immediate } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const downloadSupported =
  HTMLAnchorElement.prototype.hasOwnProperty('download');

const useStyles = makeStyles(() => ({
  button: {
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
}));

const Snapshot = ({ info, onEvent, presentationActive, screenAsVideo }) => {
  const downloadSnapshot = () => {
    eyeson.core.comApi.getSnapshot(info.id, (data) => {
      if (data.error) {
        Logger.error(data.error);
        onEvent({ type: 'warning', name: 'error:msg:unknown' });
        return;
      }
      fetch(data.links.download, {
        mode: 'cors',
        credentials: 'omit',
        cache: 'no-store',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          const name = `${
            info.room.name
          } - ${info.created_at_date.toLocaleDateString()} ${info.created_at_date.toLocaleTimeString()}.jpg`;
          link.download = name;
          link.href = url;
          link.click();
          immediate(() => URL.revokeObjectURL(url));
        })
        .catch((err) => {
          Logger.error(err);
          onEvent({ type: 'warning', name: 'error:msg:unknown' });
        });
    });
  };

  const presentSnapshot = () => {
    if (presentationActive || screenAsVideo) {
      const name = screenAsVideo
        ? 'presentation:unavailable:screenshare'
        : 'presentation:busy';
      onEvent({ type: 'warning', name });
      return;
    }
    eyeson.core.comApi.getSnapshot(info.id, (data) => {
      if (data.error) {
        Logger.error(data.error);
        onEvent({ type: 'presentation_drop_error', name: 'error:msg:unknown' });
        return;
      }
      fetch(data.links.download, {
        mode: 'cors',
        credentials: 'omit',
        cache: 'no-store',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], I18n.t('label:snapshot'), {
            type: blob.type,
          });
          file.path = file.name;
          onEvent({ type: 'presentation_drop', files: [file] });
        })
        .catch((err) => {
          Logger.error(err);
          onEvent({
            type: 'presentation_drop_error',
            message: I18n.t('error:msg:unknown'),
          });
        });
    });
  };

  const openSnapshot = () => {
    eyeson.core.comApi.getSnapshot(info.id, (data) => {
      let url = info.links.download;
      if (data.error) {
        return;
      }
      url = data.links.download;
      const link = document.createElement('a');
      link.rel = 'noopener noreferrer';
      link.target = '_blank';
      link.href = url;
      link.click();
    });
  };

  const classes = useStyles();

  if (!downloadSupported) {
    return (
      <a
        className="snapshot"
        href={info.links.download}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={info.links.download}
          className="snapshot-image"
          alt={I18n.t('aria:label:snapshot', { id: info.name })}
          loading="lazy"
        />
        <span className="snapshot-title">
          {info.created_at_date.toLocaleDateString()}{' '}
          {info.created_at_date.toLocaleTimeString()}
        </span>
      </a>
    );
  }

  return (
    <div className="snapshot">
      <div className="snapshot-buttons">
        <Tooltip title={I18n.t('action:present')}>
          <IconButton
            aria-label={I18n.t('aria:label:snapshot:present')}
            onClick={presentSnapshot}
            className={classes.button}
          >
            <PresentationIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={I18n.t('action:download')}>
          <IconButton
            aria-label={I18n.t('aria:label:snapshot:download')}
            onClick={downloadSnapshot}
            className={classes.button}
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={I18n.t('action:open')}>
          <IconButton
            aria-label={I18n.t('aria:label:snapshot:open')}
            onClick={openSnapshot}
            className={classes.button}
          >
            <LaunchIcon />
          </IconButton>
        </Tooltip>
      </div>
      <img
        src={info.links.download}
        className="snapshot-image"
        alt={I18n.t('aria:label:snapshot', { id: info.name })}
        loading="lazy"
      />
      <div className="snapshot-title">
        {info.created_at_date.toLocaleDateString()}{' '}
        {info.created_at_date.toLocaleTimeString()}
      </div>
    </div>
  );
};

export default Snapshot;
