/* eslint-disable max-lines */
import scale from './utils/scale.js';
import Logger from './Logger.js';
import debounce from './utils/debounce.js';

import {
  isVBGStream,
  getVbgTracks,
  getScreenPresentationTracks,
  getCameraTracks,
  getCanvasTracks,
  stopStream
} from './utils/StreamHelpers.js';
import FeatureDetector from './FeatureDetector.js';

const FPS = 15;
const FPS_INTERVAL = 1000 / FPS;
const CAMERA_SMALL = { width: 220, height: 140 };
const CAMERA_MEDIUM = { width: 320, height: 240 };
const CAMERA_LARGE = { width: 420, height: 340 };
const CAMERA_NONE = { width: 0, height: 0 };

const supportsTrackProcessor =
  typeof window.MediaStreamTrackProcessor === 'function';

const defaultTrack = {
  getSettings: () => ({ width: 0, height: 0 }),
  addEventListener: () => null
};

const isIOSDevice = FeatureDetector.isIOSDevice();

// Note:
// autoplay elements will stop playing in safari if not in viewport:
// https://webkit.org/blog/6784/new-video-policies-for-ios/
// that's the reason why we don't set autoplay
// video.setAttribute('autoplay', '1');
// but instead `.play` manually.
// eslint-disable-next-line max-statements
const bindVideoToStream = stream => {
  const [track] = stream.getVideoTracks();
  if (!track) {
    return null;
  }
  if (supportsTrackProcessor) {
    // eslint-disable-next-line no-undef
    const processor = new MediaStreamTrackProcessor(track);
    return processor.readable.getReader();
  }
  const { width, height } = track.getSettings();
  const video = document.createElement('video');
  video.playsInline = true;
  video.muted = true;
  video.width = width;
  video.height = height;
  video.srcObject = stream;
  track.addEventListener('stopped', () => {
    video.srcObject = null;
  });
  video
    .play()
    .catch(error =>
      Logger.warn('bindVideoToStream: play', error, error.message)
    );
  return video;
};

/* eslint-disable max-statements, id-length */
const fitImage = (target, source) => {
  const sourceAspectRatio = source.width / source.height;
  const targetAspectRatio = target.width / target.height;

  let { width, height } = target;
  let x = 0;
  let y = 0;

  // Image's aspect ratio is less than target's we fit on height
  // and place the image centrally along width
  if (sourceAspectRatio < targetAspectRatio) {
    width = source.width * (height / source.height);
    x = (target.width - width) / 2;
  }
  // Image's aspect ratio is greater than target's we fit on width
  // and place the image centrally along height
  if (sourceAspectRatio > targetAspectRatio) {
    height = source.height * (width / source.width);
    y = (target.height - height) / 2;
  }

  return { x: x, y: y, width: width, height: height };
};
/* eslint-enable max-statements */

const initIOSSettings = mixer => {
  // neccessary to run calcSizesAndPositions and draw functions
  // without camera
  mixer.cameraStream = { getVideoTracks: () => [] };
  mixer.screenStream = { getVideoTracks: () => [] };
};

const getCanvasCaptureTrackCanvas = stream => {
  if (stream && stream.getVideoTracks().length > 0) {
    const [track] = stream.getVideoTracks();
    if ('canvas' in track) {
      return track.canvas;
    }
  }
  return null;
};

class CanvasMixer {
  /* eslint-disable max-statements */
  constructor(canvas, stream) {
    this.canvas = canvas;
    this.stream = stream;
    this.camera = null;
    this.screen = null;
    this.canvasTrack = null;
    this.context = this.canvas.getContext('2d', {
      alpha: false,
      desynchronized: true
    });
    this.xPos = 0;
    this.camSize = CAMERA_MEDIUM;
    this.isCanvasCapture = false;

    this.draw = this.draw.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.onError = this.onError.bind(this);
    this.onRedraw = this.onRedraw.bind(this);
    this.setStream = this.setStream.bind(this);
    this.setCamera = this.setCamera.bind(this);
    this.drawFrame = this.drawFrame.bind(this);
    this.calcSizesAndPositions = this.calcSizesAndPositions.bind(this);

    this.bouncedDraw = debounce(this.draw, FPS_INTERVAL);
  }
  /* eslint-enable max-statements */

  onError(errorCallback) {
    this.errorCallback = errorCallback;
  }

  onRedraw(redrawCallback) {
    this.redrawCallback = redrawCallback;
  }

  start() {
    const [canvasTrack] = getCanvasTracks(this.stream);
    this.canvasTrack = canvasTrack;
    if (isIOSDevice) {
      initIOSSettings(this);
      this.setCamera({ horizontal: 'right', vertical: 'bottom' }, 'none');
    } else {
      this.setCamera({ horizontal: 'right', vertical: 'bottom' }, 'medium');
      this.setStream(this.stream);
    }
    this.draw();
  }

  stop() {
    if (this.stream) {
      stopStream(this.stream);
      this.stream = null;
      this.camera = null;
      this.screen = null;
      this.screenStream = null;
      this.cameraStream = null;
      this.canvasTrack = null;
    }
  }

  // eslint-disable-next-line max-statements
  setStream(newStream) {
    this.stream = newStream;
    this.cameraStream = new MediaStream(
      isVBGStream(newStream)
        ? getVbgTracks(newStream)
        : getCameraTracks(newStream)
    );
    this.screenStream = new MediaStream(
      getScreenPresentationTracks(this.stream)
    );
    const originalCanvas = getCanvasCaptureTrackCanvas(this.cameraStream);
    if (originalCanvas) {
      this.isCanvasCapture = true;
      this.camera = originalCanvas;
    } else {
      this.isCanvasCapture = false;
      this.camera = bindVideoToStream(this.cameraStream);
    }
    this.screen = bindVideoToStream(this.screenStream);
    this.calcSizesAndPositions();
    this.redrawQueued = true;
  }

  setCamera(position, size) {
    this.cameraPosition = position || this.cameraPosition;
    this.cameraSizeInWords = size || this.cameraSizeInWords;
    this.redrawQueued = true;
  }

  calcSizesAndPositions() {
    // Fetch the latest settings (width and height) from the track directly,
    // otherwise resized windows won't be positioned/scaled correctly...
    const [screenTrack = defaultTrack] = this.screenStream.getVideoTracks();
    const screenStreamSettings = screenTrack.getSettings();
    const fitScreen = fitImage(this.canvas, screenStreamSettings);
    const [cameraTrack = defaultTrack] = this.cameraStream.getVideoTracks();
    const cameraTrackSettings = cameraTrack.getSettings();

    this.camSize = {
      small: CAMERA_SMALL,
      medium: CAMERA_MEDIUM,
      large: CAMERA_LARGE,
      none: CAMERA_NONE
    }[this.cameraSizeInWords];

    const scaledCam = scale(
      cameraTrackSettings.width,
      cameraTrackSettings.height,
      this.camSize
    );

    this.sizes = {
      screen: { width: fitScreen.width, height: fitScreen.height },
      camera: { width: scaledCam.width, height: scaledCam.height }
    };
    const camPosition = {
      x:
        this.cameraPosition.horizontal === 'right'
          ? this.canvas.width - this.sizes.camera.width
          : 0,
      y:
        this.cameraPosition.vertical === 'bottom'
          ? this.canvas.height - this.sizes.camera.height
          : 0
    };
    this.positions = {
      screen: { x: fitScreen.x, y: fitScreen.y },
      camera: { x: camPosition.x, y: camPosition.y }
    };
    /* eslint-enable id-length */
  }

  /* eslint-disable max-statements */
  async draw() {
    try {
      if (!this.stream || !this.stream.active) {
        return;
      }

      // Doing this on every draw due to resizeable windows while screensharing
      this.calcSizesAndPositions();

      // In case we need to report back a redraw - doc/image can re-render
      if (this.redrawCallback && this.redrawQueued) {
        this.redrawCallback();
        this.redrawQueued = false;
      }

      // Actual drawing code
      const t0 = performance.now();
      if (this.screen) {
        let screenFrame = this.screen;
        if (
          supportsTrackProcessor &&
          this.screen instanceof ReadableStreamDefaultReader
        ) {
          const result = await this.screen.read();
          screenFrame = result.value;
        }
        this.drawFrame(
          screenFrame,
          this.positions.screen,
          this.sizes.screen,
          false,
          true
        );
      }
      let cameraFrame = this.camera;
      if (
        supportsTrackProcessor &&
        this.camera instanceof ReadableStreamDefaultReader
      ) {
        const result = await this.camera.read();
        cameraFrame = result.value;
      }
      this.drawFrame(
        cameraFrame,
        this.positions.camera,
        this.sizes.camera,
        this.isCanvasCapture,
        false
      );
      const t1 = performance.now();
      if (t1 - t0 >= FPS_INTERVAL) {
        Logger.warn(`Mixer::drawFrame took ${t1 - t0}ms.`);
      }

      // Request a frame so remote end receives updates reliably
      try {
        if (this.canvasTrack) {
          this.canvasTrack.requestFrame();
        }
      } catch (error) {
        Logger.warn(`CanvasMixer draw requestFrame error - ${error.message}`);
      }

      // Request another draw if the stream is still active
      this.bouncedDraw();
    } catch (error) {
      Logger.error(error);
      this.errorCallback(error);
    }
  }
  /* eslint-enable max-statements */

  drawFrame(video, pos, size, isCanvasCapture, isScreenStream) {
    // Pollute the canvas even if we don't have a stream, so we force a frame
    // update.
    const { context, canvas } = this;
    if (
      !video ||
      (!isCanvasCapture &&
        video instanceof HTMLVideoElement &&
        (!video.srcObject || !video.srcObject.active))
    ) {
      const imgData = context.createImageData(1, 1);
      context.putImageData(imgData, 0, 0);
      return;
    }

    // Clear prev drawn frames only for screen streams, otherwise old frames
    // show up if the window is resized (smaller).
    if (isScreenStream && !isCanvasCapture) {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }

    context.drawImage(video, pos.x, pos.y, size.width, size.height);
    if (supportsTrackProcessor && typeof video.close === 'function') {
      video.close();
    }
  }
}

export default CanvasMixer;
