import React from 'react';
import Avatar from '../generic/Avatar.js';
import SoundWave from './SoundWave.js';
import VideoStream from '../streams/VideoStream.js';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import supports from '../../utils/SupportHelper.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 5, 0, 5),
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (min-height: 500px) and (min-width: 700px)': {
      minWidth: '8rem',
      minHeight: '8rem',
    },
  },
  avatar: {
    '& .MuiAvatar-root': {
      height: '4rem',
      width: '4rem',
    },
    '@media (min-height: 500px) and (min-width: 700px)': {
      '& .MuiAvatar-root': {
        height: '128px',
        width: '128px',
      },
    },
  },
  sound: {
    position: 'absolute',
    right: '0',
    top: '0',
    '& path': {
      fill:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.primary.main,
    },
  },
}));

const LocalStream = ({
  user,
  stream,
  onEvent,
  visible = false,
  playback = { playing: [] },
  className,
  voiceActivity,
  active: { screen_capture, video },
  virtualBackground,
}) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  const localPlayback = playback.playing.find(
    (p) => p.replacement_id === user.id
  );

  if (video) {
    return (
      <VideoStream
        stream={stream}
        visible={!screen_capture}
        onEvent={onEvent}
        playback={localPlayback}
        className={className}
        pipEnabled={false}
        withNameInsert={false}
        useCanvas={supports.playCanvasStreamBug && virtualBackground !== 'off'}
      />
    );
  }

  const isTalking = voiceActivity.on && user.apiId === voiceActivity.user.apiId;

  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <div className={classes.avatar}>
            <Avatar
              url={user.guest ? null : user.largeAvatar}
              label={user.name}
              onEvent={onEvent}
              playback={localPlayback}
            />
            <SoundWave active={isTalking} className={classes.sound} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LocalStream;
