import PublicEvent from './PublicEvent.js';

class StartRecordingEvent extends PublicEvent {
  handle(msg) {
    const { _api, _session } = this.context;

    _api
      .startRecording(msg)
      .catch(error => _session.emit({ type: 'recording_error', error: error }));
  }
}

export default StartRecordingEvent;
