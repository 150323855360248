import React, { useState, useEffect, useRef, useCallback } from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';
import { Logger, FeatureDetector, DeviceManager, StreamHelpers } from 'eyeson';
import Video from '../streams/Video.js';
import AudioLevel from '../device_settings/AudioLevel.js';
import ActivityMonitor from '../../utils/ActivityMonitor.js';
import StickyNotes from '../dialog/StickyNotes.js';
import JoinButton from './JoinButton.js';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import PreviewDialog from './PreviewDialog.js';
import ExperimentalBrowserAlert from '../dialog/ExperimentalBrowserAlert.js';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DataSaverPreview from './DataSaverPreview.js';

const CAMERA_ERRORS = [
  'NotAllowedError',
  'PermissionDeniedError',
  'PermissionDismissedError',
];

const ErrorHint = ({ error: { name } }) => {
  let error_message = I18n.t('error_' + name);
  if (name === 'NotAllowedError' && FeatureDetector.isIOSDevice()) {
    error_message += ' ' + I18n.t('error:ios_permissions');
  }
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
      <SnackbarContent message={error_message} />
    </Snackbar>
  );
};

const WarningHint = ({ warning: { name } }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
      <SnackbarContent message={I18n.t('warning_' + name)} />
    </Snackbar>
  );
};

let warningTimeout = null;

const MobilePreview = (props) => {
  const {
    onExit,
    config = { times: { dialog: 10, preview: 10 } },
    onEnter,
    onChange,
    isRecording = false,
    onInactivity,
    hasPresenter = false,
    isLiveStreaming = false,
    isExperimentalBrowser = false,
  } = props;

  const [error, setError] = useState({ name: '' });
  const [warning, setWarning] = useState(null);
  const [stream, setStream] = useState(null);
  const [height, setHeight] = useState(window.innerHeight);
  const [inactive, setInactive] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [devices, setDevices] = useState({
    cameras: [],
    microphones: [],
    speakers: [],
  });
  const [facingMode, setFacingMode] = useState('user');
  const [camAvailable, setCamAvailable] = useState(true);
  const [camSwitchAvailable, setCamSwitchAvailable] = useState(true);
  const [mediaOptions, setMediaOptions] = useState({
    eco: false,
    audio: true,
    video: { facingMode: facingMode },
  });
  const [dataSaver, setDataSaver] = useState('off');
  const deviceManager = useRef();
  const activityMonitor = useRef();

  const resetInactivityMonitor = () => {
    if (activityMonitor.current) {
      activityMonitor.current.reset();
    }
  };

  const onUserAction = useCallback(() => resetInactivityMonitor(), []);

  const handleInactivityReset = () => {
    onUserAction();
    setInactive(false);
  };

  const handleInactivity = useCallback(() => {
    onUserAction();
    setInactive(true);
  }, [onUserAction]);

  const handleEnter = () => {
    onUserAction();
    deviceManager.current.storeConstraints();
    onEnter(Object.assign({}, mediaOptions, { quality: dataSaver }));
  };

  const handleExit = () => {
    deviceManager.current.stop();
    onExit({ reason: 'exit', redirect: true });
  };

  const resetError = () => {
    setError({ name: '' });
    setHasError(false);
  };

  const handleError = (error) => {
    setError(error);
    setHasError(true);
  };

  const handleWarning = (warning) => {
    setWarning(warning);
    clearTimeout(warningTimeout);
    warningTimeout = setTimeout(() => {
      setWarning(null);
    }, 5000);
  };

  const handleChange = useCallback(
    (newState) => {
      if (newState.options) {
        onChange(newState.options);
      }
      if (newState.cameras) {
        const camCount = newState.cameras.length;
        setCamAvailable(camCount > 0);
        setCamSwitchAvailable(camCount > 1);
        setDevices(newState); // includes cameras, microphones, and speakers
      }
      if (deviceManager.current.terminationInProgress) {
        return;
      }
      if (newState.stream) {
        const facingMode = StreamHelpers.getFacingMode(newState.stream);
        if (facingMode) {
          setFacingMode(facingMode);
        }
        setStream(newState.stream);
        resetError();
      }
      if (newState.error) {
        handleError(newState.error);
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (!camAvailable) {
      const newOptions = {
        audio: mediaOptions.audio,
        video: false,
        eco: mediaOptions.eco,
      };
      setMediaOptions(newOptions);
    }
  }, [camAvailable, mediaOptions.eco, mediaOptions.audio]);

  useEffect(() => {
    if (hasError) {
      Logger.error('Preview::handleError', error);
    }
  }, [hasError, error, devices]);

  const handleEcoMode = (newEcoState) => {
    const newOptions = Object.assign({}, mediaOptions, {
      video: newEcoState ? false : { facingMode: facingMode },
      eco: newEcoState,
    });
    onUserAction();
    updateMedia(newOptions);
  };

  const handleDataSaver = (mode) => {
    if (
      (mode === 'eco' && !mediaOptions.eco) ||
      (mode !== 'eco' && mediaOptions.eco)
    ) {
      handleEcoMode(mode === 'eco');
    }
    setDataSaver(mode);
  };

  const toggleAudio = () => {
    const newAudioState = !mediaOptions.audio;
    const newOptions = Object.assign({}, mediaOptions, {
      audio: newAudioState,
    });
    onUserAction();
    updateMedia(newOptions);
  };

  const toggleVideo = () => {
    const newVideoState = mediaOptions.video
      ? false
      : { facingMode: facingMode };
    const newOptions = Object.assign({}, mediaOptions, {
      video: newVideoState,
    });
    onUserAction();
    updateMedia(newOptions);
  };

  const switchCam = () => {
    if (!mediaOptions.video) {
      return;
    }
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    setFacingMode(newFacingMode);
    const newOptions = Object.assign({}, mediaOptions, {
      video: { facingMode: newFacingMode },
    });
    onUserAction();
    updateMedia(newOptions);
  };

  const updateMedia = (newOptions) => {
    resetError();
    setWarning(null);
    setMediaOptions(newOptions);
    deviceManager.current.updateWithOptions(newOptions, true);
  };

  /**
   * mount/unmount
   */
  useEffect(() => {
    deviceManager.current = new DeviceManager();
    deviceManager.current.onChange(handleChange);
    deviceManager.current.start(true);
    activityMonitor.current = new ActivityMonitor(config.times.preview);
    activityMonitor.current.onInactivity(handleInactivity);
    activityMonitor.current.start();
    return () => {
      deviceManager.current.terminate();
      activityMonitor.current.stop();
    };
  }, [config.times.preview, handleInactivity, handleChange]);

  useEffect(() => {
    let resizeTimer = null;
    let orientationTimer = null;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => setHeight(window.innerHeight), 50);
    };
    const handleOrientationChange = () => {
      clearTimeout(orientationTimer);
      orientationTimer = setTimeout(() => setHeight(window.innerHeight), 300);
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      clearTimeout(resizeTimer);
      clearTimeout(orientationTimer);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <div
      className="eyeson-preview eyeson-preview--mobile"
      style={{ height: height }}
    >
      <PreviewDialog
        inactive={inactive}
        onCountdownEnd={onInactivity}
        onPrimaryClick={handleInactivityReset}
        onSecondaryClick={handleExit}
        countDownDialogTime={config.times.dialog}
      />

      <main className="content">
        <div id="preview" className="center">
          <div className="preview-wrapper">
            <StickyNotes
              recording={isRecording}
              presenting={hasPresenter}
              liveStream={isLiveStreaming}
            />
            <div id="preview-results">
              <div
                className={classNames('video-wrap', {
                  'permission-denied': CAMERA_ERRORS.includes(error.name),
                  'no-cam':
                    !stream ||
                    mediaOptions.eco ||
                    !mediaOptions.video ||
                    !camAvailable,
                  eco: mediaOptions.eco,
                })}
              >
                <Video muted={true} stream={stream} />
              </div>
              {stream && (
                <AudioLevel
                  stream={stream}
                  onError={handleError}
                  onWarning={handleWarning}
                  inactive={!mediaOptions.audio}
                />
              )}
              {hasError && error.name && <ErrorHint error={error} />}
              {warning && <WarningHint warning={warning} />}
            </div>
            <div id="preview-actions">
              <div className="tiles">
                <IconButton onClick={toggleAudio}>
                  <Icon>{mediaOptions.audio ? 'mic' : 'mic_off'}</Icon>
                </IconButton>
                <IconButton
                  disabled={mediaOptions.eco || !camAvailable}
                  onClick={toggleVideo}
                >
                  <Icon>
                    {mediaOptions.video ? 'videocam' : 'videocam_off'}
                  </Icon>
                </IconButton>
                <IconButton
                  disabled={
                    mediaOptions.eco ||
                    !mediaOptions.video ||
                    !camSwitchAvailable
                  }
                  onClick={switchCam}
                >
                  <Icon>switch_camera</Icon>
                </IconButton>
              </div>
              <div className="join-section">
                <JoinButton onClick={handleEnter} disabled={hasError} />
              </div>
              <DataSaverPreview
                mobile={true}
                mode={dataSaver}
                onChange={handleDataSaver}
              />
            </div>
          </div>
        </div>
        <ExperimentalBrowserAlert visible={isExperimentalBrowser} />
      </main>
    </div>
  );
};

export default MobilePreview;
