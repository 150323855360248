import PublicEvent from './PublicEvent.js';

class AcceptSessionEvent extends PublicEvent {
  handle(msg) {
    const {
      type,
      session: { sessionDescriptionHandler }
    } = msg;
    const localStream = sessionDescriptionHandler.getLocalStream();
    const remoteStream = sessionDescriptionHandler.getRemoteStream();
    super.handle({
      type,
      localStream,
      remoteStream
    });
  }
}

export default AcceptSessionEvent;
