import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import BaseAction from './BaseActions.js';

/**
 * Helper to extract / prepend _eco for certain warnings.
 **/
function extractWarning(name, props) {
  const {
    mediaOptions: { eco },
  } = props;
  return !eco && name === 'ice_disconnected' ? `${name}_eco` : name;
}

class HandleError extends BaseAction {
  process(_state, props) {
    props.onExit({ reason: this.message.name, code: this.message.code });
    return {};
  }
}

class HandleWarning extends BaseAction {
  process(_state, props) {
    const warning = extractWarning(this.message.name, props);
    props.enqueueSnackbar(I18n.t(warning), {
      variant: 'warning',
      autoHideDuration: 5 * 1000,
    });
    return {};
  }
}

class HandleNotice extends BaseAction {
  process() {
    Logger.error(I18n.t(this.message.name));
    return {};
  }
}

class LogAction extends BaseAction {
  process() {
    // const { name, details } = this.message;
    // captureException(new Error(`${name}: ${details}`));
    return {};
  }
}

export { HandleError, HandleWarning, HandleNotice, LogAction };
