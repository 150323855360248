import eyeson, { immediate, FeatureDetector } from 'eyeson';

let _stream = null;
let _instance = null;
let _accessKey = null;
let _currentState = null;
let _onEvent = null;
let _isSupported = null;
const _state = {
  init: 'init',
  active: 'active',
  error: 'error',
};

const _gDMConstraints = {
  audio: {
    // channelCount: 2,
    // echoCancellation: false,
    suppressLocalAudioPlayback: true,
  },
  video: {
    displaySurface: 'browser',
  },
  systemAudio: 'exclude',
  surfaceSwitching: 'include',
  selfBrowserSurface: 'exclude',
  monitorTypeSurfaces: 'exclude',
};

const checkSupport = () => {
  if (_isSupported !== null) {
    return _isSupported;
  }
  const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
  const supportsCaptureController =
    Reflect.has(window, 'CaptureController') &&
    // eslint-disable-next-line no-undef
    typeof CaptureController.prototype.setFocusBehavior === 'function';
  const supportSuppressLocalAudioPlayback =
    supportedConstraints.suppressLocalAudioPlayback;
  const supportDisplaySurface = supportedConstraints.displaySurface;
  _isSupported =
    supportSuppressLocalAudioPlayback &&
    supportDisplaySurface &&
    supportsCaptureController;
  return _isSupported;
};

const initConstraints = () => {
  if (FeatureDetector.canStereo()) {
    _gDMConstraints.audio.channelCount = 2;
    _gDMConstraints.audio.echoCancellation = false;
  }
  return Object.assign({}, _gDMConstraints);
};

const requestScreenshare = async () => {
  const constraints = initConstraints();
  // eslint-disable-next-line no-undef
  constraints.controller = new CaptureController();
  _stream = await navigator.mediaDevices.getDisplayMedia(constraints);
  if (_stream.getAudioTracks().length === 0) {
    // "focus-capturing-application" https://chromestatus.com/feature/5119529898475520
    constraints.controller.setFocusBehavior('no-focus-change');
    stopScreenshare();
    const err = new Error('No audio found. Please enable "Share tab audio".');
    err.name = 'MissingAudioError';
    throw err;
  }
  constraints.controller.setFocusBehavior('focus-captured-surface');
  _stream.getTracks().forEach((track) => {
    if (track.kind === 'video') {
      track.enabled = false;
    }
    track.addEventListener('ended', () => {
      stop();
    });
  });
};

const changeScreenshare = async () => {
  let stream = null;
  try {
    const constraints = initConstraints();
    // eslint-disable-next-line no-undef
    constraints.controller = new CaptureController();
    stream = await navigator.mediaDevices.getDisplayMedia(constraints);
    if (stream.getAudioTracks().length === 0) {
      // "focus-capturing-application" https://chromestatus.com/feature/5119529898475520
      constraints.controller.setFocusBehavior('no-focus-change');
      stream.getTracks().forEach((track) => track.stop());
      stream = null;
      const err = new Error('No audio found. Please enable "Share tab audio".');
      err.name = 'MissingAudioError';
      throw err;
    }
    constraints.controller.setFocusBehavior('focus-captured-surface');
  } catch (error) {
    emit({ type: 'warning', name: `error_Screen_${error.name}` });
  }
  if (!stream) {
    return false;
  }
  try {
    _instance.send({ type: 'replace_stream', stream });
    _stream.getTracks().forEach((track) => track.stop());
    _stream = stream;
    _stream.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        track.enabled = false;
      }
      track.addEventListener('ended', () => {
        stop();
      });
    });
  } catch (error) {
    emit({ type: 'warning', name: 'error:msg:unknown' });
    return false;
  }
  return true;
};

const stopScreenshare = () => {
  if (_stream) {
    _stream.getTracks().forEach((track) => track.stop());
    _stream = null;
  }
};

const registerUser = () => {
  return new Promise((resolve, reject) => {
    if (_accessKey) {
      resolve();
      return;
    }
    if (!_instance) {
      _instance = eyeson.createInstance();
    }
    const onEvent = (event) => {
      if (event.type === 'guest_user') {
        _accessKey = event.token;
        _instance.offEvent(onEvent);
        resolve();
      } else if (event.type === 'error') {
        _instance.offEvent(onEvent);
        reject(new Error(event.content));
      }
    };
    try {
      _instance.onEvent(onEvent);
      _instance.send({
        type: 'request_guest_user',
        api: eyeson.config.api,
        token: eyeson.room.guest_token,
        name: '[TabAudio] ' + eyeson.user.name,
      });
    } catch (error) {
      if (_instance) {
        _instance.offEvent(onEvent);
      }
      reject(error);
    }
  });
};

const onInstanceEvent = (event) => {
  const { type } = event;
  if (type === 'room_ready') {
    if (_currentState === _state.init) {
      setCurrentState(_state.active);
      if (!_instance.user.apiId) {
        _instance.user.apiId = event.content.user.id;
      }
      emit({ type: 'tab_audio_active' });
    }
  } else if (type === 'error') {
    setCurrentState(_state.error);
    emit({ type: 'warning', name: 'error:msg:unknown' });
    stop();
  } else if (type === 'exit') {
    stop();
  }
};

const start = async () => {
  setCurrentState(_state.init);
  const initPromise = await Promise.allSettled([
    requestScreenshare(),
    registerUser(),
  ]);
  if (initPromise.some((promise) => promise.status === 'rejected')) {
    setCurrentState(_state.error);
    stopScreenshare();
    if (initPromise[0].status === 'rejected') {
      emit({
        type: 'warning',
        name: `error_Screen_${initPromise[0].reason.name}`,
      });
    } else {
      emit({ type: 'warning', name: 'error:msg:unknown' });
    }
    return;
  }
  try {
    _instance.onEvent(onInstanceEvent);
    startSession();
  } catch (error) {
    setCurrentState(_state.error);
    emit({ type: 'warning', name: 'error:msg:unknown' });
    stop();
  }
};

const setCurrentState = (state) => {
  _currentState = state;
};

const getCurrentState = () => {
  return _currentState;
};

const startSession = () => {
  _instance.start(_accessKey, { stream: _stream, sendOnly: true });
};

const stop = () => {
  if (_instance) {
    _instance.offEvent(onInstanceEvent);
    if (_instance.core) {
      clearInterval(_instance.core.pollingFallbackInterval);
    }
    _instance.destroy();
  }
  stopScreenshare();
  if (_currentState) {
    setCurrentState(null);
    emit({ type: 'tab_audio_ended' });
  }
};

const onEvent = (fn) => {
  _onEvent = fn;
};

const offEvent = () => {
  _onEvent = null;
};

const emit = (msg) => {
  if (_onEvent) {
    immediate(() => _onEvent(msg));
  }
};

const _helper = {
  checkSupport,
  start,
  stop,
  getCurrentState,
  onEvent,
  offEvent,
  changeScreenshare,
};

export default _helper;
