import PublicEvent from './PublicEvent.js';

class StopBroadcastsEvent extends PublicEvent {
  handle(msg) {
    this.context._api
      .stopAllBroadcasts()
      .then(() => super.handle({ type: msg.type }));
  }
}

export default StopBroadcastsEvent;
