/* eslint-disable */
/**
 * Creates and returns a new, throttled version of the passed function, that,
 * when invoked repeatedly, will only actually call the original function at most
 * once per every wait milliseconds. Useful for rate-limiting events that occur
 * faster than you can keep up with.
 *
 * By default, throttle will execute the function as soon as you call it for the
 * first time, and, if you call it again any number of times during the wait
 * period, as soon as that period is over. If you'd like to disable the
 * leading-edge call, pass {leading: false}, and if you'd like to disable the
 * execution on the trailing-edge, pass {trailing: false}.
 *
 * @see http://underscorejs.org/#throttle
 *
 * @param {function} func - Function to be throttled
 * @param {number} wait - N milliseconds for execution
 * @param {object} [options] - default { leading: true }
 * @return {function} - throttled version of given function
 */
const throttle = (func, wait, options) => {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

export default throttle;
/* eslint-enable */
