import BaseEvent from './BaseEvent.js';

class VirtualBackgroundEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle(msg) {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.vbgMixer) {
      if (msg.state === 'suspend') {
        _session.vbgMixer.suspend();
        if (_session.canvasMixer && _session.canvasMixer.vbgMixer) {
          _session.canvasMixer.vbgMixer.suspend();
        }
      } else if (msg.state === 'resume') {
        _session.vbgMixer.resume();
        if (_session.canvasMixer && _session.canvasMixer.vbgMixer) {
          _session.canvasMixer.vbgMixer.resume();
        }
      }
    }
  }
}

export default VirtualBackgroundEvent;
