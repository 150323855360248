/**
 * CanvasAnnotation calculates and stores mouse movements in order to draw
 * lines onto a given canvas element.
 *
 * The update calculation takes mouse position, canvas position and scale into
 * consideration. See the following description for details:
 * https://stackoverflow.com/questions/43853119/javascript-wrong-mouse-position-when-drawing-on-canvas#answer-43873988
 **/
class CanvasAnnotation {
  constructor() {
    this.canvas = null;
    this.context = null;
    this.current = null;
    this.width = 1920;
    this.height = 1080;
    this.objects = [];
    this.position = {};
  }

  /**
   * @param {HTMLCanvasElement} canvas
   */
  set canvasEl(canvas) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d', { desynchronized: true });
    this.position = canvas.getBoundingClientRect();
    this.width = canvas.width;
    this.height = canvas.height;
  }

  checkResize() {
    const lastPosition = this.position;
    const newPosition = this.canvas.getBoundingClientRect();
    if (
      newPosition.width === lastPosition.width &&
      newPosition.height === lastPosition.height &&
      newPosition.left === lastPosition.left &&
      newPosition.top === lastPosition.top
    ) {
      return;
    }
    this.position = newPosition;
  }

  start(event, color = '#9e206c', width = 5) {
    if (
      (event.type === 'touchstart' && event.touches.length > 1) ||
      (event.type === 'mousedown' && event.buttons !== 1)
    )
      return;
    event.preventDefault();
    this.checkResize();
    const { position, context } = this;
    const e = event.type === 'touchstart' ? event.touches[0] : event;
    const x = ((e.pageX - position.left) / position.width) * this.width;
    const y = ((e.pageY - position.top) / position.height) * this.height;
    this.current = {
      type: 'draw',
      lineWidth: width,
      strokeStyle: color,
      points: [{ x, y }],
    };
    this.objects.push(this.current);
    context.lineJoin = 'round';
    context.lineWidth = width;
    context.strokeStyle = color;
    context.beginPath();
    context.moveTo(x, y);
  }

  move(event) {
    const e = event.type.startsWith('touch') ? event.touches[0] : event;
    if (!e) return;
    event.preventDefault();
    const { position, current } = this;
    const x = ((e.pageX - position.left) / position.width) * this.width;
    const y = ((e.pageY - position.top) / position.height) * this.height;
    if (current) {
      current.points.push({ x, y });
    }
    requestAnimationFrame(() => this.draw(x, y));
  }

  stop(event) {
    if (!this.current) return;
    event.preventDefault();
    const points = this.current.points;
    if (points.length === 1) {
      requestAnimationFrame(() => this.drawPoint(points[0]));
    } else {
      this.move(event);
    }
  }

  drawObjects(objects) {
    const { context } = this;
    for (const entry of objects) {
      const { points } = entry;
      if (points.length === 0) {
        continue;
      }
      context.lineJoin = 'round';
      context.lineWidth = entry.lineWidth;
      context.strokeStyle = entry.strokeStyle;
      if (points.length === 1) {
        context.strokeRect(points[0].x, points[0].y, 1, 1);
      } else {
        context.beginPath();
        context.moveTo(points[0].x, points[1].y);
        for (let i = 1, n = points.length, point = null; i < n; i++) {
          point = points[i];
          context.lineTo(point.x, point.y);
        }
        context.stroke();
      }
    }
  }

  draw(x, y) {
    const { context } = this;
    context.lineTo(x, y);
    context.stroke();
  }

  drawPoint(point) {
    this.context.strokeRect(point.x, point.y, 1, 1);
  }

  clear() {
    this.current = null;
    this.objects = [];
  }
}

export default CanvasAnnotation;
