import BaseEvent from './BaseEvent.js';
import PipCam from '../PipCam.js';

class StartPipCamEvent extends BaseEvent {
  handle() {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.pipCam) {
      _session.emit({ type: 'pip_cam', active: _session.pipCam.isActive });
      return;
    }
    _session.pipCam = new PipCam(_session);
    _session.pipCam.start();
  }
}

class StopPipCamEvent extends BaseEvent {
  handle() {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (!_session.pipCam) {
      _session.emit({ type: 'pip_cam', active: false });
      return;
    }
    _session.pipCam.destroy();
    _session.pipCam = null;
  }
}

class UpdatePipCamEvent extends BaseEvent {
  handle() {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.pipCam) {
      _session.pipCam.updateCamera();
    }
  }
}

export { StartPipCamEvent, StopPipCamEvent, UpdatePipCamEvent };
