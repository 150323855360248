import React, { useEffect, useState } from 'react';
import I18n from 'i18n-js';
import Box from '@material-ui/core/Box';
import Button from '../generic/Button.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import GeolocationHelper from '../../utils/GeolocationHelper.js';
import { Logger, Geolocation } from 'eyeson';
import { CircularProgress, IconButton, useTheme } from '@material-ui/core';
import { MyLocation } from '@material-ui/icons';

const Alert = ({ children, severity }) => {
  const theme = useTheme();
  const color = theme.palette[severity].main;
  return <p style={{ color }}>{children}</p>;
};

const ShareLocationDialog = ({ dialogId, onEvent, onClose }) => {
  const [open, setOpen] = useState(true);
  const [permission, setPermission] = useState('prompt');
  const [myLatitude, setMyLatitude] = useState(null);
  const [myLongitude, setMyLongitude] = useState(null);
  const [resetLatitude, setResetLatitude] = useState(null);
  const [resetLongitude, setResetLongitude] = useState(null);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const mapId = 'location-map';

  useEffect(() => {
    const initMap = async () => {
      try {
        setLoading(true);
        setMyLatitude(null);
        setMyLongitude(null);
        setResetLatitude(null);
        setResetLongitude(null);
        const { latitude, longitude } = await Geolocation.getCurrentPosition();
        if (!open) {
          return;
        }
        setPermission('granted');
        setMyLatitude(latitude);
        setMyLongitude(longitude);
        setResetLatitude(latitude);
        setResetLongitude(longitude);
        GeolocationHelper.addMapWithPositioning(
          mapId,
          latitude,
          longitude,
          17,
          (position) => {
            setMyLatitude(position.latitude);
            setMyLongitude(position.longitude);
          }
        );
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          setPermission('denied');
          onEvent({ type: 'warning', name: 'error:location:denied' });
        } else {
          setPermission('unavailable');
          onEvent({ type: 'warning', name: 'error:location:unavailable' });
        }
        initFallbackMap();
        Logger.warn('ShareLocationDialog::initMap', error);
      }
      setLoading(false);
    };

    const initFallbackMap = () => {
      setMyLatitude(0);
      setMyLongitude(0);
      GeolocationHelper.addMapWithPositioning(mapId, 0, 0, 2, (position) => {
        setMyLatitude(position.latitude);
        setMyLongitude(position.longitude);
      });
    };

    setLoading(false);
    setSendDisabled(true);
    if (GeolocationHelper.isSupported) {
      Geolocation.getPermissionStatus().then((state) => {
        setPermission(state);
        if (state === 'denied') {
          onEvent({ type: 'warning', name: 'error:location:denied' });
          initFallbackMap();
        } else {
          initMap();
        }
      });
    } else {
      initFallbackMap();
    }
    return () => {
      GeolocationHelper.removeMap(mapId);
      setOpen(false);
    };
  }, [open, mapId, onEvent]);

  useEffect(() => {
    setSendDisabled(myLatitude === null && myLongitude === null);
  }, [myLatitude, myLongitude]);

  const onShareLocation = () => {
    const { googleMaps } = Geolocation.generateLinkFromCoordinates(
      myLatitude,
      myLongitude
    );
    onEvent({ type: 'send_chat', content: googleMaps });
    onClose();
  };

  const resetMapLocation = () => {
    setMyLatitude(resetLatitude);
    setMyLongitude(resetLongitude);
    GeolocationHelper.setMapPosition(mapId, resetLatitude, resetLongitude, 17);
  };

  return (
    <>
      <div id="location-map-wrap">
        <div id={mapId}></div>
        <div id="location-map-marker">
          <img src={GeolocationHelper.markerUrl.shadow} alt="marker-shadow" />
          <img src={GeolocationHelper.markerUrl.marker} alt="marker" />
        </div>
        {resetLatitude !== null && resetLongitude !== null && (
          <IconButton
            aria-label={I18n.t('action:reset_my_location')}
            id="location-map-reset"
            title={I18n.t('action:reset_my_location')}
            disabled={
              resetLatitude === myLatitude && resetLongitude === myLongitude
            }
            onClick={resetMapLocation}
          >
            <MyLocation />
          </IconButton>
        )}
      </div>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0} component="div">
        {loading && (
          <Box marginTop={2}>
            <CircularProgress component="span" size="1rem" />{' '}
            {I18n.t('label:loading')}
          </Box>
        )}
        {permission === 'prompt' && (
          <Alert severity="warning">{I18n.t('message:location:allow')}</Alert>
        )}
        {myLatitude !== null && myLongitude !== null && (
          <p>{I18n.t('dialog:share_location:desc')}</p>
        )}
      </DialogContentText>
      <Box display="flex" flexDirection="row-reverse">
        <Button onClick={onShareLocation} disabled={sendDisabled}>
          {I18n.t('label:share')}
        </Button>
        <Button onClick={onClose} type="secondary">
          {I18n.t('label:cancel')}
        </Button>
      </Box>
    </>
  );
};

export default ShareLocationDialog;
