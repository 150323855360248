import React from 'react';
import LayoutUser from './LayoutUser.js';
import LayoutPosition from './LayoutPosition.js';
import CustomDragLayer from './CustomDragLayer.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
}));

const UserPool = ({ type, onDrop, pool, onDoubleClick, onKeyDown }) => {
  const classes = useStyles();

  if (type === 'auto') return null;

  return (
    <div>
      <CustomDragLayer />
      <LayoutPosition onDrop={onDrop} position={-1}>
        {pool.map((u) => (
          <LayoutUser
            className={classes.chip}
            key={u.id}
            user={u}
            onDoubleClick={onDoubleClick}
            onKeyDown={onKeyDown}
          />
        ))}
      </LayoutPosition>
    </div>
  );
};

export default UserPool;
