const MAX = { width: 1920, height: 1080 };

// We never want to send a larger canvas stream than 1920x1080
// So we get the smaller of the the two ratios.
// and scale height and width accordingly.
function scaleCanvas(width, height) {
  const ratio = Math.min(MAX.width / width, MAX.height / height);
  const xScaled = toFixedNumber(width * ratio);
  const yScaled = toFixedNumber(height * ratio);

  return { width: xScaled, height: yScaled };
}

function calcCanvasScale(canvasEl) {
  const { width, height, offsetWidth, offsetHeight } = canvasEl;
  const xScale = toFixedNumber(width / offsetWidth);
  const yScale = toFixedNumber(height / offsetHeight);

  return { x: xScale, y: yScale };
}

// since .toFixed converst the number to a string, we need to convert it back
// to a number. I'm sure I won't remember the + hack after a while so better to
// put it here I guess.
// 1.3333 -> 1.33
function toFixedNumber(number) {
  return +parseFloat(number).toFixed(2);
}

// thanks to
// https://sdqali.in/blog/2013/10/03/fitting-an-image-in-to-a-canvas-object/
function drawImageCentered(canvas, imageObj) {
  const context = canvas.getContext('2d', {
    alpha: false,
    desynchronized: true,
  });
  const imageAspectRatio = imageObj.width / imageObj.height;
  const canvasAspectRatio = canvas.width / canvas.height;
  let renderableHeight = 0;
  let renderableWidth = 0;
  let xStart = 0;
  let yStart = 0;

  // If image's aspect ratio is less than canvas's we fit on height
  // and place the image centrally along width
  if (imageAspectRatio < canvasAspectRatio) {
    renderableHeight = canvas.height;
    renderableWidth = imageObj.width * (renderableHeight / imageObj.height);
    xStart = (canvas.width - renderableWidth) / 2;
    yStart = 0;
  }

  // If image's aspect ratio is greater than canvas's we fit on width
  // and place the image centrally along height
  else if (imageAspectRatio > canvasAspectRatio) {
    renderableWidth = canvas.width;
    renderableHeight = imageObj.height * (renderableWidth / imageObj.width);
    xStart = 0;
    yStart = (canvas.height - renderableHeight) / 2;
  }

  // Happy path - keep aspect ratio
  else {
    renderableHeight = canvas.height;
    renderableWidth = canvas.width;
    xStart = 0;
    yStart = 0;
  }

  context.fillStyle = '#000';
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.drawImage(
    imageObj,
    xStart,
    yStart,
    renderableWidth,
    renderableHeight
  );
}

const rotateImage = (img, type) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const width = img.width;
    const height = img.height;
    const x = width / 2;
    const y = height / 2;
    const angle = (90 * Math.PI) / 180;
    canvas.width = height;
    canvas.height = width;
    context.translate(y, x);
    context.rotate(angle);
    context.drawImage(img, -x, -y, width, height);
    canvas.toBlob(resolve, type);
  });
};

const rotateCanvas = (canvas, rotationInDegrees) => {
  const context = canvas.getContext('2d');
  const { width, height } = canvas;
  const angle = rotationInDegrees % 360;

  const tempCanvas = cloneCanvas(canvas);

  if (angle === 90 || angle === 270) {
    canvas.width = height;
    canvas.height = width;
  }

  context.clearRect(0, 0, width, height);
  context.save();

  if (angle === 90 || angle === 270) {
    context.translate(height / 2, width / 2);
  } else {
    context.translate(width / 2, height / 2);
  }

  context.rotate((angle * Math.PI) / 180);
  context.drawImage(tempCanvas, -tempCanvas.width / 2, -tempCanvas.height / 2);
  context.restore();

  return canvas;
};

const cloneCanvas = (sourceCanvas) => {
  const clonedCanvas = document.createElement('canvas');
  const context = clonedCanvas.getContext('2d');
  const { width, height } = sourceCanvas;

  clonedCanvas.width = width;
  clonedCanvas.height = height;

  context.drawImage(sourceCanvas, 0, 0, width, height);

  return clonedCanvas;
};

export {
  scaleCanvas,
  cloneCanvas,
  calcCanvasScale,
  drawImageCentered,
  rotateCanvas,
  rotateImage,
};
