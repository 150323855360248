import React from 'react';
import PropTypes from 'prop-types';
import ChatFeed from './ChatFeed.js';
import ChatInput from './ChatInput.js';

const Chat = ({ me, messages, onEvent, onClose, isMobile }) => {
  if (isMobile) {
    return [
      <ChatInput
        key="input"
        onEvent={onEvent}
        onClose={onClose}
        isMobile={isMobile}
      />,
      <ChatFeed key="feed" me={me} onEvent={onEvent} messages={messages} />,
    ];
  }
  return [
    <ChatFeed key="feed" me={me} onEvent={onEvent} messages={messages} />,
    <ChatInput
      key="input"
      onEvent={onEvent}
      onClose={onClose}
      isMobile={isMobile}
    />,
  ];
};

Chat.propTypes = {
  me: PropTypes.object.isRequired,
  messages: PropTypes.array,
  onClose: PropTypes.func,
  onEvent: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  messages: [],
};

export default Chat;
