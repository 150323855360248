import hark from './hark.js';
import { StreamHelpers, FeatureDetector, immediate } from 'eyeson';

/**
 * interval: default 100ms
 * threshold: default -50db [-100, 0]
 * @see https://github.com/otalk/hark#options
 **/
const harkOptions = { play: false, threshold: -50 };
const _mobileView = FeatureDetector.environment().isPhone;
let _hidden = true;
let _showTimer = null;
let _suspendTimer = null;
let _listeners = [];

const emit = (msg) => {
  _listeners.forEach((listener) => immediate(() => listener(msg)));
};

const SpeechHelper = {
  speaking: false,
  notificationWasShown: false,
  onEvent: (handler) => {
    _listeners.push(handler);
  },
  offEvent: (handler) => {
    _listeners = _listeners.filter((listener) => listener !== handler);
  },
  startDetection: (state, stream) => {
    let speech = null;
    if (state.speech) {
      SpeechHelper.stopDetection(state);
    }
    let streamClone = new MediaStream([stream.getAudioTracks()[0].clone()]);
    StreamHelpers.enableAudio(streamClone);
    speech = hark(streamClone, harkOptions);
    if (speech === false) {
      StreamHelpers.stopStream(streamClone);
      streamClone = null;
      speech = null;
    } else {
      speech.stream = streamClone;
      SpeechHelper.notificationWasShown = false;
      speech.on('speaking', () => {
        SpeechHelper.speaking = true;
        _showTimer = setTimeout(() => {
          if (!SpeechHelper.speaking) return;
          SpeechHelper.showNotification();
        }, 1000);
      });
      speech.on('stopped_speaking', () => {
        SpeechHelper.speaking = false;
      });
    }
    return speech;
  },
  stopDetection: (state) => {
    let { speech } = state;
    if (speech) {
      speech.stop();
      if (speech.stream) {
        StreamHelpers.stopStream(speech.stream);
      }
      speech.stream = null;
      speech = null;
    }
    clearTimeout(_showTimer);
    clearTimeout(_suspendTimer);
    SpeechHelper.speaking = false;
    SpeechHelper.hideNotification();
    return speech;
  },
  suspendDetection: (state, timeout) => {
    let { speech } = state;
    if (speech && speech.state === 'running') {
      SpeechHelper.speaking = false;
      SpeechHelper.hideNotification();
      speech.suspend().then(() => {
        clearTimeout(_suspendTimer);
        _suspendTimer = setTimeout(() => {
          emit({ type: 'resume_speech_detection' });
        }, timeout);
      });
    }
  },
  resumeDetection: (state) => {
    let { speech } = state;
    if (speech && speech.state === 'suspended') {
      clearTimeout(_suspendTimer);
      SpeechHelper.notificationWasShown = false;
      speech.resume();
    }
  },
  showNotification: () => {
    if (SpeechHelper.notificationWasShown) return;
    SpeechHelper.notificationWasShown = true;
    emit({ type: 'talking_muted', state: 'on' });
    _hidden = false;
    if (_mobileView) {
      _suspendTimer = setTimeout(() => {
        SpeechHelper.notificationWasShown = false;
        SpeechHelper.hideNotification();
      }, 3000);
    }
  },
  hideNotification: () => {
    if (_hidden) return;
    emit({ type: 'talking_muted', state: 'off' });
    _hidden = true;
  },
};

export default SpeechHelper;
