import React from 'react';
import PropTypes from 'prop-types';
import { immediate } from 'eyeson';
import defaultSound from '../../assets/audio/offline.mp3';

const AudioNotification = ({ url, name, onEvent, openPanel }) => {
  if (
    name === 'new-chat-message' &&
    openPanel === 'chat' &&
    document.visibilityState === 'visible'
  ) {
    // immediate avoids clearing notifications array too early
    immediate(() => onEvent({ type: 'audio_notification_ended', name: name }));
    return null;
  }

  return (
    <audio
      src={url || defaultSound}
      type="audio/mpeg"
      onEnded={() => onEvent({ type: 'audio_notification_ended', name: name })}
      autoPlay={true}
    />
  );
};

const AudioNotifications = ({ notifications, onEvent, openPanel }) => {
  if (notifications.length === 0) {
    return null;
  }

  return notifications.map((n, idx) => (
    <AudioNotification
      key={`${n.name}-${idx}`}
      onEvent={onEvent}
      openPanel={openPanel}
      {...n}
    />
  ));
};

AudioNotifications.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  onEvent: PropTypes.func,
};

AudioNotifications.defaultProps = {
  onEvent: () => {},
};

export default AudioNotifications;
