import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import MuiIconButton from '@material-ui/core/IconButton';

const IconButton = forwardRef(
  ({ children, onClick, disabled, className, ...props }, buttonRef) => {
    const handleClick = (event) => {
      if (disabled) {
        event.preventDefault();
        return;
      }
      onClick(event);
    };

    return (
      <MuiIconButton
        ref={buttonRef}
        onClick={handleClick}
        aria-disabled={disabled}
        className={classnames(className, { 'Mui-disabled': disabled })}
        {...props}
      >
        {children}
      </MuiIconButton>
    );
  }
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  disabled: false,
  className: '',
  onClick: () => {},
};

export default IconButton;
