import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import { getScreenPresentationTracks } from '../utils/StreamHelpers.js';
import MicMixer from '../MicMixer.js';

const detectDisplaySurface = screenTrack => {
  if (screenTrack && typeof screenTrack.getSettings === 'function') {
    const settings = screenTrack.getSettings();
    if (
      Reflect.apply(Object.prototype.hasOwnProperty, settings, [
        'displaySurface'
      ])
    ) {
      return settings.displaySurface;
    }
  }
  return '';
};

class ScreenCaptureEvent extends BaseEvent {
  handle({ audio, screen = false, screenStream, surface }) {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.micMixer) {
      _session.micMixer.destroy();
    }
    _session.micMixer = new MicMixer();

    const onError = error => {
      Logger.error('ScreenCaptureEvent', error);
      if (screen) {
        let name = `error_Screen_${error.name}`;
        if (error.message.indexOf('not start audio') > -1) {
          name = `error_Screen_${error.name}_Audio`;
        }
        _session.emit({ type: 'capture_error', name });
      }
      _session.emit({ type: 'stop_presenting' });
    };

    new MediaStreamBuilder({
      audio: audio,
      video: false,
      screen: screen,
      surface: surface,
      screenStream: screenStream,
      existingStream: _session.localStream,
      micMixer: _session.micMixer,
      isPresentation: true,
      deviceMonitor: _session.deviceMonitor
    })
      .start()
      .then(stream => {
        // cleanup when screen capture is stopped via chrome's built-in/native
        // "stop sharing" button (a blue button on bottom of screen).
        let present = 'screen';
        const [screenTrack] = getScreenPresentationTracks(stream);
        if (screenTrack) {
          screenTrack.addEventListener('ended', () => {
            _session.emit({ type: 'stop_presenting' });
          });
          const displaySurface = detectDisplaySurface(screenTrack);
          if (displaySurface) {
            present = `screen-${displaySurface}`;
          }
        }
        _session.emit({ type: 'start_presenting', stream, present });
      })
      .catch(onError);
  }
}

export default ScreenCaptureEvent;
