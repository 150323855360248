import PropTypes from 'prop-types';
import LayoutUser from './LayoutUser.js';
import { DropTarget } from 'react-dnd';
import React, { Component } from 'react';

const layoutPositionTarget = {
  drop(props, monitor, component) {
    component.handleDrop(monitor.getItem());
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

class LayoutPosition extends Component {
  handleDrop = (user) => this.props.onDrop(user, this.props.position);

  render() {
    const {
      user,
      type,
      isOver,
      children,
      onDoubleClick,
      onKeyDown,
      isSpeaker,
      connectDropTarget,
    } = this.props;

    const styles = {
      margin: '1rem 0',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
    };

    if (type) {
      styles.flexBasis = type === 'nine' ? '33%' : '50%';
      styles.maxWidth = type === 'nine' ? '33%' : '50%';
    }

    return connectDropTarget(
      <div className={isOver ? 'isOver' : ''} style={styles}>
        {user ? (
          <LayoutUser
            user={user}
            position={-1}
            onDoubleClick={onDoubleClick}
            onKeyDown={onKeyDown}
            isSpeaker={isSpeaker}
          />
        ) : (
          children
        )}
      </div>
    );
  }
}

LayoutPosition.propTypes = {
  isOver: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
};

export const ItemTypes = {
  USER: 'user',
};

export default DropTarget(
  ItemTypes.USER,
  layoutPositionTarget,
  collect
)(LayoutPosition);
