import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import I18n from 'i18n-js';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem } from '@material-ui/core';
import EmojiPicker from 'emoji-picker-react';
import { useTheme } from '@material-ui/core/styles';

const getEmojiVersion = () => {
  const emoji = {
    // '16.0': '🫟',
    // 15.1: '🙂‍↔️',
    // '15.0': '🫨',
    '14.0': '🫠',
    13.1: '😶‍🌫️',
    '13.0': '🥸',
    12.1: '🧑‍🦰',
    '12.0': '🥱',
    '11.0': '🥰',
  };
  const offset = 12;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  canvas.width = 100;
  canvas.height = 30;
  ctx.fillStyle = '#f00';
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  const check = (unicode) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(unicode, 0, 0);
    return (
      ctx.getImageData(offset, offset, 1, 1).data[0] !== 0 &&
      ctx.getImageData(offset + 44, offset, 1, 1).data[0] === 0
    );
  };
  for (const version of Object.keys(emoji)) {
    if (check(emoji[version])) {
      return version;
    }
  }
  return '5.0';
};

const useStyles = makeStyles((theme) => ({
  container: {
    background:
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.primary.dark,
  },
}));

const ChatInput = ({ onEvent, onClose, isMobile = false }) => {
  const inputEl = useRef(null);
  const [message, setMessage] = useState('');
  const [settingButtonEl, setSettingButtonEl] = useState(null);
  const [emojiButtonEl, setEmojiButtonEl] = useState(null);
  const [emojiVersion, setEmojiVersion] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    if (inputEl.current) {
      if (!isMobile) {
        inputEl.current.focus();
      }
      setEmojiVersion(getEmojiVersion());
    }
  }, [isMobile]);

  const handleInput = (event) => {
    if ((event.key === 'Escape' || event.key === 'Esc') && onClose) {
      return onClose(event);
    }
    if (event.key !== 'Enter') {
      return;
    }
    if (message.trim() === '') {
      return;
    }

    onEvent({ type: 'send_chat', content: message.trim() });
    setMessage('');
  };

  const onSettingButtonClick = (event) => {
    setSettingButtonEl(event.currentTarget);
  };

  const closeSettingMenu = () => {
    setSettingButtonEl(null);
  };

  const onEmojiButtonClick = (event) => {
    setEmojiButtonEl(event.currentTarget);
  };

  const closeEmoji = () => {
    setEmojiButtonEl(null);
  };

  const onEmojiClick = ({ emoji }) => {
    const input = inputEl.current;
    closeEmoji();
    if (input) {
      const value = input.value.trim();
      let position = input.selectionStart;
      let newMessage = !value ? emoji : `${value} ${emoji}`;
      if (position < value.length) {
        newMessage =
          position === 0
            ? `${emoji} ${value}`
            : `${value.substring(0, position).trim()} ${emoji} ${value
                .substring(position)
                .trim()}`;
      }
      setMessage(newMessage);
      setTimeout(() => {
        input.focus();
        input.selectionStart = input.selectionEnd = position + emoji.length + 1;
      }, 150);
    }
  };

  const onSaveChat = () => {
    onEvent({ type: 'chat_export' });
    closeSettingMenu();
  };

  const showShareLocation = async () => {
    onEvent({ type: 'share_location_dialog' });
    closeSettingMenu();
  };

  const classes = useStyles();

  return (
    <>
      <Box p={2} className={classes.container}>
        <TextField
          fullWidth
          value={message}
          color="secondary"
          placeholder={I18n.t('aria:label:chat_input', {
            defaultValue: 'Type a message',
          })}
          inputProps={{ ref: inputEl }}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          onKeyDown={handleInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!isMobile && (
                  <IconButton
                    edge="end"
                    onClick={onEmojiButtonClick}
                    aria-controls="emoji-menu"
                    aria-haspopup="true"
                  >
                    <Icon color="action">emoji_emotions</Icon>
                  </IconButton>
                )}
                <IconButton
                  edge="end"
                  onClick={() => handleInput({ key: 'Enter' })}
                  aria-label={I18n.t('aria:label:send_message', {
                    defaultValue: 'Send message',
                  })}
                >
                  <Icon color="action">send</Icon>
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={onSettingButtonClick}
                  aria-controls="chat-options-menu"
                  aria-haspopup="true"
                >
                  <Icon color="action">more_vert</Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Menu
        id="chat-options-menu"
        keepMounted
        anchorEl={settingButtonEl}
        open={Boolean(settingButtonEl)}
        onClose={closeSettingMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <MenuItem onClick={showShareLocation}>
          {I18n.t('label:share_location')}
        </MenuItem>
        {!isMobile && (
          <MenuItem onClick={onSaveChat}>{I18n.t('label:save_chat')}</MenuItem>
        )}
      </Menu>
      <Menu
        id="emoji-menu"
        keepMounted
        anchorEl={emojiButtonEl}
        open={Boolean(emojiButtonEl)}
        onClose={closeEmoji}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        {Boolean(emojiButtonEl) && emojiVersion !== null && (
          <EmojiPicker
            getEmojiUrl={(unified) => `/images/twemoji/64/${unified}.png`}
            onEmojiClick={onEmojiClick}
            lazyLoadEmojis={true}
            emojiVersion={emojiVersion}
            theme={theme.palette.type}
          />
        )}
      </Menu>
    </>
  );
};

ChatInput.propTypes = {
  onEvent: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default ChatInput;
