import React from 'react';
import PropTypes from 'prop-types';
import DateDivider from './DateDivider.js';
import ChatMessage from './ChatMessage.js';

const ChatMessages = ({ me, messages, onEvent }) => {
  /**
   * Show a date info if two messages were on a different date.
   **/
  const showDivider = (a, b) => {
    if (!(a && b)) {
      return false;
    }
    return a.timestamp.toDateString() !== b.timestamp.toDateString();
  };

  /**
   * The header part of a chat message can be skipped if it's the same user and
   * the messages occurred in between 10 minutes (10 * 60 * 1000ms).
   **/
  const skipHeader = (a, b) => {
    if (!(a && b)) {
      return false;
    }
    if (a.content[0] === '/' || b.content[0] === '/') {
      return false;
    }

    return (
      a.user.id === b.user.id &&
      Math.abs(a.timestamp.getTime() - b.timestamp.getTime()) < 600000
    );
  };

  /**
   * Render chat messages, the inital date will have the current date before.
   * If two consecutive messages have differnt dates, the date break will be
   * displayed as well.
   **/
  return messages
    .sort((a, b) => a.timestamp - b.timestamp)
    .map((message, index, messages) => {
      const previousMessage = messages[index - 1];
      let dateDivider = '';

      if (showDivider(previousMessage, message)) {
        dateDivider = <DateDivider date={message.timestamp} />;
      }

      return (
        <div className="feed-entry" key={index}>
          {dateDivider}
          <ChatMessage
            me={me}
            onEvent={onEvent}
            skipHead={skipHeader(previousMessage, message)}
            {...message}
          />
        </div>
      );
    });
};

ChatMessages.proptTypes = {
  me: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  onEvent: PropTypes.func.isRequired,
};

export default ChatMessages;
