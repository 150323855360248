import ComApi from '../ComApi.js';
import PublicEvent from './PublicEvent.js';

/**
 * Request a user from guest token.
 * use same error if meeting is locked due to backwards compatibility for mobile apps
 * error 423 Locked = meeting locked
 **/
class RequestGuestUserEvent extends PublicEvent {
  handle(msg) {
    return new ComApi(msg.api, msg.token).requestUser(
      { name: msg.name, email: msg.email, locale: msg.locale },
      response => {
        if (!response.access_key) {
          let isLocked = response.locked;
          if (response.error && response.error.message === 'ComApiError: 423') {
            isLocked = true;
          }
          super.handle({
            type: 'error',
            content: 'Request guest user failed',
            locked: isLocked
          });
          return;
        }
        super.handle({ type: 'guest_user', token: response.access_key });
      }
    );
  }
}

export default RequestGuestUserEvent;
