import I18n from 'i18n-js';
import React, { useState, Fragment } from 'react';
import LocalStream from '../streams/LocalStream.js';
import VideoStream from '../streams/VideoStream.js';
import AudioStream from '../streams/AudioStream.js';
import RemoteAudioStream from '../streams/RemoteAudioStream.js';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import supports from '../../utils/SupportHelper.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    'flex-flow': 'column',
    'justify-content': 'flex-start',
    'align-items': 'center',
    position: 'relative',
    padding: '1.5vh',
    width: (collapsed) => (collapsed ? '3.5rem' : '20rem'),
    background:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
    '& .video-stream-container': {
      height: 'auto',
      margin: theme.spacing(1),
      boxShadow: theme.shadows[1],
      borderRadius: '0.2rem',
    },
  },
  group: {
    display: 'flex',
    width: '100%',
    'justify-content': 'center',
  },
}));

const RightSidebar = (props) => {
  const [collapsed, setCollapsed] = useState(props.collapsed);
  // NOTE: A bit of "extra" state we keep track of, because tooltip would
  // otherwise keep showing in mid-air when collapsing.
  const [tooltip, setTooltip] = useState(false);
  const handleClose = () => {
    setTooltip(false);
    setCollapsed(!collapsed);
  };

  const {
    peer,
    user,
    solo,
    active,
    stream,
    sinkId,
    playback,
    handleEvent,
    localStream,
    voiceActivity,
    usePersistentAudio,
    forwardedVideoMuted,
    mediaOptions: { eco },
    virtualBackground,
  } = props;

  const classes = useStyles(collapsed);

  const remotePlayback = playback.playing.find(
    (p) => p.replacement_id !== user.id
  );

  return (
    <div className={classes.root}>
      {collapsed ? (
        <span className="element-group">
          <Tooltip
            open={tooltip}
            title={I18n.t('label:expand')}
            placement="left"
            onOpen={() => setTooltip(true)}
            onClose={() => setTooltip(false)}
          >
            <IconButton
              onClick={handleClose}
              aria-label={I18n.t('aria:label:video_preview:expand', {
                defaultValue: 'Expand video preview panel',
              })}
            >
              <ArrowLeft />
            </IconButton>
          </Tooltip>
          <AudioStream stream={stream} sinkId={sinkId} />
        </span>
      ) : (
        <Fragment>
          <span className="element-group">
            <Tooltip
              open={tooltip}
              title={I18n.t('label:collapse')}
              placement="left"
              onOpen={() => setTooltip(true)}
              onClose={() => setTooltip(false)}
            >
              <IconButton
                onClick={handleClose}
                aria-label={I18n.t('aria:label:video_preview:collapse', {
                  defaultValue: 'Collapse video preview panel',
                })}
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
          </span>
          <LocalStream
            user={user}
            stream={localStream}
            active={active}
            visible={!solo && active.sfu}
            onEvent={handleEvent}
            playback={playback}
            className="MuiPaper-rounded"
            voiceActivity={voiceActivity}
            useCanvas={
              supports.playCanvasStreamBug && virtualBackground !== 'off'
            }
          />
          <br />
          <VideoStream
            muted={usePersistentAudio}
            stream={stream}
            sinkId={sinkId}
            visible={!forwardedVideoMuted && !eco}
            onEvent={handleEvent}
            playback={remotePlayback}
            className="MuiPaper-rounded"
            withNameInsert={false}
          />
          {(forwardedVideoMuted || eco) && (
            <RemoteAudioStream
              user={peer}
              stream={stream}
              onEvent={handleEvent}
              playback={remotePlayback}
              voiceActivity={voiceActivity}
            />
          )}
        </Fragment>
      )}
    </div>
  );
};

RightSidebar.propTypes = {};

RightSidebar.defaultProps = {
  collapsed: false,
};

export default RightSidebar;
