import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';

/**
 * Handle Disconnect from Eyeson services shuting down any active
 * presentations but remember the active state in order to be able to restore
 * things when reconnecting.
 **/
class HandleDisconnect extends BaseAction {
  process(state, props) {
    return {
      restore: {
        isPresenter: state.active.isPresenter,
      },
      active: update(state.active, {
        isPresenter: { $set: false },
        presentation: {
          $set: !state.active.isPresenter && state.active.hasPresenter,
        },
      }),
    };
  }
}

/**
 * Handle Reconnect and restore things that have been pushed to our state.
 **/
class HandleReconnect extends BaseAction {
  process(state, props) {
    if (state.restore.isPresenter) {
      return {
        active: update(state.active, {
          presentation: { $set: true },
          isPresenter: { $set: true },
        }),
      };
    }

    return {};
  }
}

/**
 * Handle Offline
 **/
class HandleOffline extends BaseAction {
  process(state, props) {
    props.enqueueSnackbar(I18n.t('error_offline'), { variant: 'warning' });
    if (state.active.screen_capture) {
      return {
        notifications: update(state.notifications, {
          audio: {
            $push: [{ name: this.message.type }],
          },
        }),
      };
    } else {
      return {};
    }
  }
}

export { HandleReconnect, HandleDisconnect, HandleOffline };
