import DeviceManager from './DeviceManager.js';
import FeatureDetector from './FeatureDetector.js';

const isIOSDevice = FeatureDetector.isIOSDevice();

class DeviceMonitor {
  // eslint-disable-next-line max-statements
  constructor() {
    this.tempAudioTrack = null;
    this.tempVideoTrack = null;
    this.audioTrack = null;
    this.videoTrack = null;
    this.audioEnded = null;
    this.videoEnded = null;
    this.defaultMic = null;
    this.boundOnAudioEnd = this.onAudioEnd.bind(this);
    this.boundOnVideoEnd = this.onVideoEnd.bind(this);
    this.boundOnAudioDeviceChange = this.onAudioDeviceChange.bind(this);
    this.listeners = [];
  }

  addAudioTrack(track) {
    if (!isIOSDevice && track && track.readyState !== 'ended') {
      this.tempAudioTrack = track;
    }
  }

  addVideoTrack(track) {
    if (!isIOSDevice && track && track.readyState !== 'ended') {
      this.tempVideoTrack = track;
    }
  }

  applyTempTracks() {
    this.clearTracks();
    if (this.tempAudioTrack) {
      this.audioTrack = this.tempAudioTrack;
      this.tempAudioTrack = null;
      this.audioTrack.addEventListener('ended', this.boundOnAudioEnd);
      this.checkDefaultMic();
    }
    if (this.tempVideoTrack) {
      this.videoTrack = this.tempVideoTrack;
      this.tempVideoTrack = null;
      this.videoTrack.addEventListener('ended', this.boundOnVideoEnd);
    }
  }

  discardTempTracks() {
    this.tempAudioTrack = null;
    this.tempVideoTrack = null;
  }

  async checkDefaultMic() {
    try {
      const settings = this.audioTrack.getSettings();
      // currently only in Chromium browsers
      if (settings.deviceId === 'default') {
        const mic = (await DeviceManager.getDevices()).find(
          ({ deviceId, kind }) =>
            kind === 'audioinput' && deviceId === 'default'
        );
        if (mic) {
          this.defaultMic = mic;
          navigator.mediaDevices.addEventListener(
            'devicechange',
            this.boundOnAudioDeviceChange
          );
        }
      }
      // eslint-disable-next-line no-empty, no-unused-vars
    } catch (error) {}
  }

  async onAudioDeviceChange() {
    try {
      const current = this.defaultMic;
      this.abortAudioDeviceChange();
      const mic = (await DeviceManager.getDevices()).find(
        ({ deviceId, kind }) => kind === 'audioinput' && deviceId === 'default'
      );
      if (
        mic &&
        current &&
        (current.label !== mic.label || current.groupId !== mic.groupId)
      ) {
        this.audioTrack.stop();
        this.onAudioEnd();
      }
      // eslint-disable-next-line no-empty, no-unused-vars
    } catch (error) {}
  }

  abortAudioDeviceChange() {
    this.defaultMic = null;
    navigator.mediaDevices.removeEventListener(
      'devicechange',
      this.boundOnAudioDeviceChange
    );
  }

  onAudioEnd() {
    this.abortAudioDeviceChange();
    if (this.videoEnded) {
      clearTimeout(this.videoEnded);
      this.emit({ type: 'all_devices_ended' });
      return;
    }
    this.audioEnded = setTimeout(() => {
      if (this.videoEnded) {
        clearTimeout(this.videoEnded);
        this.emit({ type: 'all_devices_ended' });
      } else {
        this.emit({ type: 'audio_device_ended' });
      }
    }, 1000);
  }

  onVideoEnd() {
    this.videoEnded = setTimeout(() => {
      if (!this.audioEnded) {
        this.emit({ type: 'video_device_ended' });
      }
    }, 1000);
  }

  onEvent(callback) {
    this.listeners.push(callback);
  }

  emit(msg) {
    this.listeners.forEach(listener => listener(msg));
  }

  // eslint-disable-next-line max-statements
  clearTracks() {
    clearTimeout(this.audioEnded);
    clearTimeout(this.videoEnded);
    this.abortAudioDeviceChange();
    if (this.audioTrack) {
      this.audioTrack.removeEventListener('ended', this.boundOnAudioEnd);
      this.audioTrack = null;
    }
    if (this.videoTrack) {
      this.videoTrack.removeEventListener('ended', this.boundOnVideoEnd);
      this.videoTrack = null;
    }
    this.audioEnded = null;
    this.videoEnded = null;
    this.defaultMic = null;
  }

  destroy() {
    this.listeners.length = 0;
    this.discardTempTracks();
    this.clearTracks();
  }
}

export default DeviceMonitor;
