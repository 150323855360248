import React from 'react';

// https://github.com/tasti/react-linkify/issues/78
const LinkifyDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

export default LinkifyDecorator;
