import I18n from 'i18n-js';
import React, { useState } from 'react';
import Button from '../generic/Button.js';
import DialogActions from '@material-ui/core/DialogActions';
import { getRoomToken } from '../../utils/UrlHelpers.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const EcoModeDialog = ({ eco, audio, video, quality, onEnter, onClose }) => {
  const [mode, setMode] = useState(quality !== null || eco ? 'off' : 'eco');

  const handleClick = () => {
    const token = getRoomToken();
    const path = window.location.pathname;
    window.history.replaceState(null, 'eyeson', path + '?' + token);
    const options = {
      eco: mode === 'eco',
      video: mode === 'eco' ? false : eco === true ? true : video,
      audio,
    };
    if (mode === 'low' || mode === 'medium') {
      options.quality = mode;
    }
    onEnter(token, options);
  };

  return (
    <>
      <DialogContentText>{I18n.t('data_saver:description')}</DialogContentText>
      <FormControl>
        <Select
          id="dialog-datasaver-select"
          color="secondary"
          SelectDisplayProps={{ 'aria-label': 'data saving' }}
          value={mode}
          onChange={({ target }) => setMode(target.value)}
        >
          {(quality !== null || eco) && (
            <MenuItem value="off">{I18n.t('label:disable')}</MenuItem>
          )}
          {!eco && (
            <MenuItem value="eco">{I18n.t('data_saver:mode:eco')}</MenuItem>
          )}
          {quality !== 'low' && (
            <MenuItem value="low">
              {I18n.t('data_saver:mode:low_quality')}
            </MenuItem>
          )}
          {quality !== 'medium' && (
            <MenuItem value="medium">
              {I18n.t('data_saver:mode:medium_quality')}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <DialogActions>
        <Button onClick={handleClick}>{I18n.t('label:switch')}</Button>
        <Button type="secondary" onClick={onClose}>
          {I18n.t('label:cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default EcoModeDialog;
