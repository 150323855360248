import FeatureDetector from './FeatureDetector.js';
import MediaStreamBuilder from './MediaStreamBuilder.js';
import eyesonOptions from './options.js';
import Logger from './Logger.js';
import { stopStream } from './utils/StreamHelpers.js';
import VirtualBackgroundMixer from './VirtualBackgroundMixer.js';
import DeviceManager from './DeviceManager.js';
import DeviceMonitor from './DeviceMonitor.js';

class PipCam {
  constructor(session) {
    this.video = null;
    this.stream = null;
    this.canvasStream = null;
    this.vbgMixer = null;
    this.deviceMonitor = null;
    this.session = session;
    this.isActive = false;
    this.startTimer = null;
  }

  // eslint-disable-next-line max-statements
  async start() {
    const { session } = this;
    if (!FeatureDetector.hasPipCamSupport()) {
      session.emit({ type: 'pip_cam_warning_unsupported' });
      return;
    }
    try {
      this.startTimer = setTimeout(
        () => this.onError(new Error('Starting timeout 5s')),
        5000
      );
      this.initFakeStream();
      await this.initVideoAndPip();
      await this.initCamera();
      clearTimeout(this.startTimer);
      this.isActive = true;
      session.emit({ type: 'pip_cam', active: true });
    } catch (error) {
      this.onError(error);
    }
  }

  onError(error) {
    const { session } = this;
    clearTimeout(this.startTimer);
    Logger.error('PipCam error', error);
    if (session) {
      session.emit({ type: 'pip_cam_error' });
      session.emit({ type: 'stop_pip_cam' });
    } else {
      this.stop();
    }
  }

  // eslint-disable-next-line max-statements
  async updateCamera() {
    if (!this.isActive) {
      return;
    }
    const { video, stream, canvasStream, vbgMixer, deviceMonitor } = this;
    this.startTimer = setTimeout(
      () => this.onError(new Error('Starting timeout 5s')),
      5000
    );
    try {
      video.srcObject = canvasStream;
      await video.play();
      if (deviceMonitor) {
        deviceMonitor.destroy();
        this.deviceMonitor = null;
      }
      if (vbgMixer) {
        vbgMixer.stopOriginalStream();
        vbgMixer.terminate();
        this.vbgMixer = null;
      }
      if (stream) {
        stopStream(stream);
        this.stream = null;
      }
      await this.initCamera();
      clearTimeout(this.startTimer);
    } catch (error) {
      this.onError(error);
    }
  }

  // eslint-disable-next-line max-statements
  initFakeStream() {
    const { widescreen } = eyesonOptions;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { alpha: false });
    canvas.width = 640;
    canvas.height = widescreen ? 360 : 480;
    ctx.fillStyle = '#404040';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    // https://aydos.com/svgedit/
    const path = new Path2D(
      widescreen
        ? 'M320 180c31 0 56-25 56-56s-25-56-56-56-56 25-56 56 25 56 56 56zm0 28c-37 0-112 19-112 56v28h224v-28c0-37-75-56-112-56z'
        : 'M320 240c31 0 56-25 56-56s-25-56-56-56-56 25-56 56 25 56 56 56zm0 28c-37 0-112 19-112 56v28h224v-28c0-37-75-56-112-56z'
    );
    ctx.fillStyle = '#212121';
    ctx.fill(path);
    this.canvasStream = canvas.captureStream();
  }

  async initVideoAndPip() {
    const video = document.createElement('video');
    this.video = video;
    video.muted = true;
    video.playsInline = true;
    video.onleavepictureinpicture = () => this.onExitPip();
    video.onpause = ({ target }) => this.onVideoPause(target);
    video.srcObject = this.canvasStream;
    await video.play();
    await video.requestPictureInPicture();
  }

  // eslint-disable-next-line max-statements
  async initCamera() {
    const { virtualBackground } = this.session.options;
    if (virtualBackground) {
      const type = DeviceManager.getStoredVirtualBackgroundType();
      this.vbgMixer = new VirtualBackgroundMixer('PipCam');
      this.vbgMixer.changeBackground(type);
    }
    this.deviceMonitor = new DeviceMonitor();
    this.deviceMonitor.onEvent(event => this.onDeviceEvent(event));

    const options = {
      audio: false,
      video: true,
      virtualBackground: virtualBackground,
      vbgMixer: this.vbgMixer,
      deviceMonitor: this.deviceMonitor
    };
    this.stream = await new MediaStreamBuilder(options).start();
    this.video.srcObject = this.stream;
    await this.video.play();
  }

  onExitPip() {
    if (this.session) {
      this.session.emit({ type: 'stop_pip_cam' });
    } else {
      this.stop();
    }
  }

  onVideoPause(video) {
    // eslint-disable-next-line no-empty-function
    setTimeout(() => video.play().catch(() => {}));
  }

  onDeviceEvent({ type }) {
    if (type.endsWith('_ended')) {
      clearTimeout(this.startTimer);
      if (this.session) {
        this.session.emit({ type: 'pip_cam_warning_video_ended' });
        this.session.emit({ type: 'stop_pip_cam' });
      } else {
        this.stop();
      }
    }
  }

  // eslint-disable-next-line max-statements
  async stop() {
    const { video, stream, canvasStream, vbgMixer, deviceMonitor } = this;
    clearTimeout(this.startTimer);
    if (video) {
      video.onpause = null;
      video.onleavepictureinpicture = null;
      if (document.pictureInPictureElement === video) {
        try {
          await document.exitPictureInPicture();
          // eslint-disable-next-line no-empty, no-unused-vars
        } catch (error) {}
      }
      video.srcObject = null;
      this.video = null;
    }
    if (deviceMonitor) {
      deviceMonitor.destroy();
      this.deviceMonitor = null;
    }
    if (vbgMixer) {
      vbgMixer.stopOriginalStream();
      vbgMixer.terminate();
      this.vbgMixer = null;
    }
    if (stream) {
      stopStream(stream);
      this.stream = null;
    }
    if (canvasStream) {
      stopStream(canvasStream);
      this.canvasStream = null;
    }
    if (this.isActive) {
      if (this.session) {
        this.session.emit({ type: 'pip_cam', active: false });
      }
      this.isActive = false;
    }
  }

  async destroy() {
    await this.stop();
    this.session = null;
  }
}

export default PipCam;
