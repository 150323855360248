const padStartSupport = typeof String.prototype.padStart === 'function';

const pad2 = (num) => {
  if (padStartSupport) {
    return String(num).padStart(2, '0');
  }
  return (num < 10 ? '0' : '') + num;
};

const Utils = {};

/**
 * ensureDate will convert a date if not already.
 **/
Utils.ensureDate = function (date) {
  return (
    {
      number: new Date(date),
      string: new Date(Date.parse(date)),
    }[typeof date] || date
  );
};

/**
 * formatDate will try native JavaScript toLocalDate method or build own format
 * to output.
 **/
Utils.formatDate = function (dateParam) {
  let date = Utils.ensureDate(dateParam);
  if (!date.toLocaleDateString) {
    return Utils.beautifulDate(date);
  }

  return date.toLocaleDateString();
};

/**
 * monthNames.
 **/
Utils.monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * beautifulDate will present a date like November 25th, 2016.
 **/
Utils.beautifulDate = function (dateParam) {
  let date = Utils.ensureDate(dateParam);
  const year = date.getFullYear();
  const month = Utils.monthNames[date.getMonth()];
  let day = date.getDate();
  day += { 1: 'st', 2: 'nd', 3: 'rd' }[day % 10] || 'th';

  let formattedDate = `${month} ${day}`;
  if (year !== new Date().getFullYear()) {
    formattedDate += `, ${year}`;
  }
  return formattedDate;
};

/**
 * formatTime will try native JavaScript toLocalTime method or build a own
 * format to output.
 **/
Utils.formatTime = function (dateParam) {
  let date = Utils.ensureDate(dateParam);
  if (!date.toLocaleTimeString) {
    return date.toTimeString().split(':').slice(0, 2).join(':');
  }

  return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
};

Utils.displaySeconds = (seconds) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds / 60) % 60;
  const s = seconds % 60;
  const useH = h > 0;
  return (useH ? pad2(h) + ':' : '') + (useH ? pad2(m) : m) + ':' + pad2(s);
};

export default Utils;
