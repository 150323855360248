import I18n from 'i18n-js';
import React from 'react';
import QRCode from 'qrcode.react';
import CopyInput from './../layout/CopyInput.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Arrow from '../../assets/arrow.svg';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '1rem 0',
    '@media (max-width: 350px)': {
      display: 'block',
    },
  },
  qr: {
    marginRight: '1rem',
    border: '0.5rem solid #f0f0f0',
  },
  caption: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.grey[600]
        : theme.palette.grey[300],
  },
  arrow: {
    '@media (max-width: 350px)': {
      display: 'none',
    },
  },
}));

const QuickJoinDialog = ({ dialogId, onCopy, token, options }) => {
  const classes = useStyles();
  let url = `${window.location.href.split('?')[0]}?guest=${token}`;
  if (options.suggest_guest_names === false) {
    url += '&suggest=false';
  }

  return (
    <>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0}>
        {I18n.t('dialog:quick_join')}
      </DialogContentText>
      <CopyInput
        id="quick-join-link"
        value={url}
        onCopy={() => onCopy({ type: 'copy_guest_link' })}
        shareMessage={I18n.t('dialog:share:title')}
      />
      <div className={classes.row}>
        <QRCode
          value={url}
          bgColor="#f0f0f0"
          fgColor="#404040"
          className={classes.qr}
          aria-label={I18n.t('aria:label:qrcode_link', {
            defaultValue: 'QR code link',
          })}
        />

        <Typography variant="caption" className={classes.caption}>
          <div>{I18n.t('dialog:quick_join:desc:qr')}</div>

          <div>
            <div>{I18n.t('dialog:quick_join:qr')}</div>
            <img
              src={Arrow}
              alt="Pointer towards QR code"
              className={classes.arrow}
            />
          </div>
        </Typography>
      </div>
    </>
  );
};

export default QuickJoinDialog;
