import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dateHelper from '../../../../utils/dateHelper.js';
import Avatar from '../../../generic/Avatar.js';
import Linkify from 'react-linkify';
import LinkifyDecorator from './LinkifyDecorator.js';

class PlainMessage extends Component {
  /**
   * It's always a plain message.
   **/
  static test(props) {
    return true;
  }

  render() {
    const { user, timestamp, content } = this.props;
    return (
      <div className="eyeson-chat-message">
        <Avatar url={user.avatar} label={user.name} />
        <div className="content">
          <span className="details">
            <strong className="username">{user.name}</strong>
            <span className="date">{dateHelper.formatTime(timestamp)}</span>
          </span>
          <Linkify className="body" componentDecorator={LinkifyDecorator}>
            {content}
          </Linkify>
        </div>
      </div>
    );
  }
}

PlainMessage.propTypes = {
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date).isRequired,
};

export default PlainMessage;
