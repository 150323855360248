import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import React, { Fragment, useState, useEffect } from 'react';
import Button from '../generic/Button.js';
import TextField from '../generic/TextField.js';
import Switch from '../generic/Switch.js';
import Icon from '@material-ui/core/Icon';
import DialogActions from '@material-ui/core/DialogActions';
import LiveStreamOption from './LiveStreamOption.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import overlayImg from '../../assets/logo-overlay.png';
import overlayImgWidescreen from '../../assets/logo-overlay-widescreen.png';

const fixUrlEnd = (url) => (url[url.length - 1] === '/' ? url : url + '/');

const LiveStreamDialog = ({
  dialogId,
  eyeson,
  onEvent,
  activated = [],
  widescreen = false,
  broadcasts = [],
  broadcastHelper = null,
  liveStreamPhase = 'initial',
}) => {
  const [rtmpUrl, setRtmpUrl] = useState('');
  const [rtmpKey, setRtmpKey] = useState('');
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => broadcastHelper.initApis(), [broadcastHelper]);

  const published = (platform) =>
    broadcastHelper.isPublished(platform, broadcasts);

  const multipleBroadcastsPublished =
    broadcasts.filter((b) => b.player_url).length > 1;

  const deactivateAll = () => {
    broadcastHelper.deactivateAll();
    onEvent({ type: 'stop_live_stream' });
  };

  const sendRtmp = () => {
    if (rtmpUrl === '' || rtmpKey === '') {
      return;
    }
    const streamUrl = fixUrlEnd(rtmpUrl) + rtmpKey;
    if (showLogo === true) {
      const url =
        window.location.origin +
        (widescreen ? overlayImgWidescreen : overlayImg);
      eyeson.core.comApi.setLayer({ url });
    }
    eyeson.core.comApi
      .startBroadcast({ streamUrl }, 'generic')
      .then(() => broadcastHelper.activate('generic'))
      .catch((err) => {
        onEvent({ type: 'warning', name: 'dialog:live_stream:rtmp_error' });
        Logger.error(err);
        if (showLogo === true) {
          eyeson.core.comApi.clearFrontLayer();
        }
      });
  };

  const stopRtmp = () => {
    eyeson.core.comApi.stopBroadcast('generic');
    eyeson.core.comApi.clearFrontLayer();
  };

  const isActive = (platform) =>
    broadcasts.find((broadcast) => broadcast.platform === platform);

  return (
    <Fragment>
      {isActive('generic') ? (
        <>
          <DialogContentText tabIndex={0}>
            {I18n.t('dialog:live_stream:rtmp')}
          </DialogContentText>

          <LiveStreamOption
            published
            title="RTMP"
            icon={<Icon>settings_input_antenna</Icon>}
            onClick={stopRtmp}
          />
        </>
      ) : (
        <>
          <DialogContentText tabIndex={0}>
            {I18n.t('dialog:live_stream:rtmp')}
          </DialogContentText>

          <TextField
            fullWidth
            id="input-livestream-rtmp-url"
            value={rtmpUrl}
            onChange={(val) => setRtmpUrl(val)}
            label={I18n.t('dialog:live_stream:rtmp_url')}
          />
          <TextField
            fullWidth
            id="input-livestream-rtmp-key"
            value={rtmpKey}
            onChange={(val) => setRtmpKey(val)}
            label={I18n.t('dialog:live_stream:rtmp_key')}
          />

          <DialogActions>
            <label htmlFor="dialog-livestream-showlogo-switch">
              {I18n.t('dialog:live_stream:show_eyeson_logo')}
            </label>
            <Switch
              id="dialog-livestream-showlogo-switch"
              on={showLogo}
              onClick={() => setShowLogo(!showLogo)}
            />
          </DialogActions>

          <LiveStreamOption
            title="RTMP"
            onClick={sendRtmp}
            icon={<Icon>settings_input_antenna</Icon>}
            spinning={activated['generic'] && liveStreamPhase !== 'published'}
            published={published('generic')}
          />
        </>
      )}

      <DialogActions>
        {multipleBroadcastsPublished && (
          <>
            <hr />
            <Button onClick={deactivateAll}>
              {I18n.t('btn:label:stop_all')}
            </Button>
          </>
        )}
      </DialogActions>
    </Fragment>
  );
};

export default LiveStreamDialog;
