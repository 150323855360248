import BaseAction from './BaseActions.js';
import update from 'immutability-helper';
import instanceHelper from '../../utils/eyesonInstanceHelper.js';

/**
 * Set the layout.
 **/
class SetLayout extends BaseAction {
  process(state, props) {
    props.eyeson.send({ type: 'set_layout', params: this.message.params });
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
    };
  }
}

/**
 * Toggle the name inserts.
 **/
class ToggleNameInserts extends BaseAction {
  process(state, props) {
    const { show_names, layout, layout_name, layout_users, voice_activation } =
      state.options;
    const params = {
      show_names: !show_names,
      layout,
      name: layout_name,
      users: layout_users,
      voice_activation,
    };
    props.eyeson.send({ type: 'set_layout', params: params });
  }
}

/**
 * Updates current room options.
 **/
class OptionsUpdate extends BaseAction {
  process(state, props) {
    const showLocalStream = state.solo && !this.message.options.layout_users;
    instanceHelper.onLayoutUpdate(this.message.options);
    return {
      options: update(state.options, { $set: this.message.options }),
      showLocalStream: showLocalStream,
    };
  }
}

export { SetLayout, ToggleNameInserts, OptionsUpdate };
