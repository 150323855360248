/**
 * Broadcasts Facade.
 **/
class BroadcastsFacade {
  constructor(broadcasts, eyeson, liveStream) {
    this.broadcasts = broadcasts;
    this.playerUrls = broadcasts
      .filter((broadcast) => {
        return broadcast.player_url !== null;
      })
      .map((broadcast) => broadcast.player_url);
    this.user = eyeson.user.id;
    this.liveStream = liveStream;
  }

  get hasPlayerUrls() {
    return this.playerUrls.length > 0;
  }

  get hasBroadcasts() {
    return this.broadcasts.length > 0;
  }

  get broadcastUser() {
    if (this.broadcasts.length === 0) {
      return null;
    }
    const latest = this.broadcasts[this.broadcasts.length - 1];
    return latest.user ? latest.user.id : null;
  }

  get dialogVisible() {
    return false;
  }

  get liveStreamPhase() {
    return [
      {
        condition: () => this.broadcasts.length === 0 || !this.hasPlayerUrls,
        phase: () => 'initial',
      },
      {
        condition: () => this.hasPlayerUrls,
        phase: () => 'published',
      },
      {
        condition: () => true,
        phase: () => 'unkown',
      },
    ]
      .find((phase) => phase.condition())
      .phase();
  }
}

export default BroadcastsFacade;
