import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Tooltip from '@material-ui/core/Tooltip';

const MicCheck = ({ onClick, playerMuted }) => {
  return (
    <Tooltip title={I18n.t('label:mic_check')} placement="top">
      <IconButton
        onClick={onClick}
        color={!playerMuted ? 'secondary' : 'default'}
        aria-label={I18n.t('label:mic_check', {
          defaultValue: 'Microphone check',
        })}
        aria-pressed={!playerMuted}
      >
        {playerMuted ? <MicIcon /> : <MicOffIcon />}
      </IconButton>
    </Tooltip>
  );
};

MicCheck.propTypes = {
  onClick: PropTypes.func,
  playerMuted: PropTypes.bool,
};

export default MicCheck;
