import PublicEvent from './PublicEvent.js';
import { isPresentationStream } from '../utils/StreamHelpers.js';

class PresentationUpdateEvent extends PublicEvent {
  handle(msg) {
    super.handle(msg);
    const { _session } = this.context;
    if (_session) {
      const { presentation } = msg;
      const { localStream } = _session;
      if (
        !presentation &&
        isPresentationStream(localStream) &&
        !_session.stopPresentingSet
      ) {
        _session.emit({ type: 'stop_presenting' });
      }
    }
  }
}

export default PresentationUpdateEvent;
