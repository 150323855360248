import I18n from 'i18n-js';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Logger, FeatureDetector } from 'eyeson';
import defaultPreview from '../../assets/audio/preview.mp3';
import IconButton from '@material-ui/core/IconButton';
import PlayIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Tooltip from '@material-ui/core/Tooltip';

const AudioPreview = ({ onClick, sinkId, soundUrl }) => {
  const [playingAudio, setPlayingAudio] = useState(false);

  let audioEl = useRef(null);

  useEffect(() => updateSinkId(sinkId), [sinkId]);

  function updateSinkId(sinkId) {
    if (FeatureDetector.canChangeAudioOutput() && audioEl.current) {
      const newSinkId = !sinkId || sinkId === 'default' ? '' : sinkId;
      audioEl.current
        .setSinkId(newSinkId)
        .catch(() => Logger.warn('AudioPreview::failed to update sink'));
    }
  }

  /**
   * Play or pause the audio preview.
   **/
  function toggleSound() {
    if (playingAudio) {
      audioEl.current.pause();
    } else {
      audioEl.current.load();
      const playPromise = audioEl.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          Logger.debug('AudioPreview::toggleSound error:', error);
        });
      }
    }
    setPlayingAudio(!playingAudio);
    onClick({ playingAudio: !playingAudio });
  }

  return (
    <Tooltip title={I18n.t('tooltip:audio_preview')} placement="top">
      <div>
        <IconButton
          color={playingAudio ? 'secondary' : 'default'}
          onClick={toggleSound}
          aria-label={I18n.t('tooltip:audio_preview', {
            defaultValue: 'Play audio preview',
          })}
          aria-pressed={playingAudio}
        >
          {playingAudio ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <audio
          src={soundUrl || defaultPreview}
          ref={audioEl}
          loop={true}
          type="audio/mpeg"
          preload="auto"
        />
      </div>
    </Tooltip>
  );
};

AudioPreview.propTypes = {
  label: PropTypes.string,
  soundUrl: PropTypes.string,
};

AudioPreview.defaultProps = {
  label: '',
};

export default AudioPreview;
