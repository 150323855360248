import React, { useState, useEffect } from 'react';
import I18n from 'i18n-js';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import VirtualBackgroundTypes from '../../utils/VirtualBackgroundTypes.js';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Tooltip from '@material-ui/core/Tooltip';

const useSectionStyles = makeStyles({
  body: {
    display: 'flex',
    alignItems: 'center',
    '& label': {
      flex: '1',
      textAlign: 'left',
    },
  },
});

const useLabelStyles = makeStyles({
  root: {
    display: 'inline',
    verticalAlign: 'middle',
    color: 'inherit',
  },
});

const useFormStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'middle',
  },
}));

const useProgressStyles = makeStyles({
  root: {
    marginLeft: '0.5rem',
    verticalAlign: 'middle',
  },
});

const VirtualBackgroundPreview = ({
  eco = false,
  loading,
  background,
  vbgBlobAvailable,
  onUpdate,
  handleLocalFile,
}) => {
  const availableBackgrounds =
    VirtualBackgroundTypes.getVirtualBackgroundTypes();
  const [currentId, setCurrentId] = useState(
    availableBackgrounds.findIndex((item) => item.type === background)
  );

  useEffect(() => {
    const changedId = availableBackgrounds.findIndex(
      (item) => item.type === background
    );
    setCurrentId(changedId);
  }, [background, availableBackgrounds]);

  const changeBackground = (event) => {
    const id = event.target.value;
    const item = availableBackgrounds[id];
    onUpdate(item.type, () => {
      setCurrentId(id);
    });
  };

  const sectionStyles = useSectionStyles();
  const labelStyles = useLabelStyles();
  const formStyles = useFormStyles();
  const progressStyles = useProgressStyles();

  return (
    <section>
      <Typography
        variant="body2"
        variantMapping={{ body2: 'div' }}
        className={sectionStyles.body}
      >
        <InputLabel id="preview-vbg-background-label" classes={labelStyles}>
          {I18n.t('label:virtual_background')}
          {loading && (
            <CircularProgress
              color="secondary"
              size="1.2rem"
              classes={progressStyles}
            />
          )}
        </InputLabel>
        <FormControl classes={formStyles} disabled={eco}>
          <Select
            value={currentId}
            id="preview-vbg-background"
            labelId="preview-vbg-background-label"
            onChange={changeBackground}
            color="secondary"
            SelectDisplayProps={{ 'aria-label': 'background' }}
          >
            {availableBackgrounds.map((item, index) => {
              return item.hidden ? null : (
                <MenuItem key={index} value={index} disabled={!item.enabled}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {vbgBlobAvailable && (
          <Tooltip title={I18n.t('label:vbg:local_image')} placement="top">
            <IconButton
              onClick={handleLocalFile}
              disabled={eco}
              aria-label={I18n.t('label:vbg:local_image', {
                defaultValue: 'Custom image',
              })}
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </Tooltip>
        )}
      </Typography>
    </section>
  );
};

export default VirtualBackgroundPreview;
