import Icon from '../generic/Icon.js';
import Tooltip from '@material-ui/core/Tooltip';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FeatureDetector } from 'eyeson';
import Utils from '../../utils/a11yHelper.js';

const ActionButton = ({
  id,
  icon,
  label,
  action,
  locked,
  toggled,
  onClick,
  warning,
  tooltip,
  visible,
  children,
  disabled,
  className,
  keyShortcut,
  ariaHasPopup,
  focusAfterAction,
}) => {
  const buttonEl = useRef(null);

  const handleClick = () => {
    if (!locked) {
      Utils.registerActiveElement(buttonEl);
      buttonEl.current.blur();
      onClick({ type: action });
      if (focusAfterAction && buttonEl.current) {
        buttonEl.current.focus();
      }
    }
  };

  return (
    <Tooltip title={tooltip} placement="right">
      <button
        id={id}
        type="button"
        ref={buttonEl}
        onClick={handleClick}
        className={classNames(`eyeson-action-button ${className}`, {
          hidden: !visible,
          disabled: disabled,
          locked: locked,
        })}
        aria-label={label}
        aria-pressed={locked ? null : toggled}
        aria-keyshortcuts={keyShortcut}
        aria-disabled={locked}
        aria-haspopup={ariaHasPopup}
      >
        <Icon type={icon} title={tooltip || action} warning={warning} />
        {children}
      </button>
    </Tooltip>
  );
};

ActionButton.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  toggled: PropTypes.bool,
  warning: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  withTooltip: PropTypes.bool,
  keyShortcut: PropTypes.string,
  ariaHasPopup: PropTypes.string,
  focusAfterAction: PropTypes.bool,
};

ActionButton.defaultProps = {
  visible: true,
  toggled: null,
  className: '',
  keyShortcut: null,
  ariaHasPopup: null,
  focusAfterAction: false,
  withTooltip: FeatureDetector.canDisplayTooltips(),
};

export default ActionButton;
