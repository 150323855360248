import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';

/**
 * Snapshot
 **/
class Snapshot extends BaseAction {
  process(state, props) {
    props.eyeson.throttledSend({ type: 'snapshot' });
    return {
      active: update(state.active, {
        snapshot: { $set: true },
      }),
    };
  }
}

class SnapshotUpdate extends BaseAction {
  process(state, props) {
    const snapshots = this.message.snapshots.filter(
      (entry) => entry.created_at && entry.links && entry.links.download
    );
    if (snapshots.length === 0) {
      return {
        active: update(state.active, {
          snapshot: { $set: false },
        }),
      };
    }

    const [newSnapshot] = snapshots;
    snapshots.forEach((snapshot) => {
      snapshot.created_at_date = new Date(snapshot.created_at);
    });

    const newSnapshotList = snapshots.concat(state.snapshots);

    if (!state.active.snapshot) {
      props.enqueueSnackbar(
        I18n.t('notification:snapshot', { user: newSnapshot.creator.name })
      );
      return {
        snapshots: newSnapshotList,
      };
    }

    props.enqueueSnackbar(I18n.t('message:snapshot_success'));

    return {
      active: update(state.active, {
        snapshot: { $set: false },
      }),
      snapshots: newSnapshotList,
    };
  }
}

export { Snapshot, SnapshotUpdate };
