import I18n from 'i18n-js';
import React from 'react';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '../../generic/IconButton.js';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '0.5rem',
  },
  textField: {
    width: '4rem',
    marginRight: theme.spacing(1),
  },
}));

const NavigationTools = ({
  pageFlip,
  page,
  numPages = 1,
  available,
  handleChange,
}) => {
  const classes = useStyles();
  const handlePrev = () => pageFlip('prev');
  const handleNext = () => pageFlip('next');

  const beginning = page === 1;
  const end = page >= numPages;

  if (!available) {
    return null;
  }

  return (
    <div className={classes.row}>
      <TextField
        id="input-presentation-page"
        variant="filled"
        type="number"
        label={I18n.t('label:page')}
        margin="dense"
        value={page}
        inputProps={{
          min: 1,
          max: numPages,
        }}
        onChange={handleChange}
        className={classes.textField}
      />
      {I18n.t('label:of')} {numPages}
      <IconButton
        onClick={handlePrev}
        disabled={beginning || !page}
        aria-label={I18n.t('aria:label:previous_page', {
          defaultValue: 'previous page',
        })}
      >
        <ArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNext}
        disabled={end || !page}
        aria-label={I18n.t('aria:label:next_page', {
          defaultValue: 'next page',
        })}
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};

export default NavigationTools;
