import I18n from 'i18n-js';
import React, { Fragment } from 'react';
import CountdownDialog from './CountdownDialog.js';
import AudioNotifications from '../notifications/AudioNotifications.js';

const InactiveDialog = ({ countDownDialogTime = 100, context, dialogId }) => (
  <Fragment>
    <AudioNotifications notifications={[{ name: 'inactivity' }]} />
    <CountdownDialog
      dialogId={dialogId}
      title={I18n.t('dialog:title:inactive')}
      message={I18n.t('message:inactive')}
      startTime={countDownDialogTime}
      actionLabel={I18n.t('dialog:prompt:extend')}
      onCountdownEnd={context.onInactivity}
      onAction={() => {
        context.onEvent({
          type: 'active',
          activityMonitor: context.activityMonitor,
        });
      }}
    />
  </Fragment>
);

export default InactiveDialog;
