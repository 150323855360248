const immediate = (() => {
  const stack = [];
  const target = '*';
  const messageName = 'com.eyeson.immediate';
  const handleMessage = event => {
    if (event.source === window && event.data === messageName) {
      event.stopPropagation();
      const fn = stack.shift();
      if (fn) {
        fn();
      }
    }
  };
  window.addEventListener('message', handleMessage);
  /**
   * Returns a function that is called asynchronious and immediately, even faster
   * than setTimeout. This implementation only works in browsers that support
   * window.postMessage.
   *
   * @see https://github.com/YuzuJS/setImmediate/blob/modern-browsers-only/setImmediate.js
   *
   * @param {function} fn - Function to be called immediately
   */
  const immediateFn = fn => {
    stack.push(fn);
    window.postMessage(messageName, target);
  };
  return immediateFn;
})();

export default immediate;
