import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import { FullscreenHelper, Logger } from 'eyeson';
import supports from '../SupportHelper.js';

/**
 * Toggle device settings dialog.
 **/
class ToggleSettings extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        dialog: {
          $set: state.visible.dialog === 'settings' ? '' : 'settings',
        },
      }),
    };
  }
}

class ToggleChat extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        panel: { $set: state.visible.panel !== 'chat' ? 'chat' : '' },
      }),
    };
  }
}

class ToggleGiphy extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        panel: { $set: state.visible.panel !== 'gifs' ? 'gifs' : '' },
      }),
    };
  }
}

/**
 * Toggle mini toolbar.
 **/
class CollapsedToolbar extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        collapsed_toolbar: { $set: !state.visible.collapsed_toolbar },
      }),
    };
  }
}

/**
 * Toggle full screen.
 **/
class ToggleFullScreen extends BaseAction {
  process(_state, props) {
    const fs = new FullscreenHelper();
    fs.toggle().then(
      () => {
        if (supports.KeyboardLockAPI) {
          if (fs.isFullscreen()) {
            navigator.keyboard.lock(['Escape']).catch(Logger.error);
          } else {
            try {
              navigator.keyboard.unlock();
            } catch (error) {
              Logger.error(error);
            }
          }
        }
      },
      (error) => {
        Logger.error('ToggleFullScreen:', error);
        props.enqueueSnackbar(I18n.t('message:error:full_screen'));
      }
    );
    return {};
  }
}

/**
 * Toggle picture in picture mode (user click in toolbar)
 **/
class TogglePip extends BaseAction {
  process(state, props) {
    const {
      solo,
      forwardedVideoMuted,
      options,
      active: { screen_capture, isPresenter },
    } = state;
    const {
      mediaOptions: { eco },
    } = props;
    if (
      forwardedVideoMuted ||
      (solo && !options.layout_users) ||
      eco ||
      screen_capture ||
      isPresenter
    ) {
      return;
    }
    window.dispatchEvent(new Event('toggle_pip'));
  }
}

/**
 * Set the picture in picture mode (after event has dispatched)
 * in case the user toggles in the os view.
 **/
class SetPip extends BaseAction {
  process(state) {
    return {
      isPip: update(state.isPip, {
        $set: this.message.mode === 'picture-in-picture',
      }),
    };
  }
}

class ToggleMinified extends BaseAction {
  process(state) {
    const { minified } = this.message;

    return {
      minified: minified,
      visible: update(state.visible, {
        collapsed_toolbar: { $set: minified },
      }),
    };
  }
}

class ShowReactionPicker extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        reaction_picker: { $set: true },
      }),
    };
  }
}

class HideReactionPicker extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        reaction_picker: { $set: false },
      }),
    };
  }
}

class ToggleReactionPicker extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        reaction_picker: { $set: !state.visible.reaction_picker },
      }),
    };
  }
}

class ToggleMobileParticipants extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        mobile_participants_list: {
          $set: !state.visible.mobile_participants_list,
        },
      }),
    };
  }
}

class ToggleMobileConnectionInfo extends BaseAction {
  process(state) {
    return {
      visible: update(state.visible, {
        mobile_connection_info: { $set: !state.visible.mobile_connection_info },
      }),
    };
  }
}

class ToggleTheme extends BaseAction {
  process(state, props) {
    props.onThemeToggle(this.message.details);
  }
}

export {
  SetPip,
  TogglePip,
  ToggleMinified,
  ToggleSettings,
  CollapsedToolbar,
  ToggleFullScreen,
  ShowReactionPicker,
  HideReactionPicker,
  ToggleChat,
  ToggleGiphy,
  ToggleReactionPicker,
  ToggleTheme,
  ToggleMobileParticipants,
  ToggleMobileConnectionInfo,
};
