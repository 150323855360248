import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EyesonLogo } from '../../assets/logo.svg';

const Logo = ({ url, showBranding = true }) => {
  if (!showBranding) {
    return null;
  }
  if (url) {
    return <img src={url} alt="Logo" id="logo" className="logo" />;
  }

  return <EyesonLogo />;
};

Logo.propTypes = {
  url: PropTypes.string,
  showBranding: PropTypes.bool,
};

export default Logo;
