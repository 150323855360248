import I18n from 'i18n-js';
import Icon from '../generic/Icon.js';
import Link from '@material-ui/core/Link';
import Button from '../generic/Button.js';
import Avatar from '../generic/Avatar.js';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { getRoomToken } from '../../utils/UrlHelpers.js';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
  },
}));

const Participant = ({ placeholder, id, avatar, name }) => {
  const className = 'participant' + (placeholder ? ' placeholder' : '');

  return (
    <div className={className} key={id}>
      {name && <Avatar url={avatar} label={name} />}
      {name && <Typography variant="subtitle1">{name}</Typography>}
    </div>
  );
};

// type is "audio", "video" or "presentation"
const Podium = ({ type, icon, participants }) => {
  const classes = useStyles();

  return (
    <div className={type}>
      <Typography variant="subtitle1" className={classes.row}>
        <Icon type={icon || type} title={type} />
        {I18n.t(`eco:${type}_participants`)}
      </Typography>
      <div className="participants">
        {participants.map((p) => (
          <Participant key={p.id} {...p} />
        ))}
      </div>
    </div>
  );
};

/**
 * Podium Outline
 * When users connect to the room in call mode (without sending and receiving
 * video), we provide an outline of the current podium.
 **/
const PodiumOutline = (props) => {
  const orderedParticipants = () => {
    const { participants, sortOrder, videoSources } = props;
    return participants
      .sort((a, b) => {
        if (!sortOrder || sortOrder.length === 0) {
          return a.id < b.id ? -1 : 1;
        }
        const orderA = sortOrder.indexOf(a.id);
        const orderB = sortOrder.indexOf(b.id);
        if (orderA === -1 && orderB === -1) {
          return a.id < b.id ? -1 : 1;
        }
        if (orderA === -1) {
          return 1;
        }
        if (orderB === -1) {
          return -1;
        }
        return orderA - orderB;
      })
      .map((participant) => {
        const type = videoSources.includes(participant.id) ? 'video' : 'audio';
        return Object.assign(participant, { type: type });
      });
  };

  const buildPlaceholderPodium = (size) => {
    const placeholderParticipant = { type: 'video', placeholder: true };

    let podium = Array(size)
      .fill(placeholderParticipant)
      .map((participant, idx) => Object.assign({}, participant, { id: idx }));

    return podium;
  };

  /**
   * Start off with a placeholder podium, then splice in the actual video
   * participants.
   **/
  const buildVideoPodium = (participants) => {
    let podium = buildPlaceholderPodium(props.max);
    const videoParticipants = participants.filter((p) => p.type === 'video');

    podium.splice(0, videoParticipants.length, ...videoParticipants);

    return podium;
  };

  const handleClick = () =>
    (window.location.href = `${window.location.origin}?${getRoomToken()}`);

  if (!props.visible) {
    return null;
  }

  const {
    title = I18n.t('eco:title'),
    subtitle = I18n.t('eco:subtitle'),
    onEvent,
    active: { hasPresenter, presenter },
    withDisableOption,
  } = props;
  const participants = orderedParticipants();
  const videoPodium = buildVideoPodium(participants);
  const audioPodium = participants.filter((p) => p.type === 'audio');
  const apiPresenter = participants.filter((p) => p.id === presenter);

  return (
    <div className="podium-outline">
      <div className="header">
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{subtitle}</Typography>
        {withDisableOption && (
          <Typography variant="subtitle1">
            <Link color="secondary" onClick={handleClick}>
              {I18n.t('eco:refresh1')}
            </Link>{' '}
            {I18n.t('eco:refresh2')}
          </Typography>
        )}
      </div>
      <div className="podium-frame">
        <div className="podium">
          {hasPresenter ? (
            <Fragment>
              <Podium type="presentation" participants={apiPresenter} />
              <Button
                icon="photo_camera"
                onClick={() => onEvent({ type: 'snapshot' })}
              >
                {I18n.t('tooltip:snapshot')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Podium type="video" icon="videocam" participants={videoPodium} />
              <Podium type="audio" icon="mic" participants={audioPodium} />
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

PodiumOutline.propTypes = {
  max: PropTypes.number,
  visible: PropTypes.bool,
  sortOrder: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
};

PodiumOutline.defaultProps = {
  max: 9,
  visible: true,
  withDisableOption: true,
};

export default PodiumOutline;
