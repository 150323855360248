/*!
 * this is hark v1.2.3 from https://github.com/otalk/hark
 * it has modifications and updates in hark.suspend and hark.resume
 * and it is completely reworked for ES2015
 * and it has a new SimpleEmitter instead of WildEmitter
 */

class SimpleEmitter {
  constructor() {
    this.listeners = {};
  }
  on(type, cb) {
    const { listeners } = this;
    if (typeof cb !== 'function') {
      throw new TypeError('Listener must be a function');
    }
    if (!listeners[type]) {
      listeners[type] = [];
    }
    listeners[type].push(cb);
  }
  off(type, cb) {
    if (!type && !cb) {
      this.listeners = {};
      return;
    }
    const { listeners } = this;
    if (!listeners[type]) return;
    if (!cb) {
      delete listeners[type];
      return;
    }
    listeners[type] = listeners[type].filter((stored) => stored !== cb);
  }
  emit(type, arg1, arg2) {
    const { listeners } = this;
    if (!listeners[type]) return;
    listeners[type].forEach((cb) => cb(arg1, arg2));
  }
}

const getMaxVolume = (analyser, fftBins) => {
  let maxVolume = -100;
  analyser.getFloatFrequencyData(fftBins);

  for (let i = 4, ii = fftBins.length; i < ii; i++) {
    let fftBin = fftBins[i];
    if (fftBin > maxVolume && fftBin < 0) {
      maxVolume = fftBin;
    }
  }
  return maxVolume;
};

const audioContextType =
  window.AudioContext || window.webkitAudioContext || false;
const analyseSupported =
  !!window.AnalyserNode && !!AnalyserNode.prototype.getFloatFrequencyData;

const hark = (stream, options) => {
  let audioContext = null;
  let looperTimer = null;
  let harker = new SimpleEmitter();

  // make it not break in non-supported browsers
  if (!audioContextType || !analyseSupported) return false;

  //Config
  options = options || {};
  let smoothing = options.smoothing || 0.1;
  let interval = options.interval || 50;
  let threshold = options.threshold;
  let play = options.play;
  let history = options.history || 10;
  let running = true;
  let volume = -100;

  audioContext = options.audioContext || new audioContextType();

  // may happen in Safari when too many AudioContext's are created
  if (!audioContext) return false;

  let sourceNode, fftBins, analyser;

  analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;
  analyser.smoothingTimeConstant = smoothing;
  fftBins = new Float32Array(analyser.frequencyBinCount);

  if (stream.jquery) stream = stream[0];
  if (
    stream instanceof HTMLAudioElement ||
    stream instanceof HTMLVideoElement
  ) {
    //Audio Tag
    sourceNode = audioContext.createMediaElementSource(stream);
    if (typeof play === 'undefined') play = true;
    threshold = threshold || -50;
  } else {
    //WebRTC Stream
    sourceNode = audioContext.createMediaStreamSource(stream);
    threshold = threshold || -50;
  }

  sourceNode.connect(analyser);
  if (play) analyser.connect(audioContext.destination);

  harker.speaking = false;

  harker.suspend = () => {
    running = false;
    clearTimeout(looperTimer);
    return audioContext.suspend().then(() => {
      volume = -100;
      harker.emit('volume_change', volume, threshold);
      if (harker.speaking) {
        harker.speaking = false;
        harker.emit('stopped_speaking');
      }
    });
  };

  harker.resume = () => {
    return audioContext.resume().then(() => {
      running = true;
      looper();
    });
  };

  Object.defineProperty(harker, 'state', {
    get: () => (audioContext ? audioContext.state : 'closed'),
  });

  audioContext.onstatechange = () => {
    const { state } = audioContext;
    harker.emit('state_change', state);
    if (state === 'closed') {
      audioContext.onstatechange = null;
      audioContext = null;
      harker.off();
    }
  };

  harker.setThreshold = (t) => (threshold = t);

  harker.setInterval = (i) => (interval = i);

  harker.stop = () => {
    running = false;
    clearTimeout(looperTimer);
    harker.emit('volume_change', -100, threshold);
    if (harker.speaking) {
      harker.speaking = false;
      harker.emit('stopped_speaking');
    }
    analyser.disconnect();
    sourceNode.disconnect();
    // triggers onstatechange
    if (audioContext && audioContext.close && audioContext.state !== 'closed') {
      audioContext.close();
    }
  };

  harker.speakingHistory = [];
  for (let i = 0, speakingHistory = harker.speakingHistory; i < history; i++) {
    speakingHistory.push(0);
  }

  // Poll the analyser node to determine if speaking
  // and emit events if changed
  const looper = () => {
    looperTimer = setTimeout(() => {
      //check if stop has been called
      if (!running) return;

      const { speakingHistory } = harker;
      let currentVolume = getMaxVolume(analyser, fftBins);
      if (currentVolume !== volume) {
        volume = currentVolume;
        harker.emit('volume_change', currentVolume, threshold);
      }

      let history = 0;
      if (currentVolume > threshold && !harker.speaking) {
        // trigger quickly, short history
        for (let n = speakingHistory.length, i = n - 3; i < n; i++) {
          history += speakingHistory[i];
        }
        if (history >= 2) {
          harker.speaking = true;
          harker.emit('speaking');
        }
      } else if (currentVolume < threshold && harker.speaking) {
        for (let i = 0, n = speakingHistory.length; i < n; i++) {
          history += speakingHistory[i];
        }
        if (history === 0) {
          harker.speaking = false;
          harker.emit('stopped_speaking');
        }
      }
      speakingHistory.shift();
      speakingHistory.push(0 + (currentVolume > threshold));

      looper();
    }, interval);
  };
  looper();

  return harker;
};

export default hark;
