import { LocalStorage } from 'eyeson';

class PreferenceStore {
  getOptions() {
    const defaultOptions = {
      eco: false,
      audio: true,
      video: true,
    };
    return LocalStorage.load('preferences.options', defaultOptions);
  }

  setOptions(newOptions) {
    const options = Object.assign(this.getOptions(), newOptions);
    LocalStorage.store('preferences.options', options);
  }

  getTheme(pref = 'light') {
    const theme = LocalStorage.load('preferences.theme', pref);
    return theme === 'light' ? 'light' : 'dark';
  }

  setTheme(newTheme) {
    return LocalStorage.store('preferences.theme', newTheme);
  }

  getRetryCounter(id, token) {
    const setting = LocalStorage.load('retry.' + id);
    if (
      setting &&
      setting.token === token &&
      setting.timestamp >= Date.now() - 1000 * 60 * 10
    ) {
      return setting.counter;
    }
    return 0;
  }

  setRetryCounter(id, token, counter) {
    const setting = { counter: counter, token: token, timestamp: Date.now() };
    LocalStorage.store('retry.' + id, setting);
  }
}

export default PreferenceStore;
