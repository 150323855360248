import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { FeatureDetector } from 'eyeson';
import Logo from '../layout/Logo.js';
import Timer from '../generic/Timer.js';
import Box from '@material-ui/core/Box';
import Container from '../layout/Container.js';
import Seperator from '../generic/Seperator.js';
import ActionButton from '../layout/ActionButton.js';
import ActionMarker from '../toolbar/ActionMarker.js';
import ActionMarkerButton from './ActionMarkerButton.js';
import LiveStreamMarker from '../toolbar/LiveStreamMarker.js';
import ReactionPickerButton from '../toolbar/ReactionPickerButton.js';
import ToolbarExtras from './ToolbarExtras.js';
import Paper from '@material-ui/core/Paper';
import micIndicator from '../../utils/MicIndicator.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  micLevel: {
    display: 'none',
    background: '#08C008',
    position: 'absolute',
    top: '.415rem',
    left: '50%',
    width: '.5rem',
    height: '1.05rem',
    marginLeft: '-.25rem',
    borderRadius: '.25rem',
    willChange: 'clip-path',
    transition: 'clip-path 70ms ease',
  },
});

const Toolbar = ({
  eco,
  solo,
  audio,
  video,
  tabAudio,
  screenAsVideo,
  screenAddition,
  isPip = false,
  isLocked = false,
  eyeson,
  options = {},
  theme,
  canPip = false,
  hidden = false,
  visible,
  onEvent,
  logoUrl,
  feedback,
  canReact = true,
  minified = false,
  recording = false,
  broadcasts = [],
  isIOSDevice,
  isPresenter,
  live_stream,
  audioSupport = true,
  videoSupport = true,
  showBranding = true,
  hasPresenter,
  isFullscreen,
  screen_capture,
  hasAudioPosition,
  recordingCreatedAt = Math.floor(Date.now() / 1000),
  toggleVideoDisabled = false,
  forwardedVideoMuted,
  talkingMuted,
  hideRecording,
  disableRecording,
  hideGuestLogin,
  hideScreenshare,
  hideLayoutSettings,
  activeRecording,
}) => {
  const classes = useStyles();
  const micLevelIcon = useRef();

  const videoToolTip = () => {
    let key = !videoSupport ? 'video_support' : 'video';
    key = screen_capture ? 'video_screen_capture' : key;
    key = eco ? 'video_eco' : key;
    const state = video || !videoSupport;

    return tooltip(key, state);
  };

  const audioToolTip = () => {
    let key = !hasAudioPosition ? 'audio_position' : 'audio';
    const state = audio || !hasAudioPosition;

    return tooltip(key, state);
  };

  const presentationToolTip = () => {
    let key = eco && screen_capture ? 'not_presenter' : 'presentation';
    return tooltip(key, hasPresenter);
  };

  const pipToolTip = () => {
    if (eco) {
      return tooltip('pip_eco');
    }
    return tooltip('pip', !isPip);
  };

  const reactionTooltip = () => {
    if (hasPresenter) {
      return tooltip('reaction', hasPresenter);
    }
    if (eco) {
      return tooltip('reaction_eco');
    }
    return '';
  };

  const tooltip = (key, state) => {
    if (state === true || state === false) {
      key += state ? ':on' : ':off';
    }
    return I18n.t('tooltip:' + key);
  };

  const isGuest = !!eyeson.user.guest;

  useEffect(() => {
    if (audio) {
      micIndicator.resume();
      if (micLevelIcon.current) {
        micLevelIcon.current.style.clipPath = 'inset(100% 0 0 0)';
        micLevelIcon.current.style.display = 'block';
      }
    } else {
      micIndicator.pause();
      if (micLevelIcon.current) {
        micLevelIcon.current.style.display = 'none';
        micLevelIcon.current.style.clipPath = 'inset(100% 0 0 0)';
      }
    }
  }, [audio]);

  useEffect(() => {
    micIndicator.onUpdate((value) => {
      if (micLevelIcon.current) {
        const clip = Math.round((1 - value / 255) * 100);
        micLevelIcon.current.style.clipPath = `inset(${clip}% 0 0 0)`;
      }
    });
    return () => {
      micIndicator.offUpdate();
    };
  }, []);

  if (hidden) {
    return null;
  }

  return (
    <Paper square component="section" className="eyeson-container toolbar">
      <Container customClassNames="top-controls">
        <Logo url={logoUrl} showBranding={showBranding} />
      </Container>

      <Container customClassNames="bottom-controls" role="navigation">
        <ActionButton
          id="toggle-video"
          icon={video ? 'videocam' : 'videocam_off'}
          label={I18n.t('label:toggle_video', { defaultValue: 'Toggle video' })}
          action="toggle_video"
          locked={eco || screen_capture || toggleVideoDisabled}
          onClick={onEvent}
          tooltip={videoToolTip()}
          warning={!videoSupport}
          disabled={
            !video || !videoSupport || screen_capture || toggleVideoDisabled
          }
          keyShortcut="Control+,"
          toggled={!video}
          focusAfterAction={true}
        />
        <ActionMarkerButton
          id="toggle-audio"
          icon={audio ? 'mic' : 'mic_off'}
          label={I18n.t('label:toggle_audio', { defaultValue: 'Toggle audio' })}
          action="toggle_audio"
          onClick={onEvent}
          tooltip={audioToolTip()}
          active={talkingMuted}
          activeLabel={I18n.t('label:talking_muted')}
          stopAction="suspend_speech_detection"
          activeActionLabel={I18n.t('btn:dismiss')}
          activeActionTooltip={I18n.t('btn:dismiss')}
          warning={!audioSupport}
          disabled={!audio || !audioSupport || !hasAudioPosition}
          keyShortcut="Control+."
          toggled={!(audio || !hasAudioPosition)}
          focusAfterAction={true}
          activeLabelIsAlert={true}
        >
          <div ref={micLevelIcon} className={classes.micLevel}></div>
        </ActionMarkerButton>
        {isIOSDevice && (
          <ActionButton
            id="switch-cam"
            icon="switch_camera"
            label={I18n.t('label:toggle_camera', {
              defaultValue: 'Toggle camera',
            })}
            action="toggle_camera"
            onClick={onEvent}
            tooltip={I18n.t('label:toggle_camera')}
            locked={!video || eco || screen_capture || toggleVideoDisabled}
            warning={!videoSupport}
            disabled={
              !video || !videoSupport || screen_capture || toggleVideoDisabled
            }
          />
        )}
        <Seperator visible={!minified} />
        <ActionButton
          id="guest-link"
          icon="person_add"
          label={I18n.t('label:quick_join', { defaultValue: 'Invite guest' })}
          action="guest_link"
          onClick={onEvent}
          tooltip={tooltip('quick_join')}
          visible={
            !!eyeson.room.guest_token &&
            !hideGuestLogin &&
            !minified &&
            !isLocked
          }
          ariaHasPopup="dialog"
        />
        {!minified && (!hideScreenshare || hasPresenter) && (
          <ActionMarker
            icon="presentation"
            action="presentation"
            locked={screen_capture && !isPresenter && eco}
            active={hasPresenter}
            highlight={screenAsVideo || screenAddition || tabAudio}
            onClick={onEvent}
            tooltip={presentationToolTip()}
            label={I18n.t('label:presentation', {
              defaultValue: 'Presentation',
            })}
            ariaHasPopup="dialog"
          />
        )}
        {canPip && !minified && (
          <ActionButton
            id="toggle-pip"
            icon={isPip ? 'pip_off' : 'pip_on'}
            label={I18n.t('label:pip', { defaultValue: 'Picture in Picture' })}
            action="toggle_pip"
            locked={
              forwardedVideoMuted ||
              (solo && !options.layout_users) ||
              eco ||
              screen_capture ||
              isPresenter
            }
            tooltip={pipToolTip()}
            onClick={onEvent}
            toggled={isPip}
          />
        )}
        {canReact && (
          <ReactionPickerButton
            eyeson={eyeson}
            locked={hasPresenter || eco}
            action={video ? 'reaction' : 'ignore'}
            visible={visible.reaction_picker}
            tooltip={reactionTooltip()}
            onEvent={onEvent}
          />
        )}
        {live_stream && (
          <ActionMarker
            active
            icon="settings_input_antenna"
            action="live_stream"
            onClick={onEvent}
            label={I18n.t('label:live_stream', { defaultValue: 'Live stream' })}
            tooltip={tooltip('live_stream', live_stream)}
            activeLabel={
              <LiveStreamMarker
                eyeson={eyeson}
                onEvent={onEvent}
                broadcasts={broadcasts}
              />
            }
          />
        )}
        {recording && !hideRecording && (
          <ActionMarker
            active
            icon="recording"
            action="recording"
            onClick={onEvent}
            tooltip={tooltip('recording', recording)}
            activeLabel={<Timer start={recordingCreatedAt} />}
            labelIsStatus={false}
            stopOption={disableRecording ? <Fragment /> : null}
            label={I18n.t('label:recording', { defaultValue: 'Recording' })}
          />
        )}
        {isLocked && (
          <ActionMarker
            active
            icon="lock"
            action="meeting_locked"
            onClick={onEvent}
            activeLabel={I18n.t('label:meeting_locked', {
              defaultValue: 'Meeting locked',
            })}
            stopOption={<Fragment />}
            tooltip={tooltip('locked')}
            label={I18n.t('label:meeting_locked', {
              defaultValue: 'Meeting locked',
            })}
          />
        )}
        {(isIOSDevice || FeatureDetector.isSafari()) && (
          <ActionButton
            id="revitalize-stream"
            icon="healing"
            label={I18n.t('label:revitalize_stream', {
              defaultValue: 'Revitalize Video',
            })}
            action="revitalize_stream_dialog"
            tooltip={I18n.t('label:revitalize_stream', {
              defaultValue: 'Revitalize Video',
            })}
            onClick={onEvent}
            ariaHasPopup="dialog"
          />
        )}
        <ToolbarExtras
          theme={theme}
          canPip={canPip}
          onEvent={onEvent}
          options={options}
          isGuest={isGuest}
          hideRecording={hideRecording}
          disableRecording={disableRecording}
          hideGuestLogin={hideGuestLogin}
          hideScreenshare={hideScreenshare}
          hideLayoutSettings={hideLayoutSettings}
          guestTokenAvailable={!!eyeson.room.guest_token}
          minified={minified}
          disableSettings={isPresenter || isIOSDevice}
          activeRecording={activeRecording}
          fullscreenActive={isFullscreen}
          isLocked={isLocked}
          feedback={feedback}
          tooltip={tooltip('actions_menu')}
        />
        <Seperator visible={!minified} />
        <Box pb={1.5}>
          <ActionButton
            id="exit-room"
            icon="power_settings_new"
            label={I18n.t('label:exit', { defaultValue: 'Exit' })}
            action={isGuest ? 'exit_prompt_guest' : 'exit_prompt'}
            onClick={onEvent}
            tooltip={tooltip('exit')}
            ariaHasPopup="dialog"
          />
        </Box>
      </Container>
    </Paper>
  );
};

Toolbar.propTypes = {
  logoUrl: PropTypes.string,
  isPip: PropTypes.bool,
  canPip: PropTypes.bool,
  hidden: PropTypes.bool,
  eyeson: PropTypes.object.isRequired,
  visible: PropTypes.object.isRequired,
  onEnter: PropTypes.func,
  canReact: PropTypes.bool,
  minified: PropTypes.bool,
  recording: PropTypes.bool,
  broadcast: PropTypes.array,
  isIOSDevice: PropTypes.bool,
  videoSupport: PropTypes.bool,
  audioSupport: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  forwardedVideoMuted: PropTypes.bool,
  isLocked: PropTypes.bool,
  talkingMuted: PropTypes.bool,
  toggleVideoDisabled: PropTypes.bool,
  activeRecording: PropTypes.bool,
};

export default React.memo(Toolbar);
