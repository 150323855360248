import BaseEvent from './BaseEvent.js';

class StopPresentingEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle() {
    const { _session, _api } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.canvasMixer) {
      if (_session.canvasMixer.vbgMixer) {
        _session.canvasMixer.vbgMixer.stopOriginalStream();
        _session.canvasMixer.vbgMixer.terminate();
        _session.canvasMixer.vbgMixer = null;
      }
      _session.canvasMixer.stop();
      _session.canvasMixer = null;
    }
    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    if (_session.videoPlayer) {
      _session.videoPlayer.destroy();
      _session.videoPlayer = null;
    }
    // _session.send({ type: 'desktopstreaming', on: false });
    // _session.send({ type: 'set_presenter', on: false });
    _api.stopPresentation();
    _session.emit({ type: 'presentation_ended' });
    _session.stopPresentingSet = true;
  }
}

export default StopPresentingEvent;
