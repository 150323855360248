import React, { useState } from 'react';
import I18n from 'i18n-js';
import Box from '@material-ui/core/Box';
import Button from '../generic/Button.js';
import TextField from '../generic/TextField.js';
import DialogContentText from '@material-ui/core/DialogContentText';

const FeedbackDialog = ({ dialogId, url, onClose }) => {
  const [msg, setMsg] = useState('');

  const submit = (event) => {
    event.preventDefault();

    if (msg.trim() === '') {
      return;
    }

    const body = new FormData();
    body.append('feedback[body]', msg);
    fetch(url, {
      method: 'POST',
      headers: { Accept: 'application/json' },
      mode: 'cors',
      body,
    });
    onClose();
  };

  return (
    <>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0}>
        {I18n.t('dialog:feedback:message')}
      </DialogContentText>
      <TextField
        multiline
        fullWidth
        autoFocus
        onChange={(value) => setMsg(value)}
        label=""
        aria-label={I18n.t('aria:label:feedback', { defaultValue: 'Feedback' })}
      />
      <Box display="flex" flexDirection="row-reverse">
        <Button onClick={submit}>{I18n.t('action:send')}</Button>
      </Box>
    </>
  );
};

export default FeedbackDialog;
