import React, { Component } from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import dateHelper from '../../../../utils/dateHelper.js';
import LinkifyDecorator from './LinkifyDecorator.js';
import Icon from '@material-ui/core/Icon';

class NotifyMessage extends Component {
  /**
   * An notification message starts with "/notify ".
   **/
  static test(props) {
    return props.content.substr(0, 8) === '/notify ';
  }

  get content() {
    return this.props.content.substr(8);
  }

  render() {
    return (
      <div className="eyeson-chat-message notify">
        <span className="icon-wrap icon-wrap__notify">
          <Icon className="icon-notify">new_releases</Icon>
        </span>
        <div className="content">
          <span className="details">
            <strong className="username">{this.props.user.name}</strong>
            <span className="date">
              {dateHelper.formatTime(this.props.timestamp)}
            </span>
          </span>
          <Linkify className="body" componentDecorator={LinkifyDecorator}>
            {this.content}
          </Linkify>
        </div>
      </div>
    );
  }
}

NotifyMessage.propTypes = {
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
};

export default NotifyMessage;
