/* eslint-disable */
/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @see https://davidwalsh.name/javascript-debounce-function
 *
 * @param {function} func - Function to be debounced
 * @param {number} wait - N milliseconds for execution
 * @param {boolean} [immediate] - If true, trigger on the leading edge, default false
 * @return {function} - debounced version of given function
 */
const debounce = (func, wait, immediate) => {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export default debounce;
/* eslint-enable */
