import React from 'react';
import I18n from 'i18n-js';
import Paper from '@material-ui/core/Paper';
import Artwork from './../layout/Artwork.js';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  panel: {
    width: theme.spacing(48),
    position: 'absolute',
    'z-index': '-1',
    top: '50%',
    left: '-68%',
    textAlign: 'left',
    '& .datasaver-artwork svg': {
      top: '-8.6rem',
      position: 'absolute',
      height: '119px',
    },
  },
  paper: {
    padding: theme.spacing(3),
    position: 'relative',
    '@media (min-width: 330px)': {
      margin: theme.spacing(0, 5),
    },
    background:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
  },
  formControl: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    justifyItems: 'start',
    gap: '0.5em',
    '& > label + .MuiInput-formControl': {
      marginTop: 0,
    },
  },
  marginTop: {
    margin: theme.spacing(2, 0),
  },
  subtitle: {
    '@media (max-height: 700px)': {
      display: 'none',
    },
  },
  minifiedSection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1, 0, 0),
  },
  minifiedRow: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  minifiedLabel: {},
}));

const DataSaverDropdown = ({ value, onChange, disabled }) => {
  return (
    <Select
      id="preview-datasaver-select"
      color="secondary"
      SelectDisplayProps={{ 'aria-label': 'Data Saving' }}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      <MenuItem value="eco">{I18n.t('data_saver:mode:eco')}</MenuItem>
      <MenuItem value="low">{I18n.t('data_saver:mode:low_quality')}</MenuItem>
      <MenuItem value="medium">
        {I18n.t('data_saver:mode:medium_quality')}
      </MenuItem>
    </Select>
  );
};

const DataSaverPreview = ({
  mode,
  onChange,
  minified = false,
  mobile = false,
  disabled = false,
  className,
}) => {
  const classes = useStyles();
  const selectValue = mode === 'off' ? 'eco' : mode;
  const toggleState = mode !== 'off';

  const onChangeDataSaver = ({ target }) => {
    onChange(target.value);
  };

  const onToggleDataSaver = ({ target }) => {
    const value = target.checked ? selectValue : 'off';
    onChange(value);
  };

  if (mobile) {
    return (
      <div id="eco-mode-section" className={className}>
        <div className="row">
          <DataSaverDropdown
            value={selectValue}
            onChange={onChangeDataSaver}
            disabled={disabled}
          />
          <Switch
            id="preview-datasaver-toggle"
            checked={toggleState}
            onClick={onToggleDataSaver}
          />
        </div>
        <Typography variant="body2" color="textSecondary" component="p">
          {I18n.t('data_saver:description')}
        </Typography>
      </div>
    );
  }

  if (minified) {
    return (
      <section className={classes.minifiedSection}>
        <div className={classes.minifiedRow}>
          <div>
            <DataSaverDropdown
              value={selectValue}
              onChange={onChangeDataSaver}
              disabled={disabled}
            />
            <Tooltip
              arrow
              title={I18n.t('data_saver:description')}
              placement="top"
            >
              <HelpIcon
                style={{
                  opacity: 0.7,
                  marginInlineStart: '0.3rem',
                  fontSize: '1rem',
                }}
              />
            </Tooltip>
          </div>
          <Switch
            id="preview-datasaver-toggle"
            checked={toggleState}
            onClick={onToggleDataSaver}
          />
        </div>
      </section>
    );
  }

  return (
    <div className={classes.panel}>
      <Paper className={classes.paper}>
        <Artwork type="data_saver" className="datasaver-artwork" />
        <Typography variant="subtitle1" className={classes.subtitle}>
          {I18n.t('data_saver:title:short')}
        </Typography>
        <Typography variant="h5">{I18n.t('data_saver:subtitle')}</Typography>

        <div className={classes.marginTop}>
          {I18n.t('data_saver:description')}
        </div>

        <FormControl className={classes.formControl}>
          <DataSaverDropdown
            value={selectValue}
            onChange={onChangeDataSaver}
            disabled={disabled}
          />
          <Switch
            id="preview-datasaver-toggle"
            checked={toggleState}
            onClick={onToggleDataSaver}
          />
        </FormControl>
      </Paper>
    </div>
  );
};

export default DataSaverPreview;
