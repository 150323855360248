import EventEmitter from './eventEmitter.js';

class Transport extends EventEmitter {
  constructor(options) {
    super();
    this.connection = null;
    this.options = options;
    this.wasOpen = false;
  }

  connect() {
    const { options } = this;
    this.wasOpen = false;
    const connection = new WebSocket(
      `${options.endpoint}?auth_token=${options.auth_token}`
    );
    connection.onopen = () => {
      this.wasOpen = true;
      this.emit({ type: 'connected' });
    };
    connection.onmessage = ({ data }) => {
      this.emit({ type: 'message', message: data });
    };
    connection.onerror = () => {
      this.emit({ type: 'transportError' });
    };
    connection.onclose = () => {
      // https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close_event
      this.destroy();
    };
    this.connection = connection;
  }

  isConnected() {
    const { connection } = this;
    if (connection) {
      return connection.readyState === WebSocket.OPEN;
    }
    return false;
  }

  send(message) {
    const { connection } = this;
    if (!connection) {
      return false;
    }
    connection.send(message);
    return true;
  }

  // eslint-disable-next-line max-statements
  destroy() {
    const { connection, wasOpen } = this;
    if (connection) {
      this.connection = null;
      connection.onopen = null;
      connection.onmessage = null;
      connection.onerror = null;
      connection.onclose = null;
      if (connection.readyState <= WebSocket.OPEN) {
        connection.close();
      }
      this.emit({ type: 'disconnected', was_open: wasOpen });
    }
    this.removeAllListeners();
  }
}

export default Transport;
