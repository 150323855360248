import React from 'react';
import I18n from 'i18n-js';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { StreamHelpers } from 'eyeson';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'none',
    '@media (min-height: 780px)': {
      'flex-basis': '100%',
      display: 'flex',
      'flex-flow': 'column',
      'justify-content': 'center',
    },
    '@media (min-height: 800px)': {
      marginBottom: theme.spacing(3),
    },
  },
  h2: {
    lineHeight: '1.2em',
    '@media (max-height: 780px)': {
      fontSize: '3em',
    },
  },
}));

const Header = ({ stream, hasError }) => {
  const classes = useStyles();

  const hasAudio = StreamHelpers.hasAudio(stream);
  const hasVideo = StreamHelpers.hasVideo(stream);

  let text = I18n.t('preview:subheader:looks_and_settings');
  if (hasAudio && !hasVideo) {
    text = I18n.t('preview:subheader:mic');
  }
  if (!hasAudio && !hasVideo) {
    text = I18n.t('preview:subheader:ninja');
  }
  if (hasError) {
    text = I18n.t('preview:subheader:error');
  }

  return (
    <div className={classes.header}>
      <Typography variant="h2" className={classes.h2}>
        {I18n.t('preview:header')}
      </Typography>
      <Typography variant="subtitle1">{text}</Typography>
    </div>
  );
};

export default Header;
