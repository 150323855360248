import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Switch from '../../generic/Switch.js';
import LayoutOption from './LayoutOption.js';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selection: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LayoutSelectionDialog = ({
  dialogId,
  onEvent,
  showNames,
  layoutName,
  layoutOptions,
  widescreen,
}) => {
  const classes = useStyles();

  const onClick = (name) => {
    onEvent({ type: 'layout_dialog', layout: name });
  };
  const toggleNameInserts = () => onEvent({ type: 'toggle_name_inserts' });
  const isSelected = ({ type, alternatives }) => {
    return (
      (layoutName &&
        (layoutName === type ||
          (alternatives && alternatives.includes(layoutName)))) ||
      (!layoutName && type === 'auto')
    );
  };

  return (
    <Fragment>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0}>
        {I18n.t('layout_selection_descr')}
      </DialogContentText>
      {Object.keys(layoutOptions).map((group) => (
        <div className={classes.selection} key={group} role="group">
          {layoutOptions[group]
            .filter((option) =>
              widescreen === false && option.widescreenOnly ? false : true
            )
            .map((option) => {
              return (
                <LayoutOption
                  key={option.key}
                  name={option.name}
                  group={group}
                  layoutKey={option.key}
                  onClick={onClick}
                  selected={isSelected(option)}
                />
              );
            })}
        </div>
      ))}

      <DialogActions>
        <label htmlFor="dialog-nameinserts-switch">
          {I18n.t('name_inserts_descr')}
        </label>
        <Switch
          id="dialog-nameinserts-switch"
          on={!!showNames}
          disabled={false}
          onClick={toggleNameInserts}
        />
      </DialogActions>
    </Fragment>
  );
};

LayoutSelectionDialog.propTypes = {
  dialogId: PropTypes.string,
  onEvent: PropTypes.func.isRequired,
  layoutOptions: PropTypes.object,
};

LayoutSelectionDialog.defaultProps = {
  dialogId: '',
  showNames: true,
  layoutName: 'auto',
  layoutOptions: {
    normal: [
      { name: 'layout_auto', key: 'auto', type: 'auto' },
      {
        name: 'layout_one',
        key: 'one',
        type: 'one',
        alternatives: ['aspect-fit'],
      },
      { name: 'layout_two', key: 'two', type: 'two' },
      { name: 'layout_four', key: 'four', type: 'four' },
      { name: 'layout_nine', key: 'nine', type: 'nine' },
    ],
    speaker: [
      {
        name: 'layout_one_plus_six',
        key: 'one_plus_six',
        type: 'present-upper-6',
        alternatives: ['present-upper-6-aspect-fit'],
      },
      {
        name: 'layout_two_plus_six',
        key: 'two_plus_six',
        type: 'present-two-upper-6',
        alternatives: ['present-two-upper-6-aspect-fit'],
      },
      {
        name: 'layout_widescreen_one_plus_six',
        key: 'widescreen_one_plus_six',
        type: 'present-one-6',
        alternatives: ['present-one-6-aspect-fit'],
        widescreenOnly: true,
      },
    ],
  },
};

export default LayoutSelectionDialog;
