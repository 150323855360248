import CanvasStream from '../streams/CanvasStream.js';
import React, { Fragment, Component } from 'react';
import { Logger } from 'eyeson';
import {
  calcCanvasScale,
  drawImageCentered,
  rotateImage,
} from '../../utils/CanvasHelper.js';

// Note: 'Image' is reserved
class ImagePresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      page: 1,
      file: this.props.file,
      allFiles: this.props.presentationFiles,
      scale: {},
      drawObjects: [],
      rotationActive: false,
    };
    this.img = null;
    this.drawObjects = {};
  }

  componentDidMount() {
    this.setImg(this.props.file);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.file.name !== prevProps.file.name ||
      this.props.file.lastModified !== prevProps.file.lastModified
    ) {
      this.setImg(this.props.file);
    }
  }

  componentWillUnmount() {
    this.drawObjects = {};
  }

  setRef = (node) => {
    this.canvasEl = node;
    this.canvasCtx = this.canvasEl.getContext('2d');
  };

  findPage = (file) => {
    let page = 1;

    page = this.state.allFiles.findIndex((f) => {
      return f.name === file.name && f.lastModified === file.lastModified;
    });

    return page + 1;
  };

  setImgFromPage = (newPage) => {
    if (newPage > 1) {
      newPage = Math.min(newPage, this.state.allFiles.length);
    }
    this.setImg(this.state.allFiles[newPage - 1]);
  };

  setImg = (newFile) => {
    if (!newFile) {
      return;
    }

    this.setState(
      {
        url: URL.createObjectURL(newFile),
        file: newFile,
        page: this.findPage(newFile),
        drawObjects: [],
        rotationActive: false,
      },
      () => {
        this.img = new Image();
        this.img.onload = this.renderCanvas;
        this.img.src = this.state.url;
      }
    );
  };

  pageFlip = (kind) => {
    const { file } = this.state;
    let fileIdx = this.state.allFiles.findIndex((f) => {
      return f.name === file.name && f.lastModified === file.lastModified;
    });

    if (fileIdx > -1 && fileIdx <= this.state.allFiles.length - 1) {
      fileIdx = kind === 'next' ? fileIdx + 1 : fileIdx - 1;
    } else {
      fileIdx = 0;
    }

    const newFile = this.state.allFiles[fileIdx];
    this.setImg(newFile);
  };

  handleRotation = () => {
    const currentFile = this.state.file;
    const currentPage = this.state.page;
    this.handleNewDrawObject([]);
    this.setState({ rotationActive: true });
    rotateImage(this.img, currentFile.type)
      .then((blob) => {
        const newFile = new File([blob], currentFile.name, {
          type: blob.type,
        });
        Object.keys(currentFile).forEach((key) => {
          newFile[key] = currentFile[key];
        });
        const newAllFiles = this.state.allFiles.map((file, index) => {
          return index === currentPage - 1 ? newFile : file;
        });
        this.setState({ allFiles: newAllFiles }, () => {
          this.setImg(newFile);
        });
      })
      .catch((err) => {
        Logger.error(err);
      });
  };

  renderCanvas = () => {
    if (!this.canvasEl) {
      return;
    }
    drawImageCentered(this.canvasEl, this.img);
    this.setState({ scale: calcCanvasScale(this.canvasEl) });
    if (this.drawObjects[`page_${this.state.page}`]) {
      this.setState({
        drawObjects: this.drawObjects[`page_${this.state.page}`],
      });
    }
  };

  handleNewDrawObject = (obj) => {
    this.drawObjects[`page_${this.state.page}`] = obj;
  };

  render() {
    return (
      <Fragment>
        <CanvasStream
          {...this.props}
          {...this.state}
          setRef={this.setRef}
          pageFlip={this.pageFlip}
          numPages={this.state.allFiles.length}
          renderCanvas={this.renderCanvas}
          handleChange={this.setImgFromPage}
          onRotate={this.handleRotation}
          onDraw={this.handleNewDrawObject}
          onThumbnailClick={this.setImg}
        />
      </Fragment>
    );
  }
}

export default ImagePresentation;
