import React from 'react';
import I18n from 'i18n-js';
import LayoutPosition from './LayoutPosition.js';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles((theme) => ({
  preview: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& .isOver': {
      '& .MuiChip-outlined': {
        background: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
  passiveRow: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.4,
  },
  passiveFace: {
    margin: '0 .5rem',
    padding: '1px',
    lineHeight: '1',
    borderRadius: '50%',
    border:
      theme.palette.type === 'light'
        ? '1px solid rgba(0, 0, 0, 0.23)'
        : '1px solid rgba(255, 255, 255, 0.23)',
  },
}));

const speakerStyles = makeStyles({
  root: {
    flexDirection: 'column',
    width: '50%',
    height: '5rem',
    justifyContent: 'space-evenly',
  },
  icon: {
    margin: 0,
  },
});

const layoutLookup = {
  auto: { group: 'normal', passive: 0 },
  one: { group: 'normal', passive: 0 },
  two: { group: 'normal', passive: 0 },
  four: { group: 'normal', passive: 0 },
  nine: { group: 'normal', passive: 0 },
  one_plus_six: { group: 'speaker', passive: 6 },
  two_plus_six: { group: 'speaker', passive: 6 },
  widescreen_one_plus_six: { group: 'speaker', passive: 6 },
};

const PodiumPreview = ({ type, podium, onDrop, onDoubleClick, onKeyDown }) => {
  const classes = useStyles();
  const speakerClasses = speakerStyles();
  const currentType = layoutLookup[type];
  const isSpeaker = currentType.group === 'speaker';
  return (
    <>
      <div className={classes.preview}>
        {podium.map((p, position) => {
          return (
            <LayoutPosition
              key={position}
              type={type}
              user={podium[position]}
              isSpeaker={isSpeaker}
              onDrop={onDrop}
              position={position}
              onDoubleClick={onDoubleClick}
              onKeyDown={onKeyDown}
            >
              <Chip
                label={I18n.t('label:drag_speaker')}
                icon={<FaceIcon />}
                variant="outlined"
                classes={isSpeaker ? speakerClasses : null}
                onClick={onDoubleClick}
                tabIndex={-1}
              />
            </LayoutPosition>
          );
        })}
      </div>
      {currentType.passive > 0 && (
        <div className={classes.passiveRow}>
          {Array.from({ length: currentType.passive }).map((entry, index) => (
            <span className={classes.passiveFace} key={index}>
              <FaceIcon
                style={{ verticalAlign: 'middle', fontSize: '1.1em' }}
              />
            </span>
          ))}
        </div>
      )}
    </>
  );
};

export default PodiumPreview;
