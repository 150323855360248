let _numberFormat = new Intl.NumberFormat('en', { maximumFractionDigits: 2 });
let _percentFormat = new Intl.NumberFormat('en', {
  style: 'percent',
  maximumFractionDigits: 0,
});

const NumberFormat = {};

NumberFormat.setLocale = (locale) => {
  _numberFormat = new Intl.NumberFormat(locale, { maximumFractionDigits: 2 });
  _percentFormat = new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0,
  });
};

NumberFormat.bitrate = (value) => {
  if (value === null) {
    return '';
  }
  const result = value / 1000;
  if (result >= 1000) {
    return _numberFormat.format(result / 1000) + ' Mbps';
  }
  return _numberFormat.format(Math.round(result)) + ' kbps';
};

NumberFormat.roundTripTime = (value) => {
  if (value === null) {
    return '';
  }
  if (value >= 1.0) {
    return _numberFormat.format(value) + ' s';
  }
  return _numberFormat.format(Math.round(value * 1000)) + ' ms';
};

NumberFormat.packetLoss = (value) => {
  return value === null ? '' : _percentFormat.format(value);
};

NumberFormat.jitter = (value) => {
  if (value === null) {
    return '';
  }
  if (value >= 1.0) {
    return _numberFormat.format(value) + ' s';
  }
  return _numberFormat.format(Math.round(value * 1000)) + ' ms';
};

export default NumberFormat;
