import I18n from 'i18n-js';
import { FeatureDetector, throttle } from 'eyeson';
import classNames from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NameInserts from '../name_inserts/NameInserts.js';
import KeyboardControl from '../../utils/KeyboardControl.js';
import CanvasAnnotation from '../../utils/CanvasAnnotation.js';
import PresentationToolbar from '../presentation/PresentationToolbar.js';
import Status from '../status/Status.js';
import Typography from '@material-ui/core/Typography';

class CanvasStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawing: false,
      collapsed: this.props.collapsed,
      markerColor: 'red',
      markerActive: false,
      undoActive: false,
      redoActive: false,
    };

    this.originalCanvas = null;
    this.canvasAnnotation = new CanvasAnnotation();
    this.throttledPageChange = throttle(this.pageChange, 300);
    this.renderCanvas = this.renderCanvas.bind(this);
    this.redoStack = [];
  }

  componentDidMount() {
    if (Object.keys(this.props.file).length > 0) {
      this.keyboardControl = new KeyboardControl((event) => {
        if (event.type === 'next_slide') {
          this.throttledPageChange({ type: 'flip', details: 'next' });
        }
        if (event.type === 'prev_slide') {
          this.throttledPageChange({ type: 'flip', details: 'prev' });
        }
      });
    }
    try {
      this.props.onEvent({
        type: 'start_mixer',
        canvas: this.canvasEl,
        screen: this.props.screen,
        surface: 'monitor',
        onRedraw: this.renderCanvas,
        changeStream: this.props.screen,
      });
    } catch (error) {
      this.props.onEvent({
        type: 'capture_error',
        name: `error_${error.name}`,
      });
    }
  }

  componentWillUnmount() {
    if (this.keyboardControl) {
      this.keyboardControl.destroy();
    }
    this.redoStack.length = 0;
    this.canvasAnnotation.clear();
  }

  componentDidUpdate(prevProps) {
    const { width, height, drawObjects } = this.props;
    if (width !== prevProps.width || height !== prevProps.height) {
      this.renderCanvas();
    }
    if (prevProps.drawObjects !== drawObjects) {
      this.canvasAnnotation.objects = drawObjects;
      this.canvasAnnotation.drawObjects(drawObjects);
      this.updateUndoRedoStates();
    }
  }

  updateUndoRedoStates = () => {
    this.setState({
      undoActive: this.props.drawObjects.length > 0,
      redoActive: this.redoStack.length > 0,
    });
  };

  setRef = (ref) => {
    if (ref) {
      this.canvasEl = ref;
      this.canvasAnnotation.canvasEl = this.canvasEl;
      if (this.props.setRef) {
        this.props.setRef(ref);
      }
    }
  };

  handleMouseUp = (event) => {
    if (!this.state.drawing) {
      return;
    }
    event.persist();
    this.setState({ drawing: false }, () => {
      this.canvasAnnotation.stop(event);
      this.props.onDraw(this.canvasAnnotation.objects);
      this.updateUndoRedoStates();
    });
  };

  handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!this.state.markerActive) {
      return;
    }
    event.persist();
    this.setState({ drawing: true }, () => {
      this.canvasAnnotation.start(event, this.state.markerColor);
    });
  };

  handleMouseMove = (event) => {
    if (!this.state.drawing) {
      return;
    }
    event.persist();
    this.canvasAnnotation.move(event);
  };

  handleMarkerClick = () => {
    this.setState({ markerActive: !this.state.markerActive });
    this.props.onEvent({ type: 'marker_click' });
  };

  handleColorClick = (newColor) => {
    this.setState({ markerColor: newColor, markerActive: true });
    this.props.onEvent({ type: 'marker_color', color: newColor });
  };

  renderCanvas = () => {
    this.props.renderCanvas();
    this.canvasAnnotation.drawObjects(this.props.drawObjects);
  };

  clearCanvas = () => {
    this.redoStack.length = 0;
    this.canvasAnnotation.clear();
    this.props.onDraw(this.canvasAnnotation.objects);
    this.props.renderCanvas();
    this.updateUndoRedoStates();
  };

  handleEraserClick = () => {
    this.clearCanvas();
    this.props.onEvent({ type: 'clear_presentation_annotation' });
  };

  handleCollapseClick = () => {
    const collapsed = !this.state.collapsed;
    this.setState({ collapsed });
    this.props.onEvent({
      type: 'toggle_presentation_toolbar',
      details: collapsed ? 'collapsed' : 'expanded',
    });
  };

  handleUndoClick = () => {
    if (this.canvasAnnotation.objects.length > 0) {
      const obj = this.canvasAnnotation.objects.pop();
      this.redoStack.push(obj);
      this.props.onDraw(this.canvasAnnotation.objects);
      this.props.renderCanvas();
      this.canvasAnnotation.drawObjects(this.props.drawObjects);
    }
    this.updateUndoRedoStates();
    this.props.onEvent({ type: 'undo_click' });
  };

  handleRedoClick = () => {
    if (this.redoStack.length > 0) {
      const obj = this.redoStack.pop();
      this.canvasAnnotation.objects.push(obj);
      this.props.onDraw(this.canvasAnnotation.objects);
      this.props.renderCanvas();
      this.canvasAnnotation.drawObjects(this.props.drawObjects);
    }
    this.updateUndoRedoStates();
    this.props.onEvent({ type: 'redo_click' });
  };

  handleChange = (event) => {
    if (!event.target) {
      return;
    }
    const newPage = parseInt(event.target.value.trim());
    if (!newPage) {
      return;
    }
    this.redoStack.length = 0;
    this.props.handleChange(newPage);
  };

  // Central spot for all page changes (keyboard, navigation clicks,
  // thumbnail clicks)
  pageChange = (change) => {
    if (change.type === 'thumbnail') {
      this.redoStack.length = 0;
      this.props.onThumbnailClick(change.details);
    }
    if (change.type === 'flip') {
      this.redoStack.length = 0;
      this.props.pageFlip(change.details);
    }
    if (change.type === 'input') {
      this.handleChange(change.details);
    }
  };

  buildCanvasSnapshot = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const { width, height } = this.canvasEl;

    canvas.width = width;
    canvas.height = height;

    context.drawImage(this.canvasEl, 0, 0, width, height);

    return canvas;
  };

  handleRotationClick = () => {
    this.canvasAnnotation.clear();
    this.props.onRotate();
    this.props.onEvent({
      type: 'presentation_rotation',
    });
  };

  startStream = () => {
    this.props.onEvent({ type: 'start_presenting', name: 'image' });
    this.props.onStart();
  };

  stopStream = () => {
    this.props.onEvent({ type: 'presentation' });
    this.props.onStop();
  };

  handleDoubleClick = () => {
    if (this.state.markerActive) {
      return;
    }
    if (!FeatureDetector.hasMobileDevice()) {
      this.props.onEvent({ type: 'full_screen' });
    }
  };

  render() {
    const customStatusCss = {
      width: '100%',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.5)',
    };
    return (
      <div className="canvas">
        {this.props.options.show_names && (
          <NameInserts {...this.props} canvasCollapsed={this.state.collapsed} />
        )}
        <div
          className={`canvas-wrapper ${
            this.state.collapsed ? 'collapsed' : 'expanded'
          }`}
        >
          {this.props.screen && (
            <Status
              message={I18n.t('presentation:active')}
              style={customStatusCss}
            />
          )}
          {!this.props.active.isPresenting && (
            <Status message={I18n.t('label:preview')} style={customStatusCss}>
              <Typography variant="h5">
                {I18n.t('presentation:preview-text')}
              </Typography>
            </Status>
          )}
          <canvas
            ref={this.setRef}
            width={1920}
            height={1080}
            aria-label={I18n.t('aria:label:presentation_canvas', {
              defaultValue: 'Presentation canvas',
            })}
            className={classNames({ cloudy: this.props.screen })}
            onMouseUp={this.handleMouseUp}
            onMouseDown={this.handleMouseDown}
            onMouseMove={this.handleMouseMove}
            onMouseLeave={this.handleMouseUp}
            onTouchStart={this.handleMouseDown}
            onTouchEnd={this.handleMouseUp}
            onTouchMove={this.handleMouseMove}
            onDoubleClick={this.handleDoubleClick}
          />
        </div>
        <PresentationToolbar
          {...this.props}
          {...this.state}
          pageChange={this.throttledPageChange}
          stopStream={this.stopStream}
          onCollapse={this.handleCollapseClick}
          startStream={this.startStream}
          onColorClick={this.handleColorClick}
          onMarkerClick={this.handleMarkerClick}
          onEraserClick={this.handleEraserClick}
          onRotationClick={this.handleRotationClick}
          onUndoClick={this.handleUndoClick}
          onRedoClick={this.handleRedoClick}
        />
      </div>
    );
  }
}

CanvasStream.propTypes = {
  file: PropTypes.object,
  screen: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  renderCanvas: PropTypes.func.isRequired,
  onEvent: PropTypes.func,
};

CanvasStream.defaultProps = {
  file: {},
  screen: false,
  collapsed: false,
  renderCanvas: () => {},
};

export default CanvasStream;
