const control_messages = ['/will_terminate', '/kick '];

class ControlMessage {
  /**
   * Control Message is any of message content matching control_messages.
   **/
  static test(props) {
    const message = props.content;
    return control_messages.some((entry) => {
      return message.startsWith(entry);
    });
  }

  process(msg, state, props) {
    const message = msg.content;
    if (message === '/will_terminate') {
      if (props.eyeson.shutdownBy) {
        return;
      }
      props.eyeson.shutdownBy = msg.user;
    } else if (message.startsWith('/kick ')) {
      const userId = message.split('/kick ')[1];
      if (!props.eyeson.kicked) {
        props.eyeson.kicked = [];
      }
      props.eyeson.kicked.push({ userId, by: msg.user });
    }
  }
}

export default ControlMessage;
