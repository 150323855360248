import React, { Component } from 'react';
import Status from '../status/Status.js';
import VideoStream from '../streams/VideoStream.js';
import Button from '../generic/Button.js';
import I18n from 'i18n-js';

class ScreenStream extends Component {
  componentDidMount() {
    this.props.onEvent({ type: 'start_screen_capture' });
  }

  togglePipCam = () => {
    this.props.onEvent({ type: 'toggle_pip_cam' });
  };

  render() {
    const { pipCam } = this.props;
    return (
      <div className="screen-stream">
        <Status message="Presentation active">
          {pipCam.available && (
            <Button
              disabled={pipCam.request}
              onClick={this.togglePipCam}
              icon={pipCam.active ? 'videocam_off' : 'videocam'}
            >
              {I18n.t(
                'label:pip_cam:' +
                  (pipCam.active ? 'hide_camera' : 'show_camera')
              )}
            </Button>
          )}
        </Status>
        <VideoStream
          {...this.props}
          stream={this.props.localStream}
          visible={false}
          className="cloudy"
        />
      </div>
    );
  }
}

const Screen = (props) => {
  // June 2019 decided to stick to "old" screensharing for now, since
  // performance on older, less powerful machines is not great.
  // if (props.environment.canMix) {
  //   return <CanvasStream {...props} screen={true} collapsed={true} />;
  // }

  return <ScreenStream {...props} />;
};

export default Screen;
