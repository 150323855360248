import I18n from 'i18n-js';
import Artwork from './../layout/Artwork';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '../generic/TextField.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import JoinButton from '../preview/JoinButton.js';
import { getGuestToken } from '../../utils/UrlHelpers.js';
import { makeStyles } from '@material-ui/core/styles';
import guestNameGenerator from '../../utils/GuestNameGenerator.js';
import { supportedLocales } from '../../utils/AppConfig.js';
import InterCommunicationHelper from '../../utils/InterCommunicationHelper.js';
import { Icon, useTheme } from '@material-ui/core';
import readySound from '../../assets/audio/offline.mp3';
import DataSaverPreview from '../preview/DataSaverPreview.js';

const _isPermalinkRoute = window.location.pathname === '/permalink/';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    'flex-flow': 'column nowrap',
    alignItems: 'center',
    overflow: 'auto',
  },
  center: {
    'text-align': 'center',
  },
  heading: {
    '@media (max-height: 700px)': {
      fontSize: '3em',
    },
  },
  padding: {
    '@media (min-height: 500px)': {
      padding: theme.spacing(3),
    },
    '@media (min-width: 1024px) and (min-height: 900px)': {
      padding: theme.spacing(16, 3, 3, 3),
    },
  },
  marginTop: {
    '@media (min-height: 530px)': {
      margin: theme.spacing(3, 0),
    },
  },
}));

const Alert = ({ children, severity }) => {
  const theme = useTheme();
  const color = theme.palette[severity].main;
  return (
    <Typography style={{ color }} variant="body2">
      {children}
    </Typography>
  );
};

const QuickJoinPage = (props) => {
  const { eyeson, handlePermalinkUnavailable } = props;
  const [dataSaver, setDataSaver] = useState('off');
  const [name, setName] = useState(
    props.suggestGuestNames ? guestNameGenerator() : ''
  );
  const [height, setHeight] = useState(window.innerHeight);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [locale, setLocale] = useState(I18n.locale);
  const [token, setToken] = useState(null);
  const [permalinkWaiting, setPermalinkWaiting] = useState(false);
  const [permalinkMeetingStarted, setPermalinkMeetingStarted] = useState(false);
  const initRef = useRef();

  const classes = useStyles();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.trim() === '') {
      setError(I18n.t('error:msg:name'));
      return;
    }
    setSubmitted(true);
    requestGuestUser();
    setMessage(I18n.t('message:quick_join_redirect'));
  };

  useEffect(() => {
    if (initRef.current) {
      return;
    }
    initRef.current = true;
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    const guestToken = getGuestToken();
    setToken(guestToken);
    if (_isPermalinkRoute) {
      const permalinkEventListener = (event) => {
        if (event.type === 'error') {
          InterCommunicationHelper.onMeetingLeave('access_denied');
          handlePermalinkUnavailable();
          return;
        }
        if (event.type === 'permalink_meeting_info') {
          if (!event.room.started_at && !permalinkWaiting) {
            setPermalinkWaiting(true);
          }
          if (event.room.started_at) {
            eyeson.offEvent(permalinkEventListener);
            setPermalinkMeetingStarted(true);
            return;
          }
        }
      };
      eyeson.onEvent(permalinkEventListener);
      eyeson.send({
        type: 'request_permalink_meeting_info',
        api: eyeson.config.api,
        token: guestToken,
      });
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [initRef, eyeson, handlePermalinkUnavailable, permalinkWaiting]);

  // NOTE: Allow mobile users to join directly from guest form. save a click,
  //       save the world.
  const handleEnter = (event) => {
    const accessKey = event.token;
    const path = window.location.pathname.replace('/permalink', '');
    const eco = dataSaver === 'eco';
    window.history.replaceState(null, '', path + '?' + accessKey);
    props.onEnter(accessKey, {
      eco: eco,
      video: !eco,
      audio: true,
      quality: dataSaver,
    });
  };

  const handleRedirect = (event) => {
    const accessKey = event.token;
    let destination = `${window.location.origin}/?${accessKey}`;
    window.location.href = destination;
  };

  const onChangeLocale = (event) => {
    const newLocale = event.target.value;
    setLocale(newLocale);
    props.config.setLocale({ custom_fields: { locale: newLocale } });
  };

  const LanguageSelect = () => (
    <Select
      value={locale}
      onChange={onChangeLocale}
      color="secondary"
      // SelectDisplayProps={{ 'aria-label': label }}
    >
      {Object.entries(supportedLocales).map(([code, title]) => (
        <MenuItem key={code} value={code}>
          {title}
        </MenuItem>
      ))}
    </Select>
  );

  /**
   * Request a guest user and redirect if one is received.
   **/
  const requestGuestUser = () => {
    eyeson.onEvent((event) => {
      if (event.type === 'error') {
        InterCommunicationHelper.onMeetingLeave(
          event.locked ? 'meeting_locked' : 'access_denied'
        );
        setError(
          I18n.t(event.locked ? 'error:msg:guest_locked' : 'error:msg:guest')
        );
        return;
      }
      if (event.type !== 'guest_user') {
        return;
      }

      (props.environment.isPhone ? handleEnter : handleRedirect)(event);
    });

    eyeson.send({
      type: 'request_guest_user',
      api: props.eyeson.config.api,
      name: '[Guest] ' + name,
      token: token,
      locale: locale,
    });
  };

  const inputDisabled =
    name.length === 0 ||
    submitted ||
    (_isPermalinkRoute && !permalinkMeetingStarted);

  return (
    <form
      className={classes.root}
      style={{ height: height }}
      onSubmit={handleSubmit}
    >
      <Box
        display="flex"
        flexWrap="nowrap"
        className={classes.padding}
        flexDirection="column"
      >
        <div className={classes.center}>
          <Typography variant="h2" className={classes.heading}>
            {I18n.t('page:header:quick_join')}
          </Typography>
          <Typography variant="subtitle1" className={classes.marginTop}>
            {I18n.t('message:quick_join')}
          </Typography>
        </div>
        <TextField
          id="name"
          type="text"
          value={name}
          required
          onChange={(value) => setName(value)}
          label={I18n.t('label:name')}
          error={error.length !== 0}
          helperText={error.length !== 0 ? error : message}
          autoFocus
        />
      </Box>
      {_isPermalinkRoute && permalinkWaiting && !submitted && (
        <div className={classes.center}>
          {permalinkMeetingStarted ? (
            <>
              <Alert severity="success">
                {I18n.t('permalink:quickjoin:ready')}
              </Alert>
              <audio src={readySound} type="audio/mpeg" autoPlay={true} />
            </>
          ) : (
            <Alert severity="warning">
              <Icon style={{ verticalAlign: 'bottom' }}>schedule</Icon>{' '}
              {I18n.t('permalink:quickjoin:waiting')}
            </Alert>
          )}
        </div>
      )}
      {props.environment.isPhone && (
        <>
          <Box paddingBottom={2}>
            <JoinButton
              action="submit"
              onClick={handleSubmit}
              disabled={inputDisabled}
            />
          </Box>
          <div style={{ margin: '1rem' }}>{LanguageSelect()}</div>
          <DataSaverPreview
            mobile={true}
            mode={dataSaver}
            onChange={(mode) => setDataSaver(mode)}
            disabled={inputDisabled}
            className="quickjoin-mobile"
          />
        </>
      )}
      {!props.environment.isPhone && (
        <>
          <JoinButton
            action="submit"
            onClick={handleSubmit}
            disabled={inputDisabled}
          />
          <div style={{ textAlign: 'center', marginTop: '1.25rem' }}>
            {LanguageSelect()}
          </div>
          <Artwork type="quick_join" />
        </>
      )}
    </form>
  );
};

QuickJoinPage.propTypes = {
  eyeson: PropTypes.object.isRequired,
  onEnter: PropTypes.func.isRequired,
  environment: PropTypes.object.isRequired,
  suggestGuestNames: PropTypes.bool.isRequired,
};

QuickJoinPage.defaultProps = {
  suggestGuestNames: true,
};

export default QuickJoinPage;
