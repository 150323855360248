import I18n from 'i18n-js';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../generic/Button.js';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import ImageIcon from '@material-ui/icons/Collections';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import { Typography } from '@material-ui/core';
import Utils from '../../utils/a11yHelper.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import instanceHelper from '../../utils/eyesonInstanceHelper.js';
import tabAudioHelper from '../../utils/eyesonTabAudioHelper.js';
import Divider from '@material-ui/core/Divider';
import { ReactComponent as ScreenshareFullIcon } from '../../assets/screenshare_full.svg';
import { ReactComponent as ScreenshareAdditionalIcon } from '../../assets/screenshare_additional.svg';
import { ReactComponent as ScreenshareInsteadCamIcon } from '../../assets/screenshare_instead_cam.svg';
import { ReactComponent as ScreenshareStopIcon } from '../../assets/screenshare_stop.svg';

const useOptionStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    opacity: ({ disabled }) => (disabled ? '0.38' : null),
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  action: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginRight: theme.spacing(1), // stop all wouldn't be aligned
  },
  buttonSpinner: {
    marginLeft: '0.5rem',
    verticalAlign: 'middle',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PresentationOption = ({
  icon,
  hint,
  description,
  buttonTitle,
  onButtonClick,
  disabled,
  spinning = false,
}) => {
  const classes = useOptionStyles({ disabled });
  return (
    <div className={classes.row}>
      <div className={classes.description}>
        <div>{description}</div>
        {hint && (
          <div>
            <Typography variant="caption">{hint}</Typography>
          </div>
        )}
      </div>
      <div className={classes.action}>
        <Button icon={icon} onClick={() => onButtonClick()} disabled={disabled}>
          {buttonTitle}
          {spinning && (
            <CircularProgress
              color="inherit"
              size="1.2rem"
              className={classes.buttonSpinner}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

const ScreenCaptureHint = ({ eco, environment }) => {
  if (!environment.canScreenCapture) {
    return (
      <Typography variant="caption">
        {I18n.t('dialog:presentation:screen_capture_not_supported')}
      </Typography>
    );
  }
  if (eco) {
    return (
      <Typography variant="caption">
        {I18n.t('dialog:presentation:eco')}
      </Typography>
    );
  }
  return null;
};

const PresentationDialog = ({
  dialogId,
  environment,
  eco,
  onEvent,
  tabAudio,
  screenAsVideo,
  screenAddition,
  quickJoin,
  isLocked,
}) => {
  const screenCaptureDisabled = !environment.canScreenCapture || eco;
  const filePresentationDisabled = !environment.canPresentFiles || eco;
  const tabAudioSupported = quickJoin && tabAudioHelper.checkSupport();
  const classes = useOptionStyles({ disabled: false });
  return (
    <Fragment>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0}>
        {I18n.t('dialog:subtitle:presentation')}
      </DialogContentText>
      <PresentationOption
        icon={<ScreenshareFullIcon />}
        description={I18n.t('action:share_screen')}
        buttonTitle={I18n.t('label:share')}
        onButtonClick={() => {
          onEvent({ type: 'activate_screen_capture' });
        }}
        disabled={screenCaptureDisabled || screenAsVideo || screenAddition}
      />
      {screenAddition && <Divider className={classes.hr} />}
      {screenAddition ? (
        <>
          <div className={classes.description}>
            {I18n.t('action:share_screen_additional_user')}
          </div>
          <div className={classes.row}>
            <Button
              type="secondary"
              onClick={async () => {
                if (await instanceHelper.changeScreenshare()) {
                  onEvent({ type: 'hide_dialog' });
                }
              }}
            >
              {I18n.t('action:change_screen')}
            </Button>
            <Button
              type="secondary"
              disabled={instanceHelper.isCurrentPresentationLayout()}
              onClick={() => {
                instanceHelper.setPresentationLayout();
                onEvent({ type: 'hide_dialog' });
              }}
            >
              {I18n.t('action:presentation_mode')}
            </Button>
            <span className={classes.action}>
              <Button
                icon={<ScreenshareStopIcon />}
                onClick={() => {
                  onEvent({ type: 'hide_dialog' });
                  onEvent({ type: 'screen_addition_stop' });
                }}
              >
                {I18n.t('label:stop')}
              </Button>
            </span>
          </div>
        </>
      ) : (
        quickJoin && (
          <PresentationOption
            icon={<ScreenshareAdditionalIcon />}
            description={I18n.t('action:share_screen_additional_user')}
            buttonTitle={I18n.t('label:share')}
            spinning={instanceHelper.getCurrentState() === 'init'}
            hint={
              isLocked &&
              I18n.t(
                'dialog:presentation:screen_additional_user_not_supported:locked'
              )
            }
            disabled={
              isLocked ||
              screenCaptureDisabled ||
              instanceHelper.getCurrentState() === 'init'
            }
            onButtonClick={() => {
              Utils.clearActiveElement();
              onEvent({ type: 'screen_as_addition' });
            }}
          />
        )
      )}
      {(screenAddition || screenAsVideo) && <Divider className={classes.hr} />}
      {screenAsVideo ? (
        <>
          <div className={classes.description}>
            {I18n.t('action:share_screen_as_cam')}
          </div>
          <div className={classes.row}>
            <Button
              type="secondary"
              onClick={() => {
                onEvent({ type: 'hide_dialog' });
                onEvent({ type: 'screen_as_video_change' });
              }}
            >
              {I18n.t('action:change_screen')}
            </Button>
            <span className={classes.action}>
              <Button
                icon={<ScreenshareStopIcon />}
                onClick={() => {
                  onEvent({ type: 'hide_dialog' });
                  onEvent({ type: 'screen_video_ended' });
                }}
              >
                {I18n.t('label:stop')}
              </Button>
            </span>
          </div>
        </>
      ) : (
        <PresentationOption
          icon={<ScreenshareInsteadCamIcon />}
          description={I18n.t('action:share_screen_as_cam')}
          buttonTitle={I18n.t('label:share')}
          onButtonClick={() => {
            onEvent({ type: 'hide_dialog' });
            onEvent({ type: 'screen_as_video' });
          }}
          disabled={screenCaptureDisabled}
        />
      )}
      {(screenAsVideo || tabAudio) && <Divider className={classes.hr} />}
      {tabAudioSupported && tabAudio && (
        <>
          <div className={classes.description}>
            {I18n.t('action:share_tab_audio')}
          </div>
          <div className={classes.row}>
            <Button
              type="secondary"
              onClick={async () => {
                if (await tabAudioHelper.changeScreenshare()) {
                  onEvent({ type: 'hide_dialog' });
                }
              }}
            >
              {I18n.t('action:switch_tab')}
            </Button>
            <span className={classes.action}>
              <Button
                icon={<AudiotrackIcon />}
                onClick={() => {
                  onEvent({ type: 'hide_dialog' });
                  onEvent({ type: 'stop_tab_audio' });
                }}
              >
                {I18n.t('label:stop')}
              </Button>
            </span>
          </div>
        </>
      )}
      {tabAudioSupported && !tabAudio && (
        <PresentationOption
          icon={<AudiotrackIcon />}
          description={I18n.t('action:share_tab_audio')}
          buttonTitle={I18n.t('label:share')}
          spinning={tabAudioHelper.getCurrentState() === 'init'}
          hint={
            isLocked &&
            I18n.t('dialog:presentation:tab_audio_not_supported:locked')
          }
          disabled={
            isLocked ||
            screenCaptureDisabled ||
            tabAudioHelper.getCurrentState() === 'init'
          }
          onButtonClick={() => {
            Utils.clearActiveElement();
            onEvent({ type: 'start_tab_audio' });
          }}
        />
      )}
      {tabAudio && <Divider className={classes.hr} />}
      <PresentationOption
        icon={<ImageIcon />}
        description={I18n.t('action:show_files')}
        buttonTitle={I18n.t('label:share')}
        onButtonClick={() => {
          Utils.clearActiveElement();
          onEvent({ type: 'show_drop_zone' });
        }}
        disabled={filePresentationDisabled || screenAsVideo || screenAddition}
      />
      <DialogContentText component="div">
        <ScreenCaptureHint eco={eco} environment={environment} />
      </DialogContentText>
    </Fragment>
  );
};

PresentationDialog.propTypes = {
  eco: PropTypes.bool,
  onEvent: PropTypes.func.isRequired,
  environment: PropTypes.object,
  quickJoin: PropTypes.bool,
  isLocked: PropTypes.bool,
};

PresentationDialog.defaultProps = {
  eco: false,
  environment: { canScreenCapture: true, isIncongnito: false },
  quickJoin: true,
  isLocked: false,
};

export default PresentationDialog;
