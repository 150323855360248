import update from 'immutability-helper';
import BaseAction from './BaseActions.js';

/**
 * HandleAudioNotificationEnded.
 **/
class HandleAudioNotificationEnded extends BaseAction {
  process(state, props) {
    if (this.message.name === 'offline') {
      props.onExit({ reason: this.message.name });
    }
    return {
      notifications: update(state.notifications, {
        audio: {
          $set: [],
        },
      }),
    };
  }
}

export { HandleAudioNotificationEnded };
