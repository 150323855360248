import PublicEvent from './PublicEvent.js';

/**
 * Uploads a file using the com api.
 **/

class UploadFileEvent extends PublicEvent {
  handle(msg) {
    this.context._api.uploadFile(msg.file, this.addFile.bind(this));
  }

  addFile(response) {
    super.handle({
      type: 'add_file',
      file: response
    });
  }
}

export default UploadFileEvent;
