import screenfull from 'screenfull';

/**
 * Fullscreen helper. Wrapper for screenfull plugin and fullscreen support
 */
class FullscreenHelper {
  constructor() {
    this.onChange = this.onChange.bind(this);
    this.hasSupport = Boolean(screenfull.isEnabled);
  }

  /**
   * Toggle fullscreen on element
   *
   * @param {Element|null} [element] - Element to toggle fullscreen, default documentElement
   * @param {object} [options] - https://developer.mozilla.org/en-US/docs/Web/API/Element/requestFullscreen#options
   * @return {Promise|null}
   */
  toggle(element, options) {
    return this.hasSupport ? screenfull.toggle(element, options) : null;
  }

  /**
   * Check if fullscreen is supported
   *
   * @return {boolean} - is supported
   */
  canFullscreen() {
    return this.hasSupport;
  }

  /**
   * Get current fullscreen state
   *
   * @return {boolean} - is fullscreen
   */
  isFullscreen() {
    return this.hasSupport && screenfull.isFullscreen;
  }

  /**
   * Register on change event handler
   *
   * @param {function} callback - Callback change event handler
   */
  onChange(callback) {
    if (!this.hasSupport) {
      return;
    }
    screenfull.on('change', ({ target }) =>
      callback(screenfull.isFullscreen, target)
    );
  }

  /**
   * Remove all change event handler
   */
  off() {
    if (!this.hasSupport) {
      return;
    }
    screenfull.off('change');
  }
}

export default FullscreenHelper;
