import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import GifChannel from './giphy/GifChannel.js';
import Participants from './participants/Participants.js';
import Chat from './chat/Chat.js';
import CounterIcon from '../generic/CounterIcon.js';
import ConnectionInfoIcon from './connection/ConnectionInfoIcon.js';
import Snapshots from './snapshots/Snapshots.js';
import ExpansionPanel from './ExpansionPanel.js';
import Container from './ExpansionPanelContainer.js';
import ConnectionInfo from './connection/ConnectionInfo.js';
import classnames from 'classnames';

/**
 * TODO Store open panel in preferences...
 **/
const ExpansionPanels = ({
  user,
  order,
  podium,
  options = {},
  gifEndpoint,
  messages,
  playback,
  quickJoin,
  isGuest,
  isLocked,
  hideChat,
  openPanel,
  snapshots,
  handleEvent,
  setOpenPanel,
  participants,
  voiceActivity,
  screenAsVideo,
  isSFU,
  presentationActive,
  hideSnapshotButton,
  connectionStatistics,
  hideGuestLogin,
  pressureState,
}) => {
  const togglePanel = (event, id) => {
    event.preventDefault();
    setOpenPanel(id === openPanel ? '' : id);
  };

  return (
    <aside
      className={classnames('eyeson-expansion-panels', {
        'eyeson-expansion-panels--minified': openPanel === '',
      })}
    >
      <ExpansionPanel
        id="panel-gifs"
        className="eyeson-expansion-panel--content"
        headerTitle={I18n.t('label:gif_library', {
          defaultValue: 'GIF library',
        })}
        open={openPanel === 'gifs'}
        icon={<CounterIcon icon="gif" />}
        onClick={(event) => togglePanel(event, 'gifs')}
        disabled={!options.reaction_available}
      >
        <Container>
          {openPanel === 'gifs' && (
            <GifChannel
              gifs={playback.gifs}
              user={user}
              onEvent={handleEvent}
              gifEndpoint={gifEndpoint}
            />
          )}
        </Container>
      </ExpansionPanel>
      {!hideChat && (
        <ExpansionPanel
          id="panel-chat"
          className="eyeson-expansion-panel--content"
          headerTitle={I18n.t('label:chat', { defaultValue: 'Chat' })}
          open={openPanel === 'chat'}
          icon={
            <CounterIcon
              icon="message"
              count={messages.length}
              reset={openPanel === 'chat'}
            />
          }
          onClick={(event) => togglePanel(event, 'chat')}
        >
          <Container className="eyeson-chat">
            {openPanel === 'chat' && (
              <Chat
                me={user}
                open={openPanel === 'chat'}
                onClose={(event) => togglePanel(event, 'chat')}
                onEvent={handleEvent}
                messages={messages}
              />
            )}
          </Container>
        </ExpansionPanel>
      )}
      <ExpansionPanel
        id="panel-participants"
        className="eyeson-expansion-panel--content"
        headerTitle={`${I18n.t('label:participants', {
          defaultValue: 'Participants',
        })} (${participants.length})`}
        open={openPanel === 'participants'}
        icon={
          <CounterIcon
            icon="people"
            count={participants.length}
            reset={openPanel === 'participants'}
          />
        }
        onClick={(event) => togglePanel(event, 'participants')}
      >
        <Container>
          <Participants
            me={user}
            order={order}
            podium={podium}
            quickJoin={quickJoin}
            isGuest={isGuest}
            isLocked={isLocked}
            onEvent={handleEvent}
            participants={participants}
            voiceActivity={voiceActivity}
            hideGuestLogin={hideGuestLogin}
            isSFU={isSFU}
            options={options}
          />
        </Container>
      </ExpansionPanel>
      <ExpansionPanel
        id="panel-snapshots"
        className="eyeson-expansion-panel--content"
        headerTitle={I18n.t('label:snapshots', { defaultValue: 'Snapshots' })}
        open={openPanel === 'snapshots'}
        icon={
          <CounterIcon
            icon="photo_camera"
            count={snapshots.length}
            reset={openPanel === 'snapshots'}
          />
        }
        onClick={(event) => togglePanel(event, 'snapshots')}
      >
        <Container>
          <Snapshots
            snapshots={snapshots}
            screenAsVideo={screenAsVideo}
            hideSnapshotButton={hideSnapshotButton}
            presentationActive={presentationActive}
            onEvent={handleEvent}
          />
        </Container>
      </ExpansionPanel>
      {connectionStatistics && (
        <ExpansionPanel
          id="panel-connection"
          className="eyeson-expansion-panel--content"
          headerTitle={I18n.t('label:connection', {
            defaultValue: 'Connection Info',
          })}
          open={openPanel === 'connection'}
          icon={<ConnectionInfoIcon data={connectionStatistics} />}
          onClick={(event) => togglePanel(event, 'connection')}
        >
          <Container>
            <ConnectionInfo
              data={connectionStatistics}
              pressureState={pressureState}
            />
          </Container>
        </ExpansionPanel>
      )}
    </aside>
  );
};

ExpansionPanels.propTypes = {
  user: PropTypes.object.isRequired,
  order: PropTypes.array.isRequired,
  podium: PropTypes.array.isRequired,
  messages: PropTypes.array.isRequired,
  playback: PropTypes.object.isRequired,
  snapshots: PropTypes.array.isRequired,
  openPanel: PropTypes.string.isRequired,
  handleEvent: PropTypes.func.isRequired,
  setOpenPanel: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  voiceActivity: PropTypes.object.isRequired,
};

ExpansionPanels.defaultProps = {
  order: [],
  podium: [],
  messages: [],
  snapshots: [],
  participants: [],
  voiceActivity: { on: false, users: [] },
};

export default React.memo(ExpansionPanels);
