import config from './config.js';
/*eslint no-console: 0*/

/**
 * eyeson Logger implement debug|info|warn|error methods.
 */
const Logger = {
  error: (...args) => {
    return console.error(...args);
  },
  warn: (...args) => {
    return console.warn(...args);
  },
  info: (...args) => {
    if (config.verbose === false) {
      return null;
    }
    return console.log(...args);
  },
  debug: (...args) => {
    if (!console.debug || config.verbose === false) {
      return null;
    }
    return console.debug(...args);
  }
};

export default Logger;
