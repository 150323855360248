import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/Application.js';
import './styles.css';
import './assets/audio/preview.mp3';
import './assets/audio/offline.mp3';
import { getRoomToken } from './utils/UrlHelpers.js';

window.removeEventListener('error', window.onPageLoadError, false);

ReactDOM.render(
  <App token={getRoomToken()} />,
  document.getElementById('root')
);
