import React, { Component } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import classNames from 'classnames';

class Thumbnail extends Component {
  constructor(props) {
    super(props);
    this.scale = 0.09;
    this.state = { visible: true, orientation: 'portrait', url: null };
    this.renderTask = null;
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrollLeft, pdf } = this.props;
    if (scrollLeft !== prevProps.scrollLeft) {
      this.setVisibility();
    }
    if (prevProps.pdf._pdfInfo.fingerprint !== pdf._pdfInfo.fingerprint) {
      this.setState({ url: null }, () => this.buildThumbnail());
    }
  }

  componentWillUnmount() {
    if (this.renderTask) {
      this.renderTask.cancel();
    }
  }

  setRef = (node) => {
    if (node) {
      this.element = node;
      this.setVisibility();
    }
  };

  setVisibility = () => {
    const visible = this.isScrolledIntoView(this.element);
    if (visible) {
      this.buildThumbnail();
    }
    this.setState({ visible });
  };

  // We're a bit generous here.
  isScrolledIntoView = (el) => {
    const { x } = el.getBoundingClientRect();
    return x >= 0 && x < window.innerWidth;
  };

  // NOTE: async getPage and render, ensure we're returning thumbnails in
  // correct order.
  buildThumbnail = async () => {
    // skip if we've already built it
    if (this.state.url) {
      return;
    }

    const page = await this.props.pdf.getPage(this.props.pageNum);

    let canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const vp = page.getViewport({ scale: this.scale });
    canvas.width = vp.width;
    canvas.height = vp.height;
    this.renderTask = page.render({ canvasContext: ctx, viewport: vp });

    this.renderTask.promise
      .then(() => {
        // this is what we're here for, generating the URL from canvas
        const url = canvas.toDataURL();
        canvas.remove();

        this.setState({
          url: url,
          orientation: vp.width > vp.height ? 'landscape' : 'portrait',
        });
      })
      .catch((error) => {
        Logger.warn('Thumbnail:buildThumbnail', error.message || error);
      });
  };

  handleClick = (event) => {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && (event.key === 'Enter' || event.key === ' '))
    ) {
      this.props.onClick(this.props.pageNum);
    }
  };

  get inlineStyle() {
    if (!this.state.url || this.props.expanding) {
      return { backgroundImage: 'none' };
    }

    return { backgroundImage: `url(${this.state.url})` };
  }

  render() {
    const { pageNum, active } = this.props;

    return (
      <div
        id={`thumbnail-${pageNum}`}
        ref={this.setRef}
        role="listitem"
        aria-label={I18n.t('aria:label:thumbnail', {
          id: pageNum,
          defaultValue: 'Thumbnail {{id}}',
        })}
        aria-current={active ? true : null}
        style={this.inlineStyle}
        onClick={this.handleClick}
        onKeyDown={this.handleClick}
        tabIndex="-1"
        className={classNames('thumbnail', this.state.orientation, {
          active: active,
          visible: this.state.visible,
          preview: this.state.url,
          'no-preview': !this.state.url,
        })}
      >
        <span className="page-num">{pageNum}</span>
      </div>
    );
  }
}

Thumbnail.propTypes = {
  pdf: PropTypes.object.isRequired,
  thumbnail: PropTypes.object.isRequired,
  scrollLeft: PropTypes.number.isRequired,
};

Thumbnail.defaultProps = {
  reset: false,
};

export default Thumbnail;
