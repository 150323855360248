import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import DialogWindow from '../dialog/DialogWindow.js';
import CountdownDialog from '../dialog/CountdownDialog.js';
import AudioNotifications from '../notifications/AudioNotifications.js';

const PreviewDialog = ({
  inactive,
  onCountdownEnd,
  onPrimaryClick,
  onSecondaryClick,
  countDownDialogTime,
}) => {
  if (!inactive) {
    return null;
  }

  return (
    <DialogWindow
      id="inactive-dialog"
      title={I18n.t('dialog:title:inactive')}
      onClose={onPrimaryClick}
      role="alertdialog"
      useAriaDescription
    >
      <AudioNotifications notifications={[{ name: 'inactivity' }]} />
      <CountdownDialog
        dialogId="inactive-dialog"
        message={I18n.t('message:inactive')}
        startTime={countDownDialogTime}
        actionLabel={I18n.t('dialog:prompt:extend')}
        cancelLabel={I18n.t('dialog:prompt:close_room')}
        onCountdownEnd={onCountdownEnd}
        onAction={onPrimaryClick}
        onCancel={onSecondaryClick}
      />
    </DialogWindow>
  );
};

PreviewDialog.propTypes = {
  inactive: PropTypes.bool.isRequired,
  onCountdownEnd: PropTypes.func.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  countDownDialogTime: PropTypes.number.isRequired,
};

PreviewDialog.defaultProps = {};

export default PreviewDialog;
