import { LocalStorage } from 'eyeson';

class PlaybackStore {
  static selection() {
    return LocalStorage.load('playbacks.selection', 'trending');
  }

  static setSelection(selection) {
    LocalStorage.store('playbacks.selection', selection);
  }

  static recent() {
    return LocalStorage.load('playbacks.recent', []);
  }

  static favs() {
    return LocalStorage.load('playbacks.favs', []);
  }

  static addRecent(playback) {
    PlaybackStore.add(playback, 'playbacks.recent');
  }

  static toggleFav(playback) {
    if (PlaybackStore.favs().find((p) => playback.id === p.id)) {
      PlaybackStore.remove(playback, 'playbacks.favs');
    } else {
      PlaybackStore.add(playback, 'playbacks.favs');
    }
  }

  // Add to front, avoid duplication and trim
  static add(playback, storageId) {
    let playbacks = LocalStorage.load(storageId, []);
    const index = playbacks.findIndex((p) => p.id === playback.id);
    if (index > -1) {
      playbacks.splice(index, 1);
    }
    playbacks.unshift(playback);
    const reduced = playbacks.slice(0, 20);
    LocalStorage.store(storageId, reduced);
  }

  static remove(playback, storageId) {
    let playbacks = LocalStorage.load(storageId, []);
    const index = playbacks.findIndex((p) => p.id === playback.id);
    if (index > -1) {
      playbacks.splice(index, 1);
    }
    LocalStorage.store(storageId, playbacks);
  }
}

export default PlaybackStore;
