import I18n from 'i18n-js';
import Icon from '@material-ui/core/Icon';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import LinkifyDecorator from './LinkifyDecorator.js';
import dateHelper from '../../../../utils/dateHelper.js';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

class QuestionMessage extends Component {
  state = { shared: false };

  handleClick = () => {
    if (this.state.shared) {
      this.props.onEvent({ type: 'clear_front_layer' });
    } else {
      this.props.onEvent({
        type: 'set_layer',
        params: {
          insert: {
            icon: this.props.user.avatar,
            title: `${this.props.user.name}:`,
            content: this.content.substr(0, 281),
          },
        },
      });
    }
    this.setState({ shared: !this.state.shared });
  };

  /**
   * A question message starts with "/ask ".
   **/
  static test(props) {
    return props.content.substr(0, 5) === '/ask ';
  }

  get content() {
    return this.props.content.replace('/ask ', '');
  }

  /**
   * Render a message that describes a question.
   **/
  render() {
    return (
      <div className="eyeson-chat-message question">
        <span className="icon-wrap icon-wrap__question">
          <Icon className="icon-question">live_help</Icon>
        </span>
        <div className="content">
          <div className="content-wrap">
            <span className="details">
              <strong className="username">{this.props.user.name}</strong>
              <span className="date">
                {dateHelper.formatTime(this.props.timestamp)}
              </span>
            </span>
            <Linkify className="body" componentDecorator={LinkifyDecorator}>
              {this.content}
            </Linkify>
          </div>
        </div>
        <Tooltip
          title={
            this.state.shared
              ? I18n.t('label:clear_from_stream')
              : I18n.t('label:show_in_stream')
          }
          placement="top"
        >
          <IconButton
            className="chat-message-button__flex-center"
            onClick={this.handleClick}
          >
            <Icon title="Show">
              {this.state.shared ? 'visibility_off' : 'visibility'}
            </Icon>
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

QuestionMessage.propTypes = {
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
};

export default QuestionMessage;
