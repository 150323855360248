import EmojiMap from './EmojiMap.js';

const match = (m) => EmojiMap[m.substr(1, m.length - 2)] || m;

/**
 * emojify some text content, replace :smth: to corresponding utf8 emoji.
 **/
const emojify = (content) => content.replace(/:[\w-+_]+:/g, match);

export default emojify;
