import I18n from 'i18n-js';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/icons/AddPhotoAlternate';
import { makeStyles } from '@material-ui/core/styles';
import processHeicImages from '../utils/heicImageHelper.js';
import { Button } from '@material-ui/core';

const VIDEO_MIME_TYPE = ['video/*'];
const OTHER_MIME_TYPES = ['image/*', 'image/heic', '.heic', 'application/pdf'];
const VIDEO_MAX_FILES = 1;
const OTHER_MAX_FILES = 25;

// https://stackoverflow.com/a/55971696
const partition = (array, isValid) => {
  const pass = [];
  const fail = [];
  array.forEach((element) => {
    if (isValid(element)) {
      pass.push(element);
    } else {
      fail.push(element);
    }
  });
  return [pass, fail];
};

const filterSupportedImages = async (files, signal) => {
  const result = {
    accepted: [],
    rejected: [],
  };
  const [heicImages, nonHeicImages] = partition(
    files,
    (file) => file.type === 'image/heic' || /\.heic$/i.test(file.name)
  );
  const promises = nonHeicImages.map((file) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(file);
      const img = new Image();
      img.onload = () => {
        URL.revokeObjectURL(url);
        result.accepted.push(file);
        resolve();
      };
      img.onerror = () => {
        URL.revokeObjectURL(url);
        result.rejected.push(file);
        resolve();
      };
      img.src = url;
    });
  });
  if (heicImages.length > 0) {
    promises.push(
      processHeicImages(heicImages, result.accepted, result.rejected, signal)
    );
  }
  await Promise.all(promises);
  return result;
};

const Disabled = ({ eco, unsupported, screenAsVideo }) => {
  let text = I18n.t('presentation:busy');
  if (eco) {
    text = I18n.t('presentation:unavailable:eco');
  }
  if (unsupported) {
    text = I18n.t('message:alt_browser');
  }
  if (screenAsVideo) {
    text = I18n.t('presentation:unavailable:screenshare');
  }
  return <Typography variant="h4">{text}</Typography>;
};

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    outlineOffset: '5px',
  },
}));

const Regular = ({ open, autoFocus, onCancelLoading, loading = false }) => {
  const classes = useStyles();
  const linkEl = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      open();
    }
  };

  useEffect(() => {
    if (autoFocus && linkEl.current) {
      linkEl.current.focus();
    }
  }, [autoFocus, linkEl]);

  return (
    <Fragment>
      <Icon style={{ fontSize: '4em' }} />
      <Typography variant="h1">{I18n.t('dropzone:header')}</Typography>
      {loading ? (
        <>
          <Typography variant="h5">{I18n.t('label:loading')}</Typography>
          <p>
            <Button variant="text" color="secondary" onClick={onCancelLoading}>
              {I18n.t('label:cancel')}
            </Button>
          </p>
        </>
      ) : (
        <Typography
          variant="h5"
          onClick={open}
          onKeyDown={handleKeyDown}
          className={classes.link}
          role="link"
          tabIndex={0}
          ref={linkEl}
        >
          {I18n.t('dropzone:description')}
        </Typography>
      )}
      <br />
      <div>{I18n.t('dropzone:restriction:pdf')}</div>
      <div>{I18n.t('dropzone:restriction:jpg')}</div>
      <div>{I18n.t('dropzone:restriction:video')}</div>
    </Fragment>
  );
};

const PresentationDropzone = ({
  eco,
  onEvent,
  children,
  disabled,
  zoneVisible,
  supportFiles,
  supportMedia,
  screenAsVideo,
}) => {
  const unsupported = !supportFiles && !supportMedia;
  const [isProcessingFiles, setIsProcessingFiles] = useState(false);
  const abortController = useRef(null);
  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (disabled || (!supportFiles && !supportMedia)) {
        return null;
      }
      setIsProcessingFiles(true);
      abortController.current = new AbortController();

      const videoFiles = acceptedFiles.filter((file) =>
        file.type.startsWith('video/')
      );
      let otherFiles = acceptedFiles.filter(
        (file) => !file.type.startsWith('video/')
      );
      if (
        videoFiles.length === 0 &&
        otherFiles.some((file) => file.type === 'application/pdf') === false
      ) {
        const check = await filterSupportedImages(
          otherFiles,
          abortController.current.signal
        );
        if (check.rejected.length > 0) {
          check.rejected.forEach((file) => {
            rejectedFiles.push({
              file,
              errors: [
                {
                  code: 'file-invalid-type',
                  message:
                    'File type must be one of image/*, application/pdf, video/*',
                },
              ],
            });
          });
        }
        otherFiles = check.accepted;
      }
      const showVideo = videoFiles.length > 0 && otherFiles.length === 0;

      if (
        (!showVideo && otherFiles.length > OTHER_MAX_FILES) ||
        (showVideo && videoFiles.length > VIDEO_MAX_FILES)
      ) {
        onEvent({
          type: 'presentation_drop_error',
          message: I18n.t('error:too_many_files'),
        });
      }

      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach(({ file }) => {
          onEvent({
            type: 'presentation_drop_error',
            message: file.name + ' ' + I18n.t('error:file_rejected'),
          });
        });
      }

      if (showVideo && videoFiles.length > 0) {
        onEvent({
          type: 'presentation_drop_media',
          files: videoFiles.slice(0, VIDEO_MAX_FILES),
        });
      } else if (!showVideo && otherFiles.length > 0) {
        onEvent({
          type: 'presentation_drop',
          files: otherFiles.slice(0, OTHER_MAX_FILES),
        });
      }
      abortController.current = null;
      setIsProcessingFiles(false);
      onEvent({ type: 'hide_dialog' });
    },
    [onEvent, disabled, supportFiles, supportMedia]
  );

  const mime_types = [];
  if (supportFiles) {
    Array.prototype.push.apply(mime_types, OTHER_MIME_TYPES);
  }
  if (supportMedia) {
    Array.prototype.push.apply(mime_types, VIDEO_MIME_TYPE);
  }
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: mime_types,
    noClick: true,
    onDrop,
  });

  const onCancelLoading = () => {
    if (abortController.current) {
      abortController.current.abort();
      abortController.current = null;
    }
  };

  return (
    <div
      {...getRootProps({ tabIndex: null })}
      className={classNames('dropzone', {
        'dropzone--isActive': isDragActive,
      })}
    >
      <input {...getInputProps({ disabled: false })} />
      {(isDragActive || zoneVisible || isProcessingFiles) && (
        <div className="drop-popup">
          <div className="frame">
            {disabled || unsupported ? (
              <Disabled
                eco={eco}
                unsupported={unsupported}
                screenAsVideo={screenAsVideo}
              />
            ) : (
              <Regular
                open={open}
                loading={isProcessingFiles}
                autoFocus={zoneVisible}
                onCancelLoading={onCancelLoading}
              />
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default PresentationDropzone;
