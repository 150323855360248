import { Logger } from 'eyeson';

class BroadcastHelper {
  constructor(eyeson) {
    this.eyeson = eyeson;
    this.initApis = this.initApis.bind(this);
  }

  initApis() {
    if (this.eyeson) {
      this.platforms = ['generic'];
      // this.generic = this.generic || new RtmpApi(this.eyeson);
    } else {
      Logger.warn('BroadcastHelper, eyeson missing!');
    }
  }

  available(platform) {
    return platform in this;
  }

  login(platform) {
    if (!this.available(platform)) {
      return;
    }
    this[platform].login();
  }

  activate(platform) {
    if (!this.available(platform)) {
      return;
    }
    this[platform].activate();
  }

  publish(platform) {
    if (!this.available(platform)) {
      return;
    }
    this[platform].publish();
  }

  deactivate(platform) {
    if (!this.available(platform)) {
      return;
    }
    this[platform].deactivate();
  }

  deactivateAll() {
    this.platforms.forEach((platform) => this.deactivate(platform));
  }

  isPublished(platform, broadcasts) {
    if (!this.available(platform)) {
      return;
    }
    const broadcast = broadcasts.find((bcast) => bcast.platform === platform);
    return Boolean(broadcast && broadcast.player_url);
  }

  getPlayerUrl(platform, broadcasts) {
    if (!this.available(platform)) {
      return;
    }
    const broadcast = broadcasts.find((bcast) => bcast.platform === platform);
    return (broadcast && broadcast.player_url) || this[platform].permalLinkUrl;
  }
}

export default BroadcastHelper;
