import I18n from 'i18n-js';
import { FeatureDetector } from 'eyeson';
import Button from '../generic/Button.js';
import Artwork from './../layout/Artwork.js';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import useInterval from '../../hooks/useInterval.js';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'flex-flow': 'column',
  },
  top: {
    'flex-grow': 1,
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    'flex-flow': 'column',
    'flex-wrap': 'wrap',
    'text-align': 'center',
    '& > *': {
      margin: theme.spacing(1, 0, 1, 0),
    },
  },
  subtitle: {
    'max-width': '75ch',
  },
}));

const ErrorPage = ({
  header,
  status,
  message,
  onClick,
  exitCode,
  onSecondaryClick,
  children,
  primaryLabel,
  secondaryLabel,
  eyeson,
  hasExitURL,
  getRetryCounter,
  setRetryCounter,
}) => {
  const classes = useStyles();
  const [reloadCountdown, setReloadCountdown] = useState(3);
  const retryCounter = getRetryCounter(status);

  const headerText = I18n.t('error:page:header:' + status);
  let messageText = I18n.t('error:page:msg:' + status);

  const showTroubleshoot =
    [
      'permission',
      'ice_failed',
      'session_failed',
      'transport_error',
      'request_too_large',
      'abrupt_disconnect',
      'ice_error',
    ].includes(status) && !hasExitURL;

  useInterval(
    () => {
      const counter = reloadCountdown - 1;
      setReloadCountdown(counter);
      if (counter === 0) {
        setRetryCounter(status, retryCounter + 1);
        window.location.reload();
      }
    },
    status === 'transport_error' && retryCounter < 3 && reloadCountdown > 0
      ? 1000
      : null
  );

  if (
    status === 'end_meeting' &&
    eyeson &&
    typeof eyeson.shutdownBy === 'object' &&
    eyeson.shutdownBy !== null
  ) {
    messageText = I18n.t('error:page:msg:end_meeting_user', {
      user: eyeson.shutdownBy.name,
    });
    exitCode = '';
  }
  if (status === 'meeting_locked' && eyeson && Array.isArray(eyeson.kicked)) {
    const kicked = eyeson.kicked.find(
      (entry) => entry.userId === eyeson.user.clientId
    );
    if (kicked) {
      messageText = I18n.t('error:page:msg:kick_user', {
        user: kicked.by.name,
      });
    }
    exitCode = '';
  }
  if (status === 'permission' && FeatureDetector.isIOSDevice()) {
    messageText += ' ' + I18n.t('error:ios_permissions');
  }

  const QuicktestLink = ({ label }) => (
    <Link
      href="https://app.eyeson.team/quicktest/"
      target="_blank"
      rel="noopener noreferrer"
      color="secondary"
    >
      {label}
    </Link>
  );

  return (
    <section className={classes.root}>
      <section className={classes.top}>
        <Typography variant="h2">{headerText}</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {messageText}
        </Typography>
        {status === 'transport_error' && retryCounter < 3 && (
          <Typography variant="body1" color="secondary">
            {I18n.t('error:page:msg:reconnect', { countdown: reloadCountdown })}
          </Typography>
        )}
        <div>
          {onSecondaryClick && (
            <Button type="secondary" onClick={onSecondaryClick}>
              {secondaryLabel}
            </Button>
          )}
          {onClick && (
            <Button type="primary" onClick={onClick}>
              {primaryLabel}
            </Button>
          )}
        </div>
        {showTroubleshoot && (
          <Typography variant="caption">
            {I18n.t('error:page:msg:quicktest')}
            &thinsp;
            <QuicktestLink label={I18n.t('error:page:action:quicktest')} />
          </Typography>
        )}
        {exitCode && (
          <Typography variant="caption" color="secondary">
            Codename: {exitCode}
          </Typography>
        )}
        {children}
      </section>
      <Artwork type={status} />
    </section>
  );
};

ErrorPage.propTypes = {
  status: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClick: PropTypes.func,
  primaryLabel: PropTypes.string,
  onSecondaryClick: PropTypes.func,
  secondaryLabel: PropTypes.string,
  getRetryCounter: PropTypes.func,
  setRetryCounter: PropTypes.func,
};

ErrorPage.defaultProps = {
  message: null,
  eyeson: {},
  exitCode: '',
  onSecondaryClick: null,
  secondaryLabel: 'Help',
  primaryLabel: 'Start Over',
  getRetryCounter: () => 0,
  setRetryCounter: () => {},
};

export default ErrorPage;
