import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import SpeechHelper from './../SpeechHelper.js';
import newMessageSound from '../../assets/audio/bing.mp3';

/**
 * Add a user to participants list. Send a notification if a guest is joining
 * the meeting, but only if the user is not a guest himself.
 **/
class AddUser extends BaseAction {
  audioNotifications(solo, notify) {
    if (solo && notify) {
      return {
        audio: {
          $push: [{ name: 'add-user', url: newMessageSound }],
        },
        lastPlay: { $set: Date.now() },
      };
    }
    return {};
  }

  process(state, props) {
    const user = this.message.user;
    const notify = !this.message.initial && user.id !== state.user.id;
    const solo = state.participants.length === 1;

    if (notify) {
      props.enqueueSnackbar(`${user.name} ${I18n.t('message:joined_room')}`);
    }

    return {
      notifications: update(
        state.notifications,
        this.audioNotifications(solo, notify)
      ),
      participants: update(state.participants, { $push: [user] }),
    };
  }
}

/**
 * Remove a user from participants list.
 **/
class RemoveUser extends BaseAction {
  process(state, props) {
    const { id, name } = this.message.user;
    let notify = id !== state.user.id;
    if (
      Array.isArray(props.eyeson.kicked) &&
      props.eyeson.kicked.find((entry) => entry.userId === id)
    ) {
      // notification already provided
      notify = false;
      props.eyeson.kicked = props.eyeson.kicked.filter(
        (entry) => entry.userId !== id
      );
    }

    if (notify) {
      props.enqueueSnackbar(`${name} ${I18n.t('message:left_room')}`);
    }

    return {
      participants: state.participants.filter((p) => p.id !== id),
    };
  }
}

/**
 * Remove a user from participants list.
 **/
class KickUser extends BaseAction {
  process(state, props) {
    const { eyeson } = props;
    const { name: userName, id: userId } = this.message.user;
    eyeson.send({ type: 'send_chat', content: `/kick ${userId}` });
    eyeson.send({
      type: 'send_chat',
      content: `/status ${JSON.stringify({
        id: 'message:kick_user',
        userA: `**${state.user.name}**`,
        userB: `**${userName}**`,
      })}`,
    });
    setTimeout(() => {
      eyeson.send({ type: 'kick_user', userId });
    }, 200);

    return {};
  }
}

class VoiceActivity extends BaseAction {
  process(state, props) {
    return {
      voiceActivity: update(state.voiceActivity, {
        on: { $set: this.message.on },
        user: { $set: this.message.user },
      }),
    };
  }
}

class TalkingMutedAction extends BaseAction {
  process(state) {
    let talkingMuted = this.message.state === 'on';
    return {
      active: update(state.active, {
        talkingMuted: { $set: talkingMuted },
      }),
    };
  }
}

class SuspendSpeechDetectionAction extends BaseAction {
  process(state) {
    // suspend for 2 minutes or end
    SpeechHelper.suspendDetection(state, 2 * 60 * 1000);
    return {};
  }
}

class ResumeSpeechDetectionAction extends BaseAction {
  process(state) {
    SpeechHelper.resumeDetection(state);
    return {};
  }
}

export {
  AddUser,
  RemoveUser,
  KickUser,
  VoiceActivity,
  TalkingMutedAction,
  SuspendSpeechDetectionAction,
  ResumeSpeechDetectionAction,
};
