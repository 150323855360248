import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  playback: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
}));

const Playback = ({ playback, onEvent }) => {
  const classes = useStyles();
  const video = useRef(null);

  const { url } = playback;

  const handleEnded = () => {
    if (video.current.src === '') {
      return;
    }
    onEvent({ type: 'playback_ended', playback: playback });
    video.current.src = '';
  };

  useEffect(() => {
    if (!video.current.hasAttribute('playsinline')) {
      video.current.setAttribute('playsinline', '');
    }
    video.current.play();
  }, [url]);

  useEffect(() => {
    return () => {
      handleEnded();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <video
      ref={video}
      src={url}
      muted={true}
      controls={false}
      onEnded={handleEnded}
      className={classes.playback}
    />
  );
};

export default Playback;
