import React from 'react';
import PropTypes from 'prop-types';

import UiSwitch from '@material-ui/core/Switch';

const Switch = ({ disabled, on, onClick, ...rest }) => (
  <UiSwitch checked={on} onChange={!disabled && onClick} {...rest} />
);

Switch.propTypes = {
  on: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Switch.defaultProps = { disabled: false };

export default Switch;
