import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import CopyInput from '../layout/CopyInput.js';
import Button from '../generic/Button.js';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  status: {
    flex: 2,
  },
  action: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginRight: theme.spacing(1), // stop all wouldn't be aligned
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: 12,
  },
}));

const LiveStreamStatusInfo = ({
  url,
  title,
  phase,
  health = {},
  published,
  spinning,
}) => {
  if (published) {
    return url ? <CopyInput value={url} silent={true} /> : null;
  }
  if (spinning) {
    return `${I18n.t('label:waiting_for')} ${title}${
      phase ? ': ' + I18n.t(`label:${phase}`) : ''
    } ${health.status ? I18n.t(`label:stream:health:${health.status}`) : ''}`;
  }
  return '';
};

const LiveStreamOption = (props) => {
  const { title, icon, published, spinning, children, onClick } = props;
  const classes = useStyles(props);

  let label = I18n.t('label:start');
  if (published) {
    label = I18n.t('label:stop');
  }
  if (spinning) {
    label = I18n.t('label:cancel');
  }

  return (
    <span className={classes.row}>
      <span className={classes.status}>
        <LiveStreamStatusInfo {...props} />
      </span>
      <span className={classes.action}>
        <Button
          id={'request-' + title}
          icon={icon}
          onClick={onClick}
          aria-label={title}
        >
          {label}
          {children}
        </Button>
        {spinning && (
          <CircularProgress
            size={24}
            onClick={onClick}
            className={classes.buttonProgress}
          />
        )}
      </span>
    </span>
  );
};

LiveStreamOption.propTypes = {
  icon: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  spinning: PropTypes.bool,
  published: PropTypes.bool,
};

LiveStreamOption.defaultProps = {
  url: '',
  spinning: false,
  published: false,
};

export default LiveStreamOption;
