import React from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'eyeson';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as InUse } from '../../assets/artwork/twotimer.svg';
import { ReactComponent as Access } from '../../assets/artwork/rip.svg';
import { ReactComponent as DataSaver } from '../../assets/artwork/data-saver.svg';
import { ReactComponent as Offline } from '../../assets/artwork/connection-lost.svg';
import { ReactComponent as Default } from '../../assets/artwork/lost-video.svg';
import { ReactComponent as Inactive } from '../../assets/artwork/lightsout.svg';
import { ReactComponent as Unsupported } from '../../assets/artwork/browser.svg';
import { ReactComponent as QuickJoin } from '../../assets/artwork/meet.svg';
import { ReactComponent as LockedMeeting } from '../../assets/artwork/locked-meeting.svg';
import { ReactComponent as EndMeeting } from '../../assets/artwork/end-meeting.svg';

const useStyles = makeStyles({
  artwork: {
    width: '100%',
    'max-width': '1200px',
    display: 'flex',
    order: 2,
    flex: 1,
    position: 'relative',
    'justify-content': 'center',
    'align-items': 'flex-end',
    '& svg': {
      height: '100%',
      width: '100%',
      'max-height': '40vh',
    },
  },
});

const Artwork = ({ type, className }) => {
  const artworks = {
    bye: <Default />,
    offline: <Offline />,
    default: <Default />,
    inactive: <Inactive />,
    unsupported: <Unsupported />,
    access_denied: <Access />,
    session_in_use: <InUse />,
    quick_join: <QuickJoin />,
    data_saver: <DataSaver />,
    meeting_locked: <LockedMeeting />,
    end_meeting: <EndMeeting />,
    permission: <Default />,
    ice_failed: <Default />,
    session_failed: <Default />,
    transport_error: <Default />,
    request_too_large: <Default />,
    abrupt_disconnect: <Default />,
    not_readable: <Default />,
    ice_error: <Default />,
    permalink_unavailable: <Access />,
  };
  const classes = useStyles();
  if (!artworks[type]) {
    Logger.warn(`Artwork "${type}" is not registered. Default displayed.`);
  }

  return (
    <section className={classNames(classes.artwork, className)}>
      {artworks[type] || artworks['default']}
    </section>
  );
};

Artwork.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Artwork;
