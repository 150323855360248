import BaseEvent from './BaseEvent.js';

class StartPlaybackEvent extends BaseEvent {
  handle(msg) {
    this.context._api.startPlayback(msg.playback);
  }
}

class StopPlaybackEvent extends BaseEvent {
  handle(msg) {
    this.context._api.stopPlayback(msg.playback);
  }
}

export { StartPlaybackEvent, StopPlaybackEvent };
