/**
 * Recording Facade.
 **/
class RecordingFacade {
  constructor(recording) {
    this.recording = recording;
  }

  get id() {
    return this.recording.id;
  }

  get user() {
    return this.recording.user;
  }

  get createdAt() {
    return this.recording.created_at;
  }

  get stopped() {
    return Boolean(this.recording.duration);
  }

  get ended() {
    return Boolean(this.recording.duration && this.recording.links.download);
  }

  get active() {
    return (
      this.recording.duration === null && this.recording.links.download === null
    );
  }
}

export default RecordingFacade;
