import { WebHIDManager, FeatureDetector } from 'eyeson';

let manager = null;
let supported = null;

const WebHIDHelper = {
  init: async () => {
    if (!manager && WebHIDHelper.isSupported()) {
      manager = await WebHIDManager.initialize();
    }
  },
  isSupported: () => {
    if (supported === null) {
      supported = WebHIDManager.supported && !FeatureDetector.isPhone();
    }
    return supported;
  },
  isBlocked: () => {
    if (manager) {
      return manager.blocked;
    }
    return false;
  },
  onEvent: (fn) => {
    if (manager) {
      manager.onEvent(fn);
    }
  },
  offEvent: (fn) => {
    if (manager) {
      manager.offEvent(fn);
    }
  },
  destroy: () => {
    if (manager) {
      manager.destroy();
      manager = null;
    }
  },
  pairDeviceRequest: async () => {
    if (manager) {
      await manager.pairDeviceRequest();
    }
  },
  emitDeviceList: () => {
    if (manager) {
      manager.emitDeviceList();
    }
  },
  removeDevice: async (id) => {
    if (manager) {
      await manager.removeDevice(id);
    }
  },
  setMuteActive: (active) => {
    if (manager) {
      manager.setMuteActive(active);
    }
  },
  setCallActive: (active) => {
    if (manager) {
      manager.setCallActive(active);
    }
  },
};

export default WebHIDHelper;
