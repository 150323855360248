import update from 'immutability-helper';

/**
 * Knows nothing.
 **/
class BaseAction {
  constructor(msg) {
    this.message = msg;
    this.process = this.process.bind(this);
  }

  process(state, props) {
    return {};
  }
}

/**
 * Does nothing.
 **/
class IgnoreAction extends BaseAction {}

/**
 * Forwards an action to the Eyeson library.
 **/
class ForwardAction extends BaseAction {
  process(state, props) {
    props.eyeson.send(this.message);
    return {};
  }
}

/**
 * Exit.
 **/
class Exit extends BaseAction {
  process(state, props) {
    if (state.active.isPresenter) {
      props.eyeson.send({ type: 'stop_presenting' });
    }
    setTimeout(() => {
      props.onExit({
        code: 'Ahoi',
        reason: this.message.reason || 'closed',
        destination: this.message.destination,
      });
    }, 100);
    return {};
  }
}

/**
 * Terminate Meeting when exit
 **/
class ExitRoomAndTerminateMeeting extends BaseAction {
  process(state, props) {
    props.eyeson.send({ type: 'send_chat', content: '/will_terminate' });
    setTimeout(() => {
      props.eyeson.send({ type: 'terminate_meeting' });
    }, 100);

    return {
      specialExit: { reason: 'exit_room', redirect: true },
    };
  }
}

/**
 * Exit the room and navigate to destination.
 **/
class ExitRoom extends BaseAction {
  process(state, props) {
    props.onExit({ reason: 'exit_room', redirect: true });
    return {};
  }
}

class RefreshRoom extends BaseAction {
  process(state, props) {
    const room = this.message.room;

    const recording = room.recording;
    const broadcasts = room.broadcasts;

    return {
      active: update(state.active, {
        recording: { $set: Boolean(recording && !recording.duration) },
      }),
      recording: {
        id: recording ? recording.id : null,
        user: recording ? recording.user.name : null,
        createdAt: recording ? recording.created_at : null,
      },
      options: room.options,
      broadcasts: broadcasts,
    };
  }
}

class ResetRoomState extends BaseAction {
  process(state, props) {
    const { eyeson, snapshots, broadcasts, recording } = props;

    return {
      solo: false,
      user: eyeson.user,
      peer: null,
      isPip: false,
      sinkId: 'default',
      poster: '',
      stream: null,
      options: {},
      restore: {},
      sortOrder: [],
      snapshots: snapshots,
      accepted: false,
      connecting: true,
      broadcasts: broadcasts,
      localStream: null,
      presentationStream: null,
      canvasPresentation: update(state.canvasPresentation, {
        files: { $set: [] },
      }),
      participants: [],
      sources: [],
      videoSources: [],
      showLocalStream: false,
      isChangingStream: false,
      notifications: update(state.notifications, {
        audio: { $set: [] },
        lastPlay: { $set: Date.now() },
      }),
      wasVideoActive: state.wasVideoActive,
      visible: update(state.visible, {
        panel: { $set: '' },
        dialog: { $set: '' },
        right_sidebar: { $set: false },
        reaction_picker: { $set: false },
        mobile_participants_list: { $set: false },
      }),
      playback: update(state.playback, {
        gifs: { $set: [] },
        files: { $set: [] },
        playing: { $set: [] },
        preview: { $set: null },
      }),
      recording: update(state.recording, {
        user: { $set: recording ? recording.user.name : null },
        createdAt: { $set: recording ? recording.created_at : null },
      }),
      voiceActivity: update(state.voiceActivity, {
        user: { $set: {} },
        on: { $set: false },
      }),
      speech: null,
      pipCam: update(state.pipCam, {
        available: { $set: false },
        active: { $set: false },
        autoStart: { $set: false },
        request: { $set: false },
      }),
    };
  }
}

export {
  BaseAction,
  IgnoreAction,
  ForwardAction,
  Exit,
  ExitRoom,
  ExitRoomAndTerminateMeeting,
  RefreshRoom,
  ResetRoomState,
};

export default BaseAction;
