import React from 'react';
import I18n from 'i18n-js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '../generic/Avatar.js';
import Button from '../generic/Button.js';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const MobileParticipants = ({
  me,
  onEvent,
  isGuest,
  isLocked,
  quickJoin,
  participants,
  kickAvailable,
  hideGuestLogin,
}) => {
  const onKickUser = (user) => {
    if (user.id !== me.clientId) {
      onEvent({ type: 'toggle_mobile_participants' });
      onEvent({ type: 'kick_user_prompt', user: user });
    }
  };

  return (
    <div id="mobile-participants-list">
      <div className="participants-list--scrollable">
        <List>
          {participants.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar
                  alt={I18n.t('aria:label:avatar', {
                    username: user.name,
                    defaultValue: 'Avatar of {{username}}',
                  })}
                  url={user.avatar}
                  label={user.name}
                />
              </ListItemAvatar>
              <ListItemText
                primary={user.name}
                style={{ wordBreak: 'break-word' }}
              />
              {kickAvailable && !isGuest && me.clientId !== user.id && (
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label={I18n.t('label:kick_user:remove')}
                    onClick={() => onKickUser(user)}
                  >
                    <CloseIcon style={{ fontSize: '1rem' }} />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </div>
      {!isLocked && quickJoin && !hideGuestLogin && (
        <div className="participants-list--fixed_bottom">
          <Button
            onClick={() => onEvent({ type: 'share_room_dialog' })}
            icon="person_add"
          >
            {I18n.t('label:quick_join', { defaultValue: 'Invite guest' })}
          </Button>
        </div>
      )}
    </div>
  );
};

export default MobileParticipants;
