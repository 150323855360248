import React from 'react';
import { ConnectionStatistics } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';
import BadSignalIcon from '@material-ui/icons/SignalCellularConnectedNoInternet1Bar';
import SignalIcon from '@material-ui/icons/SignalCellularAlt';
import NoSignalIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';

const { Status } = ConnectionStatistics;

const statusIcons = {};
statusIcons[Status.NO_CONNECTION] = NoSignalIcon;
statusIcons[Status.BAD] = BadSignalIcon;
statusIcons[Status.OK] = SignalIcon;
statusIcons[Status.GOOD] = SignalIcon;

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.action.active,
    width: '1em',
    height: '1em',
    flexShrink: '0',
    userSelect: 'none',
    fontSize: '2rem',
    lineHeight: '1',
    textAlign: 'center',
    position: 'relative',
  },
  status: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: (data) => {
      return data.status === Status.BAD || data.status === Status.NO_CONNECTION
        ? '.35em'
        : '.2em';
    },
    marginTop: (data) => {
      return data.status === Status.BAD || data.status === Status.NO_CONNECTION
        ? '.1em'
        : '0';
    },
    width: '12px',
    height: '12px',
    backgroundColor: (data) => {
      if (data.status === Status.BAD || data.status === Status.NO_CONNECTION) {
        return '#e53729';
      }
      if (data.status === Status.OK) {
        return '#c7bb2a';
      }
      return '#08C008';
    },
    borderRadius: '50%',
    border: `2px solid ${theme.palette.background.paper}`,
    transition: 'border-color 400ms ease',
  },
}));

const ConnectionInfoIcon = ({ data }) => {
  const classes = useStyles(data);
  let signalIcon = <SignalIcon />;
  if (data.status === Status.BAD) {
    signalIcon = <BadSignalIcon />;
  } else if (data.status === Status.NO_CONNECTION) {
    signalIcon = <NoSignalIcon />;
  }
  return (
    <div className={classes.icon}>
      <div className={classes.status} id="connection-info-icon-status"></div>
      {signalIcon}
    </div>
  );
};

export default ConnectionInfoIcon;
