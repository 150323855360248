import Gif from './Gif.js';
import React, { useRef } from 'react';
import I18n from 'i18n-js';
import { makeStyles } from '@material-ui/core/styles';
import { FeatureDetector } from 'eyeson';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    outline: 'none',
  },
}));

const activeGif = { current: null, channel: null };

const Gifs = ({ gifs, onFav, onClick, channel }) => {
  const classes = useStyles();
  const sectionRef = useRef();
  const gifList = [];

  const handleSectionFocus = (event) => {
    event.stopPropagation();
    if (sectionRef.current && event.target === sectionRef.current) {
      if (gifList.length > 0) {
        if (
          activeGif.current &&
          activeGif.channel === channel &&
          gifList.includes(activeGif.current)
        ) {
          activeGif.current.focus();
        } else {
          activeGif.current = gifList[0];
          activeGif.channel = channel;
          activeGif.current.focus();
        }
      }
    }
    if (sectionRef.current && event.target !== event.currentTarget) {
      sectionRef.current.tabIndex = '-1';
    }
  };

  const handleSectionBlur = (event) => {
    if (sectionRef.current && event.target !== event.currentTarget) {
      sectionRef.current.tabIndex = '0';
    }
  };

  const handleSectionNavigation = (event) => {
    if (!event.key.startsWith('Arrow')) {
      return;
    }
    let next = null;
    const current = gifList.indexOf(event.target);
    if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      next = current === gifList.length - 1 ? 0 : current + 1;
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      next = current === 0 ? gifList.length - 1 : current - 1;
    }
    if (gifList[next]) {
      activeGif.current = gifList[next];
      activeGif.current.focus();
    }
  };

  return (
    <div
      role="list"
      ref={sectionRef}
      tabIndex="0"
      aria-label={I18n.t('aria:label:gif_list', {
        defaultValue: 'List of GIFs from Giphy',
      })}
      className={classes.root}
      onFocus={handleSectionFocus}
      onBlur={handleSectionBlur}
      onKeyDown={handleSectionNavigation}
    >
      {gifs.map((gif, index) => {
        return (
          <Gif
            ref={(element) => (gifList[index] = element)}
            key={gif.id}
            fav={gif.fav}
            title={gif.title}
            width={gif.images.fixed_width.width}
            height={gif.images.fixed_width.height}
            onFav={() => onFav(gif)}
            onClick={() => onClick(gif)}
            videoUrl={
              FeatureDetector.hasWebpSupport()
                ? gif.images.fixed_width.webp
                : gif.images.fixed_width_downsampled.url
            }
            imageUrl={gif.images.fixed_width_still.url}
          />
        );
      })}
    </div>
  );
};

export default Gifs;
