import BaseAction from './BaseActions.js';

class StartAnnotation extends BaseAction {
  process(state, props) {
    if (state.annotating) {
      return;
    }
    props.eyeson.send({ type: 'start_annotation' });
    return { annotating: true };
  }
}

class StopAnnotation extends BaseAction {
  process(state, props) {
    props.eyeson.send({ type: 'stop_annotation' });
    return { annotating: false };
  }
}

export { StartAnnotation, StopAnnotation };
