import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}))(MuiButton);

const IconLeft = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
    fill:
      theme.palette.type === 'light'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
  },
}))(Icon);

const Button = ({ children, icon, type, action = 'default', ...props }) => (
  <StyledButton
    color="secondary"
    variant={type === 'primary' ? 'contained' : 'text'}
    type={action === 'default' ? 'button' : action}
    {...props}
  >
    {icon && <IconLeft>{icon}</IconLeft>}
    {children}
  </StyledButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Button.defaultProps = { type: 'primary' };

export default Button;
