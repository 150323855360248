import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Heart from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
  gifWrapper: {
    position: 'relative',
    margin: theme.spacing(1, 0),
    outline: 'none',
    opacity: (props) => (props.hover ? '1' : '0.5'),
    cursor: 'pointer',
  },
  gif: {
    background: theme.palette.primary.dark,
  },
  heart: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    height: '1.5rem',
    fill: (props) =>
      props.fav ? theme.palette.secondary.main : theme.palette.common.white,
    color: (props) =>
      props.fav ? theme.palette.secondary.main : theme.palette.common.white,
    cursor: 'pointer',
  },
}));

const Gif = forwardRef(
  (
    {
      fav = false,
      title = 'A GIF from giphy',
      width,
      height,
      videoUrl,
      imageUrl,
      onFav,
      onClick,
    },
    gifRef
  ) => {
    const [hover, setHover] = useState(false);
    const classes = useStyles({ fav, hover });

    const handleFav = (event) => {
      event.stopPropagation();
      onFav();
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        event.stopPropagation();
        onClick();
      }
    };

    return (
      <div
        ref={gifRef}
        tabIndex="-1"
        role="listitem"
        onBlur={() => setHover(false)}
        onFocus={() => setHover(true)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        className={classNames('gif-wrapper', classes.gifWrapper)}
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
      >
        <img
          alt={title}
          src={hover ? videoUrl : imageUrl}
          width={180}
          height={height}
          loading="lazy"
          className={classes.gif}
        />
        {(hover || fav) && (
          <Heart className={classes.heart} onClick={handleFav} />
        )}
      </div>
    );
  }
);

Gif.propTypes = {
  fav: PropTypes.bool,
  onFav: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.fav === nextProps.fav &&
    prevProps.videoUrl === nextProps.videoUrl &&
    prevProps.imageUrl === nextProps.imageUrl
  );
};

export default React.memo(Gif, areEqual);
