/**
 * importScript
 * lazy-load script files, promise-based
 */
const importScript = (url, integrity = null) => {
  return new Promise((resolve, reject) => {
    const src = new URL(url, location.href);
    const script = document.createElement('script');
    script.async = true;
    script.onload = () => {
      if (script.parentNode) {
        script.remove();
      }
      resolve(script);
    };
    script.onerror = () => {
      if (script.parentNode) {
        script.remove();
      }
      reject(new Error('Unable to initiate script ' + url));
    };
    if (integrity) {
      script.integrity = integrity.algo + '-' + integrity.hash;
    }
    script.src = src;
    document.head.append(script);
  });
};

export default importScript;
