import Video from './Video.js';
import PropTypes from 'prop-types';
import NameInserts from '../name_inserts/NameInserts.js';
import React, { Fragment } from 'react';

const VideoStream = (props) => {
  if (!props.visible || !props.stream) {
    return null;
  }

  return (
    <Fragment>
      {props.withNameInsert && <NameInserts {...props} />}
      <div className="video-stream-container">
        <Video {...props} />
      </div>
    </Fragment>
  );
};

VideoStream.propTypes = {
  muted: PropTypes.bool,
  poster: PropTypes.string,
  stream: PropTypes.object,
  sinkId: PropTypes.string,
  visible: PropTypes.bool,
  onEvent: PropTypes.func,
  controls: PropTypes.bool,
  playback: PropTypes.object,
  className: PropTypes.string,
  withNameInsert: PropTypes.bool,
};

VideoStream.defaultProps = {
  muted: true,
  visible: true,
  sinkId: '',
  playback: {},
  withNameInsert: true,
};

export default VideoStream;
