import React, { Component } from 'react';
import { debounce } from 'eyeson';

/**
 * Eyeson Draggable container
 *
 * NOTE: we are using screenX, screenY instead of clientX, clientY since some
 * browser don't handle the latter nicely (zero on drag end o.O).
 **/
class Draggable extends Component {
  state = { x: 0, y: 0 };

  constructor(props) {
    super(props);
    this.initial = { x: 0, y: 0 };
    this.offset = { x: 0, y: 0 };
    this.handleResize = this.handleResize.bind(this);
    this.resizeTimeout = null;
  }

  componentDidMount() {
    if (this.node) {
      const observer = new MutationObserver(() => {
        debounce(this.handleResize, 200);
        this.resizeTimeout = setTimeout(this.handleResize, 500);
      });
      observer.observe(this.node.parentElement.parentElement, {
        attributes: true,
        subtree: true,
      });
    }
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearTimeout(this.resizeTimeout);
  }

  dragStart = (event) => {
    const e = event.type === 'touchstart' ? event.touches[0] : event;
    this.initial.x = e.clientX - this.offset.x;
    this.initial.y = e.clientY - this.offset.y;
    document.addEventListener('mousemove', this.drag, false);
    document.addEventListener('mouseup', this.dragEnd, false);
    document.addEventListener('touchmove', this.drag, false);
    document.addEventListener('touchend', this.dragEnd, false);
  };

  drag = (event) => {
    const e = event.type === 'touchmove' ? event.touches[0] : event;
    event.preventDefault();
    this.offset.x = e.clientX - this.initial.x;
    this.offset.y = e.clientY - this.initial.y;
    this.updateTranslation(this.offset.x, this.offset.y);
  };

  dragEnd = () => {
    document.removeEventListener('mousemove', this.drag, false);
    document.removeEventListener('mouseup', this.dragEnd, false);
    document.removeEventListener('touchmove', this.drag, false);
    document.removeEventListener('touchend', this.dragEnd, false);
    this.initial.x = this.state.x;
    this.initial.y = this.state.y;
  };

  handleResize() {
    this.updateTranslation(this.state.x, this.state.y);
  }

  // NOTE: Translation border has some miscalculation based on the negative
  // margin of the expansion panel causing to be off -72pixel.
  updateTranslation = (x, y) => {
    if (!this.node || !this.node.parentElement) {
      return;
    }

    const border = this.translationBorder;
    x = Math.max(border.x[0] - 72, Math.min(x, border.x[1] - 72));
    y = Math.max(border.y[0], Math.min(border.y[1], y));
    this.setState({ x, y });
  };

  get translationBorder() {
    const parentElement = this.node.parentElement;
    const parentBorder = {
      x: [
        parentElement.offsetLeft,
        parentElement.offsetLeft + parentElement.offsetWidth,
      ],
      y: [
        parentElement.offsetTop,
        parentElement.offsetTop + parentElement.offsetHeight,
      ],
    };

    return {
      x: [
        parentBorder.x[0] - this.node.offsetLeft,
        parentBorder.x[1] - (this.node.offsetLeft + this.node.offsetWidth),
      ],
      y: [
        parentBorder.y[0] - this.node.offsetTop,
        parentBorder.y[1] - (this.node.offsetTop + this.node.offsetHeight),
      ],
    };
  }

  get style() {
    return { transform: `translate(${this.state.x}px, ${this.state.y}px)` };
  }

  render() {
    return (
      <div
        ref={(n) => (this.node = n)}
        style={this.style}
        className={`eyeson-draggable ${this.props.className}`}
        onMouseDown={this.dragStart}
        onTouchStart={this.dragStart}
      >
        {this.props.children}
      </div>
    );
  }
}

Draggable.propTypes = {};
Draggable.defaultProps = {};

export default Draggable;
