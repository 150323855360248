import { Logger, FeatureDetector } from 'eyeson';
import supports from './SupportHelper.js';

const supported = !FeatureDetector.isPhone() && supports.PiPConferenceControls;

const handlers = {
  microphone: null,
  camera: null,
  hangup: null,
  autopip: null,
};

const PipControlsHelper = {
  setMicrophone: (handler) => {
    if (supported) {
      try {
        navigator.mediaSession.setActionHandler('togglemicrophone', handler);
        handlers.microphone = handler;
      } catch (error) {
        Logger.error('PipControlsHelper::setMicrophone', error);
      }
    }
  },
  clearMicrophone: () => {
    PipControlsHelper.setMicrophone(null);
  },
  setCamera: (handler) => {
    if (supported) {
      try {
        navigator.mediaSession.setActionHandler('togglecamera', handler);
        handlers.camera = handler;
      } catch (error) {
        Logger.error('PipControlsHelper::setCamera', error);
      }
    }
  },
  clearCamera: () => {
    PipControlsHelper.setCamera(null);
  },
  setHangup: (handler) => {
    if (supported) {
      try {
        navigator.mediaSession.setActionHandler('hangup', handler);
        handlers.hangup = handler;
      } catch (error) {
        Logger.error('PipControlsHelper::setHangup', error);
      }
    }
  },
  clearHangup: () => {
    PipControlsHelper.setHangup(null);
  },
  hideHangup: () => {
    if (handlers.hangup) {
      const handler = handlers.hangup;
      PipControlsHelper.setHangup(null);
      handlers.hangup = handler;
    }
  },
  showHangup: () => {
    if (handlers.hangup) {
      PipControlsHelper.setHangup(handlers.hangup);
    }
  },
  setAutoPipHandler: (handler) => {
    handlers.autopip = handler;
  },
  enableAutoPip: () => {
    if (handlers.autopip) {
      try {
        navigator.mediaSession.setActionHandler(
          'enterpictureinpicture',
          handlers.autopip
        );
      } catch (error) {
        // Logger.error('PipControlsHelper::autoPip', error);
      }
    }
  },
  disableAutoPip: () => {
    try {
      navigator.mediaSession.setActionHandler('enterpictureinpicture', null);
    } catch (error) {
      // Logger.error('PipControlsHelper::autoPip', error);
    }
  },
  clearAutoPip: () => {
    handlers.autopip = null;
    PipControlsHelper.disableAutoPip();
  },
};

export default PipControlsHelper;
