import { Logger } from 'eyeson';

/**
 * Help center holds a collection of keys that map to help center resources.
 **/
export default (function () {
  const MESSAGES = {
    _: '',
  };

  return {
    fetch: function (key) {
      if (!MESSAGES[key]) {
        Logger.warn('HelpCenter', 'No message found!', key);
        return 'https://help.eyeson.com/';
      }

      return MESSAGES[key];
    },
  };
})();
