import I18n from 'i18n-js';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useInterval from '../../hooks/useInterval.js';

const Timer = ({ start }) => {
  const [ticks, setTicks] = useState(0);

  useInterval(() => setTicks(ticks + 1), 1000);

  const now = () => Math.floor(Date.now() / 1000);

  if (!start) {
    return <small className="pulse">{I18n.t('label:awaiting')}</small>;
  }

  // In case the server is in front
  if (start > now()) {
    return '00:00:00';
  }

  const date = new Date(null);
  date.setSeconds(now() - start);
  return date.toISOString().substr(11, 8);
};

Timer.propTypes = {
  start: PropTypes.number,
  interval: PropTypes.number,
};

Timer.defaultProps = {
  interval: 1000,
};

export default Timer;
