import Avatar from '../../../generic/Avatar.js';
import dateHelper from '../../../../utils/dateHelper.js';
import React, { Component } from 'react';
import Linkify from 'react-linkify';
import PropTypes from 'prop-types';
import LinkifyDecorator from './LinkifyDecorator.js';

class MyMessage extends Component {
  /**
   * It my message if flagged as own message.
   **/
  static test(props) {
    return props.user.clientId === props.me.clientId;
  }

  /**
   * Render the plain message.
   **/
  render() {
    const { user, timestamp, content } = this.props;

    return (
      <div className="eyeson-chat-message me">
        <div className="content">
          <span className="details">
            <strong className="username">{user.name}</strong>
            <span className="date">{dateHelper.formatTime(timestamp)}</span>
          </span>
          <Linkify className="body" componentDecorator={LinkifyDecorator}>
            {content}
          </Linkify>
        </div>
        <Avatar url={user.avatar} label={user.name} />
      </div>
    );
  }
}

MyMessage.propTypes = {
  me: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  timestamp: PropTypes.instanceOf(Date).isRequired,
};

export default MyMessage;
