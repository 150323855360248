import I18n from 'i18n-js';
import React, { useState } from 'react';
import useInterval from '../../hooks/useInterval.js';
import PropTypes from 'prop-types';
import PromptDialog from './PromptDialog.js';

const CountdownDialog = (props) => {
  const { startTime, message, onCountdownEnd } = props;
  const [remainingTime, setRemainingTime] = useState(startTime);
  const [over, setOver] = useState(false);

  useInterval(
    () => {
      const newRemainingTime = remainingTime - 1;
      const isOver = newRemainingTime <= 0;
      setRemainingTime(newRemainingTime);
      setOver(isOver);
      if (isOver) onCountdownEnd();
    },
    over ? null : 1000
  );

  const msg = `${message} ${remainingTime} ${I18n.t('label:seconds')}.`;
  return <PromptDialog {...props} message={msg} />;
};

CountdownDialog.propTypes = {
  startTime: PropTypes.number.isRequired,
  onCountdownEnd: PropTypes.func.isRequired,
};

export default CountdownDialog;
