import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import VideoPlayer from '../VideoPlayer.js';

class StartMediaEvent extends BaseEvent {
  handle(message) {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    const onError = error => {
      Logger.error('StartMediaEvent', error);
      const name =
        error && (error instanceof MediaError || error instanceof DOMException)
          ? 'error_VideoError'
          : 'error_TypeError';
      _session.emit({ type: 'warning', name: name });
      _session.emit({ type: 'stop_presenting' });
    };

    try {
      _session.videoPlayer = new VideoPlayer(message);
      _session.videoPlayer.onError(onError);
    } catch (error) {
      onError(error);
    }
  }
}

export { StartMediaEvent };
