import I18n from 'i18n-js';
import Icon from '../generic/Icon.js';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  marker: {
    display: 'flex',
    position: 'relative',
    'justify-content': 'space-between',
    padding: '0.5rem 0',
    margin: '0.5rem 0 0 0',
    color: theme.palette.common.white,
    background: (props) => (props.active ? theme.palette.secondary.main : ''),
    width: (props) => (props.hover ? '15rem' : '4.5rem'),
    transition: 'width 0.2s ease',
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    minWidth: '4.5rem',
    width: '4.5rem',
    'justify-content': 'center',
    'align-items': 'center',
    margin: 0,
    height: '2.2rem',
    '& svg': {
      fill: (props) =>
        props.active && theme.palette.type === 'light'
          ? theme.palette.common.white
          : '',
    },
  },
  icon: {
    fill: (props) => {
      if (props.active) {
        return theme.palette.type === 'light' ? theme.palette.common.white : '';
      }
      if (props.highlight) {
        return theme.palette.secondary.main;
      }
      return '';
    },
    color: (props) => {
      if (props.active) {
        return theme.palette.type === 'light' ? theme.palette.common.white : '';
      }
      if (props.highlight) {
        return theme.palette.secondary.main;
      }
      return '';
    },
  },
  content: {
    display: (props) => (props.hover ? 'flex' : 'none'),
    'justify-content': 'space-between',
    'align-items': 'center',
    'line-height': '1em',
    width: '100%',
    'max-height': '2.2rem',
    'margin-right': '0.5rem',
  },
  label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    'text-transform': 'none',
  },
}));

const Label = ({ content, labelIsStatus = true }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="button"
      display="block"
      className={classes.label}
      role={labelIsStatus ? 'status' : null}
    >
      {content}
    </Typography>
  );
};

const ActionMarker = ({
  icon,
  label = null,
  active = false,
  locked,
  action,
  hovered = false,
  visible,
  onClick,
  tooltip = '',
  disabled,
  highlight = false,
  stopAction = null,
  stopOption,
  activeLabel = I18n.t('label:active', { defaultValue: 'active' }),
  labelIsStatus = true,
}) => {
  const [hover, setHover] = useState(hovered); // storybook
  const classes = useStyles({ active, hover, locked, disabled, highlight });

  const handleClick = () => {
    if (active || locked) {
      return;
    }
    onClick({ type: action });
  };

  const handleStopClick = (event) => {
    event.currentTarget.blur();
    setHover(false);
    onClick({ type: 'stop_' + action });
  };

  return (
    <div
      className={classes.marker}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      tabIndex={active ? 0 : null}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
      aria-label={
        active ? label + (labelIsStatus ? ' ' + activeLabel : '') : null
      }
      role={active ? 'region' : null}
    >
      <Tooltip open={!active && hover} title={tooltip} placement="right">
        <button
          type="button"
          id={action}
          onClick={handleClick}
          className={classes.button}
          aria-pressed={disabled}
          aria-label={label}
          tabIndex={active ? -1 : null}
          onFocus={() => setHover(true)}
          onBlur={() => setHover(false)}
        >
          <Icon type={icon} className={classes.icon} title={icon} />
          {active}
        </button>
      </Tooltip>
      {active && (
        <span className={classes.content}>
          <Label content={activeLabel} labelIsStatus={labelIsStatus} />
          {stopOption ? (
            stopOption
          ) : (
            <Tooltip title={tooltip} placement="top">
              <MuiButton
                size="small"
                color="primary"
                className={classes.icon}
                onClick={handleStopClick}
                tabIndex={null}
                aria-label={tooltip}
              >
                {I18n.t('label:stop')}
              </MuiButton>
            </Tooltip>
          )}
        </span>
      )}
    </div>
  );
};

ActionMarker.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  action: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  stopOption: PropTypes.object,
  stopAction: PropTypes.string,
  labelIsStatus: PropTypes.bool,
  activeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default ActionMarker;
