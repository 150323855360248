import PublicEvent from './PublicEvent.js';
import ChangeStreamEvent from './ChangeStreamEvent.js';
import { hasAudio, hasVideo } from '../utils/StreamHelpers.js';
import Logger from '../Logger.js';
import DeviceManager from '../DeviceManager.js';
import LocalStorage from '../LocalStorage.js';
import FeatureDetector from '../FeatureDetector.js';

class AudioDeviceEndEvent extends PublicEvent {
  // eslint-disable-next-line max-statements
  async handle(msg) {
    const { _session } = this.context;
    try {
      const audio = hasAudio(_session.localStream);
      const video = hasVideo(_session.localStream);
      await new ChangeStreamEvent(this.context).handle({ audio, video });
      const tracks = _session.localStream.getAudioTracks();
      if (tracks && tracks.length > 0) {
        const [track] = tracks;
        const devices = await DeviceManager.fetchInputDevices();
        const { deviceId } = track.getSettings();
        const found = devices.find(device => device.deviceId === deviceId);
        if (!FeatureDetector.canMultipleDifferentMicrophones()) {
          const mediaConstraints = LocalStorage.load('mediaConstraints');
          mediaConstraints.audio = { deviceId: { exact: deviceId } };
          LocalStorage.store('mediaConstraints', mediaConstraints);
          LocalStorage.store('audioId', deviceId);
        }
        if (found) {
          msg.newAudioDevice = found.label;
        }
      }
    } catch (error) {
      Logger.error('AudioDeviceEndEvent', error);
    }
    super.handle(msg);
  }
}

class VideoDeviceEndEvent extends PublicEvent {
  async handle(msg) {
    const { _session } = this.context;
    try {
      const audio = hasAudio(_session.localStream);
      await new ChangeStreamEvent(this.context).handle({ audio, video: false });
    } catch (error) {
      Logger.error('VideoDeviceEndEvent', error);
    }
    super.handle(msg);
  }
}

class AllDevicesEndEvent extends PublicEvent {
  // eslint-disable-next-line max-statements
  async handle(msg) {
    const { _session } = this.context;
    try {
      const audio = hasAudio(_session.localStream);
      await new ChangeStreamEvent(this.context).handle({ audio, video: false });
      const tracks = _session.localStream.getAudioTracks();
      if (tracks && tracks.length > 0) {
        const [track] = tracks;
        const devices = await DeviceManager.fetchInputDevices();
        const { deviceId } = track.getSettings();
        const found = devices.find(device => device.deviceId === deviceId);
        if (!FeatureDetector.canMultipleDifferentMicrophones()) {
          const mediaConstraints = LocalStorage.load('mediaConstraints');
          mediaConstraints.audio = { deviceId: { exact: deviceId } };
          LocalStorage.store('mediaConstraints', mediaConstraints);
          LocalStorage.store('audioId', deviceId);
        }
        if (found) {
          msg.newAudioDevice = found.label;
        }
      }
    } catch (error) {
      Logger.error('AllDevicesEndEvent', error);
    }
    super.handle(msg);
  }
}

export { AudioDeviceEndEvent, VideoDeviceEndEvent, AllDevicesEndEvent };
