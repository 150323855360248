import React from 'react';
import PropTypes from 'prop-types';
import twemoji from 'twemoji';

const TwitterEmoji = ({ emoji, label }) => {
  return (
    <span
      role="img"
      title={label}
      aria-label={label}
      dangerouslySetInnerHTML={{
        __html: twemoji.parse(emoji),
      }}
    />
  );
};

TwitterEmoji.propTypes = {
  emoji: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default TwitterEmoji;
