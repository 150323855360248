import React, { Component, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { FeatureDetector } from 'eyeson';

const ImageThumbnail = ({ file, active, onClick, setActiveRef }) => {
  const url = useRef('');

  useEffect(() => {
    // NOTE this makes development with storybook easier.
    try {
      url.current = URL.createObjectURL(file);
    } catch (e) {
      url.current = file;
    }
  }, [file]);

  const handleClick = (event) => {
    if (
      event.type === 'click' ||
      (event.type === 'keydown' && (event.key === 'Enter' || event.key === ' '))
    ) {
      onClick(file);
    }
  };

  return (
    <img
      src={url.current}
      role="listitem"
      aria-current={active ? true : null}
      alt={`thumbnail${active ? ' active' : ''} ${file.name}`}
      ref={(element) => (active ? setActiveRef(element) : null)}
      tabIndex="-1"
      onClick={handleClick}
      onKeyDown={handleClick}
      className={`thumbnail visible ${active ? 'active' : ''}`}
    />
  );
};

class ImageThumbnails extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.file.name !== prevProps.file.name &&
      this.props.lastModified !== prevProps.file.lastModified
    ) {
      this.scrollIntoView();
    }
  }

  setRef = (node) => {
    if (node) {
      this.thumbnailsEl = node;
    }
  };

  setActiveRef = (node) => {
    if (node) {
      this.activeEl = node;
    }
  };

  scrollIntoView = () => {
    if (this.props.expanded) {
      this.activeEl.scrollIntoView();
    }
  };

  handleWheel = (event) => {
    let { deltaY } = event;

    if (!deltaY) {
      return;
    }

    if (deltaY < 10 && FeatureDetector.platform().name === 'Firefox') {
      deltaY = deltaY * 10;
    }

    this.thumbnailsEl.scrollLeft = this.thumbnailsEl.scrollLeft + deltaY;
  };

  render() {
    return (
      <section
        ref={this.setRef}
        aria-label={I18n.t('aria:label:thumbnails', {
          defaultValue: 'Thumbnails',
        })}
        onWheel={this.handleWheel}
        className="thumbnails"
        style={{ maxWidth: `calc(100vw - ${this.props.borderOffset})` }}
      >
        {this.props.files.map((file) => {
          return (
            <ImageThumbnail
              key={`${file.lastModified}-${file.name}`}
              file={file}
              active={
                file.name === this.props.file.name &&
                file.lastModified === this.props.file.lastModified
              }
              onClick={this.props.onThumbnailClick}
              setActiveRef={this.setActiveRef}
            />
          );
        })}
      </section>
    );
  }
}

ImageThumbnails.defaultProps = {
  file: {},
  files: [],
};

ImageThumbnails.propTypes = {
  file: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  borderOffset: PropTypes.string.isRequired,
};

export default ImageThumbnails;
