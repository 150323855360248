import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import CanvasStream from '../streams/CanvasStream.js';
import React, { Fragment, Component } from 'react';
import {
  scaleCanvas,
  calcCanvasScale,
  drawImageCentered,
} from '../../utils/CanvasHelper.js';

class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: null,
      page: 1,
      scale: {},
      numPages: 1,
      drawObjects: [],
    };

    // Note: These are not covered in react state, because the timing
    // of setState causes issues.
    this.pendingPage = null;
    this.pageRendering = false;
    this.drawObjects = {};
    this.hiddenCanvas = null;
    this.hiddenCanvasContext = null;
  }

  componentDidMount() {
    this.setPdfFromFile();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.file.name !== prevProps.file.name ||
      this.props.file.lastModified !== prevProps.file.lastModified
    ) {
      this.setPdfFromFile();
    }
  }

  componentWillUnmount() {
    this.drawObjects = {};
  }

  setRef = (canvas) => {
    if (canvas) {
      this.canvasEl = canvas;
    }
  };

  setHiddenCanvasRef = (canvas) => {
    if (canvas) {
      this.hiddenCanvas = canvas;
      this.hiddenCanvasContext = canvas.getContext('2d', {
        alpha: false,
        desynchronized: true,
      });
    }
  };

  setPdfFromFile = () => {
    import(/* webpackPreload: true */ 'pdfjs-dist/webpack')
      .then((pdfjsLib) => {
        const fileUrl = URL.createObjectURL(this.props.file);
        const loadingTask = pdfjsLib.getDocument(fileUrl);
        return loadingTask.promise.then((pdf) => {
          this.setState(
            {
              pdf: pdf,
              numPages: pdf.numPages,
              page: 1,
              drawObjects: [],
            },
            () => {
              this.state.pdf
                .getPage(this.state.page)
                .then(this.queueRenderPage);
            }
          );
        });
      })
      .catch((error) => {
        Logger.warn('Document::setPdfFromFile', error);
        this.props.onEvent({
          type: 'presentation_drop_error',
          message:
            (error && error.message) || I18n.t('error:password_protected'),
        });
      });
  };

  pageFlip = (kind) => {
    let newPage = kind === 'next' ? this.state.page + 1 : this.state.page - 1;
    this.setPage(newPage);
  };

  setPage = (requestedPage, force = false) => {
    if (requestedPage === this.state.page && !force) {
      return;
    }

    let newPage = 1;
    if (requestedPage > 1) {
      newPage = Math.min(requestedPage, this.state.numPages);
    }
    this.setState({ page: newPage, drawObjects: [] }, () => {
      this.state.pdf.getPage(newPage).then(this.queueRenderPage);
    });
  };

  renderPage = (page) => {
    const context = this.hiddenCanvasContext;
    const viewport = page.getViewport({ scale: 1 });
    const scaled = scaleCanvas(viewport.width, viewport.height);
    const scaledViewport = page.getViewport({
      scale: scaled.width / viewport.width,
    });
    const scaledWidth = Math.round(scaledViewport.width);
    const scaledHeight = Math.round(scaledViewport.height);

    if (
      this.hiddenCanvas.width !== scaledWidth ||
      this.hiddenCanvas.height !== scaledHeight
    ) {
      this.hiddenCanvas.width = scaledWidth;
      this.hiddenCanvas.height = scaledHeight;
    }

    const renderTask = page.render({
      viewport: scaledViewport,
      canvasContext: context,
    });

    this.pageRendering = true;
    renderTask.promise
      .then(() => {
        this.pageRendering = false;
        if (this.pendingPage !== null) {
          this.renderPage(this.pendingPage);
          this.pendingPage = null;
        } else {
          drawImageCentered(this.canvasEl, this.hiddenCanvas);

          this.setState({ scale: calcCanvasScale(this.canvasEl) });
          const newDrawObjects = this.getDrawObjectsByPage(this.state.page);
          if (!!newDrawObjects) {
            this.setState({ drawObjects: newDrawObjects });
          }
        }
      })
      .catch((error) => {
        Logger.warn('Document::renderPage', error);
      });
  };

  queueRenderPage = (page) => {
    if (this.pageRendering) {
      this.pendingPage = page;
    } else {
      this.renderPage(page);
    }
  };

  renderCanvas = () => this.setPage(this.state.page, true);

  getDrawObjectsByPage = (page) => {
    const id = 'page_' + parseInt(page);
    return this.drawObjects[id];
  };

  handleNewDrawObject = (obj) => {
    const id = 'page_' + parseInt(this.state.page);
    this.drawObjects[id] = obj;
  };

  render() {
    if (!this.state.pdf) {
      return null;
    }

    return (
      <Fragment>
        <canvas style={{ display: 'none' }} ref={this.setHiddenCanvasRef} />
        <CanvasStream
          {...this.props}
          {...this.state}
          setRef={this.setRef}
          numPages={this.state.numPages}
          pageFlip={this.pageFlip}
          renderCanvas={this.renderCanvas}
          onDraw={this.handleNewDrawObject}
          handleChange={this.setPage}
          onThumbnailClick={this.setPage}
        />
      </Fragment>
    );
  }
}

export default Document;
