import BaseEvent from './BaseEvent.js';

class SendChatEvent extends BaseEvent {
  handle(msg) {
    this.context._api.sendMessage({
      type: 'chat',
      content: msg.content
    });
  }
}

export default SendChatEvent;
