import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import DeviceManager from '../DeviceManager.js';
import MicMixer from '../MicMixer.js';
import FeatureDetector from '../FeatureDetector.js';
import { getScreenAsVideoTracks, stopStream } from '../utils/StreamHelpers.js';
import PodiumEvent from './PodiumEvent.js';

class StartStreamEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle({
    audio = true,
    video = true,
    virtualBackground = false,
    screen = false,
    surface = null
  }) {
    Logger.debug(
      `StartStreamEvent::handle audio: ${audio} video: ${video} screen: ${screen}`
    );
    const { _session, _connection } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    _session.options.virtualBackground = virtualBackground;
    if (_session.vbgMixer) {
      const type = DeviceManager.getStoredVirtualBackgroundType();
      _session.vbgMixer.stopOriginalStream();
      _session.vbgMixer.terminate();
      _session.vbgMixer.changeBackground(type);
    }
    if (
      _session.localStream &&
      !FeatureDetector.canMultipleDifferentMicrophones()
    ) {
      stopStream(_session.localStream);
    }
    const options = {
      audio: audio,
      video: video,
      virtualBackground: _session.options.virtualBackground,
      vbgMixer: _session.vbgMixer,
      deviceMonitor: _session.deviceMonitor
    };
    if (screen) {
      _session.micMixer = new MicMixer();
      options.video = false;
      options.screen = true;
      options.surface = surface;
      options.virtualBackground = false;
      options.micMixer = _session.micMixer;
    }
    return new MediaStreamBuilder(options)
      .start()
      .then(stream => {
        const [screenTrack] = getScreenAsVideoTracks(stream);
        if (screenTrack) {
          screenTrack.addEventListener('ended', () => {
            _session.emit({ type: 'stop_screen_video' });
          });
        }
        return stream;
      })
      .then(_session.setStream)
      .then(() => _session.send({ type: 'mute_video', on: !(video || screen) }))
      .then(() => {
        if (_session.externalStream) {
          _session.externalStream = null;
          _connection.hasExternalStream = false;
        }
        if (_session.lastResolution) {
          const event = new PodiumEvent(this.context);
          event.adjustResolution(_session, _session.lastResolution);
        }
      })
      .catch(error => {
        Logger.error('StartStreamEvent', error);
        if (screen) {
          if (_session.micMixer) {
            _session.micMixer.destroy();
            _session.micMixer = null;
          }
          let name = `error_Screen_${error.name}`;
          if (error.message.indexOf('not start audio') > -1) {
            name = `error_Screen_${error.name}_Audio`;
          }
          _session.emit({ type: 'screen_capture_error', name });
        }
      });
  }
}

export default StartStreamEvent;
