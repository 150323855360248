import Logger from './../Logger.js';
import BaseEvent from './BaseEvent.js';

/**
 * We handle participants list update is separated in two actions: one to
 * add, one to remove. In order to keep it easy to handle, the user data is
 * fetched from api (or later received via websocket). Not to mess with
 * comApi current handling, we keep it here for a while.
 **/
class ParticipantListUpdateEvent extends BaseEvent {
  constructor(context) {
    super(context);
    this.getUser = this.getUser.bind(this);
  }

  getUser(id) {
    return new Promise((resolve, reject) => {
      if (!this.context._api) {
        reject(new Error('missing ComAPI'));
        return;
      }
      try {
        this.context._api.getUser(id, resolve);
      } catch (error) {
        reject(error);
      }
    });
  }

  handle(msg) {
    (msg.add || []).forEach((user, idx, add) => {
      const id = user.cid;
      const { clientId } = this.context._connection;

      const initial = Boolean(add.find(addition => addition.cid === clientId));

      this.getUser(id)
        .then(apiUser => {
          this.context.send({
            type: 'add_user',
            user: apiUser,
            initial: initial
          });
        })
        .catch(error => Logger.error(error));
    });
    (msg.del || []).forEach(user => {
      const id = user;

      this.getUser(id)
        .then(apiUser => {
          this.context.send({ type: 'remove_user', userId: id, user: apiUser });
        })
        .catch(error => Logger.error(error));
    });
  }
}

export default ParticipantListUpdateEvent;
