import React, { useState, useEffect } from 'react';
import Lottie from '../Lottie.js';
import classNames from 'classnames';
// import animation from '../../assets/animations/alpaca.json'; // wait for it
import animation from '../../assets/animations/planet-animation.json';

export default React.memo(({ initialDelay = true }) => {
  const [data, setData] = useState(null);

  useEffect(() => setData(animation), []);

  const animationProps = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      className: classNames('animation', { delayed: initialDelay }),
    },
  };

  if (!data) {
    return null;
  }

  return (
    <span style={{ maxWidth: '480px' }}>
      <Lottie
        width="100%"
        height="100%"
        isStopped={false}
        ariaRole="presentation"
        ariaLabel="loading animation"
        tabIndex={null}
        options={animationProps}
      />
    </span>
  );
});
