import I18n from 'i18n-js';
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../generic/Button.js';
import Link from '@material-ui/core/Link';

const ErrorDialog = ({
  href = 'https://help.eyeson.com/',
  title = I18n.t('dialog:title:error'),
  description = I18n.t('dialog:error:default:desc'),
  primaryLabel = I18n.t('dialog:error:default:primary_label'),
  secondaryLabel = I18n.t('dialog:error:default:secondary_label'),
  onSecondaryClick,
}) => {
  const setFocus = (element) => {
    const dialogElement = element.firstChild;
    dialogElement && dialogElement.focus();
  };

  const paperProps = {
    tabIndex: 0,
    role: 'alertdialog',
    'aria-modal': 'true',
    'aria-labelledby': 'error-dialog-title',
    'aria-describedby': 'error-dialog-desc',
  };

  return (
    <Dialog
      PaperProps={paperProps}
      TransitionProps={{ onEnter: setFocus }}
      open
    >
      <DialogTitle id="error-dialog-title">{title}</DialogTitle>
      <DialogContent className="eyeson-error-dialog">
        <DialogContentText id="error-dialog-desc" tabIndex={0}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Link
          color="secondary"
          variant="button"
          rel="noopener noreferrer"
          href={href}
          target="_blank"
        >
          {primaryLabel}
        </Link>
        <Button onClick={onSecondaryClick} type="secondary">
          {secondaryLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
