import React from 'react';
import Avatar from '../generic/Avatar.js';
import SoundWave from './SoundWave.js';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 5, 0, 5),
    position: 'relative',
    minWidth: '8rem',
    minHeight: '8rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    '& .MuiAvatar-root': {
      height: '128px',
      width: '128px',
    },
  },
  sound: {
    position: 'absolute',
    right: '0',
    top: '0',
    '& path': {
      fill:
        theme.palette.type === 'light'
          ? theme.palette.grey[600]
          : theme.palette.primary.main,
    },
  },
}));

const RemoteAudioStream = ({
  user,
  sinkId,
  stream,
  onEvent,
  playback,
  voiceActivity,
}) => {
  const classes = useStyles();
  if (!user) {
    return null;
  }
  const isTalking = voiceActivity.on && user.apiId === voiceActivity.user.apiId;

  return (
    <Card>
      <CardContent>
        <div className={classes.root}>
          <div className={classes.avatar}>
            <Avatar
              url={user.largeAvatar}
              onEvent={onEvent}
              playback={playback}
            />
            <SoundWave active={isTalking} className={classes.sound} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default RemoteAudioStream;
