import update from 'immutability-helper';
import I18n from 'i18n-js';
import { Logger, immediate } from 'eyeson';

import BaseAction from './BaseActions.js';
import emojify from '../emojify.js';

import ControlMessage from './../../components/expansion_panels/chat/messages/ControlMessage.js';
import QuestionMessage from './../../components/expansion_panels/chat/messages/QuestionMessage.js';
import NotifyMessage from './../../components/expansion_panels/chat/messages/NotifyMessage.js';
import StatusMessage from './../../components/expansion_panels/chat/messages/StatusMessage.js';
import PlainMessage from './../../components/expansion_panels/chat/messages/PlainMessage.js';

import newMessageSound from '../../assets/audio/bing.mp3';
import instanceHelper from '../../utils/eyesonInstanceHelper.js';

/**
 * Chat message to receive.
 **/
class ReceiveChatMessage extends BaseAction {
  notify(props, state) {
    if (NotifyMessage.test(this.message)) {
      props.enqueueSnackbar(emojify(this.content), {
        autoHideDuration: 5 * 1000,
      });
    } else if (StatusMessage.test(this.message)) {
      props.enqueueSnackbar(
        emojify(
          StatusMessage.parseContent(this.message).replace(
            /\*\*(.+?)\*\*/g,
            '$1'
          )
        ),
        { autoHideDuration: 5 * 1000 }
      );
    } else if (QuestionMessage.test(this.message)) {
      props.enqueueSnackbar(emojify(this.content), {
        autoHideDuration: 5 * 1000,
      });
    } else if (
      state.visible.panel !== 'chat' &&
      PlainMessage.test(this.message)
    ) {
      this.ariaNewMessageAlert();
    }
  }

  get content() {
    return this.message.content.replace(/^\/(notify|ask|status) /, '');
  }

  audioNotifications(state) {
    const waitedLongEnough = Date.now() - state.notifications.lastPlay > 10000;

    if (waitedLongEnough) {
      return {
        audio: {
          $push: [{ name: 'new-chat-message', url: newMessageSound }],
        },
        lastPlay: { $set: Date.now() },
      };
    }
    return {};
  }

  ariaNewMessageAlert() {
    const div = document.createElement('div');
    div.setAttribute('role', 'alert');
    div.className = 'a11y-chat-info';
    div.textContent = I18n.t('aria:alert:new_chat_message');
    document.body.insertAdjacentElement('beforeend', div);
    immediate(() => div.remove());
  }

  process(state, props) {
    if (ControlMessage.test(this.message)) {
      const controlMessage = new ControlMessage();
      return controlMessage.process(this.message, state, props);
    }
    this.notify(props, state);

    return {
      notifications: update(
        state.notifications,
        this.audioNotifications(state)
      ),
      messages: update(state.messages, {
        $push: [
          {
            user: this.message.user,
            timestamp: this.message.timestamp,
            content: this.message.content,
          },
        ],
      }),
    };
  }
}

class ChatExportAction extends BaseAction {
  process(state, props) {
    const messages = state.messages.filter(
      ({ content }) =>
        /^\/(status|will_terminate|kick|notify) /.test(content) === false
    );
    try {
      const header = `${new Date().toLocaleDateString(I18n.locale, {
        dateStyle: 'full',
      })} - ${props.eyeson.room.name}\n\n`;
      const entries = messages.map(({ content, timestamp, user: { name } }) => {
        const time = timestamp.toTimeString().split(' ')[0];
        return `[${time}] ${name}:\n  ${content}\n\n`;
      });
      const blob = new Blob([header, entries.join('')], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = `${I18n.t('chat:file_name', {
        defaultValue: 'meeting_saved_chat',
      })}.txt`;
      a.href = url;
      a.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 10);
    } catch (error) {
      Logger.warn('ChatExportAction', error);
      props.enqueueSnackbar(I18n.t('chat:save_error'), {
        variant: 'warning',
        autoHideDuration: 5 * 1000,
      });
    }
  }
}

class HandleCustomMessage extends BaseAction {
  process() {
    try {
      if (this.message.content) {
        const data = JSON.parse(this.message.content);
        if (data.type === 'screenshareUserPreventLayout') {
          instanceHelper.setOptions({ screenshareUserPreventLayout: data.on });
        }
      }
    } catch (error) {
      Logger.warn('HandleCustomMessage', error);
    }
  }
}

export { ReceiveChatMessage, ChatExportAction, HandleCustomMessage };
