import React, { Component } from 'react';
import { FeatureDetector } from 'eyeson';

class AudioStream extends Component {
  constructor(props) {
    super(props);
    this.element = null;
    this.handleRef = this.handleRef.bind(this);
  }

  componentDidMount() {
    if (this.props.stream) {
      if (this.props.persistent) {
        const clone = new MediaStream(this.props.stream.getAudioTracks());
        this.element.srcObject = clone;
      } else {
        this.element.srcObject = this.props.stream;
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.persistent) {
      const wasUnused = !this.element.srcObject;
      const hasStream = !!nextProps.stream;
      const sessionRestart = nextProps.sessionCount !== this.props.sessionCount;
      return hasStream && (wasUnused || sessionRestart);
    }
    return true;
  }

  componentDidUpdate() {
    this.element.muted = this.props.muted;
    if (this.props.sinkId && this.props.sinkId !== this.element.sinkId) {
      this.updateSinkId(this.props.sinkId);
    }
    // especially used for safari to ensure streaming audio and prevent dom updates
    if (this.props.persistent && this.props.stream) {
      const clone = new MediaStream(this.props.stream.getAudioTracks());
      this.element.srcObject = clone;
    }
  }

  handleRef(node) {
    if (node) {
      this.element = node;
    }
  }

  updateSinkId = (sinkId) => {
    if (!FeatureDetector.canChangeAudioOutput()) {
      return;
    }

    window.requestAnimationFrame(() => {
      const newSinkId = !sinkId || sinkId === 'default' ? '' : sinkId;
      if (this.element.sinkId !== newSinkId) {
        this.element.setSinkId(newSinkId);
      }
    });
  };

  render() {
    return (
      <audio ref={this.handleRef} autoPlay={true} muted={this.props.muted} />
    );
  }
}

export default AudioStream;
