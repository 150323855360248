import React, { useState, useEffect } from 'react';
import MainView from './MainView';
import PropTypes from 'prop-types';
import LocalStream from './streams/LocalStream.js';
import DialogWindowSelector from './DialogWindowSelector.js';
import TopToolbar from './mobile/TopToolbar.js';
import BottomToolbar from './mobile/BottomToolbar.js';
import AudioStream from './streams/AudioStream.js';
import { FeatureDetector } from 'eyeson';
import Chat from './expansion_panels/chat/Chat.js';

const MobileRoom = (props) => {
  const {
    mediaOptions: { eco },
    user,
    solo,
    isPip,
    eyeson,
    config,
    active,
    options,
    visible,
    messages,
    isLocked,
    isVisible,
    handleEvent,
    environment,
    localStream,
    participants,
    onInactivity,
    showBranding,
    voiceActivity,
    virtualBackground,
    connectionStatistics,
  } = props;

  const usePersistentAudio = FeatureDetector.disallowAudioSyncSrcChange();

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    let resizeTimer = null;
    let orientationTimer = null;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => setHeight(window.innerHeight), 50);
    };
    const handleOrientationChange = () => {
      clearTimeout(orientationTimer);
      orientationTimer = setTimeout(() => setHeight(window.innerHeight), 300);
    };
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);
    return () => {
      clearTimeout(resizeTimer);
      clearTimeout(orientationTimer);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <section className="eyeson-mobile-room" style={{ height: height }}>
      <TopToolbar
        me={user}
        isPip={isPip}
        canPip={environment.canPip}
        active={active}
        visible={visible}
        isGuest={eyeson.user.guest}
        isLocked={isLocked}
        onEvent={handleEvent}
        quickJoin={!!eyeson.room.guest_token}
        participants={participants}
        pipDisabled={
          props.forwardedVideoMuted || (solo && !options.layout_users) || eco
        }
        kickAvailable={options && options.kick_available}
        hideGuestLogin={props.hideGuestLogin}
        connectionStatistics={connectionStatistics}
      />
      <main className="content">
        {visible.panel === 'chat' && (
          <div className="eyeson-chat">
            <Chat
              me={user}
              onEvent={handleEvent}
              messages={messages}
              isMobile={true}
            ></Chat>
          </div>
        )}
        <MainView
          eco={eco}
          user={user}
          onEvent={handleEvent}
          isVisible={isVisible}
          environment={environment}
          showBranding={showBranding}
          usePersistentAudio={usePersistentAudio}
          {...props}
        />
        <DialogWindowSelector
          active={props.visible.dialog}
          eyeson={eyeson}
          config={config}
          context={{
            eco: eco,
            onEvent: handleEvent,
            environment: environment,
            feedbackUrl: props.feedbackUrl,
            onInactivity: onInactivity,
            ...props,
          }}
        />
        <section className="bottom">
          <span className="self-view-frame">
            <div className="self-view">
              <LocalStream
                user={user}
                stream={localStream}
                active={active}
                visible={!solo && active.sfu && !active.hasPresenter}
                onEvent={handleEvent}
                playback={props.playback}
                voiceActivity={voiceActivity}
                virtualBackground={virtualBackground}
              />
            </div>
          </span>
        </section>
        {usePersistentAudio && (
          <AudioStream
            stream={props.stream}
            sinkId={props.sinkId}
            persistent={true}
            muted={false}
            sessionCount={props.sessionCount}
          />
        )}
      </main>
      <BottomToolbar
        eco={eco}
        eyeson={eyeson}
        active={active}
        sources={props.sources}
        visible={visible}
        messages={messages}
        onEvent={handleEvent}
        canReact={props.options && props.options.reaction_available}
        reactionPickerVisible={!eco && props.visible.reaction_picker}
        hasPresenter={props.hasPresenter}
      />
    </section>
  );
};

MobileRoom.propTypes = {
  solo: PropTypes.bool,
  active: PropTypes.object.isRequired,
  connecting: PropTypes.bool.isRequired,
  handleEvent: PropTypes.func.isRequired,
  environment: PropTypes.object.isRequired,
};

MobileRoom.defaultProps = {
  solo: true,
  options: {},
};

export default MobileRoom;
