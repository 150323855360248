import I18n from 'i18n-js';
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import EmojiButton from './EmojiButton.js';

const ReactionPicker = forwardRef(
  ({ reactions, onClick, id, setReactionList, onKeyDown }, pickerRef) => (
    <span
      className="reaction_picker frame"
      id={id}
      ref={pickerRef}
      role="menu"
      aria-label={I18n.t('label:reactions:picker', {
        defaultValue: 'GIF reactions picker',
      })}
      onKeyDown={onKeyDown}
    >
      <span className="content">
        {reactions.map((reaction, index) => (
          <EmojiButton
            key={reaction.query}
            ref={(element) => setReactionList(index, element)}
            emoji={reaction.emoji}
            onClick={() => onClick(reaction)}
            onTouch={() => onClick(reaction)}
            ariaRole="menuitem"
            ariaLabel={I18n.t(`label:reaction:${reaction.label}`, {
              defaultValue: reaction.label,
            })}
            className="reaction-button"
            tabIndex="-1"
          />
        ))}
      </span>
    </span>
  )
);

ReactionPicker.propTypes = {
  reactions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  setReactionList: PropTypes.func,
  onKeyDown: PropTypes.func,
};

ReactionPicker.defaultProps = {
  setReactionList: () => {},
  onKeyDown: () => {},
};

export default ReactionPicker;
