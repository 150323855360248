import BaseEvent from './BaseEvent.js';

/**
 * Deletes a file using the com api.
 **/
class DeleteFileEvent extends BaseEvent {
  handle(msg) {
    this.context._api.deleteFile(msg.file);
  }
}

export default DeleteFileEvent;
