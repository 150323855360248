import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { makeStyles, withStyles } from '@material-ui/styles';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '../../generic/Avatar.js';
import Box from '@material-ui/core/Box';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles({
  context: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignSelf: 'center',
    alignItems: 'center',
    color: '#c6c6c6',
  },
  icon: { color: '#606060' },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    minHeight: 'auto',
    lineHeight: 'unset',
  },
}))(MenuItem);

const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: '5px',
    color: '#606060',
  },
}))(IconButton);

const ParticipantEntry = ({
  user,
  me,
  podium,
  voiceActivity,
  isGuest,
  isSFU,
  onEvent,
  options,
}) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const classes = useStyles();

  const isMe = me.clientId === user.id;

  const onPodium = (id) => podium.indexOf(id) !== -1;
  const audioActive = (apiId) =>
    isSFU && voiceActivity.on && voiceActivity.user.apiId === apiId;
  const joinedLabel = (dateStr) => {
    if (!dateStr || dateStr.length === 0) {
      return '';
    }
    const date = new Date(dateStr);
    return I18n.t('label:joined') + ' ' + date.toLocaleTimeString();
  };

  const handleClick = (event) => {
    let { clientY: top, clientX: left } = event;
    // react emulates mouse event from keyboard enter or space
    if (top === 0 && left === 0) {
      const rect = event.target.getBoundingClientRect();
      top = rect.top + rect.height;
      left = rect.left + rect.width;
    }
    setPosition({ top, left });
  };
  const handleClose = () => setPosition({ top: 0, left: 0 });

  const handleAction = (type) => {
    if (type === 'kick_user_prompt') {
      if (user.id !== me.clientId) {
        onEvent({ type, user });
      }
    }
    handleClose();
  };

  let menuOpen = position.top !== 0 && position.left !== 0;

  return (
    <Fragment>
      <ListItem alignItems="flex-start" className="participantslist__item">
        <ListItemAvatar>
          <Avatar
            alt={I18n.t('aria:label:avatar', {
              username: user.name,
              defaultValue: 'Avatar of {{username}}',
            })}
            url={user.avatar}
            label={user.name}
          />
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={joinedLabel(user.joined_at)}
        />
        <Box className={classes.context}>
          <Box mr={1} ml={1}>
            <Icon className={classes.icon}>
              {onPodium(user.id) ? 'videocam' : 'videocam_off'}
            </Icon>
          </Box>
          <Icon className={classes.icon}>
            {audioActive(user.apiId) ? 'phone_in_talk' : 'microphone'}
          </Icon>
          {options.kick_available && !isGuest && !isMe && (
            <Fragment>
              <StyledIconButton
                edge="end"
                aria-label={I18n.t('aria:label:actions_menu', {
                  defaultValue: 'Actions menu',
                })}
                aria-haspopup="menu"
                aria-pressed={menuOpen}
                onClick={handleClick}
              >
                <Icon>more_vert</Icon>
              </StyledIconButton>
              <Menu
                keepMounted
                open={menuOpen}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={position}
              >
                <StyledMenuItem
                  onClick={() => handleAction('kick_user_prompt')}
                >
                  <ListItemText primary={I18n.t('label:kick_user:remove')} />
                </StyledMenuItem>
              </Menu>
            </Fragment>
          )}
        </Box>
      </ListItem>
      <Divider variant="inset" component="li" />
    </Fragment>
  );
};

ParticipantEntry.propTypes = {
  voiceActivity: PropTypes.object,
};

export default ParticipantEntry;
