import React, { useState, Fragment } from 'react';
import I18n from 'i18n-js';
import Arrow from '@material-ui/icons/ArrowUpward';
import Tooltip from '@material-ui/core/Tooltip';
import VideoCall from '@material-ui/icons/VideoCall';
import VideocamOff from '@material-ui/icons/VideocamOff';
import IconButton from '../../generic/IconButton.js';

const positions = [
  {
    horizontal: 'right',
    vertical: 'bottom',
    icon: <Arrow />,
  },
  {
    horizontal: 'right',
    vertical: 'top',
    icon: <Arrow style={{ transform: 'rotate(-90deg)' }} />,
  },
  {
    horizontal: 'left',
    vertical: 'top',
    icon: <Arrow style={{ transform: 'scaleY(-1)' }} />,
  },
  {
    horizontal: 'left',
    vertical: 'bottom',
    icon: <Arrow style={{ transform: 'rotate(90deg)' }} />,
  },
];

const sizes = {
  small: <VideoCall style={{ height: '1.5rem' }} />,
  medium: <VideoCall style={{ height: '1.5rem' }} />,
  large: <VideocamOff style={{ height: '1.5rem' }} />,
  none: <VideoCall style={{ height: '1.5rem' }} />,
};

const CameraOverlayActions = ({ onEvent, disabled }) => {
  const [size, setSize] = useState('medium');
  const [position, setPosition] = useState(positions[0]);

  // small -> medium -> large -> none -> small ...
  const handleSizeChange = (sizes) => {
    const keys = Object.keys(sizes);
    const idx = keys.findIndex((el) => el === size);
    const newSize = keys[(idx + 1) % keys.length];
    setSize(newSize);
    onEvent({ type: 'change_camera_overlay', size: newSize });
  };

  const handlePositionChange = () => {
    if (size === 'none') {
      return;
    }
    const idx = positions.findIndex(
      (el) =>
        el.horizontal === position.horizontal &&
        el.vertical === position.vertical
    );
    const newPosition = positions[(idx + 1) % positions.length];
    setPosition(newPosition);
    onEvent({ type: 'change_camera_overlay', position: newPosition });
  };

  const sizeButtonTooltip = () => {
    if (size === 'large') {
      return I18n.t('tooltip:camera-off', { defaultValue: 'Turn camera off' });
    }
    if (size === 'none') {
      return I18n.t('tooltip:camera-on', { defaultValue: 'Turn camera on' });
    }
    return I18n.t('tooltip:camera-size', {
      defaultValue: 'Change camera size',
    });
  };

  return (
    <Fragment>
      <Tooltip title={sizeButtonTooltip()} placement="top">
        <IconButton
          onClick={() => handleSizeChange(sizes)}
          aria-label={sizeButtonTooltip()}
          disabled={disabled}
        >
          {sizes[size]}
        </IconButton>
      </Tooltip>
      <Tooltip title={I18n.t('tooltip:move-camera')} placement="top">
        <IconButton
          onClick={handlePositionChange}
          aria-label={I18n.t('tooltip:move-camera', {
            defaultValue: 'Move camera',
          })}
          disabled={disabled || size === 'none'}
        >
          {position.icon}
        </IconButton>
      </Tooltip>
    </Fragment>
  );
};

export default CameraOverlayActions;
