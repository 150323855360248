import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import I18n from 'i18n-js';
import { FeatureDetector, Logger, immediate } from 'eyeson';
import supports from '../../utils/SupportHelper.js';

const canShare = FeatureDetector.canShare();

const CopyInput = ({ id, value, silent, onCopy, shareMessage }) => {
  const [message, setMessage] = useState('');
  const inputEl = useRef(null);

  const handleShareClick = (event) => {
    clearMessage();
    event.stopPropagation();
    navigator
      .share({ url: value, text: shareMessage })
      .then(() => {
        onCopy();
        Logger.debug('CopyInput::handleShare: success!');
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          handleSelect();
          setMessage(I18n.t('label:copy_shortcut'));
          Logger.error('CopyInput::handleShare', error);
        }
      });
  };

  const handleClickEvent = () => {
    clearMessage();
    handleSelect();
  };

  const handleCopyEvent = () => {
    if (message === '') {
      immediate(() => setMessage(I18n.t('message:clipboard_success')));
    }
  };

  const handleCopyClick = (event) => {
    clearMessage();
    event.stopPropagation();
    const button = event.target;
    if (FeatureDetector.supportsBlurOnSVG()) {
      button.blur();
    }

    try {
      if (supports.ClipboardAPI) {
        navigator.clipboard
          .writeText(value)
          .then(() => {
            setMessage(I18n.t('message:clipboard_success'));
            button.focus();
            onCopy();
          })
          .catch(() => {
            handleSelect();
            setMessage(I18n.t('label:copy_shortcut'));
            onCopy();
          });
        return;
      }
      handleSelect();
      document.execCommand('copy');
      immediate(() => {
        setMessage(I18n.t('message:clipboard_success'));
        button.focus();
      });
    } catch (err) {
      immediate(() => setMessage(I18n.t('label:copy_shortcut')));
    }
    onCopy();
  };

  const handleSelect = () => {
    const input = inputEl.current;
    if (input) {
      input.focus();
      input.select();
      input.setSelectionRange(0, input.value.length);
    }
  };

  const clearMessage = () => {
    setMessage('');
  };

  return (
    <TextField
      fullWidth
      id={id}
      label={I18n.t('label:copy_link')}
      defaultValue={value}
      inputProps={{ ref: inputEl }}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={handleCopyClick}
              aria-labelledby={id + '-label'}
            >
              <Icon>file_copy</Icon>
            </IconButton>
            {canShare && (
              <IconButton
                edge="end"
                onClick={handleShareClick}
                aria-label={I18n.t('aria:label:share_link', {
                  defaultValue: 'Share link',
                })}
              >
                <Icon>share</Icon>
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      variant="filled"
      color="secondary"
      helperText={!silent && message}
      FormHelperTextProps={{ role: !silent && message ? 'status' : null }}
      onCopy={handleCopyEvent}
      onClick={handleClickEvent}
    />
  );
};

CopyInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  silent: PropTypes.bool,
  onCopy: PropTypes.func,
  shareMessage: PropTypes.string,
};

CopyInput.defaultProps = {
  silent: false,
  shareMessage: '',
  onCopy: () => {},
};

export default CopyInput;
