import BaseAction from './BaseActions.js';

/**
 * Lock Meeting
 * is triggered when user clicks on popup button
 **/
class LockMeeting extends BaseAction {
  process(state, props) {
    const { eyeson } = props;
    eyeson.send({ type: 'lock_meeting' });
    eyeson.send({
      type: 'send_chat',
      content: `/status ${JSON.stringify({
        id: 'message:user_locked_meeting',
        user: `**${eyeson.user.name}**`,
      })}`,
    });
    return {
      isLocked: true,
    };
  }
}

/**
 * Lock Meeting Action
 * receive lock event
 **/
class LockMeetingAction extends BaseAction {
  process() {
    return {
      isLocked: this.message.locked,
    };
  }
}

export { LockMeeting, LockMeetingAction };
