import I18n from 'i18n-js';
import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import MuiButton from '@material-ui/core/Button';
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const PresentationActions = ({
  disabled,
  started,
  stopStream,
  startStream,
  inSelfPreview,
}) => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);

  if (started) {
    return (
      <MuiButton
        color="secondary"
        variant="contained"
        onClick={() => stopStream()}
        startIcon={<StopIcon />}
      >
        {I18n.t('label:stop')}
      </MuiButton>
    );
  }

  return (
    <div className={classes.row}>
      <Tooltip
        open={tooltip}
        onOpen={() => setTooltip(true)}
        onClose={() => setTooltip(false)}
        title={
          disabled && !inSelfPreview
            ? I18n.t('not_presenter')
            : I18n.t('label:start')
        }
        placement="top"
      >
        <div>
          <MuiButton
            color="secondary"
            variant="contained"
            onClick={() => {
              startStream();
              setTooltip(false);
            }}
            disabled={disabled}
            startIcon={<PlayIcon />}
            className={classes.button}
          >
            {I18n.t('action:present')}
          </MuiButton>
        </div>
      </Tooltip>
      <MuiButton
        color="secondary"
        onClick={() => stopStream()}
        className={classes.button}
      >
        {I18n.t('label:cancel')}
      </MuiButton>
    </div>
  );
};

export default PresentationActions;
