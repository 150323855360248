import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Container simply groups some children.
 **/
class Container extends Component {
  /**
   * Attach any custom class names to the default one.
   **/
  getClassNames() {
    let classNames = ['eyeson-container'];
    if (typeof this.props.customClassNames === 'string') {
      classNames.push(this.props.customClassNames);
    }
    if (this.props.customClassNames instanceof Array) {
      classNames.concat(this.props.customClassNames);
    }
    return classNames;
  }

  render() {
    if (this.props.visible === false) {
      return null;
    }

    return (
      <section
        className={this.getClassNames().join(' ')}
        role={this.props.role}
      >
        {this.props.children}
      </section>
    );
  }
}

Container.propTypes = {
  visible: PropTypes.bool,
  role: PropTypes.string,
  customClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Container.defaultProps = {
  visible: true,
  role: null,
  customClassNames: [],
};

export default Container;
