import { Logger } from 'eyeson';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import instanceHelper from '../../utils/eyesonInstanceHelper.js';

class RemoteDescriptionUpdate extends BaseAction {
  process(state, props) {
    const { sfu } = this.message.update;

    return {
      forwardedVideoMuted: sfu && state.hasMutedVideoPeers,
      active: update(state.active, {
        sfu: { $set: sfu },
      }),
    };
  }
}

class Podium extends BaseAction {
  getPresenterId() {
    if (!this.message.presenter) {
      return null;
    }

    return this.message.presenter.split('@').shift();
  }

  process(state, _props) {
    Logger.debug('Podium::process', this.message);
    const presenterId = this.getPresenterId();

    let sortOrder = state.sortOrder || [];

    const videoSources = this.buildVideoSources(
      this.message.sources,
      this.message.videoSources
    );

    if (this.message.hasVideoSources) {
      sortOrder = this.buildSortOrder(state.participants, videoSources);
    }

    const showLocalStream = this.message.solo && !state.options.layout_users;

    if (this.message.hasPresenter) {
      instanceHelper.clearStoredLayout();
      instanceHelper.stop();
    }
    let screenAsVideo = state.active.screenAsVideo;
    if (
      screenAsVideo &&
      this.message.hasPresenter &&
      !this.message.isPresenter
    ) {
      screenAsVideo = false;
    }

    return {
      solo: this.message.solo,
      sources: this.message.sources,
      sortOrder: sortOrder,
      hasMutedVideoPeers: this.message.hasMutedVideoPeers,
      forwardedVideoMuted: state.active.sfu && this.message.hasMutedVideoPeers,
      videoSources: videoSources,
      showLocalStream: showLocalStream,
      active: update(state.active, {
        presenter: { $set: presenterId },
        isPresenter: { $set: this.message.isPresenter },
        hasPresenter: { $set: this.message.hasPresenter },
        hasAudioPosition: { $set: this.message.audio },
        screenAsVideo: { $set: screenAsVideo },
      }),
      visible: update(state.visible, {
        right_sidebar: {
          $set:
            (this.message.hasPresenter && this.message.isPresenter) ||
            state.canvasPresentation.files.length > 0 ||
            state.mediaPresentation.file !== null,
        },
      }),
    };
  }

  buildVideoSources(sources, msgVideoSources) {
    return msgVideoSources
      .map((videoSourceIndex) => {
        return videoSourceIndex >= 0
          ? sources[videoSourceIndex].split('@').shift()
          : null;
      })
      .filter((source) => source);
  }

  // Build source order, starting with videoSources and followed by the
  // remaining participants.
  buildSortOrder(participants, videoSources) {
    let remainingParticipantIds = participants
      .filter((participant) => !videoSources.includes(participant.id))
      .map((participant) => participant.id);

    return videoSources.concat(remainingParticipantIds.sort());
  }
}

export { Podium, RemoteDescriptionUpdate };
