import I18n from 'i18n-js';
import React, { Fragment } from 'react';
import Marker from './Marker.js';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '../../generic/IconButton.js';
import Rotate from '@material-ui/icons/RotateRight';
import DeleteForever from '@material-ui/icons/DeleteForever';
import UndoButton from '@material-ui/icons/Undo';
import RedoButton from '@material-ui/icons/Redo';

const AnnotationTools = ({
  available,
  markerColor,
  markerActive,
  markerDisabled,
  eraserDisabled,
  rotationAvailable,
  onColorClick,
  onMarkerClick,
  onEraserClick,
  onRotationClick,
  onUndoClick,
  onRedoClick,
  undoActive,
  redoActive,
  rotationActive,
}) => {
  if (!available) {
    return null;
  }

  return (
    <Fragment>
      <Tooltip title={I18n.t('tooltip:eraser')} placement="top">
        <span>
          <IconButton
            onClick={onEraserClick}
            disabled={eraserDisabled}
            aria-label={I18n.t('aria:label:clear_annotations', {
              defaultValue: 'Clear annotations',
            })}
          >
            <DeleteForever />
          </IconButton>
        </span>
      </Tooltip>
      <Marker
        color={markerColor}
        onClick={onMarkerClick}
        isActive={markerActive}
        onColorClick={onColorClick}
        disabled={markerDisabled}
      />
      {rotationAvailable && (
        <Tooltip title={I18n.t('tooltip:rotate')} placement="top">
          <span>
            <IconButton
              onClick={onRotationClick}
              disabled={rotationActive}
              aria-label={I18n.t('tooltip:rotate', { defaultValue: 'Rotate' })}
            >
              <Rotate />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <Tooltip title={I18n.t('tooltip:undo')} placement="top">
        <span>
          <IconButton
            onClick={onUndoClick}
            disabled={!undoActive}
            aria-label={I18n.t('tooltip:undo', { defaultValue: 'Undo' })}
          >
            <UndoButton />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={I18n.t('tooltip:redo')} placement="top">
        <span>
          <IconButton
            onClick={onRedoClick}
            disabled={!redoActive}
            aria-label={I18n.t('tooltip:redo', { defaultValue: 'Redo' })}
          >
            <RedoButton />
          </IconButton>
        </span>
      </Tooltip>
    </Fragment>
  );
};

export default AnnotationTools;
