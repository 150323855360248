const EventTargetConstructorSupport = (() => {
  try {
    // eslint-disable-next-line no-new
    new EventTarget();
    return true;
    // eslint-disable-next-line no-empty, no-unused-vars
  } catch (error) {}
  return false;
})();

class EventEmitter {
  constructor() {
    this.listeners = new Map();
    this.target = EventTargetConstructorSupport
      ? new EventTarget()
      : document.createElement('i');
  }

  onEvent(cb) {
    const fn = ({ detail }) => cb(detail);
    this.target.addEventListener('event', fn);
    this.listeners.set(cb, fn);
  }

  offEvent(cb) {
    const fn = this.listeners.get(cb);
    if (fn) {
      this.target.removeEventListener('event', fn);
      this.listeners.delete(cb);
    }
  }

  removeAllListeners() {
    const { listeners, target } = this;
    listeners.forEach(fn => target.removeEventListener('event', fn));
    listeners.clear();
  }

  emit(data) {
    const event = new CustomEvent('event', { detail: data });
    this.target.dispatchEvent(event);
  }
}

export default EventEmitter;
