import I18n from 'i18n-js';
import ErrorPage from './ErrorPage.js';
import CopyInput from './../layout/CopyInput.js';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DownloadLink from './../generic/DownloadLink.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spaced: {
    display: 'flex',
    width: '100%',
    'justify-content': 'space-evenly',
  },
  extraDense: {
    padding: 0,
    margin: 0,
  },
}));

const BrowserList = ({ isIOSDevice }) => {
  const classes = useStyles();

  let browsers = [I18n.t('browsers:safari')];
  if (!isIOSDevice) {
    browsers = [
      I18n.t('browsers:chrome'),
      I18n.t('browsers:firefox'),
      I18n.t('browsers:safari'),
      I18n.t('browsers:edge'),
    ];
  }

  return (
    <List className={classes.extraDense} dense>
      {browsers.map((b) => (
        <ListItem key={b}>
          <ListItemText primary={b} />{' '}
        </ListItem>
      ))}
    </List>
  );
};

const BrowserSupportPage = ({ isIOSDevice }) => {
  const classes = useStyles();

  return (
    <ErrorPage
      status="unsupported"
      message={I18n.t('message:unsupported_browser')}
    >
      <BrowserList isIOSDevice={isIOSDevice} />
      <CopyInput id="guest-link" value={window.location.href} />
      {!isIOSDevice && (
        <section className={classes.spaced}>
          <DownloadLink
            href="https://www.google.com/chrome/"
            label={I18n.t('btn:download:chrome')}
          />
          <DownloadLink
            href="https://www.mozilla.org/firefox/new/"
            label={I18n.t('btn:download:firefox')}
          />
        </section>
      )}
    </ErrorPage>
  );
};

BrowserSupportPage.defaultProps = {};

export default BrowserSupportPage;
