import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import RecordingFacade from '../../utils/RecordingFacade.js';

/**
 * Start Recording.
 **/
class StartRecording extends BaseAction {
  process(state, props) {
    props.eyeson.send({ type: 'start_recording' });
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
    };
  }
}

/**
 * Stop Recording.
 **/
class StopRecording extends BaseAction {
  process(_state, props) {
    props.eyeson.send({ type: 'stop_recording' });
  }
}

/**
 * Recording Update.
 **/
class RecordingUpdate extends BaseAction {
  visibleDialog(props, recording) {
    if (recording.stopped || props.eyeson.user.id === recording.user.id) {
      return '';
    }

    return 'recording_started';
  }

  process(state, props) {
    if (state.customOptions.hideRecording) {
      return;
    }
    const recording = new RecordingFacade(this.message.recording);
    // recording ended event after new recording started
    if (state.recording.id !== null && state.recording.id !== recording.id) {
      return;
    }
    if (recording.ended) {
      props.enqueueSnackbar(I18n.t('message:recording_stopped'));
    }
    return {
      recording: update(state.recording, {
        id: { $set: recording.active ? recording.id : null },
        user: { $set: recording.active ? recording.user.name : null },
        createdAt: { $set: recording.active ? recording.createdAt : null },
      }),
      active: update(state.active, {
        recording: { $set: recording.active },
      }),
      visible: update(state.visible, {
        dialog: { $set: this.visibleDialog(props, recording) },
      }),
    };
  }
}

class RecordingError extends BaseAction {
  process(_state, props) {
    props.enqueueSnackbar(I18n.t('message:recording_error'), {
      variant: 'error',
    });
  }
}

export { StartRecording, StopRecording, RecordingUpdate, RecordingError };
