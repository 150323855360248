import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { SoundMeter } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '0.6rem',
    margin: '0.3rem 0',
    overflow: 'hidden',
    borderRadius: '0.2rem',
    'box-sizing': 'border-box',
  },
  progress: {
    width: '0%',
    height: '100%',
    transition: 'width 100ms ease-out',
    background: theme.palette.secondary.main,
    borderRadius: '0.2rem',
  },
}));

const areEqual = (prevProps, nextProps) =>
  prevProps.stream &&
  nextProps.stream &&
  prevProps.stream.id === nextProps.stream.id;

const AudioLevel = memo((props) => {
  const { stream, onError, onWarning, inactive } = props;
  const [level, setLevel] = useState(0);
  const classes = useStyles();
  useEffect(() => {
    if (inactive) {
      setLevel(0);
      return;
    }
    const soundMeter = new SoundMeter();
    stream.oninactive = () => soundMeter.offUpdate();
    soundMeter.onUpdate((event) => {
      if (event.warning) {
        onWarning({ name: event.warning });
        return;
      }
      if (event.error) {
        onError({ name: event.error });
        return;
      }
      setLevel(event.value);
    });
    soundMeter.connectToSource(stream);
    return () => soundMeter.stop();
  }, [stream, onError, onWarning, inactive]);

  if (!stream) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div
        // eslint-disable-next-line jsx-a11y/aria-role
        role="meter"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={parseInt(level, 10)}
        aria-label={I18n.t('aria:label:audio_level_meter', {
          defaultValue: 'Audio level meter',
        })}
        style={{ width: parseInt(level, 10) + '%' }}
        className={classes.progress}
      />
    </div>
  );
}, areEqual);

AudioLevel.propTypes = {
  stream: PropTypes.object.isRequired,
  onError: PropTypes.func.isRequired,
  onWarning: PropTypes.func.isRequired,
};

export default AudioLevel;
