const noFormInput = (target) => {
  return ['TEXTAREA', 'INPUT'].indexOf(target.tagName) === -1;
};

const noActiveButton = (target) => {
  return !target.closest('button');
};

const noDialog = (target) => {
  return !target.classList.value.includes('gif');
};

const keyDownActions = [
  {
    test: (event) => event.key === 'C' && noFormInput(event.target),
    event: { type: 'toggle_chat' },
  },
  {
    test: (event) => event.ctrlKey && event.key === ',',
    event: { type: 'toggle_video' },
  },
  {
    test: (event) => event.ctrlKey && event.key === '.',
    event: { type: 'toggle_audio' },
  },
  {
    test: (event) =>
      event.key === ' ' &&
      noFormInput(event.target) &&
      noActiveButton(event.target),
    event: { type: 'next_slide' },
  },
  {
    test: (event) => event.key === 'ArrowRight' && noFormInput(event.target),
    event: { type: 'next_slide' },
  },
  {
    test: (event) => event.key === 'ArrowLeft' && noFormInput(event.target),
    event: { type: 'prev_slide' },
  },
  {
    test: (event) => event.key === '?' && noFormInput(event.target),
    event: { type: 'toggle_help' },
  },
  {
    test: (event) => event.key === 'S' && noFormInput(event.target),
    event: { type: 'activate_screen_capture' },
  },
  {
    test: (event) =>
      event.key === 'L' &&
      noFormInput(event.target) &&
      !event.ctrlKey &&
      !event.metaKey,
    event: { type: 'live_stream' },
  },
  {
    test: (event) =>
      event.key === 'F' && noFormInput(event.target) && noDialog(event.target),
    event: { type: 'show_drop_zone' },
  },
  {
    test: (event) => event.key === 'G' && noFormInput(event.target),
    event: { type: 'toggle_giphy' },
  },
  {
    test: (event) =>
      event.key === 'T' && noFormInput(event.target) && !event.ctrlKey,
    event: { type: 'toggle_theme' },
  },
  {
    test: (event) =>
      event.key === 'B' && noFormInput(event.target) && !event.ctrlKey,
    event: { type: 'toggle_theme', details: { color: 'brown' } },
  },
  {
    test: (event) => event.key === 'P' && noFormInput(event.target),
    event: { type: 'toggle_pip' },
  },
  {
    test: (event) => event.key === 'Escape' || event.key === 'Esc',
    event: [{ type: 'hide_dialog' }, { type: 'hide_reaction_picker' }],
  },
  {
    test: (event) => event.key === 'm' && noFormInput(event.target),
    event: { type: 'push_to_talk', state: 'on' },
  },
  {
    test: (event) => event.key === 'I' && noFormInput(event.target),
    event: { type: 'guest_link_clipboard' },
  },
  {
    test: (event) => event.key === 'v' && noFormInput(event.target),
    event: { type: 'suspend_virtual_background', state: 'suspend' },
  },
  {
    test: (event) =>
      event.code === 'KeyI' &&
      event.altKey &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      noFormInput(event.target),
    event: { type: 'session_info' },
  },
  {
    test: (event) =>
      event.code === 'KeyC' &&
      event.altKey &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      noFormInput(event.target),
    event: { type: 'chat_export' },
  },
  {
    test: (event) => event.key === 'A' && noFormInput(event.target),
    event: { type: 'snapshot' },
  },
];

const keyUpActions = [
  {
    test: (event) => event.key === 'm' && noFormInput(event.target),
    event: { type: 'push_to_talk', state: 'off' },
  },
  {
    test: (event) => event.key === 'v' && noFormInput(event.target),
    event: { type: 'suspend_virtual_background', state: 'resume' },
  },
];

/**
 * Handle keyboard input.
 **/
class KeyboardControl {
  constructor(handler) {
    this.handler = handler;
    this.keyDownFired = false;
    this.boundOnKeyUp = this.onKeyUp.bind(this);
    this.boundOnKeyDown = this.onKeyDown.bind(this);
    document.addEventListener('keyup', this.boundOnKeyUp);
    document.addEventListener('keydown', this.boundOnKeyDown);
  }

  destroy() {
    document.removeEventListener('keyup', this.boundOnKeyUp);
    document.removeEventListener('keydown', this.boundOnKeyDown);
  }

  onKeyDown(event) {
    if (this.keyDownFired) {
      return;
    }
    const action = keyDownActions.find((action) => action.test(event));
    if (action) {
      this.keyDownFired = true;
      this.emitAction(action);
    }
  }

  onKeyUp(event) {
    if (this.keyDownFired) {
      this.keyDownFired = false;
    }
    const action = keyUpActions.find((action) => action.test(event));
    if (action) {
      this.emitAction(action);
    }
  }

  emitAction(action) {
    const events = Array.isArray(action.event) ? action.event : [action.event];
    events.forEach((event) => {
      this.handler(Object.assign({ src: 'keyboardControl' }, event));
    });
  }
}

export default KeyboardControl;
