import I18n from 'i18n-js';
import Icon from '../generic/Icon.js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Utils from '../../utils/a11yHelper.js';

const useStyles = makeStyles((theme) => ({
  marker: {
    display: 'flex',
    position: 'relative',
    'justify-content': 'space-between',
    padding: '0.25rem 0',
    margin: '0.5rem 0 0 0',
    color: theme.palette.common.white,
    background: (props) => (props.active ? theme.palette.secondary.main : ''),
    width: (props) => (props.sticky || props.hover ? 'max-content' : '4.5rem'),
    transition: 'width 0.2s ease',
  },
  button: {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',
    minWidth: '4.5rem',
    width: '4.5rem',
    'justify-content': 'center',
    'align-items': 'center',
    margin: 0,
    height: '2.2rem',
    '& svg': {
      fill: (props) =>
        props.active && theme.palette.type === 'light'
          ? theme.palette.common.white
          : '',
    },
  },
  actionButton: {
    fill: (props) =>
      props.active && theme.palette.type === 'light'
        ? theme.palette.common.white
        : '',
    color: (props) =>
      props.active && theme.palette.type === 'light'
        ? theme.palette.common.white
        : '',
  },
  icon: {
    'font-size': '2rem',
    fill: (props) =>
      props.active && theme.palette.type === 'light'
        ? theme.palette.common.white
        : '',
    color: (props) =>
      props.active && theme.palette.type === 'light'
        ? theme.palette.common.white
        : '',
  },
  content: {
    display: (props) => (props.sticky || props.hover ? 'flex' : 'none'),
    'justify-content': 'space-between',
    'align-items': 'center',
    'line-height': '1em',
    width: '100%',
    'max-height': '2.2rem',
    'margin-right': '0.5rem',
  },
  label: {
    display: 'block',
    padding: '0 1rem',
    'text-transform': 'none',
  },
}));

const Label = ({ content, isAlert }) => {
  const classes = useStyles();
  return (
    <Typography
      variant="button"
      className={classes.label}
      role={isAlert ? 'alert' : ''}
    >
      {content}
    </Typography>
  );
};

const ActionMarkerButton = ({
  id,
  icon,
  label,
  active = false,
  locked = false,
  action,
  visible = true,
  children,
  onClick,
  toggled = null,
  warning,
  tooltip = '',
  disabled,
  sticky = true,
  stopAction = null,
  stopOption,
  keyShortcut = null,
  activeLabel = I18n.t('label:active'),
  activeLabelIsAlert = false,
  focusAfterAction = false,
  activeActionLabel,
  activeActionTooltip,
}) => {
  const buttonEl = useRef(null);
  const actionButtonEl = useRef(null);
  const [hover, setHover] = useState(false);
  const classes = useStyles({ active, hover, locked, disabled, sticky });

  const handleClick = () => {
    if (!locked) {
      Utils.registerActiveElement(buttonEl);
      buttonEl.current.blur();
      onClick({ type: action });
      if (focusAfterAction && buttonEl.current) {
        buttonEl.current.focus();
      }
    }
  };

  const onActionClick = () => {
    actionButtonEl.current.blur();
    setHover(false);
    onClick({ type: stopAction });
    if (focusAfterAction && buttonEl.current) {
      buttonEl.current.focus();
    }
  };

  return (
    <div
      className={classes.marker}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tooltip title={tooltip} placement={active ? 'top' : 'right'}>
        <button
          type="button"
          ref={buttonEl}
          id={action}
          onClick={handleClick}
          className={classNames(classes.button, {
            hidden: !visible,
            disabled: disabled,
            locked: locked,
          })}
          aria-pressed={toggled}
          aria-label={label}
          aria-keyshortcuts={keyShortcut}
        >
          <Icon
            type={icon}
            title={tooltip || action}
            className={classes.icon}
            warning={warning}
          />
          {children}
        </button>
      </Tooltip>
      {active && (
        <span className={classes.content}>
          <Label content={activeLabel} isAlert={activeLabelIsAlert} />
          {stopOption ? (
            stopOption
          ) : (
            <Tooltip title={activeActionTooltip} placement="top">
              <MuiButton
                ref={actionButtonEl}
                size="small"
                color="primary"
                className={classes.actionButton}
                onClick={onActionClick}
                aria-label={activeActionLabel}
              >
                {activeActionLabel}
              </MuiButton>
            </Tooltip>
          )}
        </span>
      )}
    </div>
  );
};

ActionMarkerButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  action: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  sticky: PropTypes.bool,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  toggled: PropTypes.bool,
  warning: PropTypes.bool,
  stopOption: PropTypes.object,
  stopAction: PropTypes.string,
  activeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keyShortcut: PropTypes.string,
  focusAfterAction: PropTypes.bool,
  activeLabelIsAlert: PropTypes.bool,
};

export default ActionMarkerButton;
