import { Logger } from 'eyeson';

const _max = Function.prototype.apply.bind(Math.max, null);

let _micIndicator = null;

class AudioIndicator {
  constructor() {
    this.audioContext = null;
    this.analyser = null;
    this.data = null;
    this.source = null;
    this.timer = null;
    this.listeners = [];
    this.running = false;
    this.lastValue = 0;
  }

  init() {
    const analyserOptions = {
      fftSize: 32,
      smoothingTimeConstant: 0.35,
    };
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (typeof AudioContext === 'undefined') {
      Logger.error('MicIndicator', 'AudioContext is not available.');
      return;
    }
    try {
      this.audioContext = new AudioContext();
      this.analyser = Object.assign(
        this.audioContext.createAnalyser(),
        analyserOptions
      );
      this.data = new Uint8Array(this.analyser.frequencyBinCount);
    } catch (error) {
      Logger.error('MicIndicator', error);
    }
  }

  connect(mediaStream) {
    const { audioContext } = this;
    if (!audioContext) {
      return;
    }
    this.disconnect();
    if (
      !(
        mediaStream instanceof MediaStream &&
        mediaStream.active &&
        mediaStream.getAudioTracks().length > 0 &&
        mediaStream
          .getAudioTracks()
          .every((track) => track.readyState === 'live')
      )
    ) {
      return;
    }
    mediaStream
      .getAudioTracks()
      .forEach((track) => track.addEventListener('ended', () => this.pause()));
    this.source = audioContext.createMediaStreamSource(mediaStream);
    this.source.connect(this.analyser);
    this.resume();
  }

  pause() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.running = false;
    this.lastValue = 0;
  }

  resume() {
    this.pause();
    this.timer = setInterval(() => {
      if (this.analyser && this.source) {
        this.analyser.getByteFrequencyData(this.data);
        const max = Math.min(
          Math.max(Math.floor(_max(this.data) / 63.75) * 63.75, 0),
          255
        );
        if (max !== this.lastValue && (max === 0 || max > 100)) {
          this.emit(max);
          this.lastValue = max;
        }
      }
    }, 60);
    this.running = true;
  }

  onUpdate(fn) {
    const { listeners } = this;
    if (fn && !listeners.includes(fn)) {
      this.listeners.push(fn);
    }
  }

  offUpdate(fn) {
    if (fn) {
      this.listeners = this.listeners.filter((listener) => listener !== fn);
      return;
    }
    this.listeners.length = 0;
  }

  emit(value) {
    this.listeners.forEach((fn) => fn(value));
  }

  disconnect() {
    this.pause();
    if (this.source) {
      this.source.disconnect();
      this.source = null;
    }
  }

  async destroy() {
    this.offUpdate();
    this.disconnect();
    this.analyser = null;
    this.data = null;
    if (this.audioContext && this.audioContext.state !== 'closed') {
      await this.audioContext.close();
      this.audioContext = null;
    }
  }
}

const init = () => {
  _micIndicator = new AudioIndicator();
  _micIndicator.init();
};

const terminate = () => {
  if (_micIndicator) {
    _micIndicator.destroy();
    _micIndicator = null;
  }
};

const resume = () => {
  if (_micIndicator) {
    _micIndicator.resume();
  }
};

const pause = () => {
  if (_micIndicator) {
    _micIndicator.pause();
  }
};

const connect = (stream) => {
  if (_micIndicator) {
    _micIndicator.connect(stream);
  }
};

const onUpdate = (fn) => {
  if (_micIndicator) {
    _micIndicator.onUpdate(fn);
  }
};

const offUpdate = (fn) => {
  if (_micIndicator) {
    _micIndicator.offUpdate(fn);
  }
};

const collection = {
  init,
  terminate,
  connect,
  onUpdate,
  offUpdate,
  resume,
  pause,
};

export default collection;
