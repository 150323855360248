import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import SoundWave from '../streams/SoundWave.js';
import classNames from 'classnames';
import MuiAvatar from '@material-ui/core/Avatar';
import Playback from '../streams/Playback.js';

const Avatar = ({
  url,
  onEvent,
  playback,
  voiceActivity,
  label = I18n.t('alt:avatar_default'),
}) => {
  const shortname = label
    .replace('[Guest] ', '')
    .replace(/[^a-z0-9 ]/gi, '')
    .split(/ +/)
    .filter(
      (_value, index, arr) =>
        index === 0 || (index > 0 && index === arr.length - 1)
    )
    .map((w) => w.charAt(0).toUpperCase())
    .join('');
  return (
    <span>
      {playback ? (
        <div className="MuiAvatar-root eyeson-avatar">
          <Playback onEvent={onEvent} playback={playback} />
        </div>
      ) : (
        <MuiAvatar
          src={url}
          alt={`${I18n.t('alt:avatar')} ${label}`}
          className={classNames('eyeson-avatar', {
            talking: voiceActivity,
          })}
        >
          {shortname}
        </MuiAvatar>
      )}
      {voiceActivity && (
        <SoundWave active={voiceActivity} width={'100%'} height={'100%'} />
      )}
    </span>
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  voiceActivity: PropTypes.bool,
};

// NOTE default props are not supplied here due to:
// 1. the I18n.js issue see README for more information
// 2. guest avatar urls are supplied as "null" and default props don't trigger
//    for null
Avatar.defaultProps = {};

export default Avatar;
