import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '../generic/Button.js';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  secondaryAction: {
    marginRight: 'auto',
  },
}));

const PromptDialog = ({
  dialogId,
  icon = null,
  message = 'Hi there',
  children,
  footerVisible = true,
  actionLabel = I18n.t('default'),
  secondaryActionLabel = I18n.t('default'),
  cancelLabel = I18n.t('label:close'),
  onAction,
  onSecondaryAction,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {icon}
      <DialogContentText id={dialogId ? dialogId + '-desc' : null} tabIndex={0}>
        {message}
      </DialogContentText>
      {children && <div>{children}</div>}
      {footerVisible && (
        <DialogActions>
          {onSecondaryAction && (
            <Button
              onClick={onSecondaryAction}
              type="secondary"
              className={classes.secondaryAction}
            >
              {secondaryActionLabel}
            </Button>
          )}
          {onClose && (
            <Button onClick={onClose} type="secondary">
              {cancelLabel}
            </Button>
          )}
          <Button onClick={onAction}>{actionLabel}</Button>
        </DialogActions>
      )}
    </Fragment>
  );
};

PromptDialog.propTypes = {
  dialogId: PropTypes.string,
  icon: PropTypes.object,
  message: PropTypes.string,
  children: PropTypes.array,
  footerVisible: PropTypes.bool,
  actionLabel: PropTypes.string,
  onAction: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  onSecondaryAction: PropTypes.func,
  cancelLabel: PropTypes.string,
};

export default PromptDialog;
