import I18n from 'i18n-js';
import React, { useState, useEffect } from 'react';
import Status from './Status.js';
import FunFact from './FunFact.js';
import Animation from './Animation.js';
import Typography from '@material-ui/core/Typography';

const Connecting = ({
  status = 'room',
  withFunFact = false,
  initialDelay = false,
  withAnimation = false,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      return;
    }
    const timeout = setTimeout(() => setShow(true), 1500);
    return () => clearTimeout(timeout);
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className="eyeson-room">
      <main id="main">
        <Status visible={status !== 'exit_room'}>
          {withAnimation ? (
            <Animation initialDelay={initialDelay} />
          ) : (
            <Typography variant="h2">{I18n.t('status:connecting')}</Typography>
          )}
          <Typography variant="body1">
            {I18n.t('status:connect_note')}
          </Typography>
          {withFunFact && <FunFact initialDelay={status === 'initialize'} />}
        </Status>
      </main>
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.status === nextProps.status &&
    prevProps.withFunFact === nextProps.withFunFact &&
    prevProps.initialDelay === nextProps.initialDelay &&
    prevProps.withAnimation === nextProps.withAnimation
  );
}

export default React.memo(Connecting, areEqual);
