import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    flexFlow: 'column',
    margin: theme.spacing(0, 0, 0, 2),
    padding: theme.spacing(1),
    color: 'white',
    background: theme.palette.secondary.main,
    'box-shadow': '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
  },
}));

const StickyNote = ({ visible, title, subtitle }) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.flex}>
      <Typography variant="overline">{title}</Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </div>
  );
};

StickyNote.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  visible: PropTypes.bool,
};

StickyNote.defaultProps = {
  title: 'Heads up',
  subtitle: 'Have a nice day',
  visible: false,
};

export default StickyNote;
