import { Logger } from 'eyeson';

let _supportsHeicImages = null;
let _supportsOffscreenCanvas = null;

const processWorker = (files, accepted, rejected, signal) => {
  return new Promise((resolve) => {
    const content = `importScripts('https://cdn.jsdelivr.net/npm/libheif-js@1.17.1/libheif-wasm/libheif-bundle.min.js');

const libHeifModule = libheif();

const processSingleImage = image => {
  return new Promise((resolve, reject) => {
    const w = image.get_width();
    const h = image.get_height();
    const whiteImage = new ImageData(w, h);
    for (let i = 0; i < w * h; i++) {
      whiteImage.data[i * 4 + 3] = 255;
    }
    image.display(whiteImage, imageData => {
      if (!imageData) {
        return reject(
          "ERR_LIBHEIF Error while processing single image and generating image data, could not ensure image"
        );
      }
      resolve(imageData);
    });
  });
};

onmessage = message => {
  const id = message.data.id;
  try {
    const decoder = new libHeifModule.HeifDecoder();
    let imagesArr = decoder.decode(message.data.buffer);
    if (!imagesArr || !imagesArr.length) {
      throw "ERR_LIBHEIF format not supported";
    }
    imagesArr = imagesArr.filter((x) => {
      let valid = true;
      try {
        /*
        sometimes the heic container is valid
        yet the images themselves are corrupt
        */
        x.get_height();
      } catch (e) {
        valid = false;
      }
      return valid;
    });
    if (!imagesArr.length) {
      throw "ERR_LIBHEIF Heic doesn't contain valid images";
    }

    Promise.all(imagesArr.map((image) => processSingleImage(image)))
      .then((imageDataArr) => {
        postMessage({ type: 'image', id, imageDataArr, error: '' });
      })
      .catch((e) => {
        postMessage({
          type: 'image',
          id,
          imageDataArr: [],
          error: e && e.toString ? e.toString() : e,
        });
      });
  } catch (e) {
    postMessage({
      type: 'image',
      id,
      imageDataArr: [],
      error: e && e.toString ? e.toString() : e,
    });
  }
};

postMessage({ type: 'ready' });
`;
    const workerBlob = new Blob([content], { type: 'application/javascript' });
    const workerUrl = URL.createObjectURL(workerBlob);
    const worker = new Worker(workerUrl);
    const terminate = () => {
      worker.terminate();
      URL.revokeObjectURL(workerUrl);
    };
    const abort = () => {
      terminate();
      accepted.length = 0;
      rejected.length = 0;
      resolve();
    };
    let fileCounter = files.length;
    if (signal.aborted) {
      abort();
      return;
    }
    signal.addEventListener('abort', () => abort());
    worker.addEventListener('message', async ({ data }) => {
      if (data.type === 'ready') {
        files.forEach(async (file, index) => {
          try {
            const buffer = await new Response(file).arrayBuffer();
            worker.postMessage({ id: index, buffer });
          } catch (error) {
            Logger.error('heicImageHelper::parseFile', error);
            rejected.push(file);
            fileCounter--;
          }
        });
      } else if (data.type === 'image') {
        if (data.error) {
          rejected.push(files[data.id]);
        } else {
          const newFile = await imageDataToFile(
            data.imageDataArr[0],
            files[data.id]
          );
          if (newFile) {
            accepted.push(newFile);
          } else {
            rejected.push(files[data.id]);
          }
        }
        fileCounter--;
        if (fileCounter === 0) {
          terminate();
          resolve();
        }
      }
    });
  });
};

const imageDataToFile = async (imageData, originalFile) => {
  try {
    let blob = null;
    if (_supportsOffscreenCanvas) {
      const canvas = new OffscreenCanvas(imageData.width, imageData.height);
      const context = canvas.getContext('2d');
      context.putImageData(imageData, 0, 0);
      blob = await canvas.convertToBlob({ type: 'image/png' });
    } else {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = imageData.width;
      canvas.height = imageData.height;
      context.putImageData(imageData, 0, 0);
      blob = await new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(blob), 'image/png');
      });
    }
    const file = new File([blob], originalFile.name, { type: blob.type });
    file.path = file.name;
    return file;
  } catch (error) {}
  return null;
};

const checkSupport = (file) => {
  return new Promise(async (resolve) => {
    if (
      typeof window.ImageDecoder === 'function' &&
      Reflect.has(window.ImageDecoder, 'isTypeSupported')
    ) {
      _supportsHeicImages = await window.ImageDecoder.isTypeSupported(
        'image/heic'
      );
      resolve();
      return;
    }
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      URL.revokeObjectURL(url);
      _supportsHeicImages = true;
      resolve();
    };
    img.onerror = () => {
      URL.revokeObjectURL(url);
      _supportsHeicImages = false;
      resolve();
    };
    img.src = url;
  });
};

const checkOffscreenCanvasSupport = () => {
  _supportsOffscreenCanvas =
    typeof window.OffscreenCanvas === 'function' &&
    (() => {
      try {
        new OffscreenCanvas(1, 1).getContext('2d');
        return true;
        // eslint-disable-next-line no-empty
      } catch (error) {}
      return false;
    })();
};

const processHeicImages = async (files, accepted, rejected, signal) => {
  if (_supportsHeicImages === null) {
    await checkSupport(files[0]);
  }
  if (_supportsOffscreenCanvas === null) {
    checkOffscreenCanvasSupport();
  }
  if (_supportsHeicImages) {
    await Promise.all(
      files.map((file) => {
        return new Promise((resolve) => {
          const url = URL.createObjectURL(file);
          const img = new Image();
          img.onload = () => {
            URL.revokeObjectURL(url);
            accepted.push(file);
            resolve();
          };
          img.onerror = () => {
            URL.revokeObjectURL(url);
            rejected.push(file);
            resolve();
          };
          img.src = url;
        });
      })
    );
  } else {
    try {
      await processWorker(files, accepted, rejected, signal);
    } catch (error) {
      Logger.error('heicImageHelper::processWorker', error);
      accepted.length = 0;
      rejected.length = 0;
    }
  }
};

export default processHeicImages;
