import React from 'react';
import I18n from 'i18n-js';
import { ConnectionStatistics } from 'eyeson';
import classnames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Typography from '@material-ui/core/Typography';
import NumberFormat from '../../utils/NumberFormatHelper.js';

const { Status } = ConnectionStatistics;

const statusNames = {};
statusNames[Status.NO_CONNECTION] = 'status:connection:no_connection';
statusNames[Status.BAD] = 'status:connection:poor';
statusNames[Status.OK] = 'status:connection:unstable';
statusNames[Status.GOOD] = 'status:connection:excellent';

const useStyles = makeStyles((theme) => ({
  table: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& th': {
      fontWeight: 'normal',
      textAlign: 'left',
    },
    '& tr.row-status th, & tr.row-status td': {
      fontWeight: 'bold',
    },
    '& th, & td': {
      padding: '.25em .5em',
    },
    '& tr.hidden': {
      display: 'none',
    },
  },
  status: {
    color: (data) => {
      if (data.status === Status.BAD || data.status === Status.NO_CONNECTION) {
        return '#e53729';
      }
      if (data.status === Status.OK) {
        return '#c7bb2a';
      }
      return '#08C008';
    },
  },
  description: {
    margin: theme.spacing(2),
  },
}));

const MobileConnectionInfo = ({ data, onEvent }) => {
  const classes = useStyles(data);
  const handleClose = () => {
    onEvent({ type: 'toggle_mobile_connection_info' });
  };
  return (
    <div id="mobile-connection-info">
      <div className="overlay-close">
        <IconButton
          size="small"
          aria-label={I18n.t('label:close', { defaultValue: 'close' })}
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <table className={classes.table}>
        <tbody>
          <tr className="row-status">
            <th>{I18n.t('label:connection_info:connection_status')}</th>
            <td className={classes.status}>
              {I18n.t(statusNames[data.status])}
            </td>
          </tr>
          {data.status !== Status.NO_CONNECTION && (
            <>
              <tr>
                <th>{I18n.t('label:connection_info:bitrate')}</th>
                <td>
                  <nobr>
                    <ArrowUpward style={{ fontSize: '1em' }} />
                    {NumberFormat.bitrate(data.bitrateSend)}
                  </nobr>{' '}
                  <nobr>
                    <ArrowDownward style={{ fontSize: '1em' }} />
                    {NumberFormat.bitrate(data.bitrateRecv)}
                  </nobr>
                </td>
              </tr>
              <tr className={classnames({ hidden: data.jitter === null })}>
                <th>{I18n.t('label:connection_info:jitter')}</th>
                <td>{NumberFormat.jitter(data.jitter)}</td>
              </tr>
              <tr className={classnames({ hidden: data.packetLoss === null })}>
                <th>{I18n.t('label:connection_info:packet_loss')}</th>
                <td>{NumberFormat.packetLoss(data.packetLoss)}</td>
              </tr>
              <tr
                className={classnames({ hidden: data.roundTripTime === null })}
              >
                <th>{I18n.t('label:connection_info:connection_delay')}</th>
                <td>{NumberFormat.roundTripTime(data.roundTripTime)}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <Typography
        variant="body2"
        className={classes.description}
        color="textSecondary"
      >
        {I18n.t('label:connection_info:description')}
      </Typography>
    </div>
  );
};

export default MobileConnectionInfo;
