// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
const parseJwtPayload = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    return false;
  }
};

export default parseJwtPayload;
