const _store = {
  activeElement: null,
};

const Utils = {
  registerActiveElement: (element) => {
    _store.activeElement = element;
  },
  clearActiveElement: () => {
    _store.activeElement = null;
  },
  getRegisteredElement: (once = true) => {
    const element =
      _store.activeElement && _store.activeElement.current
        ? _store.activeElement.current
        : null;
    if (once) {
      Utils.clearActiveElement();
    }
    return element;
  },
};

export default Utils;
