/**
 * definition of plural rules for i18n-js (https://github.com/fnando/i18n-js)
 * rules can be found here:
 * https://unicode-org.github.io/cldr-staging/charts/37/supplemental/language_plural_rules.html
 *
 */
const PluralRules = {
  de: (count) => {
    return count === 1 ? 'one' : 'other';
  },
  es: (count) => {
    return count === 1 ? 'one' : 'other';
  },
  fr: (count) => {
    return count === 0 || count === 1 ? 'one' : 'other';
  },
  pt: (count) => {
    return count === 0 || count === 1 ? 'one' : 'other';
  },
  ru: (count) => {
    const mod10 = count % 10;
    const mod100 = count % 100;
    if (mod10 === 1 && mod100 !== 11) {
      return 'one';
    }
    if ([2, 3, 4].includes(mod10) && ![12, 13, 14].includes(mod100)) {
      return 'few';
    }
    if (
      [0, 5, 6, 7, 8, 9].includes(mod10) ||
      [11, 12, 13, 14].includes(mod100)
    ) {
      return 'many';
    }
    return 'other';
  },
};

export default PluralRules;
