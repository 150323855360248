import React from 'react';
import I18n from 'i18n-js';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '1em',
    marginLeft: '.25em',
  },
}));

const HelpLink = ({
  label = I18n.t('label:help'),
  color = 'secondary',
  className,
  link,
}) => {
  const classes = useStyles();

  return (
    <Button
      rel="noopener noreferrer"
      href={link}
      target="_blank"
      color={color}
      className={className}
    >
      {label}
      <Icon className={classes.icon}>open_in_new</Icon>
    </Button>
  );
};

export default HelpLink;
