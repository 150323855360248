import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Button from '../../generic/Button.js';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background:
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
  },
}));

const SnapshotButton = ({ onEvent }) => {
  const classes = useStyles();
  return (
    <Box p={2} className={classes.container}>
      <Button icon="photo_camera" onClick={() => onEvent({ type: 'snapshot' })}>
        {I18n.t('snapshots:action')}
      </Button>
    </Box>
  );
};

SnapshotButton.propTypes = { onEvent: PropTypes.func.isRequired };

export default SnapshotButton;
