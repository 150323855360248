import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Box from '@material-ui/core/Box';
import Button from '../../generic/Button.js';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as MuteAll } from '../../../assets/mute_all.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    background:
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.primary.dark,
  },
}));

const MUTE_TIMEOUT_IN_SECONDS = 10;

// TODO: use StfuButton instead...
const StfuButton = ({ onClick }) => {
  const [disabled, setDisabled] = useState(false);
  let timeout;

  const handleClick = () => {
    setDisabled(true);
    clearTimeout(timeout);
    timeout = setTimeout(
      () => setDisabled(false),
      MUTE_TIMEOUT_IN_SECONDS * 1000
    );
    onClick();
  };

  // Clear timeout on close
  useEffect(() => {
    return () => clearTimeout(timeout);
  }, [timeout]);

  return (
    <Button
      icon={<MuteAll />}
      type="secondary"
      disabled={disabled}
      onClick={handleClick}
    >
      {I18n.t(disabled ? 'label:muted' : 'participants:mute_all')}
    </Button>
  );
};

const ParticipantActions = ({
  quickJoin = true,
  isLocked = false,
  hideGuestLogin = false,
  onEvent,
}) => {
  const classes = useStyles();
  return (
    <Box
      p={2}
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      className={classes.container}
    >
      {quickJoin && !isLocked && !hideGuestLogin && (
        <Button
          onClick={() => onEvent({ type: 'guest_link' })}
          icon="person_add"
        >
          {I18n.t('participants:quick_join')}
        </Button>
      )}
      <StfuButton onClick={() => onEvent({ type: 'request_stfu' })} />
    </Box>
  );
};

ParticipantActions.propTypes = {
  onEvent: PropTypes.func.isRequired,
  quickJoin: PropTypes.bool,
};

export default ParticipantActions;
