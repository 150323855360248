import I18n from 'i18n-js';
import React from 'react';
import FaceIcon from '@material-ui/icons/Face';
import SeatIcon from '@material-ui/icons/EventSeat';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexBasis: '20%',
    borderRadius: '0.2rem',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1),
    cursor: 'pointer',
    background: 'inherit',
    color: 'inherit',
    '&:hover': {
      background: 'rgba(250, 250, 250, 0.08)',
    },
    border: (props) => (props.selected ? '2px solid' : 'white'),
  },
  name: {
    marginTop: '5px',
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    fontWeight: '700',
    letterSpacing: '1px',
  },
}));

const LayoutOption = ({ selected, name, group, layoutKey, onClick }) => {
  const classes = useStyles({ selected, group });
  let faces = null;
  if (layoutKey === 'auto') {
    faces = <SeatIcon />;
  } else if (layoutKey === 'one') {
    faces = <FaceIcon />;
  } else if (layoutKey === 'two') {
    faces = Array.from({ length: 2 }, (element, index) => (
      <FaceIcon key={index} />
    ));
  } else if (layoutKey === 'four') {
    faces = Array.from({ length: 5 }, (element, index) =>
      index === 2 ? <br key={index} /> : <FaceIcon key={index} />
    );
  } else if (layoutKey === 'nine') {
    faces = Array.from({ length: 11 }, (element, index) =>
      (index + 1) % 4 === 0 ? <br key={index} /> : <FaceIcon key={index} />
    );
  } else if (layoutKey === 'one_plus_six') {
    faces = Array.from({ length: 8 }, (element, index) =>
      index === 1 ? (
        <br key={index} />
      ) : (
        <FaceIcon
          key={index}
          style={index > 1 ? { fontSize: '.7rem' } : { fontSize: '2rem' }}
        />
      )
    );
  } else if (layoutKey === 'two_plus_six') {
    faces = Array.from({ length: 9 }, (element, index) =>
      index === 2 ? (
        <br key={index} />
      ) : (
        <FaceIcon
          key={index}
          style={index > 2 ? { fontSize: '.7rem' } : { fontSize: '2rem' }}
        />
      )
    );
  } else if (layoutKey === 'widescreen_one_plus_six') {
    faces = (
      <>
        <FaceIcon
          key="big"
          style={{ fontSize: '3.5rem', verticalAlign: 'middle' }}
        />
        <span
          style={{
            display: 'inline-flex',
            flexFlow: 'column',
            verticalAlign: 'middle',
          }}
        >
          {Array.from({ length: 6 }, (element, index) => (
            <FaceIcon key={index} style={{ fontSize: '.7rem' }} />
          ))}
        </span>
      </>
    );
  }

  return (
    <button
      onClick={() => onClick(name)}
      className={classes.button}
      aria-current={selected ? true : null}
    >
      <span>{faces}</span>
      <span className={classes.name}>{I18n.t(name)}</span>
    </button>
  );
};

export default LayoutOption;
