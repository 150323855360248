/* eslint max-lines: off */
import Logger from './Logger.js';

/**
 * eyeson Communications Api
 */
class ComApi {
  constructor(uri, token) {
    this.uri = uri;
    this.token = token.replace(/\W+/g, '');
    this.cache = { users: [] };
    this.errorCallback = null;
    this._handleError = this._handleError.bind(this);
  }

  _request(path, options = {}) {
    return fetch(new Request(this.uri + path), options).then(response => {
      const contentType = response.headers.get('content-type');

      if (
        contentType &&
        contentType.indexOf('application/json') !== -1 &&
        response.ok
      ) {
        return response.json();
      }

      // not json - e.g. broadcast stop
      if (response.ok) {
        return response.text();
      }
      const error = new Error(`ComApiError: ${response.status}`);
      error.status = response.status;
      throw error;
    });
  }

  /**
   * NOTE: slightly adjusted from:
   * https://gist.github.com/ghinda/8442a57f22099bdb2e34
   */
  /* eslint-disable */
  _objectToFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey = null;

    for (let property in obj) {
      if (!obj.hasOwnProperty(property)) {
        return;
      }
      if (namespace) {
        // adjusted, skip "int props" for layout api
        let prop = isNaN(parseInt(property)) ? property : '';
        formKey = namespace + '[' + prop + ']';
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File, use recursivity.
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        this._objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }

    return fd;
  }
  /* eslint-enable */

  _post(path, data) {
    return this._request(path, {
      method: 'POST',
      body: this._objectToFormData(data)
    });
  }

  _put(path, data) {
    const formData = new FormData();
    /* eslint-disable guard-for-in */
    for (const key in data) {
      formData.append(key, data[key]);
    }
    /* eslint-enable */
    return this._request(path, {
      method: 'PUT',
      body: formData
    });
  }

  _handleError(error, id) {
    Logger.error('ComApi::handleError', id, error);
    if (this.errorCallback) {
      this.errorCallback(error, id);
    }
    return false;
  }

  onError(callback) {
    this.errorCallback = callback;
  }

  /**
   * Fetch room details including the user and credentials from com-api.
   * @param {function} [callback] - Callback function
   * @return {Promise}
   */
  getRoom(callback) {
    return this._request(`/rooms/${this.token}`)
      .then(callback)
      .catch(error => {
        return callback ? callback({ error: error }) : { error: error };
      });
  }

  /**
   * Terminate meeting immediately for all users
   * @return {Promise}
   */
  terminateMeeting() {
    return this._request(`/rooms/${this.token}`, {
      method: 'DELETE'
    }).catch(error => this._handleError(error, 'terminate_meeting'));
  }

  /**
   * Retrieve user information. Will use cache on multiple requests.
   * @param {string} userId - UserId
   * @param {function} callback - Callback function
   * @return {Promise}
   */
  getUser(userId, callback) {
    const user = this.cache.users.find(cachedUser => cachedUser.id === userId);

    if (user) {
      callback(user);
      return null;
    }

    return this._request(`/rooms/${this.token}/users/${userId}`)
      .then(apiUser => {
        let users = this.cache.users.filter(
          cachedUser => cachedUser.id !== userId
        );
        let extendedUser = this.addUserAttributes(apiUser, userId, userId);
        users.push(extendedUser);
        this.cache.users = users;
        callback(extendedUser);
      })
      .catch(error => this._handleError(error, 'get_user'));
  }

  addUserAttributes(user, userId, clientId) {
    let largeAvatarUrl = user.avatar;

    try {
      largeAvatarUrl = `${user.avatar}?size=large`;
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      Logger.warn('ComApi::addUserAttributes missing', user);
    }

    return Object.assign(user, {
      id: userId,
      apiId: user.id,
      clientId: clientId,
      // sipId will be removed in future (2023)!
      sipId: clientId,
      largeAvatar: largeAvatarUrl
    });
  }

  /**
   * Remove user from current meeting.
   * @param {string} userId - UserId
   * @return {Promise}
   **/
  kickUser(userId) {
    return this._request(`/rooms/${this.token}/users/${userId}`, {
      method: 'DELETE'
    })
      .then(() => {
        const user = this.cache.users.find(
          cachedUser => cachedUser.id === userId
        );
        if (user) {
          let users = this.cache.users.filter(
            cachedUser => cachedUser.id !== userId
          );
          this.cache.users = users;
        }
      })
      .catch(error => this._handleError(error, 'kick_user'));
  }

  /**
   * Request a guest user from api.
   * @param {object} user - { name, email, locale }
   * @param {function} callback - Callback function
   * @return {Promise}
   */

  requestUser(user, callback) {
    return this._post(`/guests/${this.token}`, {
      name: user.name,
      email: user.email,
      custom_fields: {
        locale: user.locale || 'en'
      }
    })
      .then(callback)
      .catch(error => callback({ error: error }));
  }

  /**
   * Broadcast to a platform.
   * @param {object} data - { playerUrl, streamUrl }
   * @param {string} platform - 'generic'
   * @return {Promise}
   */

  startBroadcast(data, platform) {
    return this._post(`/rooms/${this.token}/broadcasts`, {
      platform: platform,
      player_url: data.playerUrl || '',
      stream_url: data.streamUrl
    });
  }

  /**
   * Broadcast to a platform.
   * @param {object} data - { playerUrl }
   * @param {string} platform - 'generic'
   * @return {Promise}
   */

  publishBroadcast(data, platform) {
    return this._put(`/rooms/${this.token}/broadcasts/${platform}`, {
      player_url: data.playerUrl
    });
  }

  /**
   * Stop a broadcast from a platform.
   * @param {string} platform - 'generic'
   * @return {Promise}
   */
  stopBroadcast(platform) {
    return this._request(`/rooms/${this.token}/broadcasts/${platform}`, {
      method: 'DELETE'
    });
  }

  /**
   * Stop all broadcasts.
   * @return {Promise}
   */
  stopAllBroadcasts() {
    return this._request(`/rooms/${this.token}/broadcasts`, {
      method: 'DELETE'
    });
  }

  /**
   * Start a recording.
   * @return {Promise}
   */
  startRecording() {
    return this._request(`/rooms/${this.token}/recording`, {
      method: 'POST'
    });
  }

  /**
   * Stop a recording
   * @return {Promise}
   */
  stopRecording() {
    return this._request(`/rooms/${this.token}/recording`, {
      method: 'DELETE'
    }).catch(error => this._handleError(error, 'stop_recording'));
  }

  /**
   * Set layout
   * @see https://docs.eyeson.com/docs/rest/references/layout
   * @param {object} params - eg. { layout: 'auto' } or { users: [ id1, id2 ...] }
   * @return {Promise}
   */
  setLayout(params) {
    return this._post(`/rooms/${this.token}/layout`, params).catch(error =>
      this._handleError(error, 'set_layout')
    );
  }

  /**
   * Set layer
   * @see https://docs.eyeson.com/docs/rest/references/layers
   * @param {object} params - eg. { url: 'https://...', 'z-index': '-1' }
   * @return {Promise}
   */
  setLayer(params) {
    return this._post(`/rooms/${this.token}/layers`, params).catch(error =>
      this._handleError(error, 'set_layer')
    );
  }

  /**
   * Clear front most layer
   * @return {Promise}
   */
  clearFrontLayer() {
    return this._request(`/rooms/${this.token}/layers/1`, {
      method: 'DELETE'
    }).catch(error => this._handleError(error, 'clear_front_layer'));
  }

  /**
   * Take a snapshot of the current podium
   * @return {Promise}
   */
  takeSnapshot() {
    return this._request(`/rooms/${this.token}/snapshot`, {
      method: 'POST'
    }).catch(error => this._handleError(error, 'snapshot'));
  }

  /**
   * Get snapshot info by snapshotId
   * @param {string} snapshotId - SnapshotId
   * @param {function} [callback] - Callback function
   * @return {Promise}
   */
  getSnapshot(snapshotId, callback) {
    return this._request(`/rooms/${this.token}/snapshots/${snapshotId}`)
      .then(callback)
      .catch(error => {
        if (callback) {
          callback({ error });
          return;
        }
        this._handleError(error, 'get_snapshot');
      });
  }

  /**
   * Start a playback
   * @see https://docs.eyeson.com/docs/rest/references/playbacks
   * @param {object} playback - eg. { url: 'https://..', play_id: 'demo', audio: false }
   * @param {function} [callback] - Callback function
   * @return {Promise}
   */
  startPlayback(playback, callback) {
    return this._post(`/rooms/${this.token}/playbacks`, {
      playback: playback
    }).then(
      () => {
        return callback ? callback({}) : null;
      },
      error => {
        if (callback) {
          callback({ error });
          return;
        }
        this._handleError(error, 'start_playback');
      }
    );
  }

  /**
   * Stop a playback identified by play_id
   * @param {object} playback - eg. { play_id: 'demo' }
   * @return {Promise}
   */
  stopPlayback(playback) {
    return this._request(`/rooms/${this.token}/playbacks/${playback.play_id}`, {
      method: 'DELETE'
    }).catch(error => this._handleError(error, 'stop_playback'));
  }

  /**
   * Lock meeting to freeze current participants
   * @return {Promise}
   */
  lockMeeting() {
    return this._request(`/rooms/${this.token}/lock`, {
      method: 'POST'
    }).catch(error => this._handleError(error, 'lock_meeting'));
  }

  /**
   * Send message like type="chat"
   * @see https://docs.eyeson.com/docs/rest/references/messages
   * @param {object} params - eg. { type: 'chat', content: 'test' }
   * @return {Promise}
   */
  sendMessage(params) {
    return this._post(`/rooms/${this.token}/messages`, params).catch(error =>
      this._handleError(error, 'send_message')
    );
  }

  /**
   * Start presentation
   * @param {function} [callback] - Callback function
   * @return {Promise}
   */
  startPresentation(callback) {
    return this._request(`/rooms/${this.token}/presentation`, {
      method: 'POST'
    }).then(
      () => {
        return callback ? callback({}) : null;
      },
      error => {
        if (callback) {
          callback({ error });
          return;
        }
        this._handleError(error, 'start_presentation');
      }
    );
  }

  /**
   * Stop presentation
   * @return {Promise}
   */
  stopPresentation() {
    return this._request(`/rooms/${this.token}/presentation`, {
      method: 'DELETE'
    }).catch(error => this._handleError(error, 'stop_presentation'));
  }

  requestPermalinkMeetingInfo(callback) {
    return this._request(`/permalink/${this.token}`)
      .then(callback)
      .catch(error => callback({ error: error }));
  }

  joinPermalinkUser(callback) {
    return this._post(`/permalink/${this.token}`)
      .then(callback)
      .catch(error => callback({ error: error }));
  }
}

export default ComApi;
