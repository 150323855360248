import Logger from '../Logger.js';

class GiphyApi {
  constructor(roomUrl) {
    this.roomUrl = roomUrl;
  }

  search(query) {
    return this.request(`${this.roomUrl}/search?q=${query}`).then(
      ({ data }) => data
    );
  }

  random() {
    return this.request(`${this.roomUrl}/random`).then(({ data }) => data);
  }

  trending() {
    return this.request(`${this.roomUrl}/trending?limit=9`).then(({ data }) =>
      this.staff().then(staffGifs => [...data, ...staffGifs])
    );
  }

  staff() {
    return this.request(`${this.roomUrl}/staff`).then(({ data }) => data);
  }

  randomForQuery(query) {
    return new Promise((res, rej) => {
      this.search(query)
        .then(data => {
          if (!Array.isArray(data)) {
            return [];
          }
          return data.filter(img => img.images.original_mp4.mp4_size >= 40000);
        })
        .then(largerGifs => {
          if (largerGifs.length === 0) {
            throw new TypeError('No data found');
          }
          res(largerGifs[Math.floor(Math.random() * largerGifs.length)]);
        })
        .catch(rej);
    });
  }

  request(url) {
    return fetch(new Request(url))
      .then(response => response.json())
      .catch(error => {
        Logger.warn('GiphyApi', url, error);
        return { data: [] };
      });
  }
}

export default GiphyApi;
