import I18n from 'i18n-js';
import Icon from './../generic/Icon.js';
import React, { Fragment } from 'react';
import Button from '../generic/Button.js';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  svg: {
    width: '100%',
  },
}));

const RemoteMuteDialog = ({ user, onClick, dialogId }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.center}>
        <Icon type="mic_off" title="muted" />
      </div>
      <DialogContentText id={dialogId + '-desc'} tabIndex={0}>
        {user.name} {I18n.t('dialog:remote_muted:description')}
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClick}>{I18n.t('label:awesome')}</Button>
      </DialogActions>
    </Fragment>
  );
};

export default RemoteMuteDialog;
