import I18n from 'i18n-js';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../generic/Button.js';
import { Logger, FeatureDetector } from 'eyeson';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CopyInput from '../layout/CopyInput.js';

const RoomShareDialog = ({ url, options, onEvent, onClose }) => {
  const handleShare = () => {
    navigator
      .share({
        url: url,
        text: I18n.t('dialog:share:title'),
      })
      .then(() => {
        onEvent({ type: 'share_sheet_room_link' });
        Logger.debug('RoomShareDialog::handleShare: success!');
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          Logger.error('RoomShareDialog::handleShare: ', error);
        }
      });
  };

  if (options.suggest_guest_names === false) {
    url += '&suggest=false';
  }

  return (
    <Fragment>
      <DialogContentText tabIndex={0}>
        {I18n.t('dialog:share:desc')}
      </DialogContentText>

      <CopyInput
        id="quick-join-link"
        value={url}
        onCopy={() => onEvent({ type: 'copy_guest_link' })}
        shareMessage={I18n.t('dialog:share:title')}
      />

      <DialogActions>
        {FeatureDetector.canShare() && (
          <Button
            onClick={handleShare}
            aria-label={I18n.t('label:share_link', {
              defaultValue: 'Share link',
            })}
          >
            {I18n.t('label:share_link')}
          </Button>
        )}
        <Button type="secondary" onClick={onClose}>
          {I18n.t('label:close')}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

RoomShareDialog.propTypes = {
  url: PropTypes.string.isRequired,
  options: PropTypes.object,
  onEvent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

RoomShareDialog.defaultProps = {
  options: {},
};

export default RoomShareDialog;
