import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const ICONS = {
  generic: <Icon>settings_input_antenna</Icon>,
};

const PlatformIcons = ({ platforms, broadcasts }) => {
  return platforms.map((platform) => {
    const broadcast = broadcasts.find((bcast) => bcast.platform === platform);
    return (
      <span key={platform} style={{ display: broadcast ? '' : 'none' }}>
        {ICONS[platform]}
      </span>
    );
  });
};

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
    width: '100%',
    '& svg': {
      height: '2rem',
    },
  },
  content: {
    display: 'flex',
    'align-items': 'center',
    width: '100%',
    'justify-content': 'space-evenly',
    '& svg': {
      fill: 'white',
      display: 'flex',
      'align-items': 'center',
    },
  },
}));

const LiveStreamMarker = ({ onEvent, platforms, broadcasts }) => {
  const classes = useStyles();

  return (
    <div className={classes.flex}>
      <span className={classes.content}>
        <span className="label">{I18n.t('label:live')}</span>
        <PlatformIcons platforms={platforms} broadcasts={broadcasts} />
      </span>
    </div>
  );
};

LiveStreamMarker.propTypes = {
  onEvent: PropTypes.func.isRequired,
  platforms: PropTypes.array,
  broadcasts: PropTypes.array,
};

LiveStreamMarker.defaultProps = {
  platforms: ['generic'],
  broadcasts: [],
};

export default LiveStreamMarker;
