import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import { StreamHelpers, FeatureDetector, immediate } from 'eyeson';

const canDrawCamera = FeatureDetector.canDrawCamera();

class HandlePresentation extends BaseAction {
  process(state, props) {
    if (
      state.active.hasPresenter ||
      state.canvasPresentation.files.length > 0 ||
      state.mediaPresentation.file !== null
    ) {
      // immediate avoids conflict with PresentationEnded
      immediate(() => props.eyeson.send({ type: 'stop_presenting' }));
      return {
        canvasPresentation: update(state.canvasPresentation, {
          files: { $set: [] },
        }),
        mediaPresentation: update(state.mediaPresentation, {
          file: { $set: null },
        }),
        visible: update(state.visible, { right_sidebar: { $set: false } }),
      };
    }

    return {
      visible: update(state.visible, { dialog: { $set: 'presentation' } }),
    };
  }
}

class StartCanvasCapture extends BaseAction {
  process(state, props) {
    const { message } = this;
    props.eyeson.send({
      type: 'start_mixer',
      audio: state.active.audio,
      video: state.active.video,
      canvas: message.canvas,
      screen: message.screen,
      surface: message.surface,
      onRedraw: message.onRedraw,
      changeStream: message.changeStream,
    });

    return {
      wasVideoActive: state.active.video,
    };
  }
}

class ChangeCameraOverlay extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'update_mixer',
      size: this.message.size,
      position: this.message.position,
    });
  }
}

class PresentationDrop extends BaseAction {
  process(state) {
    const updates = {
      canvasPresentation: update(state.canvasPresentation, {
        files: { $set: this.message.files },
      }),
      visible: update(state.visible, {
        panel: { $set: '' },
        right_sidebar: { $set: true },
      }),
    };
    if (!canDrawCamera) {
      updates.toggleVideoDisabled = true;
    }
    return updates;
  }
}

class PresentationDropMedia extends BaseAction {
  process(state) {
    return {
      mediaPresentation: update(state.mediaPresentation, {
        file: { $set: this.message.files[0] },
      }),
      visible: update(state.visible, {
        right_sidebar: { $set: true },
      }),
    };
  }
}

class PresentationDropError extends BaseAction {
  process(state, props) {
    if (
      state.active.hasPresenter ||
      state.canvasPresentation.files.length > 0 ||
      state.mediaPresentation.file !== null
    ) {
      props.eyeson.send({ type: 'stop_presenting' });
    }
    props.enqueueSnackbar(this.message.message, {
      variant: 'warning',
      autoHideDuration: 5 * 1000,
    });
    return {
      visible: update(state.visible, {
        right_sidebar: { $set: false },
      }),
      canvasPresentation: update(state.canvasPresentation, {
        files: { $set: [] },
      }),
      mediaPresentation: update(state.mediaPresentation, {
        file: { $set: null },
      }),
      toggleVideoDisabled: false,
    };
  }
}

class StartScreenCapture extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'start_screen_capture',
      audio: state.active.audio,
      screen: true,
      surface: 'monitor',
    });

    return {
      wasVideoActive: state.active.video,
    };
  }
}

class ActivateScreenCapture extends BaseAction {
  process(state, props) {
    if (props.hideScreenshare) {
      return;
    }
    if (state.active.hasPresenter) {
      props.enqueueSnackbar(I18n.t('not_presenter'));
      return {};
    }

    return {
      active: update(state.active, { screen_capture: { $set: true } }),
      visible: update(state.visible, {
        dialog: { $set: '' },
        right_sidebar: { $set: true },
      }),
    };
  }
}

class StartPresenting extends BaseAction {
  process(state, props) {
    const updates = {};
    props.eyeson.send({ type: 'start_presenting', present: this.message.name });
    if (this.message.name === 'media') {
      updates.wasVideoActive = state.active.video;
      updates.toggleVideoDisabled = true;
    }
    return updates;
  }
}

class PresentationStarted extends BaseAction {
  process(state, props) {
    if (
      this.message.present === 'screen-monitor' &&
      FeatureDetector.hasPipCamSupport()
    ) {
      let start = false;
      if (state.active.video) {
        props.eyeson.send({ type: 'start_pip_cam' });
        start = true;
      }
      return {
        pipCam: update(state.pipCam, {
          autoStart: { $set: start },
          request: { $set: start },
          available: { $set: true },
        }),
      };
    }
    return {
      pipCam: update(state.pipCam, {
        available: { $set: false },
      }),
    };
  }
}

class PresentationEnded extends BaseAction {
  process(state, props) {
    if (
      StreamHelpers.isScreenPresentationStream(state.localStream) ||
      StreamHelpers.isCanvasStream(state.localStream) ||
      !state.localStream.active
    ) {
      const options = {
        type: 'start_stream',
        audio: state.active.audio,
        video: state.wasVideoActive,
        virtualBackground: state.virtualBackground !== 'off',
      };
      if (state.active.screenAsVideo) {
        options.video = false;
        options.virtualBackground = null;
        options.screen = true;
        options.surface = 'monitor';
      }
      props.eyeson.send(options);
    }

    let pipCamRequest = false;
    if (state.pipCam.active) {
      props.eyeson.send({ type: 'stop_pip_cam' });
      pipCamRequest = true;
    }

    return {
      active: update(state.active, {
        screen_capture: { $set: false },
      }),
      visible: update(state.visible, { right_sidebar: { $set: false } }),
      toggleVideoDisabled: false,
      presentationStream: null,
      canvasPresentation: update(state.canvasPresentation, {
        files: {
          $set: this.message.isPresenter ? state.canvasPresentation.files : [],
        },
      }),
      mediaPresentation: update(state.mediaPresentation, {
        file: {
          $set: this.message.isPresenter ? state.mediaPresentation.file : null,
        },
      }),
      pipCam: update(state.pipCam, {
        request: { $set: pipCamRequest },
        available: { $set: false },
      }),
    };
  }
}

/**
 * End a screen capture session and start the video stream again.
 **/
class HandleCaptureError extends BaseAction {
  process(state, props) {
    if (state.active.hasPresenter) {
      props.eyeson.send({ type: 'stop_presenting' });
    }

    props.enqueueSnackbar(
      I18n.t(this.message.name, `warning: ${this.message.name}`),
      { autoHideDuration: 5 * 1000 }
    );
    return {
      active: update(state.active, { screen_capture: { $set: false } }),
      visible: update(state.visible, { right_sidebar: { $set: false } }),
      toggleVideoDisabled: false,
    };
  }
}

class StartMedia extends BaseAction {
  process(state, props) {
    const { message } = this;
    props.eyeson.send({
      type: 'start_media',
      canvas: message.canvas,
      file: message.file,
      onReady: message.onReady,
      onDuration: message.onDuration,
      onTimeUpdate: message.onTimeUpdate,
      onEnd: message.onEnd,
      performAction: message.performAction,
    });

    return {};
  }
}

class ScreenVideoEnded extends BaseAction {
  process(state, props) {
    props.eyeson.send({
      type: 'start_stream',
      audio: state.active.audio,
      video: state.active.video,
      virtualBackground: state.virtualBackground !== 'off',
    });
    return {
      active: update(state.active, { screenAsVideo: { $set: false } }),
    };
  }
}

class SetPresentingEvent extends BaseAction {
  process(state) {
    return {
      active: update(state.active, {
        isPresenting: { $set: this.message.state },
      }),
    };
  }
}

class SetPresentionStreamEvent extends BaseAction {
  process() {
    return {
      presentationStream: this.message.stream,
    };
  }
}

export {
  StartMedia,
  StartPresenting,
  PresentationDrop,
  ScreenVideoEnded,
  PresentationEnded,
  HandlePresentation,
  HandleCaptureError,
  ActivateScreenCapture,
  StartScreenCapture,
  ChangeCameraOverlay,
  StartCanvasCapture,
  PresentationStarted,
  PresentationDropError,
  PresentationDropMedia,
  SetPresentingEvent,
  SetPresentionStreamEvent,
};
