import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';

const StyledBadge = withStyles((theme) => ({
  badge: {
    top: '75%',
    lineHeight: 1,
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    border: `.1875rem solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900]
    }`,
  },
}))(Badge);

/**
 * Counter Icon displays a icon with counter badge that displays a positive
 * integer value. If the count get's reset or is lower than the current count
 * value the base value is updated.
 **/
const CounterIcon = ({ icon, count, reset, color, isMobile = false }) => {
  const [counter, setCounter] = useState(reset ? 0 : count);

  useEffect(() => {
    setCounter(reset ? count : Math.min(counter, count));
  }, [reset, count, counter]);

  const styles = { fontSize: isMobile ? '1.5rem' : '2rem' };
  if (isMobile) {
    styles.color = 'inherit';
  }
  if (icon === 'gif') {
    styles.transform = 'scale(1.25)';
  }

  return (
    <StyledBadge badgeContent={Math.max(0, count - counter)} color="primary">
      <Icon color={color} style={styles}>
        {icon}
      </Icon>
    </StyledBadge>
  );
};

CounterIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  reset: PropTypes.bool,
  color: PropTypes.string,
};

CounterIcon.defaultProps = { color: 'action', count: 0, reset: false };

export default CounterIcon;
