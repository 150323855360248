import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { ItemTypes } from './LayoutPosition.js';
import LayoutUserDragPreview from './LayoutUserDragPreview.js';

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  };
}

/**
 * Custom Drag Layer
 * Note: has to extend Component
 **/
class CustomDragLayer extends Component {
  renderItem(type, item) {
    if (type === ItemTypes.USER) {
      return (
        <div style={{ transform: 'rotate(-2deg)' }}>
          <LayoutUserDragPreview user={item} />
        </div>
      );
    }
    return null;
  }

  itemStyles() {
    const { currentOffset } = this.props;
    if (!currentOffset) {
      return { display: 'none' };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform: transform,
      WebkitTransform: transform,
    };
  }

  render() {
    const { item, itemType, isDragging } = this.props;
    if (!isDragging) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          zIndex: '100',
          left: 0,
          top: 0,
          opacity: '0.7',
        }}
      >
        <div style={this.itemStyles()}>{this.renderItem(itemType, item)}</div>
      </div>
    );
  }
}

CustomDragLayer.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
};

export default DragLayer(collect)(CustomDragLayer);
