import SDPUtils from 'sdp';
import FeatureDetector from './FeatureDetector.js';

const EOL = '\r\n';

/**
 * Add session attribute to disable sfu mode from the client.
 **/
// eslint-disable-next-line max-statements
const addSessionAttributes = sdp => {
  // double check config.allowSafariSFU on runtime
  const splitSDP = sdp.split(EOL);
  const tLineIdx = splitSDP.findIndex(line => line.startsWith('t='));
  let addIndex = 1;
  if (FeatureDetector.canSFU()) {
    // insert sfuLine after tLine (timing), delete 0 elements
    splitSDP.splice(tLineIdx + addIndex, 0, 'a=sfu-capable');
    addIndex++;
    if (FeatureDetector.disallowAudioSyncSrcChange()) {
      splitSDP.splice(tLineIdx + addIndex, 0, 'a=disallow-audio-ssrc-change');
      addIndex++;
    }
  }
  if (FeatureDetector.canDataChannel()) {
    splitSDP.splice(
      tLineIdx + addIndex,
      0,
      'a=eyeson-datachan-capable',
      'a=eyeson-datachan-keepalive',
      'a=eyeson-sepp-messaging'
    );
  }
  return splitSDP.join(EOL);
};

// eslint-disable-next-line max-statements
const recvConstraints = (
  sdp,
  { receiveConstraints: { audio = {}, video = {} } }
) => {
  const { isInteger } = window.Number;
  if (!isInteger(audio.maxBitrate) && !isInteger(video.maxBitrate)) {
    return sdp;
  }
  const audioConstraints = [];
  const videoConstraints = [];
  // maxBitrate from bps to kbps
  if (isInteger(audio.maxBitrate)) {
    audioConstraints.push('max-bitrate=' + Math.floor(audio.maxBitrate / 1000));
  }
  if (isInteger(video.maxBitrate)) {
    videoConstraints.push('max-bitrate=' + Math.floor(video.maxBitrate / 1000));
  }
  const splitSDP = sdp.split(EOL);
  if (audioConstraints.length > 0) {
    let index = splitSDP.findIndex(line => line.startsWith('m=audio')) + 1;
    while (
      splitSDP[index].startsWith('c=') ||
      splitSDP[index].startsWith('i=')
    ) {
      index++;
    }
    splitSDP.splice(
      index,
      0,
      `a=eyeson-recv-constraints:${audioConstraints.join(' ')}`
    );
  }
  if (videoConstraints.length > 0) {
    let index = splitSDP.findIndex(line => line.startsWith('m=video')) + 1;
    while (
      splitSDP[index].startsWith('c=') ||
      splitSDP[index].startsWith('i=')
    ) {
      index++;
    }
    splitSDP.splice(
      index,
      0,
      `a=eyeson-recv-constraints:${videoConstraints.join(' ')}`
    );
  }
  return splitSDP.join(EOL);
};

// eslint-disable-next-line max-statements
const addStereo = sdp => {
  const stereo = FeatureDetector.canStereo();
  const codecMatch = sdp.match(/^a=rtpmap:(\d+) opus/m);
  if (codecMatch) {
    const fmtpRegExp = new RegExp(`^a=fmtp:${codecMatch[1]} (.+)$`, 'm');
    const fmtpMatch = sdp.match(fmtpRegExp);
    if (fmtpMatch) {
      const options = {};
      fmtpMatch[1].split(';').forEach(entry => {
        const [key, value] = entry.split('=');
        options[key] = value;
      });
      options['stereo'] = stereo ? '1' : '0';
      // options['sprop-stereo'] = '1';
      const result = Object.entries(options)
        .map(([key, value]) => `${key}=${value}`)
        .join(';');
      return sdp.replace(fmtpRegExp, `a=fmtp:${codecMatch[1]} ${result}`);
    }
  }
  return sdp;
};

const sendOnly = sdp => {
  const lines = sdp.split(EOL);
  let doIt = false;
  lines.forEach((line, row) => {
    if (line.startsWith('m=audio') || line.startsWith('m=video')) {
      doIt = true;
    } else if (line.startsWith('m=application')) {
      doIt = false;
    } else if (line === 'a=sendrecv' && doIt) {
      lines[row] = 'a=sendonly';
    }
  });
  return lines.join(EOL);
};

const writeFmtpLine = ({ parameters }) => {
  return Object.entries(parameters)
    .map(([key, value]) => {
      return value ? `${key}=${value}` : key;
    })
    .join(';');
};

const getDiffLines = (section, serialized) => {
  const sectionLines = SDPUtils.splitLines(section);
  const serializedLines = SDPUtils.splitLines(serialized);
  return sectionLines
    .filter(
      line => !serializedLines.includes(line) && !line.startsWith('a=rtpmap:')
    )
    .join(EOL);
};

const selectCodecs = (sdp, { codecSelection }) => {
  const sections = SDPUtils.splitSections(sdp);
  return (
    sections
      // eslint-disable-next-line max-statements
      .map(section => {
        const kind = SDPUtils.getKind(section);
        const filterList = codecSelection[kind];
        if (Array.isArray(filterList) && filterList.length > 0) {
          const parsed = SDPUtils.parseRtpParameters(section);
          let serialized = SDPUtils.writeRtpDescription(kind, parsed);
          const filtered = [];
          filterList.forEach(filter => {
            const codec = parsed.codecs.find(parsedCodec => {
              const sdpFmtpLine = writeFmtpLine(parsedCodec);
              const mimeType = `${kind}/${parsedCodec.name}`;
              const sameMime = filter.mimeType === mimeType;
              if (filter.sdpFmtpLine) {
                return sameMime && filter.sdpFmtpLine === sdpFmtpLine;
              }
              return sameMime;
            });
            if (codec) {
              filtered.push(codec);
            }
          });
          if (filtered.length === 0) {
            return section;
          }
          parsed.codecs = filtered;
          const diff = getDiffLines(section, serialized);
          serialized = SDPUtils.writeRtpDescription(kind, parsed);
          return serialized + diff + EOL;
        }
        return section;
      })
      .join('')
  );
};

const getModifiers = options => {
  const modifiers = {
    active: [addStereo],
    passive: [addSessionAttributes]
  };
  if (options.sendOnly === true) {
    modifiers.active.push(sendOnly);
  }
  if (options.receiveConstraints) {
    modifiers.passive.push(recvConstraints);
  }
  if (options.codecSelection && FeatureDetector.canSetCodecsLegacy()) {
    modifiers.active.push(selectCodecs);
  }
  return modifiers;
};

export { getModifiers };
