import React, { useRef, useState } from 'react';
import I18n from 'i18n-js';
import IconButton from '../../generic/IconButton.js';
import MarkerIcon from '@material-ui/icons/BorderColor';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/icons/InvertColors';

const COLORS = ['red', 'orange', 'yellow', 'green', 'blue'];

const useStyles = makeStyles((theme) => ({
  marker: {
    position: 'relative',
  },
  picker: {
    display: 'flex',
    padding: theme.spacing(1),
    background: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '0.2rem 0.2rem 0 0',
  },
  mouseZone: {
    top: '-115%',
    left: '-195%',
    position: 'absolute',
    padding: theme.spacing(0, 0, 3, 0),
  },
  color: {
    color: theme.palette.grey[200],
    minWidth: '1rem',
    minHeight: '1rem',
    margin: theme.spacing(0, 0.6, 0, 0.6),
    border: (props) => `2px solid ${props.name}`,
    fontColor: (props) => props.name,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  icon: {
    color: (props) => (props.isActive ? props.color : theme.palette.grey[600]),
  },
}));

const Color = ({ name = 'red', onClick }) => {
  const classes = useStyles({ name });
  return (
    <IconButton
      size="small"
      onClick={() => onClick(name)}
      className={classes.color}
      aria-label={I18n.t('aria:label:color:' + name, { defaultValue: name })}
    >
      <Icon />
    </IconButton>
  );
};

const ColorPicker = ({ color, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.mouseZone}>
      <div className={classes.picker}>
        {COLORS.map((c) => (
          <Color key={c} name={c} active={color === c} onClick={onClick} />
        ))}
      </div>
    </div>
  );
};

const Marker = ({ isActive, onClick, color, onColorClick, disabled }) => {
  const [hover, setHover] = useState(false);
  const classes = useStyles({ isActive, color });
  const markerEl = useRef();
  let blurTimer = null;

  const handleColorClick = (color) => {
    onColorClick(color);
    if (markerEl.current) {
      markerEl.current.focus();
    }
    setHover(false);
  };

  const handleFocus = () => {
    if (!disabled) {
      clearTimeout(blurTimer);
      setHover(true);
    }
  };

  const handleBlur = () => {
    if (!disabled) {
      clearTimeout(blurTimer);
      blurTimer = setTimeout(() => {
        setHover(false);
      }, 100);
    }
  };

  return (
    <div
      className={classes.marker}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => !disabled && setHover(false)}
      onTouchStart={() => !disabled && setHover(true)}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <IconButton
        ref={markerEl}
        onClick={onClick}
        disabled={disabled}
        aria-label={
          I18n.t('aria:label:color_picker', {
            defaultValue: 'Annotation color picker',
          }) +
          (isActive
            ? ' ' + I18n.t('aria:label:color:' + color, { defaultValue: color })
            : '')
        }
        aria-pressed={isActive}
      >
        <MarkerIcon className={classes.icon} />
      </IconButton>
      {hover && <ColorPicker color={color} onClick={handleColorClick} />}
    </div>
  );
};

export default Marker;
