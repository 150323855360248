import update from 'immutability-helper';
import BaseAction from './BaseActions.js';

/**
 * Handle Inactive.
 **/
class HandleInactive extends BaseAction {
  process(state, props) {
    if (state.active.screen_capture) {
      this.message.activityMonitor.reset();
      return {};
    }

    return {
      visible: update(state.visible, {
        dialog: { $set: 'inactivity' },
      }),
    };
  }
}

/**
 * Reset Active.
 **/
class HandleActive extends BaseAction {
  process(state, props) {
    this.message.activityMonitor.reset();
    return {
      visible: update(state.visible, {
        dialog: { $set: '' },
      }),
    };
  }
}

export { HandleInactive, HandleActive };
