/* eslint-disable max-lines */
import config from './config.js';
import Logger from './Logger.js';
import adapter from 'webrtc-adapter';
import platform from 'platform';
import FullscreenHelper from './FullscreenHelper.js';
import { isObject } from './utils/utils.js';

let _canvasBlurSupport = null;

/**
 * Feature Detector.
 **/
export default {
  environment: function () {
    return {
      canMix: this.canMix(),
      canPip: this.hasPipSupport(),
      canSFU: this.canSFU(),
      isPhone: this.isPhone(),
      inIframe: this.inIframe(),
      isIOSDevice: this.isIOSDevice(),
      canUseEyeson: this.canUseEyeson(),
      canFullscreen: this.canFullscreen(),
      hasMobileDevice: this.hasMobileDevice(),
      canToggleCamera: this.canToggleCamera(),
      canPresentFiles: this.canPresentFiles(),
      canScreenCapture: this.canScreenCapture(),
      canAdjustSettings: this.canAdjustDeviceSettings(),
      canStreamLocalMedia: this.canStreamLocalMedia(),
      isExperimentalBrowser: this.isExperimentalBrowser()
    };
  },

  platform: () => platform,

  /**
   * Test if browser should be able to use eyeson.
   * canDataChannel includes hasPeerConnection check
   **/
  canUseEyeson: function () {
    return (
      !this.isWebView() &&
      this.hasGetUserMedia() &&
      this.canDataChannel() &&
      this.isMinimumRequiredBrowser()
    );
  },

  hasGetUserMedia: function () {
    return (
      'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
    );
  },

  hasGetDisplayMedia: function () {
    return (
      'mediaDevices' in navigator && 'getDisplayMedia' in navigator.mediaDevices
    );
  },

  hasPeerConnection: function () {
    return 'RTCPeerConnection' in window;
  },

  hasCaptureStream: function () {
    return (
      'captureStream' in HTMLMediaElement.prototype ||
      'mozCaptureStream' in HTMLMediaElement.prototype
    );
  },

  hasCanvasCaptureSupport: function () {
    return (
      'CanvasCaptureMediaStream' in window ||
      'CanvasCaptureMediaStreamTrack' in window
    );
  },

  hasSenders: function () {
    return 'RTCRtpSender' in window;
  },

  /**
   * 07 Aug 2019 - Safari doesn't seem to fire the event properly.
   **/
  hasTrackOnUnmute: function () {
    if (
      this.isSafari() &&
      this.browserVersion() < 13 &&
      !this.isWorkingIOSBrowser()
    ) {
      return false;
    }
    return 'onunmute' in MediaStreamTrack.prototype;
  },

  /**
   * https://caniuse.com/#feat=webp
   **/
  hasWebpSupport: function () {
    if (this.isChrome()) {
      return adapter.browserDetails.version >= 70;
    }
    if (this.isFF()) {
      return adapter.browserDetails.version >= 65;
    }

    return false;
  },
  /**
   * Test if user agent supports changing of audio output.
   **/
  canChangeAudioOutput: function () {
    if (!document) {
      return true;
    }
    return 'setSinkId' in HTMLMediaElement.prototype;
  },

  /**
   * Test if user agent supports changing the microphone.
   * Safari bug: https://bugs.webkit.org/show_bug.cgi?id=175975 is
   * fixed since v12
   **/
  canChangeMicrophone: function () {
    let isOldSafari = false;
    if (adapter.browserDetails.browser === 'safari') {
      isOldSafari = this.browserVersion() < 12 && !this.isWorkingIOSBrowser();
    }
    return !this.isTestSuite() && !isOldSafari;
  },

  /**
   * Test if user agent supports different microphones on multiple simultanious
   * getUserMedia streams.
   * Firefox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1238038 (fixed since v101)
   **/
  canMultipleDifferentMicrophones: function () {
    return !(this.isFF() && this.browserVersion() < 101);
  },

  /**
   * Test if user agent supports remembering devices.
   * Safari bug: https://bugs.webkit.org/show_bug.cgi?id=175975 is
   * fixed since v12
   **/
  canChangeCamera: function () {
    let isOldSafari = false;
    if (adapter.browserDetails.browser === 'safari') {
      isOldSafari = this.browserVersion() < 12 && !this.isWorkingIOSBrowser();
    }
    return !this.isTestSuite() && !isOldSafari;
  },

  /**
   * Test if user agent supports screen capture feature.
   * on mobile hasGetDisplayMedia() is true, but still doesn't work:
   * https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getDisplayMedia#Browser_compatibility
   * and https://caniuse.com/#feat=mdn-api_mediadevices_getdisplaymedia
   **/
  canScreenCapture: function () {
    if (
      !config.screencapture ||
      this.isTestSuite() ||
      !this.canUseEyeson() ||
      this.hasMobileDevice()
    ) {
      return false;
    }
    return this.hasGetDisplayMedia() || (this.isFF() && this.hasGetUserMedia());
  },

  canPresentFiles: function () {
    if (
      this.isTestSuite() ||
      !this.canUseEyeson() ||
      (this.isFF() && adapter.browserDetails.version <= 60) ||
      this.isIPhone() ||
      (this.isIOSDevice() &&
        this.browserVersion() < 13 &&
        !this.isWorkingIOSBrowser())
    ) {
      return false;
    }
    return true;
  },

  canStreamLocalMedia: function () {
    const audioContext = window.AudioContext || window.webkitAudioContext;
    if (typeof audioContext !== 'function') {
      return false;
    }
    const proto = audioContext.prototype;
    const acMethods = [
      'createGain',
      'createMediaStreamSource',
      'createMediaElementSource',
      'createMediaStreamDestination'
    ].every(method => typeof proto[method] === 'function');
    return acMethods && this.hasCanvasCaptureSupport();
  },

  /**
   * Unfortunately we had issues with higher resolution constraints on
   * Safari (iOS and Mac).
   **/
  requiresLowerConstraints: function () {
    return (
      this.isSafari() &&
      this.browserVersion() < 13 &&
      !this.isWorkingIOSBrowser()
    );
  },

  browserName: function () {
    let name = adapter.browserDetails.browser.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return name;
  },

  browserVersion: function () {
    if (adapter.browserDetails.browser === 'safari') {
      return adapter.extractVersion(navigator.userAgent, /Version\/(\d+)\./, 1);
    }
    return adapter.browserDetails.version;
  },

  isEdge: function () {
    return adapter.browserDetails.browser === 'edge';
  },

  isFF: function () {
    return adapter.browserDetails.browser === 'firefox';
  },

  isChrome: function () {
    return adapter.browserDetails.browser === 'chrome';
  },

  isSafari: function () {
    return adapter.browserDetails.browser === 'safari';
  },

  isMinimumRequiredBrowser: function () {
    if (this.isChrome()) {
      return this.isMinimumRequiredChrome();
    }
    if (this.isFF()) {
      return this.isMinimumRequiredFF();
    }
    if (this.isSafari()) {
      return this.isMinimumRequiredSafari();
    }
    return false;
  },

  /**
   * 2021-01-18 Edge is not supported anymore due to PeerConnection
   * and other issues
   **/
  isMinimumRequiredEdge: function () {
    return false;
  },

  /**
   * https://en.wikipedia.org/wiki/Firefox_version_history
   * A customer is on WinXP (hello 2019) and FF seems
   * to be locked at 52 with an extended support version on that OS.
   * May god have mercy on those poor souls.
   **/
  isMinimumRequiredFF: function () {
    if (!this.isFF()) {
      return false;
    }
    return adapter.browserDetails.version >= 52;
  },

  /**
   * https://en.wikipedia.org/wiki/Google_Chrome_version_history
   **/
  isMinimumRequiredChrome: function () {
    if (!this.isChrome()) {
      return false;
    }
    return adapter.browserDetails.version >= 70;
  },

  isMinimumRequiredSafari: function () {
    if (!this.isSafari()) {
      return false;
    }
    return this.browserVersion() >= 10 || this.isWorkingIOSBrowser();
  },

  isWorkingIOSBrowser: function () {
    // EdgiOS, FxiOS, CriOS
    const iOSBrowser = / [a-zA-Z]+iOS\//.test(navigator.userAgent);
    const operaTouch = navigator.userAgent.indexOf(' OPT/') > -1;
    return (iOSBrowser || operaTouch) && this.hasGetUserMedia();
  },

  isExperimentalBrowser: function () {
    return ['safari', 'edge'].includes(adapter.browserDetails.browser);
  },

  /**
   * Unfortunately Safari on iOS pauses the local camera during PIP,
   * and afterwards the remote video is frozen.
   **/
  hasPipSupport: function () {
    if (!document) {
      return true;
    }

    return Boolean(
      'webkitSupportsPresentationMode' in HTMLVideoElement.prototype ||
        ('pictureInPictureEnabled' in document &&
          document.pictureInPictureEnabled)
    );
  },

  /**
   * Detect non-working pip in iOS webview
   **/
  iOSPipCheck: function () {
    return new Promise(resolve => {
      const support = this.hasPipSupport();
      if (!support || !this.isIOSDevice()) {
        resolve(support);
        return;
      }
      const video = document.createElement('video');
      video.preload = 'none';
      video.src = 'about:blank';
      video.onsuspend = () => {
        const wkEnabled =
          video.webkitSupportsPresentationMode('picture-in-picture');
        resolve(wkEnabled);
      };
    });
  },

  /**
   * Check user agent if it is an Android device.
   **/
  isAndroidDevice: function () {
    return Boolean(navigator.userAgent.match(/Android/i));
  },

  /**
   * Check user agent if it is an iOS device.
   * This check includes detection of "Desktop mode" on mobile devices
   **/
  isIOSDevice: function () {
    // Resource: https://stackoverflow.com/a/58064481
    return (
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
      !window.MSStream
    );
  },

  /**
   * Check user agent if it is an iPhone.
   * This check includes detection of "Desktop mode" on iPhone.
   * To distinguish between iPad and iPhone in "Desktio mode",
   * we have to additionally check for aspect ratio
   **/
  isIPhone: function () {
    // Resource: https://stackoverflow.com/a/58064481
    return (
      (/iPhone/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' &&
          navigator.maxTouchPoints > 1 &&
          window.screen.height / window.screen.width >= 1.5)) &&
      !window.MSStream
    );
  },

  /**
   * As a solution for mobile sites, our Android engineers recommend to
   * specifically detect “mobile” in the User-Agent string as well as
   * “android.”
   *
   * https://webmasters.googleblog.com/2011/03/mo-better-to-also-detect-mobile-user.html
   **/
  isAndroidPhone: function () {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isMobile = /(mobile)/i.test(navigator.userAgent);
    return isAndroid && isMobile;
  },

  isMacOS: function () {
    if (typeof navigator.userAgentData !== 'undefined') {
      return navigator.userAgentData.platform === 'macOS';
    }
    return navigator.platform.indexOf('Mac') === 0;
  },

  /**
   * Is the app running inside an iFrame.
   * https://stackoverflow.com/a/326076/980524
   **/
  inIframe: function () {
    try {
      return window.self !== window.top;
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      return true;
    }
  },

  /**
   * Phone vs. tablet
   **/
  isPhone: function () {
    return this.isIPhone() || this.isAndroidPhone();
  },

  /**
   * ...
   **/
  hasMobileDevice: function () {
    return this.isIOSDevice() || this.isAndroidDevice();
  },

  /**
   * Is the app running on a touch device.
   * https://stackoverflow.com/a/4819886/980524
   * Note: touch device, might be a laptop with a touch display or those
   * dell tablets we have laying around here.
   **/
  isTouchDevice: function () {
    return Boolean('ontouchstart' in window || navigator.maxTouchPoints);
  },

  /**
   * iOS doesn't propagate tap events to react when we have display tooltips.
   * This results in annoying "double taps" from the user.
   * But the tooltips aren't designed for touch devices anyway.
   **/
  canDisplayTooltips: function () {
    return !this.isIOSDevice() && !this.isTestSuite();
  },

  /**
   * iOS doesn't handle stream switching properly, the remote stream freezes
   * sometimes when making changes.
   **/
  canAdjustDeviceSettings: function () {
    return !this.isIOSDevice();
  },

  /**
   * iOS doesn't handle stream switching properly, the remote stream freezes
   * sometimes when making changes.
   **/
  canToggleCamera: function () {
    return true;
  },

  /**
   * iPad file presentation has issues add cam to canvas
   **/
  canDrawCamera: function () {
    return !this.isIOSDevice();
  },

  canMix: function () {
    return (
      this.isChrome() &&
      this.canScreenCapture() &&
      this.hasCanvasCaptureSupport()
    );
  },

  /**
   * Browsers don't behave the same way when setting the .enabled property of
   * a MediaSTreamTrack to false.
   * In Chrome (as of May 2019) the activity indicator stays active even if
   * the track is disabled (enabled: false).
   * https://bugs.chromium.org/p/webrtc/issues/detail?id=5942
   * While in Firefox the indicator is turned off.
   */
  stopsDeviceActivityIndicatorOnDisable: function () {
    if (this.isFF()) {
      return true;
    }
    return false;
  },

  /**
   * Edge doesn't have this property and raises an error when trying to
   * access it.
   **/
  supportsBlurOnSVG: function () {
    return (
      typeof SVGElement !== 'undefined' &&
      typeof SVGElement.prototype.blur !== 'undefined'
    );
  },

  /**
   * Chrome will throw a SecurityException when trying to read the
   * `localStorage` property.
   **/
  hasLocalStorage: function () {
    try {
      const hasLocalStorage = isObject(window.localStorage);
      window.localStorage.setItem('eyeson.test', 'test');
      const canGet = window.localStorage.getItem('eyeson.test') === 'test';
      return hasLocalStorage && canGet;
    } catch (error) {
      Logger.debug('FeatureDetector::hasLocalStorage:', error.message);
      return false;
    }
  },

  canFullscreen: function () {
    return new FullscreenHelper().canFullscreen();
  },

  isFullscreen: function () {
    return new FullscreenHelper().isFullscreen();
  },

  /**
   * Check for SFU-mode support
   * VP8 codec required, in Safari available since v12.1
   **/
  canSFU: function () {
    let safariWithoutVP8 = false;
    if (this.isSafari()) {
      if (!config.allowSafariSFU) {
        return false;
      }
      if (this.isWorkingIOSBrowser()) {
        return true;
      }
      const uAVersion = navigator.userAgent.match(/Version\/(\d+).(\d+)/);
      if (uAVersion && uAVersion.length > 1) {
        safariWithoutVP8 =
          Number(uAVersion[1]) < 12 ||
          (Number(uAVersion[1]) === 12 && Number(uAVersion[2]) < 1);
      }
    }
    return !safariWithoutVP8 && !this.isTestSuite();
  },

  canDataChannel: function () {
    return (
      this.hasPeerConnection() &&
      typeof RTCPeerConnection.prototype.createDataChannel === 'function' &&
      'RTCDataChannel' in window
    );
  },

  /**
   * SDP SSRC updates for SFU mode switch
   * Safari has a bug of broken mediastream when all ssrc's change
   **/
  disallowAudioSyncSrcChange: function () {
    return this.isSafari() && this.canSFU();
  },

  canShare: function () {
    return Boolean(navigator.share);
  },

  /**
   * https://developer.chrome.com/multidevice/user-agent#webview_user_agent
   *
   * If you’re attempting to differentiate between the WebView and Chrome for
   * Android, you should look for the presence of the Version/_X.X_ string in
   * the WebView user-agent string.
   *
   * In the newer versions of WebView, you can differentiate the WebView by
   * looking for the wv field as highlighted below
   **/
  isWebView: function () {
    return /Version\/|wv/i.test(navigator.userAgent) && this.isAndroidDevice();
  },

  /* eslint-disable no-process-env */
  /* global process */
  isTestSuite: function () {
    return (
      process.env.NODE_ENV === 'test' ||
      adapter.browserDetails.browser === 'test-suite'
    );
  },
  /* eslint-enable no-process-env */

  /**
   * mobile Safari is not able to display canvas stream in video
   * https://stackoverflow.com/a/63948310
   */
  canVirtualBackground: function () {
    return (
      !this.isIOSDevice() &&
      Boolean(window.WebAssembly) &&
      typeof WebAssembly.validate === 'function' &&
      (typeof WebAssembly.instantiateStreaming === 'function' ||
        typeof WebAssembly.instantiate === 'function') &&
      Boolean(window.CanvasRenderingContext2D) &&
      typeof window.ImageData === 'function' &&
      typeof window.requestAnimationFrame === 'function'
    );
  },

  // eslint-disable-next-line max-statements
  canvasBlurSupport: function () {
    if (_canvasBlurSupport !== null) {
      return _canvasBlurSupport;
    }
    const filterSupport =
      window.CanvasRenderingContext2D &&
      'filter' in CanvasRenderingContext2D.prototype;
    if (!filterSupport) {
      _canvasBlurSupport = false;
      return _canvasBlurSupport;
    }
    let canvas1 = document.createElement('canvas');
    let canvas2 = document.createElement('canvas');
    canvas1.width = 10;
    canvas1.height = 10;
    canvas2.width = 10;
    canvas2.height = 10;
    let ctx1 = canvas1.getContext('2d');
    let ctx2 = canvas2.getContext('2d');
    ctx1.filter = 'blur(5px)';
    ctx1.fillStyle = '#000000';
    ctx1.fillRect(3, 3, 4, 4);
    ctx2.fillStyle = '#000000';
    ctx2.fillRect(3, 3, 4, 4);
    let data1 = ctx1.getImageData(5, 5, 1, 1);
    let data2 = ctx2.getImageData(5, 5, 1, 1);
    _canvasBlurSupport = data1.data[3] !== data2.data[3];
    return _canvasBlurSupport;
  },

  /**
   * We've noticed many issues with Safari and stereo on desktop and mobile.
   * Even weired ones like https://developer.apple.com/forums/thread/672037.
   * On Android issues occur when output device is changed, especially buetooth
   * devices are difficult.
   */
  canStereo: function () {
    return !(this.isSafari() || this.isAndroidDevice());
  },

  // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackSettings/displaySurface
  canChooseDisplaySurface: function () {
    return (
      this.hasGetDisplayMedia() &&
      navigator.mediaDevices.getSupportedConstraints().displaySurface
    );
  },

  hasPipCamSupport: function () {
    return this.isChrome() && this.hasPipSupport();
  },

  canMonitorSystemPressure: function () {
    const hasPermission =
      typeof document.featurePolicy === 'object' &&
      typeof document.featurePolicy.allowedFeatures === 'function'
        ? document.featurePolicy.allowedFeatures().includes('compute-pressure')
        : true;
    return (
      typeof window.PressureObserver === 'function' &&
      // eslint-disable-next-line no-undef
      Array.isArray(PressureObserver.knownSources) &&
      // eslint-disable-next-line no-undef
      PressureObserver.knownSources.includes('cpu') &&
      hasPermission
    );
  },

  canSetCodecs: function () {
    return this.canSetCodecPreferences() || this.canSetCodecsLegacy();
  },

  canSetCodecPreferences: function () {
    return (
      window.RTCRtpTransceiver &&
      // eslint-disable-next-line no-undef
      typeof RTCRtpTransceiver.prototype.setCodecPreferences === 'function'
    );
  },

  canSetCodecsLegacy: function () {
    const supportsAddTransceiver =
      window.RTCPeerConnection &&
      'addTransceiver' in RTCPeerConnection.prototype;
    return !this.canSetCodecPreferences() && supportsAddTransceiver;
  },

  canGeolocation: function () {
    return (
      Reflect.has(window, 'navigator') &&
      Reflect.has(navigator, 'geolocation') &&
      typeof navigator.geolocation.getCurrentPosition === 'function'
    );
  }
};
/* eslint-enable max-lines */
