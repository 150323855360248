const matchMediaSupported = !!window.matchMedia;

// addListener is marked as deprecated
// use addEventListener change event instead and provide addListener fallback
// https://stackoverflow.com/a/56466334
function matchMedia(mediaQuery) {
  if (!matchMediaSupported) {
    return { matches: false, addEventListener: () => {} };
  }
  let result = window.matchMedia(mediaQuery);
  if (typeof result.addEventListener === 'function') {
    return result;
  }
  result.addEventListener = (type, listener) => result.addListener(listener);
  result.removeEventListener = (type, listener) =>
    result.removeListener(listener);
  return result;
}

export { matchMedia };
