const supportsRandomUUID =
  typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function';

const uuid = () => {
  if (supportsRandomUUID) {
    return crypto.randomUUID();
  }
  let index = 0;
  const bytes = crypto.getRandomValues(new Uint8Array(31));
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, char => {
    const byte = bytes[index];
    index += 1;
    // eslint-disable-next-line no-bitwise
    return (char ^ (byte & (15 >> (char / 4)))).toString(16);
  });
};

export default uuid;
