import I18n from 'i18n-js';

let _customImageCount = 1;

const _types = [
  {
    name: 'Disabled',
    translate: () => I18n.t('label:disabled'),
    type: 'off',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Soft blur',
    translate: () => I18n.t('label:vbg:blur_soft'),
    type: 'blur:8',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Strong blur',
    translate: () => I18n.t('label:vbg:blur_strong'),
    type: 'blur:16',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Gradient',
    translate: () => I18n.t('label:vbg:gradient'),
    type: 'image:images/background/gradient.jpg',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Meeting image',
    translate: () => I18n.t('label:vbg:image_meeting'),
    type: 'image:images/background/meeting.jpg',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Eyeson marked wall 1',
    translate: () => I18n.t('label:vbg:image_eyeson_wall_1'),
    type: 'image:images/background/eyeson-mark-wall.jpg',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Eyeson marked wall 2',
    translate: () => I18n.t('label:vbg:image_eyeson_wall_2'),
    type: 'image:images/background/eyeson-wall-1.jpg',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Eyeson marked wall 3',
    translate: () => I18n.t('label:vbg:image_eyeson_wall_3'),
    type: 'image:images/background/eyeson-wall-2.jpg',
    enabled: true,
    hidden: false,
    custom: false,
  },
  {
    name: 'Custom image',
    translate: () => I18n.t('label:vbg:local_image'),
    type: 'image:blob',
    enabled: true,
    hidden: true,
    custom: true,
  },
];

const addImage = (urls, names) => {
  const types = _types.map((entry) => entry.type);
  const namesArray = names ? names.split(',') : [];
  urls.split(',').forEach((url, index) => {
    const type = 'image:' + url.trim();
    if (!types.includes(type)) {
      const hasName =
        namesArray.length > index && namesArray[index].trim() !== '';
      const name = hasName
        ? namesArray[index].trim()
        : 'Custom image ' + _customImageCount;
      _types.push({
        name,
        translate: hasName
          ? ((name) => name).bind(null, name)
          : ((id) => {
              return I18n.t('label:vbg:custom_image', { id });
            }).bind(null, _customImageCount),
        type,
        enabled: true,
        hidden: false,
        custom: true,
      });
      if (!hasName) {
        _customImageCount++;
      }
    }
  });
  return _types;
};

const allowOnlyCustomTypes = (allowed) => {
  _types.forEach((entry) => {
    if (entry.type === 'image:blob' || entry.type === 'off') {
      return;
    }
    entry.hidden = entry.custom === !allowed ? allowed : !allowed;
  });
};

const setTypeAvailable = (options) => {
  _types.forEach((entry) => {
    if (typeof options[entry.type] === 'boolean') {
      entry.hidden = !options[entry.type];
    }
  });
};

const isTypeAvailable = (type) => {
  return _types
    .filter((entry) => entry.type === type)
    .every((entry) => entry.hidden === false);
};

const isExistingType = (type) => {
  return _types.some((entry) => entry.type === type);
};

const translate = () => {
  _types.forEach((entry) => {
    entry.name = entry.translate();
  });
};

const getVirtualBackgroundTypes = () => {
  return _types;
};

const VirtualBackgroundTypes = {
  translate,
  getVirtualBackgroundTypes,
  isExistingType,
  addImage,
  setTypeAvailable,
  isTypeAvailable,
  allowOnlyCustomTypes,
};

export default VirtualBackgroundTypes;
