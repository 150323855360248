import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import { FeatureDetector } from 'eyeson';
import AlternativeBrowserAlert from './AlternativeBrowserAlert.js';

const avoidAlertOnStableSafari =
  FeatureDetector.isIOSDevice() &&
  (FeatureDetector.browserVersion() >= 14 ||
    FeatureDetector.isWorkingIOSBrowser());

const ExperimentalBrowserAlert = ({ visible }) => {
  if (!visible || avoidAlertOnStableSafari) {
    return null;
  }

  return (
    <AlternativeBrowserAlert
      message={I18n.t('message:browser_warn', {
        browser: FeatureDetector.browserName(),
      })}
    />
  );
};

ExperimentalBrowserAlert.propTypes = {
  visible: PropTypes.bool,
};

ExperimentalBrowserAlert.defaultProps = {
  visible: true,
};

export default ExperimentalBrowserAlert;
