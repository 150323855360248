/**
 * This is where we keep the list of sip events we're interested in forwarding.
 **/
export default [
  'chat',
  'recording',
  'memberlist',
  'source_update',
  'voice_activity'
];
