import React from 'react';
import GiphyAttribution from '../../../assets/giphy.png';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    filter: theme.palette.type === 'light' ? '' : 'invert(1)',
  },
}));

/**
 * GiphyLogo for Giphy Dialog
 **/
const GiphyLogo = () => {
  const classes = useStyles();

  return (
    <img
      id="giphy-logo"
      alt="powered by giphy"
      src={GiphyAttribution}
      className={classes.root}
    />
  );
};

export default GiphyLogo;
