import SEPP from './sepp/SEPP.js';
import Logger from './Logger.js';
import WHITELIST from './SigMessageWhitelist.js';
import SigMessageTransformer from './SigMessageTransformer.js';
import SessionDescriptionHandler from './SessionDescriptionHandler.js';

const maxMessageSize = 32 * 1024;

/**
 * eyeson Sip Connection.
 *
 * Registers with provided sip credentials and handles events received.
 **/
class SigConnection {
  constructor(signaling, user) {
    this.listeners = [];
    this.messageListener = [];
    this.datachannel = null;
    this.uaOptions = this.initUserAgentOptions(signaling, user);
    this.clientId = this.uaOptions.client_id;
    this.userAgent = null;
    this.transformer = new SigMessageTransformer(this.clientId);
    this.seppMessaging = false;
  }

  initUserAgentOptions(signaling, user) {
    if (signaling.type !== 'sepp') {
      return {
        client_id: null
      };
    }
    const signalingOptions = signaling.options;
    const uaOptions = {
      client_id: signalingOptions.client_id,
      conf_id: signalingOptions.conf_id,
      client_name: user.name,
      mute_video: false,
      sessionDescriptionHandlerFactory: options => {
        return new SessionDescriptionHandler(options);
      },
      sessionDescriptionHandlerFactoryOptions: {
        stun_servers: signalingOptions.stun_servers,
        turn_servers: signalingOptions.turn_servers
      },
      transportOptions: {
        auth_token: signalingOptions.auth_token,
        endpoint: signalingOptions.endpoint
      }
    };
    return uaOptions;
  }

  /**
   * Connect and register to the configured WebSocket server.
   **/
  start() {
    return new Promise(resolve => {
      if (!this.uaOptions.client_id) {
        throw new TypeError('Invalid signaling data');
      }
      this.userAgent = new SEPP.UserAgent(this.uaOptions);
      resolve();
    });
  }

  /**
   * Start a session on current connection.
   **/
  startSession(options) {
    Object.assign(
      this.uaOptions.sessionDescriptionHandlerFactoryOptions,
      options
    );
    if (options.mediaOptions) {
      this.uaOptions.mute_video = !options.mediaOptions.video;
    }
    this.userAgent.onEvent(({ type, data }) => {
      if (type === 'registered') {
        // eslint-disable-next-line prefer-reflect
        this.userAgent.call();
      } else if (type === 'message') {
        this.handleMessage(data);
      }
    });
    this.userAgent.connect();
    return this.userAgent;
  }

  updateAuthAndRestartSession(roomData) {
    Logger.debug('updateAuthAndRestartSession', roomData);
    if (!roomData.error) {
      this.uaOptions.transportOptions.auth_token =
        roomData.signaling.options.auth_token;
    }
    this.userAgent.connect();
  }

  updateAuthAndResume(roomData) {
    Logger.debug('updateAuthAndResume', roomData);
    if (!this.userAgent) {
      Logger.warn('updateAuthAndResume', 'userAgent already gone');
      return;
    }
    if (roomData.error) {
      this.userAgent.resume(null);
      return;
    }
    this.userAgent.resume(roomData.signaling.options.auth_token);
  }

  initDatachannel(channel) {
    this.datachannel = channel;
    Logger.debug('SigConnection::initDatachannel', channel);
    channel.onmessage = event => {
      if (event.data === '{"type":"ping"}' && channel.readyState === 'open') {
        channel.send('{"type":"pong"}');
        return;
      }
      Logger.debug('SigConnection::dataChannelMessage', event.data);
      const message = JSON.parse(event.data);
      if (message.type === 'ack') {
        return;
      }
      this.handleMessage(message);
    };
  }

  /**
   * Stop listening for messages.
   **/

  close() {
    const { datachannel, userAgent } = this;
    this.datachannel = null;
    this.userAgent = null;
    if (datachannel && datachannel.readyState === 'open') {
      datachannel.close();
    }
    if (userAgent) {
      userAgent.removeAllListeners();
      userAgent.terminate();
    }
  }

  /**
   * Parse, filter, transform and finally forward messages received from SIP
   * connection.
   **/
  onMessage(callback) {
    this.messageListener.push(callback);
  }

  handleMessage(message) {
    if (!WHITELIST.includes(message.type)) {
      Logger.debug(
        `SigConnection::handleMessage ignoring "${message.type}" message.`
      );
      return;
    }
    const transformedMessage = this.transformer.process(message);
    this.messageListener.forEach(cb => cb(transformedMessage));
  }

  /**
   * Transport a message over current connection.
   **/
  // eslint-disable-next-line max-statements
  send(msg) {
    if (this.seppMessaging) {
      this.sendSeppMessage(msg);
      return;
    }
    const { datachannel } = this;
    msg.cid = this.uaOptions.client_id;
    const data = JSON.stringify(msg);
    if (
      datachannel &&
      datachannel.readyState === 'open' &&
      data.length < maxMessageSize
    ) {
      Logger.debug('SigConnection::send datachannel', data);
      datachannel.send(data);
      return;
    }
    Logger.error('SigConnection::send failed', msg);
  }

  sendSeppMessage(msg) {
    const { userAgent } = this;
    if (userAgent && userAgent.callId) {
      msg.cid = this.uaOptions.client_id;
      msg.call_id = userAgent.callId;
      const data = JSON.stringify(msg);
      if (data.length < maxMessageSize) {
        Logger.debug('SigConnection::send sepp', msg);
        userAgent.message(msg.type, msg);
        return;
      }
      Logger.error('SigConnection::send failed', msg);
    }
  }

  onError(errorHandler) {
    this.listeners.push(errorHandler);
    return this;
  }

  emit(msg) {
    this.listeners.forEach(listener => listener(msg));
  }
}

export default SigConnection;
