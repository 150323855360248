import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import twemoji from 'twemoji';
import I18n from 'i18n-js';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import ScrollContainer from '../../generic/ScrollContainer.js';
import ChatMessages from './ChatMessages.js';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

import chatImg from '../../../assets/chat.svg';

const StyledMessage = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    margin: theme.spacing(4),
    textAlign: 'center',
  },
}))(Typography);

const BadgeBox = withStyles((theme) => ({
  root: {
    borderRadius: '0',
    background: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[100],
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}))(Button);

const SpacyIcon = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}))(Icon);

const useStyles = makeStyles({
  feed: {
    display: 'flex',
    flexFlow: 'column-reverse nowrap',
    height: '100%',
    minHeight: '0',
  },
});

/**
 * Render new messages badge.
 **/
const NewMessagesBadge = ({ reset, count, onClick }) => {
  const [counter, setCounter] = useState(count);

  useEffect(() => {
    setCounter(reset ? count : Math.min(counter, count));
  }, [reset, count, counter]);

  const current = Math.max(0, count - counter);
  if (reset || current === 0) {
    return null;
  }

  return (
    <BadgeBox onClick={onClick}>
      <SpacyIcon>forum</SpacyIcon>
      <Typography variant="caption">
        {I18n.t('chat:new_messages')} ({current})
      </Typography>
    </BadgeBox>
  );
};

let lastScrollHeight, lastScrollState;

/**
 * Chat Feed present any messages received or sent.
 **/
const ChatFeed = ({ me, messages, onEvent }) => {
  const [autoscroll, setAutoscroll] = useState(true);
  const messagesContainer = useRef(null);

  /**
   * The height that needs to be scrolled is defined by the absolute
   * container height, subtracted by the container height visible.
   **/
  const getHeightToScroll = () => {
    const container = messagesContainer.current;
    return Math.max(0, container.scrollHeight - container.clientHeight);
  };

  /**
   * Autoscroll is only useful if the scroll position is at the bottom. If no
   * scrolling is yet necessary, autoscroll will always be active.
   *
   * Note: As some windows browser tend to send quite inaccurate values, we
   * compare some difference and define an offset of 10 pixel to enable
   * autoscroll.
   **/
  const handleScroll = () => {
    const container = messagesContainer.current;
    const diff = getHeightToScroll() - container.scrollTop;
    const auto = Math.abs(diff) <= 5;
    if (auto === autoscroll) {
      return;
    }
    setAutoscroll(auto);
  };

  /**
   * Scroll the container to the end.
   **/
  const handleScrollToBottom = () => {
    const container = messagesContainer.current;
    container.scrollTop = getHeightToScroll();
    handleScroll();
  };

  /**
   * On re/open of the chat feed scroll either to last position known or
   * the end of the feed if new messages are present.
   **/
  useEffect(() => {
    const container = messagesContainer.current;
    let scrollState = getHeightToScroll();

    if (container.scrollHeight === lastScrollHeight) {
      scrollState = lastScrollState || scrollState;
    }
    container.scrollTop = scrollState;

    return () => {
      lastScrollState = container.scrollTop;
      lastScrollHeight = container.scrollHeight;
    };
  }, []);

  /**
   * If new messages arrived and the user did not change the scroll position,
   * the container will follow the new messages and autoscroll for the user.
   * If the user has changed the position, a badge will be shown, so the user
   * knows new messages arrived.
   **/
  useEffect(() => {
    const container = messagesContainer.current;

    if (!autoscroll) {
      return;
    }

    const scrollTo = getHeightToScroll();
    if (scrollTo === container.scrollTop) {
      return;
    }
    container.scrollTop = scrollTo;
  }, [autoscroll, messages]);

  /**
   * Replace/Style all emojis.
   **/
  useEffect(() => {
    twemoji.parse(messagesContainer.current);
  });

  const classes = useStyles();

  return (
    <Box className={'eyeson-chat-feed ' + classes.feed} role="log">
      <ScrollContainer
        onScroll={handleScroll}
        scrollableNodeProps={{ ref: messagesContainer }}
      >
        {messages.length === 0 ? (
          <Box
            display="flex"
            flexDirection="column"
            flexWrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={chatImg}
              alt=""
              style={{ width: '75%', marginTop: '1.5rem' }}
            />
            <StyledMessage variant="body1">
              {I18n.t('chat:no_messages')}
            </StyledMessage>
          </Box>
        ) : (
          <ChatMessages me={me} messages={messages} onEvent={onEvent} />
        )}
      </ScrollContainer>
      <NewMessagesBadge
        reset={autoscroll}
        count={messages.length}
        onClick={handleScrollToBottom}
      />
    </Box>
  );
};

ChatFeed.propTypes = {
  me: PropTypes.object.isRequired,
  onEvent: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
};

export default ChatFeed;
