import I18n from 'i18n-js';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { Component, Fragment } from 'react';
import { Logger, FeatureDetector, immediate } from 'eyeson';

const safePlayVideo = (videoEl) => {
  try {
    const playPromise = videoEl.play();
    if (playPromise !== undefined) {
      playPromise.catch(handlePlaybackError);
    }
  } catch (error) {
    handlePlaybackError(error);
  }
};

const _isSafari = FeatureDetector.isSafari();
const _browserVersion = FeatureDetector.browserVersion();
const _isPipSupported = FeatureDetector.hasPipSupport();
const _useWebkitPip =
  _isPipSupported &&
  'webkitSupportsPresentationMode' in HTMLVideoElement.prototype;

const handlePlaybackError = (error) => Logger.debug('Video::error', error);

class Video extends Component {
  constructor(props) {
    super(props);
    this.videoElement = null;
    this.avoidSrc = _isSafari;
    this.isPipEnabled = _isPipSupported && this.props.pipEnabled;
    this.state = {
      showPlayback: false,
    };
  }

  componentDidMount() {
    window.addEventListener('toggle_pip', this.togglePip);
    if (this.isPipEnabled) {
      if (_useWebkitPip) {
        this.videoElement.onwebkitpresentationmodechanged = () => {
          this.setPip(this.videoElement.webkitPresentationMode);
        };
      } else {
        this.videoElement.onenterpictureinpicture = () => {
          this.setPip('picture-in-picture');
        };
        this.videoElement.onleavepictureinpicture = () => {
          this.setPip('inline');
        };
      }
    }
    this.videoElement.onpause = () => {
      immediate(() => safePlayVideo(this.videoElement));
    };
    window.setTimeout(() => {
      if (
        this.videoElement.webkitDecodedFrameCount &&
        this.videoElement.webkitDecodedFrameCount === 0
      ) {
        this.props.onEvent({
          type: 'warning',
          name: I18n.t('error:hw_enc_vp9'),
        });
      }
    }, 5000);
    if (this.props.useCanvas) {
      this.canvasInterval = setInterval(
        () => this.drawVideoCanvas(),
        1000 / 20
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { stream: s, playback: p } = this.props;
    const { stream: ns, playback: np } = nextProps;

    // if there is an error in Preview
    if (nextProps.className !== this.props.className) {
      return true;
    }

    if (!s && !ns) {
      return false;
    }

    if (
      ns &&
      ns.getVideoTracks().length > 0 &&
      !ns.getVideoTracks()[0].enabled
    ) {
      this.videoElement.srcObject = null;
      this.audioElement.srcObject = null;
    }

    // playback has started, make sure the url has changed
    // otherwise the playback will be glitchy
    if (np && np.url && (!p || p.url !== np.url)) {
      return true;
    }

    return (
      !(s && ns && s.id === ns.id) ||
      nextProps.sinkId !== this.props.sinkId ||
      nextProps.hidden !== this.props.hidden ||
      nextProps.muted !== this.props.muted ||
      nextState.showPlayback !== this.state.showPlayback
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sinkId && this.props.sinkId !== this.videoElement.sinkId) {
      this.updateSinkId(this.props.sinkId);
    }
    this.videoElement.muted = this.props.muted;
    if (this.playbackVideoElement)
      this.playbackVideoElement.muted = this.props.muted;
    if (this.props.playback && this.props.playback.url) {
      if (!this.props.muted && !this.avoidSrc) {
        this.audioElement.srcObject = this.videoElement.srcObject;
      }
      if (this.canvasInterval) {
        clearInterval(this.canvasInterval);
        this.canvasInterval = null;
        this.canvasInit = false;
      }
      if (this.avoidSrc) {
        if (this.state.showPlayback === false) {
          // needed to prevent iOS from stop local stream on hidden video element
          this.videoElement.srcObject = null;
          this.setState({ showPlayback: true }, () => {
            this.playbackVideoElement.onerror = this.handleVideoSrcError;
            this.playbackVideoElement.src = this.props.playback.url;
            this.playbackVideoElement.muted =
              this.props.playback.audio === false || this.props.muted;
          });
        }
      } else {
        // use direct onerror instead of react onError property
        // to avoid flooded console for each component update
        // and to track only playback errors
        this.videoElement.onerror = this.handleVideoSrcError;
        this.videoElement.src = this.props.playback.url;
        this.videoElement.muted =
          this.props.playback.audio === false || this.props.muted;
        this.videoElement.srcObject = null;
      }
      return;
    }
    if (this.props.stream) {
      if (this.avoidSrc) {
        if (this.state.showPlayback === true) {
          this.setState({ showPlayback: false });
          this.playbackVideoElement.onerror = null;
          this.playbackVideoElement.src = '';
        }
      } else {
        this.audioElement.srcObject = null;
        this.videoElement.onerror = null;
      }
      this.videoElement.srcObject = this.props.stream;
      if (this.props.useCanvas) {
        if (this.props.stream !== prevProps.stream) {
          this.canvasInit = false;
        }
        if (!this.canvasInterval) {
          this.canvasInterval = setInterval(
            () => this.drawVideoCanvas(),
            1000 / 20
          );
        }
      } else if (this.canvasInterval) {
        clearInterval(this.canvasInterval);
        this.canvasInterval = null;
        this.canvasInit = false;
      }
      return;
    }
    this.videoElement.onerror = null;
    this.videoElement.srcObject = null;
    this.videoElement.src = '';
    this.audioElement.srcObject = null;
    if (this.playbackVideoElement) {
      this.playbackVideoElement.onerror = null;
      this.playbackVideoElement.src = '';
    }
  }

  componentWillUnmount() {
    this.videoElement.onpause = null;
    this.videoElement.stop && this.videoElement.stop();
    if (this.isPipEnabled) {
      if (_useWebkitPip) {
        this.videoElement.onwebkitpresentationmodechanged = null;
        if (this.videoElement.webkitPresentationMode === 'picture-in-picture') {
          // Attention: this has caused safari to crash the video, but seems to be fixed now v14
          if (_browserVersion >= 14) {
            this.videoElement.webkitSetPresentationMode('inline');
          }
          this.setPip('inline');
        }
      } else {
        this.videoElement.onenterpictureinpicture = null;
        this.videoElement.onleavepictureinpicture = null;
        if (document.pictureInPictureElement === this.videoElement) {
          if (!FeatureDetector.isSafari()) {
            document.exitPictureInPicture();
          }
          this.setPip('inline');
        }
      }
    }
    window.removeEventListener('toggle_pip', this.togglePip);
    if (this.canvasInterval) {
      clearInterval(this.canvasInterval);
      this.canvasInterval = null;
    }
    this.canvasInit = false;

    this.handleEnded();
  }

  /**
   * Update audio output via sinkId.
   **/
  updateSinkId = (sinkId) => {
    if (!FeatureDetector.canChangeAudioOutput()) {
      return;
    }

    immediate(() => {
      const newSinkId = !sinkId || sinkId === 'default' ? '' : sinkId;
      if (this.videoElement && this.videoElement.sinkId !== newSinkId) {
        this.videoElement.setSinkId(newSinkId);
      }
      if (this.playbackVideoElement && this.videoElement.sinkId !== newSinkId) {
        this.playbackVideoElement.setSinkId(newSinkId);
      }
      if (this.audioElement && this.audioElement.sinkId !== newSinkId) {
        this.audioElement.setSinkId(newSinkId);
      }
    });
  };

  /**
   * We set any preselected audio output via sinkId if the browser supports
   * this feature. If any stream is given to the component, we set our video
   * element to show it.
   *
   * Note: try catch block is required since EDGE throws:
   * Unexpected call to method or property access.
   **/
  attachCustomAttributes = () => {
    const { sinkId, stream } = this.props;

    this.updateSinkId(sinkId);

    if (!stream) {
      this.videoElement.src = '';
      this.videoElement.srcObject = null;
      return;
    }
    if (_isSafari) {
      // fix for macos 14 safari 17 pip
      this.videoElement.controls = true;
    }
    this.videoElement.srcObject = stream;
    this.videoElement.controls = false;
    if (!FeatureDetector.isIOSDevice()) {
      safePlayVideo(this.videoElement);
    }
  };

  attachPlaybackCustomAttributes = () => {
    const { sinkId } = this.props;

    this.updateSinkId(sinkId);
  };

  togglePip = () => {
    if (!this.isPipEnabled || !_isPipSupported) {
      return;
    }
    const video = this.videoElement;

    if (_useWebkitPip) {
      if (
        video.webkitSupportsPresentationMode &&
        typeof video.webkitSetPresentationMode === 'function'
      ) {
        const newMode =
          video.webkitPresentationMode === 'picture-in-picture'
            ? 'inline'
            : 'picture-in-picture';
        video.webkitSetPresentationMode(newMode);
      }
      return;
    }
    if (document.pictureInPictureEnabled && !video.disablePictureInPicture) {
      if (document.pictureInPictureElement) {
        document
          .exitPictureInPicture()
          .catch((err) => Logger.error('Video::togglePip', err));
      } else {
        video
          .requestPictureInPicture()
          .catch((err) => Logger.error('Video::togglePip', err));
      }
    }
  };

  setPip = (mode) => {
    this.props.onEvent({
      type: 'set_pip',
      mode: mode,
    });
  };

  /**
   * As soon as the dom element is available, and differs from the existing node
   * attach some custom attributes to it.
   **/
  handleRef = (domNode) => {
    if (!domNode || domNode.isSameNode(this.videoElement)) {
      return;
    }

    this.videoElement = domNode;
    immediate(() => this.attachCustomAttributes());
  };

  handlePlaybackRef = (node) => {
    if (!node || node.isSameNode(this.playbackVideoElement)) {
      return;
    }
    this.playbackVideoElement = node;
    immediate(() => this.attachPlaybackCustomAttributes());
  };

  handleAudioRef = (node) => {
    if (node) {
      this.audioElement = node;
    }
  };

  handleCanvasRef = (node) => {
    if (!node || node.isSameNode(this.canvasElement)) {
      return;
    }
    this.canvasElement = node;
    this.canvasInit = false;
    this.canvasElementCtx = node.getContext('2d', { desynchronized: true });
  };

  handlePlaybackError = (error) => Logger.debug('Video::error', error);

  handleEnded = () => {
    const { playback, onEvent, stream } = this.props;
    if (this.avoidSrc) {
      this.videoElement.srcObject = stream;
      this.setState({ showPlayback: false });
      this.playbackVideoElement.onerror = null;
      this.playbackVideoElement.src = '';
      this.playbackVideoElement.muted = this.props.muted;
    } else {
      this.audioElement.srcObject = null;
      this.videoElement.onerror = null;
      this.videoElement.src = '';
      this.videoElement.srcObject = stream;
      this.videoElement.muted = this.props.muted;
    }
    onEvent({ type: 'playback_ended', playback: playback });
  };

  handleVideoSrcError = (error) => {
    this.handlePlaybackError(error);
    this.handleEnded();
  };

  drawVideoCanvas = () => {
    const { videoElement, canvasElement, canvasElementCtx } = this;
    if (
      videoElement &&
      canvasElement &&
      videoElement.videoWidth > 5 &&
      videoElement.videoHeight > 5
    ) {
      if (!this.canvasInit) {
        this.canvasInit = true;
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        return;
      }
      canvasElementCtx.drawImage(
        videoElement,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
    }
  };

  render() {
    return (
      <Fragment>
        {this.props.useCanvas && (
          <canvas
            ref={this.handleCanvasRef}
            style={{ position: 'relative' }}
            className={classNames('eyeson-video', this.props.className, {
              hidden: this.props.hidden || this.state.showPlayback,
            })}
            onDoubleClick={this.props.onDoubleClick}
          ></canvas>
        )}
        <video
          ref={this.handleRef}
          style={this.props.style}
          muted={this.props.muted}
          poster={this.props.poster}
          hidden={this.props.hidden || this.state.showPlayback}
          onClick={this.props.onClick}
          onDoubleClick={this.props.onDoubleClick}
          preload={this.props.preload}
          onEnded={this.handleEnded}
          autoPlay={this.props.autoPlay}
          className={classNames('eyeson-video', this.props.className, {
            hidden: this.props.hidden || this.state.showPlayback,
            canvasHidden: this.props.useCanvas,
          })}
          onMouseMove={this.props.onMouseMove}
          autopictureinpicture={this.props.autopictureinpicture}
          disablePictureInPicture={!this.props.pipEnabled || null}
          playsInline={true}
        />
        {this.avoidSrc && (
          <video
            ref={this.handlePlaybackRef}
            style={this.props.style}
            muted={this.props.muted}
            poster={this.props.poster}
            hidden={this.props.hidden || !this.state.showPlayback}
            onClick={this.props.onClick}
            onDoubleClick={this.props.onDoubleClick}
            preload={this.props.preload}
            onEnded={this.handleEnded}
            autoPlay={true}
            className={classNames('eyeson-video', this.props.className, {
              hidden: this.props.hidden || !this.state.showPlayback,
            })}
            onMouseMove={this.props.onMouseMove}
            autopictureinpicture="false"
            disablePictureInPicture={!this.props.pipEnabled || null}
            playsInline={true}
          />
        )}
        <audio ref={this.handleAudioRef} autoPlay={true} />
      </Fragment>
    );
  }
}

Video.propTypes = {
  style: PropTypes.object,
  muted: PropTypes.bool,
  onRef: PropTypes.func,
  stream: PropTypes.object,
  poster: PropTypes.string,
  hidden: PropTypes.bool,
  sinkId: PropTypes.string,
  preload: PropTypes.string,
  onClick: PropTypes.func,
  autoPlay: PropTypes.bool,
  onMouseMove: PropTypes.func,
};

Video.defaultProps = {
  style: {},
  muted: false,
  sinkId: '',
  poster: '',
  hidden: false,
  preload: 'auto',
  autoPlay: true,
  className: '',
  useCanvas: false,
  pipEnabled: true,
  onEvent: (event) => {
    Logger.debug('Video default onEvent: ', event);
  },
  autopictureinpicture: 'false',
};

export default Video;
