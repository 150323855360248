// import I18n from 'i18n-js';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Logger } from 'eyeson';
import Icon from '@material-ui/core/Icon';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ReactionPicker from '../toolbar/ReactionPicker.js';
import PlaybackHelper from '../../utils/PlaybackHelper.js';
import CounterIcon from '../generic/CounterIcon.js';
// import TwitterEmoji from '../generic/TwitterEmoji';
import { makeStyles } from '@material-ui/core/styles';

const useBottomActionStyles = makeStyles({
  root: {
    minWidth: '60px',
  },
});

const reactions = [
  { emoji: '👋', query: 'hello', label: 'hello' },
  { emoji: '👍', query: 'thumbs up', label: 'thumbs_up' },
  { emoji: '👎', query: 'thumbs down', label: 'thumbs_down' },
  { emoji: '🤔', query: 'think', label: 'thinking' },

  { emoji: '🤣', query: 'laughing', label: 'laughing' },
  { emoji: '😘', query: 'kisses', label: 'kiss' },
  { emoji: '😉', query: 'wink', label: 'wink' },
  { emoji: '😢', query: 'sad', label: 'sad' },

  { emoji: '😠', query: 'angry', label: 'angry' },
  { emoji: '😱', query: 'shocked', label: 'shocked' },
  { emoji: '😴', query: 'tired', label: 'tired' },
  { emoji: '🤦', query: 'facepalm', label: 'facepalm' },
];

const BottomToolbar = (props) => {
  const {
    eco,
    active,
    sources,
    eyeson,
    visible,
    messages,
    onEvent,
    audioSupport,
    videoSupport,
    canReact,
    reactionPickerVisible,
  } = props;

  const helper = new PlaybackHelper(eyeson.links && eyeson.links.self);

  const [disabledReactionPicker, setDisabledReactionPicker] = useState(false);

  const styledBottomActions = useBottomActionStyles();

  const handleNavigationChange = (_event, type) => {
    if (type === 'reaction_picker') {
      const show = reactionPickerVisible ? 'hide' : 'show';
      onEvent({ type: show + '_reaction_picker' });
      return;
    }
    onEvent({ type });
  };

  const handleReaction = async (reaction) => {
    let gif = null;
    try {
      gif = await helper.randomGifForQuery(reaction.query);
    } catch (e) {
      Logger.error(e);
      return;
    }

    onEvent({
      type: 'playback_start',
      reaction: reaction,
      playback: { url: gif.images.original_mp4.mp4 },
      gif: gif,
    });
    onEvent({ type: 'hide_reaction_picker' });
  };

  useEffect(() => {
    const numSources = sources.length;
    const disabled = !active.video && (numSources === 1 || numSources > 2);
    setDisabledReactionPicker(disabled);
  }, [active.video, sources]);

  if (props.hidden) {
    return null;
  }
  return (
    <BottomNavigation onChange={handleNavigationChange}>
      {reactionPickerVisible && (
        <ReactionPicker reactions={reactions} onClick={handleReaction} />
      )}
      <BottomNavigationAction
        icon={<Icon>{active.video ? 'videocam' : 'videocam_off'}</Icon>}
        value="toggle_video"
        disabled={!videoSupport || active.screen_capture || eco}
        classes={styledBottomActions}
      />
      <BottomNavigationAction
        icon={
          <Icon color={active.talkingMuted ? 'error' : 'inherit'}>
            {active.audio ? 'mic' : 'mic_off'}
          </Icon>
        }
        value="toggle_audio"
        disabled={!audioSupport}
        classes={styledBottomActions}
      />
      {!eco && canReact && (
        <BottomNavigationAction
          icon={<Icon fontSize="large">gif</Icon>}
          value="reaction_picker"
          disabled={
            props.hasPresenter ||
            disabledReactionPicker ||
            visible.panel === 'chat'
          }
          classes={styledBottomActions}
        />
      )}
      {!eco && (
        <BottomNavigationAction
          icon={<Icon>switch_camera</Icon>}
          value="toggle_camera"
          disabled={!active.video}
          classes={styledBottomActions}
        />
      )}
      <BottomNavigationAction
        icon={
          <CounterIcon
            icon={
              visible.panel === 'chat' ? 'speaker_notes_off_icon' : 'message'
            }
            count={messages.length}
            reset={visible.panel === 'chat'}
            isMobile={true}
          />
        }
        value="toggle_chat"
        classes={styledBottomActions}
      />
    </BottomNavigation>
  );
};

BottomToolbar.propTypes = {
  hidden: PropTypes.bool,
  active: PropTypes.object.isRequired,
  videoSupport: PropTypes.bool,
  audioSupport: PropTypes.bool,
  canReact: PropTypes.bool,
};

BottomToolbar.defaultProps = {
  hidden: false,
  videoSupport: true,
  audioSupport: true,
};

export default BottomToolbar;
