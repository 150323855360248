import PublicEvent from './PublicEvent.js';

class FetchRoomEvent extends PublicEvent {
  handle() {
    this.context._api.getRoom(room => {
      return super.handle({ type: 'fetch_room', room });
    });
  }
}

export default FetchRoomEvent;
