import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from '../../generic/ScrollContainer.js';
import I18n from 'i18n-js';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';
import shotImg from '../../../assets/shot.svg';
import Snapshot from './Snapshot.js';

const StyledMessage = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[500],
    margin: theme.spacing(4),
    textAlign: 'center',
  },
}))(Typography);

const SnapshotList = ({
  snapshots,
  onEvent,
  presentationActive,
  screenAsVideo,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    flexWrap="nowrap"
    style={{ overflow: 'hidden', height: '100%' }}
    role="log"
  >
    <ScrollContainer>
      {snapshots.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={shotImg}
            alt=""
            style={{ width: '75%', marginTop: '1.5rem', opacity: '0.35' }}
          />
          <StyledMessage variant="body1">
            {I18n.t('snapshots:no_snapshots')}
          </StyledMessage>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          {snapshots
            .sort((a, b) => b.created_at_date - a.created_at_date)
            .map((info) => (
              <Box my={1} mx={2} key={info.id}>
                <Snapshot
                  info={info}
                  onEvent={onEvent}
                  presentationActive={presentationActive}
                  screenAsVideo={screenAsVideo}
                />
              </Box>
            ))}
        </Box>
      )}
    </ScrollContainer>
  </Box>
);

SnapshotList.propTypes = { snapshots: PropTypes.array.isRequired };

export default SnapshotList;
