const cacheStorageSupport = typeof window.CacheStorage === 'function';

const storeBlobURL = async (blobURL, path) => {
  if (!cacheStorageSupport) {
    return;
  }
  try {
    const response = await fetch(blobURL);
    const blob = await response.blob();
    const cache = await caches.open('store');
    await cache.put(path, new Response(blob));
    // eslint-disable-next-line no-empty, no-unused-vars
  } catch (error) {}
};

const loadBlobURL = async path => {
  if (cacheStorageSupport) {
    try {
      const cache = await caches.open('store');
      const response = await cache.match(path);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
      // eslint-disable-next-line no-empty, no-unused-vars
    } catch (error) {}
  }
  return false;
};

const deleteBlob = async path => {
  if (!cacheStorageSupport) {
    return;
  }
  try {
    const cache = await caches.open('store');
    await cache.delete(path);
    // eslint-disable-next-line no-empty, no-unused-vars
  } catch (error) {}
};

export default {
  storeBlobURL,
  loadBlobURL,
  deleteBlob
};
