import Logger from './Logger.js';
import FeatureDetector from './FeatureDetector.js';

// https://developer.chrome.com/docs/web-platform/compute-pressure/
class SystemPressureMonitor {
  /**
   * @param {{ sampleInterval: number }} [options]
   */
  constructor(options = {}) {
    this._listener = [];
    this._monitor = null;
    this._running = false;
    this.state = null;
    this.sampleInterval = options.sampleInterval || 1000;
  }

  onUpdate(listener) {
    if (typeof listener === 'function') {
      this._listener.push(listener);
    }
  }

  offUpdate(listener) {
    if (typeof listener === 'function') {
      this._listener = this._listener.filter(fn => fn !== listener);
    } else {
      this._listener.length = 0;
    }
  }

  _emit(records) {
    // states are 'nominal', 'fair', 'serious', 'critical'
    // https://github.com/w3c/compute-pressure#design-considerations
    const { state } = records[records.length - 1];
    if (state === this.state) {
      return;
    }
    this.state = state;
    Logger.debug('SystemPressureMonitor::emit', state);
    this._listener.forEach(fn => {
      try {
        fn(state);
      } catch (error) {
        Logger.error('SystemPressureMonitor::emit', error);
      }
    });
  }

  // eslint-disable-next-line max-statements
  async start() {
    if (FeatureDetector.canMonitorSystemPressure() === false) {
      Logger.error('SystemPressureMonitor is not supported');
      return;
    }
    try {
      if (!this._monitor) {
        // eslint-disable-next-line no-undef
        this._monitor = new PressureObserver(this._emit.bind(this));
      }
      if (!this._running) {
        await this._monitor.observe('cpu', {
          sampleInterval: this.sampleInterval
        });
        this._running = true;
      }
      if (this.state === null) {
        this._emit([{ state: 'nominal' }]);
      }
      Logger.debug('SystemPressureMonitor::start');
    } catch (error) {
      Logger.error('SystemPressureMonitor::start', error);
    }
  }

  stop() {
    if (this._monitor && this._running) {
      this._monitor.disconnect();
      this._running = false;
    }
  }

  destroy() {
    this.stop();
    this.offUpdate();
    this.state = null;
    this._monitor = null;
  }
}

export default SystemPressureMonitor;
