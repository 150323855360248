import React from 'react';
import I18n from 'i18n-js';
import { FeatureDetector, ConnectionStatistics } from 'eyeson';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ScrollContainer from '../../generic/ScrollContainer.js';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Typography from '@material-ui/core/Typography';
import NumberFormat from '../../../utils/NumberFormatHelper.js';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';

const { Status } = ConnectionStatistics;

const statusNames = {};
statusNames[Status.NO_CONNECTION] = 'status:connection:no_connection';
statusNames[Status.BAD] = 'status:connection:poor';
statusNames[Status.OK] = 'status:connection:unstable';
statusNames[Status.GOOD] = 'status:connection:excellent';

// https://w3c.github.io/compute-pressure/
const supportsSystemPressure = FeatureDetector.canMonitorSystemPressure();
const pressureColor = {
  nominal: '#08C008',
  fair: '#08C008',
  serious: '#c7bb2a',
  critical: '#e53729',
};

const pressureDescription = {
  nominal: '',
  fair: '',
  serious: 'description:pressure:serious',
  critical: 'description:pressure:critical',
};

const useStyles = makeStyles((theme) => ({
  table: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& th': {
      fontWeight: 'normal',
      textAlign: 'left',
    },
    '& tr.row-status th, & tr.row-status td': {
      fontWeight: 'bold',
    },
    '& th, & td': {
      padding: '.25em .5em',
    },
    '& tr.hidden': {
      display: 'none',
    },
  },
  status: {
    color: (data) => {
      if (data.status === Status.BAD || data.status === Status.NO_CONNECTION) {
        return '#e53729';
      }
      if (data.status === Status.OK) {
        return '#c7bb2a';
      }
      return '#08C008';
    },
  },
  description: {
    margin: theme.spacing(2),
  },
}));

const ConnectionInfo = ({ data, pressureState }) => {
  const classes = useStyles(data);
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      style={{ overflow: 'hidden', height: '100%' }}
    >
      <ScrollContainer>
        <table className={classes.table}>
          <tbody>
            <tr className="row-status">
              <th>{I18n.t('label:connection_info:connection_status')}</th>
              <td className={classes.status}>
                {I18n.t(statusNames[data.status])}
              </td>
            </tr>
            {data.status !== Status.NO_CONNECTION && (
              <>
                <tr>
                  <th>{I18n.t('label:connection_info:bitrate')}</th>
                  <td>
                    <nobr>
                      <ArrowUpward style={{ fontSize: '1em' }} />
                      {NumberFormat.bitrate(data.bitrateSend)}
                    </nobr>{' '}
                    <nobr>
                      <ArrowDownward style={{ fontSize: '1em' }} />
                      {NumberFormat.bitrate(data.bitrateRecv)}
                    </nobr>
                  </td>
                </tr>
                <tr className={classnames({ hidden: data.jitter === null })}>
                  <th>{I18n.t('label:connection_info:jitter')}</th>
                  <td>{NumberFormat.jitter(data.jitter)}</td>
                </tr>
                <tr
                  className={classnames({ hidden: data.packetLoss === null })}
                >
                  <th>{I18n.t('label:connection_info:packet_loss')}</th>
                  <td>{NumberFormat.packetLoss(data.packetLoss)}</td>
                </tr>
                <tr
                  className={classnames({
                    hidden: data.roundTripTime === null,
                  })}
                >
                  <th style={{ whiteSpace: 'nowrap' }}>
                    {I18n.t('label:connection_info:connection_delay')}
                    <Tooltip
                      arrow
                      title={I18n.t('label:connection_info:description')}
                      placement="top"
                    >
                      <HelpIcon
                        style={{
                          opacity: 0.7,
                          marginInlineStart: '0.3rem',
                          fontSize: '1rem',
                        }}
                      />
                    </Tooltip>
                  </th>
                  <td>{NumberFormat.roundTripTime(data.roundTripTime)}</td>
                </tr>
              </>
            )}
            {supportsSystemPressure && pressureState !== null && (
              <tr>
                <th>{I18n.t('info:cpu_pressure:title')}</th>
                <td>
                  <span style={{ color: pressureColor[pressureState] }}>
                    {I18n.t(`status:pressure:${pressureState}`)}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {supportsSystemPressure &&
          pressureState !== null &&
          pressureDescription[pressureState] && (
            <Typography
              variant="body2"
              className={classes.description}
              color="textSecondary"
            >
              {I18n.t(pressureDescription[pressureState])}
            </Typography>
          )}
      </ScrollContainer>
    </Box>
  );
};

export default ConnectionInfo;
