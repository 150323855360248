import I18n from 'i18n-js';
import { withSnackbar } from 'notistack';
import Video from '../streams/Video.js';
import Header from './Header.js';
import PropTypes from 'prop-types';
import JoinButton from './JoinButton.js';
import classNames from 'classnames';
import AudioLevel from '../device_settings/AudioLevel.js';
import StickyNotes from '../dialog/StickyNotes.js';
import DeviceTiles from './DeviceTiles.js';
import PreviewDialog from './PreviewDialog.js';
import ActivityMonitor from '../../utils/ActivityMonitor.js';
import React, { Component } from 'react';
import ExperimentalBrowserAlert from '../dialog/ExperimentalBrowserAlert.js';
import { Logger, DeviceManager, FeatureDetector } from 'eyeson';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import { matchMedia } from '../../utils/LayoutHelper.js';
import VirtualBackgroundPreview from './VirtualBackgroundPreview.js';
import VirtualBackgroundTypes from '../../utils/VirtualBackgroundTypes.js';
import supports from '../../utils/SupportHelper.js';
import WebHIDHelper from '../../utils/WebHIDHelper.js';
import CallControlOptions from './CallControlOptions.js';
import StreamdeckHelper from '../../utils/StreamdeckHelper.js';
import DataSaverPreview from './DataSaverPreview.js';

const CAMERA_ERRORS = [
  'NotAllowedError',
  'PermissionDeniedError',
  'PermissionDismissedError',
];

const findDeviceUsedInStream = (stream, type, devices) => {
  if (!stream || devices.length === 0) {
    return null;
  }
  const tracks = stream[`get${type}Tracks`]();
  if (tracks.length === 0) {
    return null;
  }
  const trackLabel = tracks[0].srcLabel || tracks[0].label;
  const selected = devices.find((device) => device.label === trackLabel);
  if (!selected) {
    return null;
  }
  return selected.deviceId;
};

const ErrorHint = ({ error: { name } }) => {
  let error_message = I18n.t('error_' + name);
  if (name === 'NotAllowedError' && FeatureDetector.isIOSDevice()) {
    error_message += ' ' + I18n.t('error:ios_permissions');
  }
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
      <SnackbarContent message={error_message} />
    </Snackbar>
  );
};

const WarningHint = ({ warning: { name } }) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open>
      <SnackbarContent message={I18n.t('warning_' + name)} />
    </Snackbar>
  );
};

const styles = (theme) => ({
  root: {
    'border-radius': '0.2rem',
    padding: theme.spacing(4, 4, 2, 4),
    '@media (orientation: portrait) and (max-height: 640px)': {
      padding: theme.spacing(1),
    },
    '@media (orientation: landscape) and (max-height: 380px)': {
      padding: theme.spacing(1),
    },
    color:
      theme.palette.type === 'dark'
        ? theme.palette.common.white
        : theme.palette.primary.dark,
    '@media (min-width: 500px) and (min-height: 320px)': {
      'box-shadow': theme.shadows[1],
      background:
        theme.palette.type === 'light'
          ? theme.palette.common.white
          : theme.palette.primary.dark,
    },
  },
});

const StyledPreview = withStyles(styles)((props) => {
  const { classes } = props;
  return <div className={classes.root}>{props.children}</div>;
});

class Preview extends Component {
  constructor(props) {
    super(props);

    this.deviceManager = new DeviceManager();
    this.activityMonitor = new ActivityMonitor(this.props.config.times.preview);
    this.warningTimeout = null;

    // because phone mode is :extrawurst:
    this.mql = matchMedia('(max-height: 650px), (max-width: 1080px)');

    this.state = {
      error: {},
      stream: null,
      sinkId: 'default',
      hasError: false,
      inactive: false,
      cameras: [],
      speakers: [],
      minified: this.mql.matches,
      microphones: [],
      playerMuted: true,
      selected: { camera: null, microphone: null },
      options: { eco: false, audio: true, video: true },
      vbgLoading: false,
      warning: null,
      hidBlocked: false,
      hidDevices: [],
      dataSaver: 'off',
    };
  }

  componentDidMount() {
    let vbgState = false;
    if (this.props.vbgAvailable) {
      let type = DeviceManager.getStoredVirtualBackgroundType(
        this.props.vbgBlobAvailable
      );
      if (type === 'off' && this.props.vbgDefault) {
        type = this.props.vbgDefault;
      }
      if (VirtualBackgroundTypes.isExistingType(type)) {
        if (type === 'image:blob') {
          VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': true });
        }
        this.deviceManager.setVirtualBackgroundType(type).catch(() => {
          this.props.updateVirtualBackground('off');
          this.handleWarning({ name: 'vbg_ImageLoadingError' });
          StreamdeckHelper.setActions({
            virtualbackgroundtoggle: {
              enabled: this.props.vbgAvailable,
              state: false,
            },
          });
        });
        this.props.updateVirtualBackground(type);
      }
      this.deviceManager.virtualBackground.onLoading((loading) => {
        this.setState({ vbgLoading: loading });
      });
      vbgState = type === 'off';
    }
    this.deviceManager.onChange(this.handleChange);
    this.deviceManager.start();
    DeviceManager.getSinkId().then((sinkId) =>
      this.setState({ sinkId: sinkId })
    );
    this.activityMonitor.onInactivity(this.handleInactivity);
    this.activityMonitor.start();
    this.mql.addEventListener('change', this.onMediaQueryChange);
    WebHIDHelper.init().then(() => {
      this.setState({ hidBlocked: WebHIDHelper.isBlocked() });
      if (!WebHIDHelper.isBlocked()) {
        WebHIDHelper.onEvent(this.handleWebhidEvents);
      }
    });
    StreamdeckHelper.setActions({
      joinleave: { enabled: true, state: true },
      virtualbackgroundtoggle: {
        enabled: this.props.vbgAvailable,
        state: vbgState,
      },
    });
    StreamdeckHelper.onEvent(this.handleStreamdeckAction);
  }

  componentDidUpdate(_prevProps, prevState) {
    // FF returns [] for keys of MediaStreamError
    const { error, hasError } = this.state;

    if (
      (Object.keys(error).length > 0 || Boolean(error.name)) &&
      !prevState.hasError
    ) {
      this.setState({ hasError: true });
    }
    if (hasError !== prevState.hasError) {
      StreamdeckHelper.setActions({
        joinleave: { enabled: !hasError, state: true },
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.warningTimeout);
    this.deviceManager.terminate();
    this.mql.removeEventListener('change', this.onMediaQueryChange);
    this.activityMonitor.stop();
    WebHIDHelper.offEvent(this.handleWebhidEvents);
    StreamdeckHelper.offEvent(this.handleStreamdeckAction);
  }

  onUserAction = () => this.resetInactivityMonitor();

  toggleMuteVideo = () =>
    this.setState({ playerMuted: !this.state.playerMuted });

  resetInactivityMonitor = () => this.activityMonitor.reset();

  handleInactivityReset = () => {
    this.onUserAction();
    this.setState({ inactive: false });
  };

  handleInactivity = () => {
    this.onUserAction();
    this.setState({ inactive: true });
  };

  onMediaQueryChange = (event) => {
    this.setState({ minified: event.matches });
  };

  handleChange = (newState) => {
    if (newState.options) {
      this.props.onChange(newState.options);
    }
    if (newState.cameras && newState.cameras.length === 0) {
      newState['options'] = {
        audio: this.state.options.audio,
        video: false,
        eco: this.state.options.eco,
      };
    }
    if (
      newState.microphones &&
      this.state.selected.microphone &&
      !newState.microphones.find(
        (mic) => mic.deviceId === this.state.selected.microphone
      )
    ) {
      if (newState.microphones.length > 0) {
        this.handleAudioInputChange({
          target: { value: newState.microphones[0].deviceId },
        });
      }
    }
    if (
      newState.cameras &&
      this.state.selected.camera &&
      !newState.cameras.find(
        (cam) => cam.deviceId === this.state.selected.camera
      )
    ) {
      if (newState.cameras.length > 0) {
        this.handleVideoInputChange({
          target: { value: newState.cameras[0].deviceId },
        });
      }
    }
    if (
      newState.speakers &&
      this.state.sinkId &&
      !newState.speakers.find(
        (speaker) => speaker.deviceId === this.state.sinkId
      )
    ) {
      if (newState.speakers.length > 0) {
        this.handleAudioOutputChange({
          target: { value: newState.speakers[0].deviceId },
        });
      }
    }
    if (this.deviceManager.terminationInProgress) {
      return;
    }
    if (this.state.stream === null && newState.stream) {
      const { cameras, microphones } = this.state;
      const camId = findDeviceUsedInStream(newState.stream, 'Video', cameras);
      const micId = findDeviceUsedInStream(
        newState.stream,
        'Audio',
        microphones
      );
      this.setState({ selected: { camera: camId, microphone: micId } });
    }
    this.setState(newState);
    if (newState.options) {
      WebHIDHelper.setMuteActive(!newState.options.audio);
    }
  };

  handleVideoInputChange = (event) => {
    this.onUserAction();
    this.deviceManager.setVideoInput(event.target.value);
    this.deviceManager.storeConstraints();
    this.setState({
      selected: { camera: event.target.value },
      error: {},
      warning: null,
      hasError: false,
    });
  };

  handleAudioInputChange = (event) => {
    this.onUserAction();
    this.deviceManager.setAudioInput(event.target.value);
    this.deviceManager.storeConstraints();
    this.setState({
      selected: { microphone: event.target.value },
      error: {},
      hasError: false,
    });
  };

  handleAudioOutputChange = (event) => {
    this.onUserAction();
    const sinkId = event.target.value;
    this.deviceManager.setAudioOutput(sinkId || '');
    this.setState({ sinkId: sinkId });
  };

  handlePlayPreviewAudio = (event) => {
    this.onUserAction();
  };

  handleEcoMode = (newEcoState) => {
    const newOptions = Object.assign({}, this.state.options, {
      video: !newEcoState,
      eco: newEcoState,
    });
    const newState = Object.assign({}, this.state, { options: newOptions });
    this.deviceManager.updateWithOptions({
      audio: this.state.options.audio,
      video: !newEcoState,
      eco: newEcoState,
    });
    this.onUserAction();
    this.setState(newState, () => {
      this.props.onChange(newOptions);
    });
  };

  handleDataSaver = (mode) => {
    if (
      (mode === 'eco' && !this.state.options.eco) ||
      (mode !== 'eco' && this.state.options.eco)
    ) {
      this.handleEcoMode(mode === 'eco');
    }
    this.setState({ dataSaver: mode });
  };

  toggleAudio = () => {
    this.onUserAction();
    this.deviceManager.updateWithOptions({
      audio: !this.state.options.audio,
      video: this.state.options.video,
      eco: this.state.options.eco,
    });
    this.setState({ error: {}, hasError: false, warning: null });
  };

  toggleVideo = () => {
    this.onUserAction();
    this.deviceManager.updateWithOptions({
      audio: this.state.options.audio,
      video: !this.state.options.video,
      eco: this.state.options.eco,
    });
    this.setState({ error: {}, hasError: false });
  };

  /**
   * What to do once the user has decided that settings are ok.
   **/
  handleEnter = () => {
    this.onUserAction();
    this.deviceManager.storeConstraints();
    this.props.onEnter(
      Object.assign({}, this.state.options, { quality: this.state.dataSaver })
    );
  };

  handleExit = () => {
    this.deviceManager.stop();
    this.props.onExit({ reason: 'exit', redirect: true });
  };

  handleError = (error) => {
    Logger.error('Preview::handleError', error);
    this.setState({ error });
  };

  handleWarning = (warning) => {
    this.setState({ warning });
    clearTimeout(this.warningTimeout);
    this.warningTimeout = setTimeout(() => {
      this.setState({ warning: null });
    }, 5000);
  };

  updateVirtualBackground = (type, onSuccess) => {
    this.deviceManager.setVirtualBackgroundType(type).then(
      () => {
        onSuccess();
        VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': false });
        this.props.updateVirtualBackground(type);
        StreamdeckHelper.setActions({
          virtualbackgroundtoggle: {
            enabled: this.props.vbgAvailable,
            state: type === 'off',
          },
        });
      },
      () => {
        this.handleWarning({ name: 'vbg_ImageLoadingError' });
      }
    );
  };

  handleVirtualBackgroundLocalFile = () => {
    this.deviceManager.loadLocalImageForVirtualBackground((error) => {
      if (error) {
        this.handleWarning({ name: 'vbg_' + error.name });
        return;
      }
      VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': true });
      this.props.updateVirtualBackground('image:blob');
      StreamdeckHelper.setActions({
        virtualbackgroundtoggle: {
          enabled: this.props.vbgAvailable,
          state: false,
        },
      });
    });
  };

  handleWebhidEvents = (event) => {
    const { type } = event;
    if (type === 'devicelist') {
      this.setState({ hidDevices: event.devices });
    } else if (type === 'togglemute') {
      this.toggleAudio();
    } else if (type === 'togglecall' && event.active) {
      if (this.state.hasError) {
        WebHIDHelper.setCallActive(false);
      } else {
        this.handleEnter();
      }
    } else if (type === 'error') {
      this.props.enqueueSnackbar('error:webhid:' + event.id, {
        variant: 'warning',
        autoHideDuration: 5 * 1000,
      });
    }
  };

  handleStreamdeckAction = async (event) => {
    const { type } = event;
    if (type === 'audiotoggle') {
      this.toggleAudio();
    } else if (type === 'videotoggle') {
      this.toggleVideo();
    } else if (type === 'joinleave') {
      this.handleEnter();
    } else if (type === 'virtualbackgroundtoggle') {
      Logger.warn(event);
      if (event.state === true) {
        this.updateVirtualBackground('off', () => {});
      } else {
        const { settings } = event;
        if (settings.type === 'image:blob') {
          if (settings.customImage) {
            const blob = await StreamdeckHelper.base64ToBlob(
              settings.customImage,
              settings.customImageType
            );
            if (blob) {
            }
          }
        } else {
          this.updateVirtualBackground(settings.type, () => {});
        }
      }
    }
  };

  render() {
    const {
      isRecording,
      onInactivity,
      hasPresenter,
      isLiveStreaming,
      isExperimentalBrowser,
      config: {
        times: { dialog: dialogTimer },
      },
    } = this.props;

    return (
      <div className="eyeson-preview">
        <PreviewDialog
          inactive={this.state.inactive}
          onCountdownEnd={onInactivity}
          onPrimaryClick={this.handleInactivityReset}
          onSecondaryClick={this.handleExit}
          countDownDialogTime={dialogTimer}
        />

        <main className="content">
          <Header stream={this.state.stream} hasError={this.state.hasError} />

          <div id="preview" className="center">
            {!this.state.minified && (
              <DataSaverPreview
                minified={false}
                mode={this.state.dataSaver}
                onChange={this.handleDataSaver}
              />
            )}
            <StyledPreview>
              <div className="preview-wrapper">
                <StickyNotes
                  recording={isRecording}
                  presenting={hasPresenter}
                  liveStream={isLiveStreaming}
                />
                <div id="preview-results">
                  <div
                    className={classNames('video-wrap', {
                      'permission-denied': CAMERA_ERRORS.includes(
                        this.state.error.name
                      ),
                      'no-cam':
                        !this.state.stream ||
                        this.state.options.eco ||
                        !this.state.options.video,
                      eco: this.state.options.eco,
                    })}
                  >
                    <Video
                      muted={this.state.playerMuted}
                      stream={this.state.stream}
                      sinkId={this.state.sinkId}
                      pipEnabled={false}
                      useCanvas={
                        supports.playCanvasStreamBug &&
                        this.props.virtualBackground !== 'off'
                      }
                    />
                  </div>
                  {this.state.stream && (
                    <AudioLevel
                      stream={this.state.stream}
                      onError={this.handleError}
                      onWarning={this.handleWarning}
                      inactive={!this.state.options.audio}
                    />
                  )}
                  {this.state.hasError && (
                    <ErrorHint error={this.state.error} />
                  )}
                  {this.state.warning && (
                    <WarningHint warning={this.state.warning} />
                  )}
                </div>

                <div id="preview-actions">
                  <div className="preview-group">
                    <DeviceTiles
                      {...this.state}
                      eco={this.state.options.eco}
                      toggleAudio={this.toggleAudio}
                      toggleVideo={this.toggleVideo}
                      handleMicCheck={this.toggleMuteVideo}
                      handleAudioInputChange={this.handleAudioInputChange}
                      handleVideoInputChange={this.handleVideoInputChange}
                      handlePlayPreviewAudio={this.handlePlayPreviewAudio}
                      handleAudioOutputChange={this.handleAudioOutputChange}
                    />
                  </div>
                  {this.state.minified && (
                    <DataSaverPreview
                      minified={true}
                      mode={this.state.dataSaver}
                      onChange={this.handleDataSaver}
                    />
                  )}
                  <CallControlOptions
                    streamdeckState={this.props.streamdeck}
                    onStreamdeckDisable={this.props.onStreamdeckDisable}
                    hidDevices={this.state.hidDevices}
                    hidBlocked={this.state.hidBlocked}
                  />
                  {this.props.vbgAvailable && (
                    <VirtualBackgroundPreview
                      eco={this.state.options.eco}
                      loading={this.state.vbgLoading}
                      background={this.props.virtualBackground}
                      vbgBlobAvailable={this.props.vbgBlobAvailable}
                      onUpdate={this.updateVirtualBackground}
                      handleLocalFile={this.handleVirtualBackgroundLocalFile}
                    />
                  )}
                  <div className="join-section">
                    <JoinButton
                      onClick={this.handleEnter}
                      disabled={this.state.hasError}
                    />
                  </div>
                </div>
              </div>
            </StyledPreview>
          </div>
          <ExperimentalBrowserAlert visible={isExperimentalBrowser} />
        </main>
      </div>
    );
  }
}

Preview.propTypes = {
  config: PropTypes.object,
  onExit: PropTypes.func,
  onEnter: PropTypes.func,
  onChange: PropTypes.func,
  isRecording: PropTypes.bool,
  hasPresenter: PropTypes.bool,
  onInactivity: PropTypes.func,
  isLiveStreaming: PropTypes.bool,
};

Preview.defaultProps = {
  config: { times: { room: 10, dialog: 10, preview: 10 } },
  onExit: (params) => Logger.debug('Preview:onExit', params),
  onEnter: (params) => Logger.debug('Preview:onEnter', params),
  onChange: (params) => Logger.debug('Preview::onChange', params),
  onInactivity: (params) => Logger.debug('Preview:onInactivity', params),
  isRecording: false,
  hasPresenter: false,
  isLiveStreaming: false,
  isExperimentalBrowser: false,
};

export default withSnackbar(Preview);
