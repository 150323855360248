import { createTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

// https://material-ui.com/customization/palette/
const muiTheme = (mode = 'light') => {
  if (mode === 'brown') {
    return createTheme({
      palette: {
        primary: {
          // main: '#fff8dc',
          main: '#daa520',
        },
        secondary: {
          main: '#685642',
        },
        background: {
          paper: '#b3a580',
        },
      },
    });
  }

  return createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '0.8em',
        },
      },
      MuiSwitch: {
        colorSecondary: {
          '&$disabled': {
            color: mode === 'light' ? grey[400] : '#4d4d4d',
          },
        },
      },
    },
    palette: {
      type: mode,
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#ffffff',
        dark: grey[900],
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: '#0066ff',
        // dark: '#003bcc',
        // contrastText: '#ffcc00'
        main: mode === 'light' ? '#003bcc' : '#366cf7',
      },
      background: {
        default: mode === 'light' ? '#f0f0f0' : '#121212',
        paper: mode === 'light' ? '#fff' : grey[900],
      },
      action: {
        active: mode === 'light' ? grey[900] : grey[50],
      },
      // error: will use the default color
    },
    typography: {
      fontFamily: "'Hubot Sans' , 'Arial' , sans-serif",
      h2: {
        fontWeight: 700,
      },
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      button: {
        fontWeight: 700,
      },
      overline: {
        fontWeight: 700,
        letterSpacing: '0.1em',
      },
    },
    shape: {
      borderRadius: 2,
    },
  });
};

export default muiTheme;
