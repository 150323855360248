import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import CanvasMixer from '../CanvasMixer.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import {
  getScreenPresentationTracks,
  stopTrack
} from '../utils/StreamHelpers.js';
import VirtualBackgroundMixer from '../VirtualBackgroundMixer.js';
import DeviceManager from '../DeviceManager.js';

class StartMixerEvent extends BaseEvent {
  handle({
    audio,
    video,
    canvas,
    screen = false,
    surface,
    onRedraw,
    changeStream = false,
    present = 'mixer'
  }) {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    // if (_session.micMixer) {
    //   _session.micMixer.destroy();
    //   _session.micMixer = null;
    // }
    const vbgType = DeviceManager.getStoredVirtualBackgroundType();
    const vbgMixer = new VirtualBackgroundMixer('canvasmixer');
    vbgMixer.changeBackground(vbgType);

    const onError = error => {
      Logger.error('StartMixerEvent', error);
      if (!_session.canvasMixer && vbgMixer) {
        vbgMixer.terminate();
      }
      if (!error.name.match(/notallowed|permission/i) && screen) {
        _session.emit({ type: 'capture_error', name: `error_${error.name}` });
      }
      _session.emit({ type: 'stop_presenting' });
    };

    new MediaStreamBuilder({
      audio: audio,
      video: video,
      virtualBackground: _session.options.virtualBackground,
      screen: screen,
      surface: surface,
      canvas: canvas,
      existingStream: _session.localStream,
      vbgMixer: vbgMixer,
      deviceMonitor: _session.deviceMonitor
    })
      .start()
      .then(stream => {
        // cleanup when screen capture is stopped via chrome's built-in/native
        // "stop sharing" button (a blue button on bottom of screen).
        const [screenTrack] = getScreenPresentationTracks(stream);
        if (screenTrack) {
          screenTrack.addEventListener('ended', () => {
            _session.emit({ type: 'stop_presenting' });
            stopTrack(screenTrack);
          });
        }
        _session.canvasMixer = new CanvasMixer(canvas, stream);
        _session.canvasMixer.vbgMixer = vbgMixer;
        _session.canvasMixer.onError(onError);
        _session.canvasMixer.onRedraw(onRedraw);
        _session.canvasMixer.start();
        return stream;
      })
      .then(stream => {
        if (changeStream) {
          _session.emit({ type: 'start_presenting', stream, present });
        } else {
          _session.emit({ type: 'stream_update', presentationStream: stream });
        }
      })
      .catch(onError);
  }
}

class UpdateMixerEvent extends BaseEvent {
  handle({ position, size }) {
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    try {
      _session.canvasMixer.setCamera(position, size);
    } catch (error) {
      Logger.error(error);
    }
  }
}

export { StartMixerEvent, UpdateMixerEvent };
