import I18n from 'i18n-js';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 600,
    width: 'fit-content',
  },
}));

const AlternativeBrowserAlert = ({
  message = I18n.t('message:alt_browser'),
}) => {
  const vertical = 'top';
  const horizontal = 'center';
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const closeButton = (
    <IconButton
      size="small"
      aria-label={I18n.t('label:close', { defaultValue: 'close' })}
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      open={open}
      onClose={handleClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={message}
      action={closeButton}
      className={classes.root}
    />
  );
};

AlternativeBrowserAlert.propTypes = {
  message: PropTypes.string,
};

export default AlternativeBrowserAlert;
