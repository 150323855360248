import PublicEvent from './PublicEvent.js';

class ReceiveChatEvent extends PublicEvent {
  handle(msg) {
    // remove domain part to support legacy SIP user
    const userId = msg.cid.split('@').shift();

    this.msg = msg;
    this.context._api.getUser(userId, this.forwardMessage.bind(this));
  }

  forwardMessage(user) {
    const timestamp = Date.parse(this.msg.ts || this.msg.created_at);
    super.handle({
      type: this.msg.type,
      user: user,
      timestamp: new Date(timestamp),
      content: this.msg.content
    });
  }
}

export default ReceiveChatEvent;
