import { useCallback, useEffect, useState } from 'react';
import { Logger, LocalStorage } from 'eyeson';
import StreamdeckHelper from '../../utils/StreamdeckHelper';

const Streamdeck = (props) => {
  const {
    eyeson,
    handleEvent,
    hideSnapshotButton,
    hideScreenshare,
    hideChat,
    hideLayoutSettings,
    hideRecording,
    disableRecording,
    hideGuestLogin,
    isLocked,
    toggleVideoDisabled,
    presentationStream,
    options,
    virtualBackground,
    sinkId,
    mediaOptions: { eco },
    environment,
    active: {
      audio,
      video,
      recording,
      hasPresenter,
      live_stream,
      screen_capture,
      screenAddition,
      screenAsVideo,
    },
  } = props;
  const [autoStartPresent, setAutoStartPresent] = useState(null);

  const handleStreamdeckEvent = useCallback(
    async (event) => {
      const { type, state, settings, id } = event;
      const { comApi } = eyeson.core;
      if (type === 'videotoggle') {
        handleEvent({ type: 'toggle_video' });
      } else if (type === 'audiotoggle') {
        handleEvent({ type: 'toggle_audio' });
      } else if (type === 'joinleave' && state === true) {
        handleEvent({ type: 'exit_room' });
      } else if (type === 'recordtoggle') {
        comApi[recording ? 'stopRecording' : 'startRecording']().catch(
          (error) => {
            Logger.error(error);
            StreamdeckHelper.send({
              type: 'recordtoggle',
              state,
              enabled: true,
              id,
              errorMessage: error.message,
            });
          }
        );
      } else if (type === 'broadcasttoggle') {
        if (live_stream) {
          eyeson.core.comApi.stopBroadcast('generic').catch((error) => {
            Logger.error(error);
            StreamdeckHelper.send({
              type: 'broadcasttoggle',
              state,
              enabled: true,
              id,
              errorMessage: error.message,
            });
          });
        } else if (state === false && !settings.streamUrl) {
          handleEvent({ type: 'live_stream' });
        } else {
          const fixUrlEnd = (url) =>
            url[url.length - 1] === '/' ? url : url + '/';
          const streamUrl = fixUrlEnd(settings.streamUrl) + settings.streamKey;
          eyeson.core.comApi
            .startBroadcast(
              { streamUrl, playerUrl: settings.playerUrl },
              'generic'
            )
            .catch((error) => {
              Logger.error(error);
              StreamdeckHelper.send({
                type: 'broadcasttoggle',
                state,
                enabled: true,
                id,
                errorMessage: error.message,
              });
              handleEvent({
                type: 'warning',
                name: 'dialog:live_stream:rtmp_error',
              });
            });
        }
      } else if (type === 'screensharetoggle') {
        if (hasPresenter || screenAddition || screenAsVideo) {
          handleEvent({
            type: screenAddition
              ? 'screen_addition_stop'
              : screenAsVideo
              ? 'screen_video_ended'
              : 'presentation',
          });
        } else {
          handleEvent({
            type:
              settings.type === 'additional'
                ? 'screen_as_addition'
                : settings.type === 'cam'
                ? 'screen_as_video'
                : 'activate_screen_capture',
          });
        }
      } else if (type === 'presenttoggle') {
        if (hasPresenter) {
          handleEvent({ type: 'presentation' });
        } else if (state === false && !settings.file) {
          handleEvent({ type: 'show_drop_zone' });
        } else if (settings.file) {
          const blob = await StreamdeckHelper.base64ToBlob(
            settings.file,
            settings.fileType
          );
          const file = new File([blob], 'file', { type: settings.fileType });
          if (settings.fileType.startsWith('video/')) {
            handleEvent({ type: 'presentation_drop_media', files: [file] });
            if (settings.autostart === true) {
              setAutoStartPresent('media');
            }
          } else {
            file.path = file.name;
            handleEvent({ type: 'presentation_drop', files: [file] });
            if (settings.autostart === true) {
              setAutoStartPresent('image');
            }
          }
        }
      } else if (type === 'snapshot') {
        handleEvent({ type: 'snapshot' });
      } else if (type === 'muteall') {
        handleEvent({ type: 'request_stfu' });
      } else if (type === 'virtualbackgroundtoggle') {
        if (virtualBackground !== 'off') {
          LocalStorage.store('virtualBackgroundType', 'off');
          handleEvent({
            type: 'device_update',
            virtualBackground: 'off',
            sinkId,
          });
        } else {
          LocalStorage.store('virtualBackgroundType', settings.type);
          handleEvent({
            type: 'device_update',
            virtualBackground: settings.type,
            sinkId,
          });
        }
      } else if (type === 'lockmeeting' && !isLocked) {
        handleEvent({ type: 'lock_meeting' });
      }
    },
    [
      handleEvent,
      recording,
      hasPresenter,
      screenAddition,
      screenAsVideo,
      live_stream,
      eyeson,
      virtualBackground,
      sinkId,
      isLocked,
    ]
  );

  useEffect(() => {
    if (autoStartPresent && presentationStream) {
      handleEvent({ type: 'start_presenting', name: autoStartPresent });
      handleEvent({ type: 'set_presenting', state: true });
      setAutoStartPresent(null);
    }
  }, [autoStartPresent, presentationStream, handleEvent]);

  useEffect(() => {
    StreamdeckHelper.onEvent(handleStreamdeckEvent);
    return () => {
      StreamdeckHelper.offEvent(handleStreamdeckEvent);
    };
  }, [handleStreamdeckEvent]);

  const videoEnabled = !(eco || screen_capture || toggleVideoDisabled);
  const recordingEnabled =
    options.recording_available === true && !hideRecording && !disableRecording;
  const broadcastEnabled = options.broadcast_available === true;
  const screenshareEnabled =
    !hideScreenshare && !eco && environment.canScreenCapture;
  const screenshareActive = hasPresenter || screenAddition || screenAsVideo;
  const presentationEnabled = !hideScreenshare && !eco;
  const reactionEnabled =
    options.reaction_available === true && !(hasPresenter || eco);
  const layoutEnabled =
    options.layout_available === true && !hideLayoutSettings;
  const virtualBackgroundEnabled = !eco && !screenAsVideo;
  const chatEnabled = !hideChat;
  const guestEnabled =
    !!eyeson.room.guest_token && !hideGuestLogin && !isLocked;
  const lockMeetingEnabled = options.lock_available;

  useEffect(() => {
    if (StreamdeckHelper.isConnected()) {
      StreamdeckHelper.setActions({
        audiotoggle: { enabled: true, state: audio },
        videotoggle: { enabled: videoEnabled, state: video },
        recordtoggle: { enabled: recordingEnabled, state: !recording },
        broadcasttoggle: { enabled: broadcastEnabled, state: !live_stream },
        playbacktoggle: { enabled: true, state: true },
        presenttoggle: { enabled: presentationEnabled, state: !hasPresenter },
        screensharetoggle: {
          enabled: screenshareEnabled,
          state: !screenshareActive,
        },
        setlayout: { enabled: layoutEnabled, state: true },
        setlayoutmap: { enabled: layoutEnabled, state: true },
        setlayer: { enabled: true, state: true },
        watermark: { enabled: true, state: true },
        clearlayer: { enabled: true, state: true },
        chatmessage: { enabled: chatEnabled, state: true },
        reaction: { enabled: reactionEnabled, state: true },
        snapshot: { enabled: !hideSnapshotButton, state: true },
        muteall: { enabled: true, state: true },
        copyguestlink: { enabled: guestEnabled, state: true },
        virtualbackgroundtoggle: {
          enabled: virtualBackgroundEnabled,
          state: virtualBackground === 'off',
        },
        lockmeeting: { enabled: lockMeetingEnabled, state: !isLocked },
      });
    }
  }, [
    audio,
    video,
    videoEnabled,
    reactionEnabled,
    recording,
    recordingEnabled,
    broadcastEnabled,
    live_stream,
    presentationEnabled,
    hasPresenter,
    hideSnapshotButton,
    layoutEnabled,
    screenshareEnabled,
    screenshareActive,
    virtualBackgroundEnabled,
    virtualBackground,
    chatEnabled,
    guestEnabled,
    lockMeetingEnabled,
    isLocked,
  ]);

  return null;
};

export default Streamdeck;
