import I18n from 'i18n-js';
import React from 'react';
import Button from '../generic/Button.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    fill: 'white',
    width: '10rem',
  },
}));

const JoinButton = ({ action = 'default', disabled = false, onClick }) => {
  const classes = useStyles();
  return (
    <Button
      action={action}
      onClick={onClick}
      disabled={disabled}
      className={classes.root}
      aria-label={I18n.t('aria:label:join_room', {
        defaultValue: 'Join meeting',
      })}
    >
      {I18n.t('label:join_room')}
    </Button>
  );
};

export default JoinButton;
