import PropTypes from 'prop-types';
import { ItemTypes } from './LayoutPosition.js';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import React, { Component } from 'react';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

const userSource = {
  beginDrag(props) {
    return props.user;
  },
};

function collect(connect, monitor) {
  return {
    isDragging: monitor.isDragging(),
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

class LayoutUser extends Component {
  componentDidMount() {
    // Use empty image as a drag preview so browsers don't draw it
    // and we can draw whatever we want on the custom drag layer instead.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true,
    });
  }

  handleDoubleClick = () => this.props.onDoubleClick(this.props.user);

  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      this.props.onKeyDown(this.props.user);
    }
  };

  render() {
    const { user, position, className, connectDragSource, isSpeaker } =
      this.props;
    const chipStyle = { maxWidth: '100%' };
    if (isSpeaker) {
      chipStyle.height = '5rem';
    }
    return connectDragSource(
      <div
        onDoubleClick={this.handleDoubleClick}
        onKeyDown={this.handleKeyDown}
        className={className}
        style={{ maxWidth: '100%' }}
      >
        <Chip
          label={user.name}
          color={position === -1 ? 'secondary' : 'default'}
          avatar={<Avatar src={user.avatar} alt={user.name} />}
          onDelete={position === -1 ? this.handleDoubleClick : null}
          aria-pressed={position === -1}
          clickable
          style={chipStyle}
        />
      </div>
    );
  }
}

LayoutUser.propTypes = {
  isDragging: PropTypes.bool.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
};

export default DragSource(ItemTypes.USER, userSource, collect)(LayoutUser);
