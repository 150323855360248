import ComApi from '../ComApi.js';
import Logger from '../Logger.js';
import PublicEvent from './PublicEvent.js';

/**
 * Request permalink meeting info from user or guest token.
 **/
class RequestPermalinkMeetingInfoEvent extends PublicEvent {
  handle(msg) {
    const api = new ComApi(msg.api, msg.token);
    // eslint-disable-next-line max-statements
    const callback = response => {
      if (response.error) {
        Logger.debug('requestPermalinkMeetingInfo::error', response.error);
        super.handle({
          type: 'error',
          content: 'Request permalink meeting info failed'
        });
        return;
      }
      if (typeof response.permalink.expires_at === 'string') {
        const expires = Date.parse(response.permalink.expires_at);
        if (!isNaN(expires) && expires < Date.now()) {
          Logger.debug(
            'requestPermalinkMeetingInfo::expired',
            response.permalink
          );
          super.handle({
            type: 'error',
            content: 'Permalink is already expired'
          });
          return;
        }
      }
      Logger.debug('requestPermalinkMeetingInfo::info', response.room);
      super.handle({ type: 'permalink_meeting_info', room: response.room });
      if (response.room.started_at === null) {
        setTimeout(() => {
          api.requestPermalinkMeetingInfo(callback);
        }, 5000);
      }
    };
    Logger.debug('requestPermalinkMeetingInfo::start');
    api.requestPermalinkMeetingInfo(callback);
  }
}

export default RequestPermalinkMeetingInfoEvent;
