import eyeson from 'eyeson';

let _active = false;

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
const beforeUnloadHandler = (event) => {
  // console.log(eyeson);
  event.preventDefault();
  event.stopImmediatePropagation();

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};

const unloadHandler = () => {
  eyeson.session.terminateSession();
};

const enable = () => {
  if (_active) {
    return;
  }
  try {
    // terminateSession is not executed in firefox
    // firefox could use sendBeacon, but a comAPI POST handler would be needed
    // safari already navigates away 🤦, even if user clicks cancel
    const isRecentChromium =
      Reflect.has(navigator, 'userAgentData') &&
      navigator.userAgentData.brands.some(({ brand }) => brand === 'Chromium');
    if (!isRecentChromium) {
      return;
    }
    window.addEventListener('beforeunload', beforeUnloadHandler);
    window.addEventListener('pagehide', unloadHandler);
    _active = true;
  } catch (error) {}
};

const disable = () => {
  if (!_active) {
    return;
  }
  window.removeEventListener('beforeunload', beforeUnloadHandler);
  window.removeEventListener('pagehide', unloadHandler);
  _active = false;
};

const beforeUnloadHelper = { enable, disable };

export default beforeUnloadHelper;
