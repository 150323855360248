import I18n from 'i18n-js';
import eyeson, { FeatureDetector, Logger } from 'eyeson';

const inIframe = FeatureDetector.inIframe();

const parseOrigin = (url) => {
  if (!url) {
    return null;
  }
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.origin;
  } catch (e) {}
  return url;
};

const checkPresentationFiles = (files) => {
  if (!Array.isArray(files)) {
    return [];
  }
  const newFiles = files.filter(
    (file) => file instanceof Blob && file.size > 0
  );
  const pdfFiles = newFiles.filter((file) => file.type === 'application/pdf');
  if (pdfFiles.length > 0) {
    pdfFiles.length = 1; // max 1
    return pdfFiles;
  }
  const videoFiles = newFiles.filter((file) => file.type.startsWith('video/'));
  if (videoFiles.length > 0) {
    videoFiles.length = 1; // max 1
    return videoFiles;
  }
  const imageFiles = newFiles.filter(
    (file) => file.type.startsWith('image/') || /\.heic$/i.test(file.name)
  );
  if (imageFiles.length > 25) {
    imageFiles.length = 25; // max 25
  }
  return imageFiles;
};

let _authenticated = false;
let _interCommunicationHelper = null;

class InterCommunicationHelper {
  constructor() {
    this.isPhone = FeatureDetector.isPhone();
    this.inMeeting = false;
    this.channel = window.top;
    this.listeners = [];
    this.postmessageOrigin = null;
    this.postmessageOriginSetting = null;
    this.features = {
      present: this.isPhone ? false : true,
      chat: this.isPhone ? false : true,
      snapshot: this.isPhone ? false : true,
    };
    this.token = null;
    this.boundHandleMessage = this.handleMessage.bind(this);
  }

  init() {
    if (inIframe) {
      window.addEventListener('message', this.boundHandleMessage);
      this.channel.postMessage({ type: 'hello' }, '*');
    }
  }

  setOptions(options) {
    if (!options.custom_fields || _authenticated) {
      return;
    }
    const { iframe_postmessage_origin, hide_chat, hide_snapshot } =
      options.custom_fields;
    if (hide_chat === true || hide_chat === 'true') {
      this.features.chat = false;
    }
    if (hide_snapshot === true || hide_snapshot === 'true') {
      this.features.snapshot = false;
    }
    if (iframe_postmessage_origin) {
      const postmessageOrigin = parseOrigin(iframe_postmessage_origin);
      this.postmessageOriginSetting = postmessageOrigin;
      if (postmessageOrigin === this.postmessageOrigin) {
        _authenticated = true;
        this.send({ type: 'authenticated', features: this.features });
      }
    }
  }

  send(message) {
    if (this.channel && this.postmessageOrigin) {
      this.channel.postMessage(message, this.postmessageOrigin);
    }
  }

  handleMessage({ source, origin, data }) {
    // only execute messages from parent frame
    if (source === window) {
      return;
    }
    if (typeof data === 'object' && data !== null) {
      const { type } = data;
      try {
        if (type === 'init') {
          this.channel = source;
          // "null" string if parent is "file://"
          // https://stackoverflow.com/questions/52029536/window-top-postmessage-sends-null-as-its-event-origin
          this.postmessageOrigin = origin === 'null' ? '*' : origin;
          this.token = data.token;
          this.send({ type: 'response', action: 'init', status: 'success' });
        } else if (type === 'present' && !!data.file) {
          // legacy has no init event
          if (
            !this.postmessageOrigin &&
            this.postmessageOriginSetting === origin
          ) {
            this.postmessageOrigin = origin;
            _authenticated = true;
          }
          this.handleLegacyPresent(data);
        } else if (type === 'present' && !!data.files) {
          this.handlePresent(data);
        } else if (type === 'chat' && !!data.message) {
          this.handleChat(data);
        } else if (type === 'snapshot') {
          this.handleSnapshot();
        } else if (type === 'close' && this.inMeeting) {
          this.emit({ type: 'exit_room' });
        }
      } catch (error) {
        Logger.error(error);
        this.send({
          type: 'response',
          action: type,
          status: 'failed',
          reason: new DOMException('The operation failed', 'UnknownError'),
        });
      }
    }
  }

  handleLegacyPresent(data) {
    // legacy for everis digital desk
    const { file } = data;
    if (
      !_authenticated ||
      !this.inMeeting ||
      !(file instanceof Blob) ||
      !this.features.present
    ) {
      const reason = !_authenticated
        ? new DOMException('Authentication error', 'NotAllowedError')
        : !this.inMeeting
        ? new DOMException('Invalid state', 'InvalidStateError')
        : !(file instanceof Blob)
        ? new TypeError('Invalid file argument')
        : new DOMException('Not available', 'NotAllowedError');
      Logger.warn('InterCommunicationHelper::handleLegacyPresent', reason);
      this.send({
        type: 'response',
        action: 'present',
        status: 'failed',
        reason,
      });
      return;
    }
    if (file.type !== 'application/pdf' || file.size === 0) {
      const reason = new TypeError('Invalid file argument');
      Logger.warn('InterCommunicationHelper::handleLegacyPresent', reason);
      this.send({
        type: 'response',
        action: 'present',
        status: 'failed',
        reason,
      });
      this.emit({
        type: 'presentation_drop_error',
        message: file.name + ' ' + I18n.t('error:file_rejected'),
      });
      return;
    }
    file.path = file.name;
    this.emit({ type: 'presentation_drop', files: [file] });
    this.send({ type: 'response', action: 'present', status: 'success' });
  }

  handlePresent(data) {
    if (!_authenticated || !this.inMeeting || !this.features.present) {
      const reason = !_authenticated
        ? new DOMException('Authentication error', 'NotAllowedError')
        : !this.inMeeting
        ? new DOMException('Invalid state', 'InvalidStateError')
        : new DOMException('Not available', 'NotAllowedError');
      Logger.warn('InterCommunicationHelper::handlePresent', reason);
      this.send({
        type: 'response',
        action: 'present',
        status: 'failed',
        reason,
      });
      return;
    }
    const newFiles = checkPresentationFiles(data.files);
    if (newFiles.length === 0) {
      const reason = new TypeError('Invalid files argument');
      Logger.warn('InterCommunicationHelper::handlePresent', reason);
      this.send({
        type: 'response',
        action: 'present',
        status: 'failed',
        reason,
      });
      this.emit({
        type: 'presentation_drop_error',
        message: I18n.t('error:file_rejected'),
      });
      return;
    }
    if (newFiles[0].type.startsWith('video/')) {
      this.emit({ type: 'presentation_drop_media', files: newFiles });
    } else {
      newFiles.forEach((file) => {
        file.path = file.name;
      });
      this.emit({ type: 'presentation_drop', files: newFiles });
    }
    this.send({ type: 'response', action: 'present', status: 'success' });
  }

  handleChat(data) {
    const { message } = data;
    if (
      !_authenticated ||
      !this.inMeeting ||
      !this.features.chat ||
      typeof message !== 'string'
    ) {
      const reason = !_authenticated
        ? new DOMException('Authentication error', 'NotAllowedError')
        : !this.inMeeting
        ? new DOMException('Invalid state', 'InvalidStateError')
        : typeof message !== 'string'
        ? new TypeError('Invalid message type')
        : new DOMException('Not available', 'NotAllowedError');
      Logger.warn('InterCommunicationHelper::handleChat', reason);
      this.send({ type: 'response', action: 'chat', status: 'failed', reason });
      return;
    }
    this.emit({ type: 'send_chat', content: message.trim() });
    this.send({ type: 'response', action: 'chat', status: 'success' });
  }

  handleSnapshot() {
    if (!_authenticated || !this.inMeeting || !this.features.snapshot) {
      const reason = !_authenticated
        ? new DOMException('Authentication error', 'NotAllowedError')
        : !this.inMeeting
        ? new DOMException('Invalid state', 'InvalidStateError')
        : new DOMException('Not available', 'NotAllowedError');
      Logger.warn('InterCommunicationHelper::handleSnapshot', reason);
      this.send({
        type: 'response',
        action: 'snapshot',
        status: 'failed',
        reason,
      });
      return;
    }
    this.emit({ type: 'snapshot' });
    this.send({ type: 'response', action: 'snapshot', status: 'success' });
  }

  onEvent(listener) {
    if (this.postmessageOriginSetting && inIframe) {
      this.listeners.push(listener);
    }
  }

  offEvent(listener) {
    this.listeners = this.listeners.filter((fn) => fn !== listener);
  }

  emit(message) {
    this.listeners.forEach((listener) => listener(message));
  }

  onMeetingJoin() {
    this.inMeeting = true;
    if (_authenticated) {
      this.send({ type: 'join' });
      if (this.token && this.token !== eyeson.core.comApi.token) {
        this.token = eyeson.core.comApi.token;
        const user = {
          accessKey: this.token,
          id: eyeson.user.id,
          name: eyeson.user.name,
        };
        this.send({ type: 'userchange', user });
      }
    }
  }

  onMeetingLeave(reason) {
    const wasInMeeting = this.inMeeting;
    this.inMeeting = false;
    if (_authenticated || !wasInMeeting) {
      this.send({ type: 'leave', reason });
    }
  }

  onFullscreen(isFullscreen) {
    if (_authenticated) {
      this.send({ type: 'fullscreen', isFullscreen });
    }
  }

  destroy() {
    window.removeEventListener('message', this.boundHandleMessage);
    this.inMeeting = false;
    this.channel = null;
    this.listeners.length = 0;
    this.postmessageOrigin = null;
    this.postmessageOriginSetting = null;
    _authenticated = false;
  }
}

const instance = {
  init: (options) => {
    if (inIframe && !_interCommunicationHelper) {
      _interCommunicationHelper = new InterCommunicationHelper();
      _interCommunicationHelper.init(options);
    }
  },
  setOptions: (options) => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.setOptions(options);
    }
  },
  onFullscreen: (isFullscreen) => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.onFullscreen(isFullscreen);
    }
  },
  onEvent: (handler) => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.onEvent(handler);
    }
  },
  offEvent: (handler) => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.offEvent(handler);
    }
  },
  onMeetingJoin: () => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.onMeetingJoin();
    }
  },
  onMeetingLeave: (reason) => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.onMeetingLeave(reason);
    }
  },
  destroy: () => {
    if (_interCommunicationHelper) {
      _interCommunicationHelper.destroy();
      _interCommunicationHelper = null;
    }
  },
};

export default instance;
