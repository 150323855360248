import I18n from 'i18n-js';
import React from 'react';
import { ReactComponent as Arrow } from '../../../assets/arrow.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1rem 0',
    fontStyle: 'italic',
    textAlign: 'center',
  },
  svg: {
    transform: 'rotate(100deg) scale(1.5)',
  },
}));

const DragAndDropHint = () => {
  const classes = useStyles();
  return (
    <div className={classes.hint}>
      <span className={classes.svg}>
        <Arrow />
      </span>
      <span>{I18n.t('label:drag_and_drop')}</span>
    </div>
  );
};

export default DragAndDropHint;
