import React from 'react';
import Icon from '../generic/Icon.js';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import DevicePicker from '../device_settings/DevicePicker.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: '1',
    overflow: 'hidden',
    '@media (min-height: 650px) and (min-width: 800px)': {
      padding: '1rem 0.5rem 1rem 0.5rem',
    },
  },
  headerSection: {
    display: 'flex',
    'justify-content': 'space-between',
    'flex-flow': 'column',
    'align-items': 'center',
    minHeight: '8rem',
    '@media (max-height: 550px) and (max-width: 800px)': {
      'flex-flow': 'row',
      minHeight: '4rem',
    },
  },
  label: {
    '@media (max-height: 550px) and (max-width: 800px)': {
      display: 'none',
    },
  },
}));

const DeviceTile = ({
  icon,
  label,
  pickerLabel,
  stream,
  sinkId,
  devices,
  disabled,
  hasError,
  selected,
  onChange,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <Typography variant="overline" className={classes.label}>
          {label}
        </Typography>
        <Icon type={icon} title={label} fontSize="large" />
        {children}
      </div>
      <DevicePicker
        label={pickerLabel}
        stream={stream}
        sinkId={sinkId}
        devices={devices}
        hasError={hasError}
        selected={selected}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

DeviceTile.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  pickerLabel: PropTypes.string,
  stream: PropTypes.object,
  sinkId: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default DeviceTile;
