import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EmojiButton from './EmojiButton.js';
import ReactionPicker from './ReactionPicker.js';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PlaybackHelper from '../../utils/PlaybackHelper.js';
import StreamdeckHelper from '../../utils/StreamdeckHelper.js';

const reactionList = [];

// NOTE: https://material-ui.com/components/click-away-listener/ o.o
const ReactionPickerButton = ({
  locked,
  eyeson,
  visible,
  tooltip,
  onEvent,
  reactions,
}) => {
  const [buttonClicked, setButtonClicked] = useState(false);
  const helperButtonEl = useRef(null);
  const buttonEl = useRef(null);
  const pickerEl = useRef(null);
  const [active, setActive] = useState(reactions[0]);
  const helper = useMemo(
    () => new PlaybackHelper(eyeson.links && eyeson.links.self),
    [eyeson.links]
  );

  const setReactionList = (index, element) => {
    reactionList[index] = element;
  };

  const handleClick = async (reaction) => {
    if (locked) {
      return;
    }
    Logger.warn(reaction);
    buttonEl.current.blur();
    handleReaction(reaction);
  };

  const handleTouch = () => onEvent({ type: 'toggle_reaction_picker' });

  const handleReaction = useCallback(
    async (reaction) => {
      let gif = null;
      try {
        gif = await helper.randomGifForQuery(reaction.query);
      } catch (e) {
        Logger.error(e);
        return;
      }

      onEvent({
        type: 'playback_start',
        reaction: reaction,
        playback: { url: gif.images.original_mp4.mp4 },
        gif: gif,
      });
      onEvent({ type: 'hide_reaction_picker' });
      setActive(reaction);
    },
    [helper, onEvent]
  );

  const handleMouseEnter = () => {
    if (locked) {
      return;
    }
    onEvent({ type: 'show_reaction_picker' });
  };

  const handleMouseLeave = () => {
    if (locked) {
      return;
    }
    onEvent({ type: 'hide_reaction_picker' });
  };

  const handleButtonClick = () => {
    if (locked) {
      return;
    }
    setButtonClicked(true);
    onEvent({
      type: visible ? 'hide_reaction_picker' : 'show_reaction_picker',
    });
  };

  const handleReactionsKeyDown = (event) => {
    const { key } = event;
    const currentEl = event.target;
    if (key === 'Tab' && currentEl) {
      onEvent({ type: 'hide_reaction_picker' });
      return;
    }
    if (!key.startsWith('Arrow')) {
      return;
    }
    let index = reactionList.indexOf(currentEl);
    if (key === 'ArrowRight' || key === 'ArrowDown') {
      index = index === -1 || index >= reactionList.length - 1 ? 0 : index + 1;
    } else if (key === 'ArrowLeft' || key === 'ArrowUp') {
      index = index <= 0 ? reactionList.length - 1 : index - 1;
    }
    if (reactionList[index]) {
      reactionList[index].focus();
    }
  };

  useEffect(() => onEvent({ type: 'hide_reaction_picker' }), [locked, onEvent]);

  useEffect(() => {
    if (!buttonClicked) {
      return;
    }
    if (visible && reactionList.length > 0) {
      reactionList[0].focus();
    }
    if (!visible) {
      if (helperButtonEl) {
        helperButtonEl.current.focus();
      }
      setButtonClicked(false);
    }
  }, [visible, buttonClicked]);

  useEffect(() => {
    const handleStreamdeckEvent = (event) => {
      if (event.type === 'reaction') {
        const reaction = reactions.find(
          (entry) => entry.label === event.settings.reaction
        );
        if (reaction) {
          handleReaction(reaction);
        }
      }
    };
    StreamdeckHelper.onEvent(handleStreamdeckEvent);
    return () => {
      StreamdeckHelper.offEvent(handleStreamdeckEvent);
    };
  }, [reactions, handleReaction]);

  return (
    <div
      className="eyeson-reaction-picker"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip title={tooltip || I18n.t('label:reactions')} placement="right">
        <button
          ref={helperButtonEl}
          type="button"
          onClick={handleButtonClick}
          className="sr"
          aria-label={
            tooltip ||
            I18n.t('label:reactions', { defaultValue: 'GIF reactions' })
          }
          aria-haspopup="menu"
          aria-controls="reaction-picker"
          aria-disabled={locked ? true : null}
          aria-pressed={locked ? null : visible}
        >
          {I18n.t('label:reactions')}
        </button>
      </Tooltip>
      <Tooltip title={tooltip} placement="right">
        <div>
          <EmojiButton
            ref={buttonEl}
            emoji={active.emoji}
            onTouch={handleTouch}
            onClick={() => handleClick(active)}
            ariaLabel={I18n.t(`label:reaction:${active.label}`, {
              defaultValue: active.label,
            })}
            className={classNames('eyeson-action-button', {
              locked: locked,
            })}
            tabIndex="-1"
          />
        </div>
      </Tooltip>
      {visible && (
        <ReactionPicker
          reactions={reactions}
          onClick={handleClick}
          onKeyDown={handleReactionsKeyDown}
          id="reaction-picker"
          ref={pickerEl}
          setReactionList={setReactionList}
        />
      )}
    </div>
  );
};

ReactionPickerButton.propTypes = {
  tooltip: PropTypes.string,
  reactions: PropTypes.array,
};

ReactionPickerButton.defaultProps = {
  locked: false,
  visible: false,
  tooltip: 'tooltip',
  reactions: [
    { emoji: '👋', query: 'hello', label: 'hello' },
    { emoji: '👍', query: 'thumbs up', label: 'thumbs_up' },
    { emoji: '👎', query: 'thumbs down', label: 'thumbs_down' },
    { emoji: '🤔', query: 'think', label: 'thinking' },

    { emoji: '🤣', query: 'laughing', label: 'laughing' },
    { emoji: '😘', query: 'kisses', label: 'kiss' },
    { emoji: '😉', query: 'wink', label: 'wink' },
    { emoji: '😢', query: 'sad', label: 'sad' },

    { emoji: '😠', query: 'angry', label: 'angry' },
    { emoji: '😱', query: 'shocked', label: 'shocked' },
    { emoji: '😴', query: 'tired', label: 'tired' },
    { emoji: '🤦', query: 'facepalm', label: 'facepalm' },
  ],
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.visible === nextProps.visible &&
    prevProps.locked === nextProps.locked
  );
}

export default React.memo(ReactionPickerButton, areEqual);
