import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import StickyNote from '../dialog/StickyNote.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
  },
});

const StickyNotes = ({ recording, presenting, liveStream }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <StickyNote
        title={I18n.t('label:recording')}
        visible={recording}
        subtitle={I18n.t('label:active_in_room')}
      />
      <StickyNote
        title={I18n.t('label:presentation')}
        visible={presenting}
        subtitle={I18n.t('label:active_in_room')}
      />
      <StickyNote
        title={I18n.t('label:live_stream')}
        visible={liveStream}
        subtitle={I18n.t('label:active_in_room')}
      />
    </section>
  );
};

StickyNotes.propTypes = {
  recording: PropTypes.bool,
  presenting: PropTypes.bool,
  liveStream: PropTypes.bool,
};

StickyNotes.defaultProps = {
  recording: false,
  presenting: false,
  liveStream: false,
};

export default StickyNotes;
