import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AttributionMessage extends Component {
  /**
   * An attribution message starts with "/me ".
   **/
  static test(props) {
    return props.content.substr(0, 4) === '/me ';
  }

  get content() {
    return this.props.content.substr(4);
  }

  /**
   * Render a message that describes an action from first person, initialized
   * by
   **/
  render() {
    return (
      <div className="eyeson-chat-message attribution">
        <strong>{this.props.user.name}</strong>{' '}
        <span className="body text--italic">{this.content}</span>
      </div>
    );
  }
}

AttributionMessage.propTypes = {
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
};

export default AttributionMessage;
