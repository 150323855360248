import React from 'react';
import { ConnectionStatistics } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';
import BadSignalIcon from '@material-ui/icons/SignalCellularConnectedNoInternet1Bar';
import SignalIcon from '@material-ui/icons/SignalCellularAlt';
import NoSignalIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';

const { Status } = ConnectionStatistics;

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1em',
    height: '1em',
    color: theme.palette.action.active,
    position: 'relative',
  },
  status: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    marginLeft: (data) => {
      return data.status === Status.BAD || data.status === Status.NO_CONNECTION
        ? '.35em'
        : '.2em';
    },
    marginTop: (data) => {
      return data.status === Status.BAD || data.status === Status.NO_CONNECTION
        ? '.1em'
        : '0';
    },
    width: '12px',
    height: '12px',
    backgroundColor: (data) => {
      if (data.status === Status.BAD || data.status === Status.NO_CONNECTION) {
        return '#e53729';
      }
      if (data.status === Status.OK) {
        return '#c7bb2a';
      }
      return '#08C008';
    },
    borderRadius: '50%',
    border: `2px solid ${
      theme.palette.type === 'light'
        ? theme.palette.grey['100']
        : theme.palette.grey['900']
    }`,
    transition: 'border-color 400ms ease',
  },
}));

const MobileConnectionInfoIcon = ({ data }) => {
  const classes = useStyles(data);
  let signalIcon = <SignalIcon />;
  if (data.status === Status.BAD) {
    signalIcon = <BadSignalIcon />;
  } else if (data.status === Status.NO_CONNECTION) {
    signalIcon = <NoSignalIcon />;
  }
  return (
    <span className={classes.icon}>
      <span className={classes.status}></span>
      {signalIcon}
    </span>
  );
};

export default MobileConnectionInfoIcon;
