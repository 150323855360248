import Logger from './../Logger.js';

class BaseEvent {
  constructor(context) {
    this.context = context;
  }

  handle(msg) {
    Logger.error('BaseEvent::handle is not implemented', msg);
  }
}

export default BaseEvent;
