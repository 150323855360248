import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import { withStyles } from '@material-ui/styles';

const inputProps = (trailingIcon, onTrailingIconClick) => {
  if (!trailingIcon) {
    return;
  }
  if (!onTrailingIconClick) {
    return {
      endAdornment: (
        <InputAdornment position="end">
          <Icon color="action">{trailingIcon}</Icon>
        </InputAdornment>
      ),
    };
  }

  return {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton edge="end" onClick={onTrailingIconClick}>
          <Icon color="action">{trailingIcon}</Icon>
        </IconButton>
      </InputAdornment>
    ),
  };
};

const CssTextField = withStyles((theme) => ({
  root: {
    marginBottom: '.75rem',
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
}))(MuiTextField);

const TextField = forwardRef((props, ref) => {
  const { trailingIcon, onTrailingIconClick, ...fieldProps } = props;
  return (
    <CssTextField
      {...fieldProps}
      ref={ref}
      InputProps={inputProps(trailingIcon, onTrailingIconClick)}
      onChange={(event) => props.onChange(event.target.value)}
      onKeyDown={props.onKeyDown}
      variant="filled"
    />
  );
});

TextField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  trailingIcon: PropTypes.string,
  onTrailingIconClick: PropTypes.func,
};

TextField.defaultProps = { fullWidth: false };

export default TextField;
