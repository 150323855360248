import PublicEvent from './PublicEvent.js';

class StartAnnotationEvent extends PublicEvent {
  handle() {
    this.context.annotationLoop = window.setInterval(() => {
      this.context._api.createAnnotation(
        response => {
          return super.handle({ type: 'annotation', annotation: response });
        },
        () => {
          window.clearInterval(this.context.annotationLoop);
        }
      );
    }, 1000);
  }
}

class StopAnnotationEvent extends PublicEvent {
  handle() {
    window.clearInterval(this.context.annotationLoop);
    this.context._api.stopAnnotation();
  }
}

export { StartAnnotationEvent, StopAnnotationEvent };
