import I18n from 'i18n-js';
import React from 'react';
import { FeatureDetector } from 'eyeson';
import HelpLink from '../generic/HelpLink.js';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

// https://stackoverflow.com/questions/32075597/alternative-text-for-screen-reader-in-a-given-element
const useStyles = makeStyles(() => ({
  kbd: {
    position: 'relative',
  },
  srOnly: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    opacity: '0.001',
  },
}));

const KeyCombo = ({ mod, primary, label }) => {
  const classes = useStyles();
  const modifier = `<${I18n.t('label:keyboard:' + mod)}>`;
  return (
    <div>
      <kbd className={classes.kbd}>
        <span className={classes.srOnly}>{label}</span>
        <span aria-hidden="true">
          {modifier} + {primary}
        </span>
      </kbd>
    </div>
  );
};

// Apparently I18n.t hasn't loaded at this point, so we invoke the lookup in
// render bellow
const rows = (
  options = {},
  quickJoin,
  hideChat,
  hideGuestLogin,
  hideScreenshare,
  classes
) => [
  {
    key: (
      <div>
        <kbd className={classes.kbd}>
          <span className={classes.srOnly}>Escape</span>
          <span aria-hidden="true">Esc</span>
        </kbd>
      </div>
    ),
    description: I18n.t('label:close_dialog'),
    condition: true,
  },
  {
    key: <KeyCombo mod="ctrl" primary="," label="Control+comma" />,
    description: I18n.t('label:toggle_video'),
    condition: true,
  },
  {
    key: <KeyCombo mod="ctrl" primary="." label="Control+dot" />,
    description: I18n.t('label:toggle_audio'),
    condition: true,
  },
  {
    key: (
      <div>
        <kbd className={classes.kbd}>
          <span className={classes.srOnly}>m</span>
          <span aria-hidden="true">m</span>
        </kbd>
      </div>
    ),
    description: I18n.t('label:push_to_talk'),
    condition: true,
  },
  {
    key: <KeyCombo mod="shift" primary="c" label="Shift+c" />,
    description: I18n.t('label:open_chat'),
    condition: !hideChat,
  },
  {
    key: <KeyCombo mod="shift" primary="g" label="Shift+g" />,
    description: I18n.t('label:show_giphy_dialog'),
    condition: options.reaction_available === true,
  },
  {
    key: <KeyCombo mod="shift" primary="l" label="Shift+l" />,
    description: I18n.t('label:show_live_stream'),
    condition: options.broadcast_available === true,
  },
  {
    key: <KeyCombo mod="shift" primary="s" label="Shift+s" />,
    description: I18n.t('label:start_screen_sharing'),
    condition: !hideScreenshare,
  },
  {
    key: <KeyCombo mod="shift" primary="f" label="Shift+f" />,
    description: I18n.t('label:show_files'),
    condition: true,
  },
  {
    key: <KeyCombo mod="shift" primary="t" label="Shift+t" />,
    description: I18n.t('label:toggle_dark_mode'),
    condition: true,
  },
  {
    key: <KeyCombo mod="shift" primary="a" label="Shift+a" />,
    description: I18n.t('snapshots:action'),
    condition: true,
  },
  {
    key: <KeyCombo mod="shift" primary="i" label="Shift+i" />,
    description: I18n.t('label:copy_guest_link'),
    condition: quickJoin && !hideGuestLogin,
  },
  {
    key: <KeyCombo mod="shift" primary="p" label="Shift+p" />,
    description: I18n.t('label:pip'),
    condition: FeatureDetector.hasPipSupport(),
  },
  {
    key: (
      <div>
        <kbd className={classes.kbd}>
          <span className={classes.srOnly}>ArrowRight</span>
          <span aria-hidden="true">⇨</span>
        </kbd>
      </div>
    ),
    description: I18n.t('label:next_slide'),
    condition: true,
  },
  {
    key: (
      <div>
        <kbd className={classes.kbd}>
          <span className={classes.srOnly}>ArrowLeft</span>
          <span aria-hidden="true">⇦</span>
        </kbd>
      </div>
    ),
    description: I18n.t('label:previous_slide'),
    condition: true,
  },
];

const HelpDialog = ({
  options,
  quickJoin,
  hideChat,
  hideGuestLogin,
  hideScreenshare,
}) => {
  const classes = useStyles();
  return (
    <>
      <DialogContentText>{I18n.t('message:help')}</DialogContentText>
      <Box mb={2}>
        <HelpLink
          label={I18n.t('label:help_center')}
          link="https://help.eyeson.com/"
        />
        &nbsp;
        <HelpLink
          label={I18n.t('label:quick_test')}
          link="https://app.eyeson.team/quicktest/"
        />
      </Box>
      <Divider />
      <Box mt={2} mb={2}>
        <Typography variant="subtitle1" id="help-dialog-keyboard-title">
          {I18n.t('dialog:title:keyboard')}
        </Typography>
      </Box>
      <DialogContentText id="help-dialog-keyboard-desc">
        {I18n.t('dialog:message:keyboard')}
      </DialogContentText>
      <Table
        aria-labelledby="help-dialog-keyboard-title"
        aria-describedby="help-dialog-keyboard-desc"
      >
        <TableBody>
          {rows(
            options,
            quickJoin,
            hideChat,
            hideGuestLogin,
            hideScreenshare,
            classes
          )
            .filter((row) => row.condition)
            .map((row) => (
              <TableRow key={row.description} hover={true}>
                <TableCell component="th" scope="row">
                  {row.key}
                </TableCell>
                <TableCell align="right">{row.description}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default HelpDialog;
