import React, { forwardRef } from 'react';
import TwitterEmoji from '../generic/TwitterEmoji.js';

export default forwardRef(
  (
    { emoji, onClick, onTouch, ariaRole, ariaLabel, className, tabIndex },
    ref
  ) => (
    <button
      type="button"
      ref={ref}
      onClick={onClick}
      className={className}
      role={ariaRole}
      aria-label={ariaLabel}
      onTouchStart={onTouch}
      tabIndex={tabIndex}
    >
      <TwitterEmoji emoji={emoji} label={ariaLabel} />
    </button>
  )
);
