import React from 'react';
import Avatar from '../generic/Avatar.js';
import SoundWave from './SoundWave.js';
import AudioStream from '../streams/AudioStream.js';

const MainAudioStream = ({
  user,
  sinkId,
  stream,
  onEvent,
  playback,
  voiceActivity,
  muted,
}) => {
  if (!user) {
    return null;
  }
  const isTalking = voiceActivity.on && user.apiId === voiceActivity.user.apiId;

  return (
    <div className="audio-stream main">
      <h2>{user.name}</h2>
      <div className="avatar">
        <Avatar
          url={user.guest ? null : user.largeAvatar}
          label={user.name}
          onEvent={onEvent}
          playback={playback}
        />
        <SoundWave active={isTalking} />
      </div>
      <AudioStream stream={stream} sinkId={sinkId} muted={muted} />
    </div>
  );
};

export default MainAudioStream;
