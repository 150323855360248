import React from 'react';
import Toolbar from './toolbar/Toolbar.js';
import MainView from './MainView.js';
import Draggable from './generic/Draggable.js';
import LocalStream from './streams/LocalStream.js';
import ExpansionPanels from './expansion_panels/ExpansionPanels.js';
import RightSidebar from './layout/RightSidebar.js';
import DialogWindowSelector from './DialogWindowSelector.js';
import PresentationDropzone from './PresentationDropzone.js';
import Notifications from './notifications/Notifications.js';
import AudioStream from './streams/AudioStream.js';
import { FeatureDetector } from 'eyeson';
import Streamdeck from './generic/Streamdeck.js';

const DesktopRoom = (props) => {
  const {
    user,
    config,
    eyeson,
    logoUrl,
    visible,
    isVisible,
    handleEvent,
    environment,
    mediaOptions: { eco },
    onInactivity,
    onThemeToggle,
  } = props;

  const usePersistentAudio = FeatureDetector.disallowAudioSyncSrcChange();
  const presentationActive =
    props.active.hasPresenter ||
    props.active.isPresenter ||
    props.canvasPresentation.files.length > 0 ||
    props.mediaPresentation.file !== null;

  return (
    <div className="eyeson-room">
      <Streamdeck {...props} />
      <Toolbar
        eco={eco}
        solo={props.solo}
        isPip={props.isPip}
        theme={props.theme}
        isLocked={props.isLocked}
        audio={props.active.audio}
        video={props.active.video}
        tabAudio={props.active.tabAudio}
        screenAsVideo={props.active.screenAsVideo}
        screenAddition={props.active.screenAddition}
        options={props.options}
        eyeson={eyeson}
        canPip={environment.canPip}
        hidden={props.hidden}
        logoUrl={logoUrl}
        onEvent={handleEvent}
        visible={props.visible}
        feedback={Boolean(props.feedbackUrl)}
        canReact={props.options && props.options.reaction_available}
        minified={props.minified}
        collapsed={!isVisible('collapsed_toolbar')}
        recording={props.active.recording}
        broadcasts={props.broadcasts}
        isIOSDevice={environment.isIOSDevice}
        isPresenter={props.active.isPresenter}
        live_stream={props.active.live_stream}
        hasPresenter={props.active.hasPresenter}
        videoSupport={props.videoSupport}
        showBranding={props.showBranding}
        isFullscreen={props.isFullscreen}
        audioSupport={props.audioSupport}
        hideRecording={props.hideRecording}
        disableRecording={props.disableRecording}
        hideGuestLogin={props.hideGuestLogin}
        hideScreenshare={props.hideScreenshare}
        hideLayoutSettings={props.hideLayoutSettings}
        screen_capture={props.active.screen_capture}
        hasAudioPosition={props.active.hasAudioPosition}
        recordingCreatedAt={props.recording.createdAt}
        forwardedVideoMuted={props.forwardedVideoMuted}
        talkingMuted={props.active.talkingMuted}
        toggleVideoDisabled={props.toggleVideoDisabled}
        activeRecording={props.active.recording}
      />
      <main role="main" id="main">
        <PresentationDropzone
          eco={eco}
          supportFiles={environment.canPresentFiles}
          supportMedia={environment.canStreamLocalMedia}
          onEvent={handleEvent}
          disabled={eco || presentationActive || props.active.screenAsVideo}
          zoneVisible={visible.dialog === 'drop_zone'}
        >
          <MainView
            eco={eco}
            panel={props.visible.panel !== ''}
            onEvent={handleEvent}
            isPhone={props.environment.isPhone}
            isVisible={isVisible}
            usePersistentAudio={usePersistentAudio}
            {...props}
          />
        </PresentationDropzone>

        <Draggable className="self-view">
          <LocalStream
            user={user}
            stream={props.localStream}
            active={props.active}
            visible={
              props.active.sfu &&
              !eco &&
              !props.solo &&
              !isVisible('right_sidebar') &&
              !props.active.hasPresenter
            }
            onEvent={handleEvent}
            playback={props.playback}
            className="self-view-video"
            participants={props.participants}
            voiceActivity={props.voiceActivity}
            virtualBackground={props.virtualBackground}
          />
        </Draggable>

        <DialogWindowSelector
          active={visible.dialog}
          eyeson={eyeson}
          config={config}
          context={{
            eco: eco,
            onEvent: handleEvent,
            environment: environment,
            feedbackUrl: props.feedbackUrl,
            onInactivity: onInactivity,
            onThemeToggle: onThemeToggle,
            ...props,
          }}
        />
        <Notifications
          {...environment}
          onEvent={handleEvent}
          openPanel={props.visible.panel}
          notifications={props.notifications}
        />
        {usePersistentAudio && (
          <AudioStream
            stream={props.stream}
            sinkId={props.sinkId}
            persistent={true}
            muted={false}
            sessionCount={props.sessionCount}
          />
        )}
      </main>

      {isVisible('right_sidebar') && (
        <RightSidebar {...props} usePersistentAudio={usePersistentAudio} />
      )}

      {!props.connecting && (
        <ExpansionPanels
          user={user}
          order={props.sortOrder}
          podium={props.videoSources}
          options={props.options}
          messages={props.messages}
          playback={props.playback}
          snapshots={props.snapshots}
          openPanel={props.visible.panel}
          gifEndpoint={eyeson.links.self}
          quickJoin={!!eyeson.room.guest_token}
          isGuest={eyeson.user.guest}
          isLocked={props.isLocked}
          hideChat={props.hideChat}
          handleEvent={handleEvent}
          setOpenPanel={props.setOpenPanel}
          participants={props.participants}
          voiceActivity={props.voiceActivity}
          isSFU={props.active.sfu}
          pressureState={props.pressureState}
          screenAsVideo={props.active.screenAsVideo}
          hideGuestLogin={props.hideGuestLogin}
          presentationActive={presentationActive}
          hideSnapshotButton={props.hideSnapshotButton}
          connectionStatistics={props.connectionStatistics}
        />
      )}
    </div>
  );
};

function areEqual(prevProps, nextProps) {
  return prevProps.connecting && nextProps.connecting;
}

export default React.memo(DesktopRoom, areEqual);
