import GuestNameData from '../assets/guestnames.json';
import random from './randomArrayElement.js';

const GuestNameGenerator = () => {
  const prefix = random(GuestNameData['prefix']);
  const descriptor = random(GuestNameData['descriptor']);
  return `${prefix} ${descriptor}`;
};

export default GuestNameGenerator;
