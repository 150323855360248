import Logger from '../Logger.js';
import PublicEvent from './PublicEvent.js';

class StopRtmpEvent extends PublicEvent {
  handle(msg) {
    this.context._api
      .stopBroadcast('generic')
      .then(() => super.handle({ type: msg.type }))
      .catch(error => Logger.warn(error));
  }
}

export default StopRtmpEvent;
