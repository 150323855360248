import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AudioNotifications from './AudioNotifications.js';
import { withStyles } from '@material-ui/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[100],
  },
}))(Button);

const closeAction = (props) => (key) =>
  <StyledButton onClick={() => props.closeSnackbar(key)}>Close</StyledButton>;

export { closeAction };

const Notifications = ({ onEvent, openPanel, notifications: { audio } }) => (
  <AudioNotifications
    onEvent={onEvent}
    openPanel={openPanel}
    notifications={audio || []}
  />
);

Notifications.propTypes = {
  notifications: PropTypes.shape({ audio: PropTypes.array }),
  onEvent: PropTypes.func,
};

export default Notifications;
