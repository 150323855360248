import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(2),
    overflow: 'auto',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
}));

const ScrollContainer = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <SimpleBar className={classes.root} {...props}>
      {children}
    </SimpleBar>
  );
  // return <div className={classes.root} {...props}>{children}</div>;
};

ScrollContainer.propTypes = { children: PropTypes.node.isRequired };

export default ScrollContainer;
