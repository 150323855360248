import I18n from 'i18n-js';
import React from 'react';
import PropTypes from 'prop-types';
import UiIcon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

/**
 * Icon allows repositioning by x and y props.
 **/
const icons = {
  'warning-tag': {
    viewBox: '0 0 20 20',
    content: (
      <g>
        <path fill="#FED62C" d="M6.11,0,0,10.16H12.22ZM5.56,9V7.94h1.1V9Z" />
        <rect fill="#000000" x="5.56" y="7.94" width="1.1" height="1.11" />
        <rect fill="#000000" x="5.56" y="3.5" width="1.1" height="3.33" />
      </g>
    ),
  },
  warning: {
    viewBox: '0 0 12 12',
    content: (
      <g>
        <path d="M6.11,0,0,10.16H12.22ZM5.56,9V7.94h1.1V9Z" />
        <rect fill="#404040" x="5.56" y="7.94" width="1.1" height="1.11" />
        <rect fill="#404040" x="5.56" y="3.5" width="1.1" height="3.33" />
      </g>
    ),
  },
  info: {
    viewBox: '0 0 12 12',
    content: (
      <g>
        <circle cx="6" cy="6" r="5" />
        <rect fill="#003bcc" x="5.56" y="7.94" width="1.1" height="1.11" />
        <rect fill="#003bcc" x="5.56" y="3.5" width="1.1" height="3.33" />
      </g>
    ),
  },
  info_2: {
    viewBox: '0 0 24 24',
    content: (
      <g id="surface1" fill="#f0f0f0">
        <path d="M 21.398438 10.601563 L 13.398438 2.601563 C 12.601563 1.800781 11.398438 1.800781 10.601563 2.601563 L 2.601563 10.601563 C 1.800781 11.398438 1.800781 12.601563 2.601563 13.398438 L 10.601563 21.398438 C 11.398438 22.199219 12.601563 22.199219 13.398438 21.398438 L 21.398438 13.398438 C 22.199219 12.601563 22.199219 11.398438 21.398438 10.601563 Z M 13 17 L 11 17 L 11 15 L 13 15 Z M 13 13 L 11 13 L 11 7 L 13 7 Z " />
      </g>
    ),
  },
  question: {
    viewBox: '0 0 24 24',
    content: (
      <g>
        <path d="M20,2H4C2.895,2,2,2.895,2,4v18l4-4h14c1.105,0,2-0.895,2-2V4C22,2.895,21.105,2,20,2z M13,14h-2v-2h2V14z M15,9 c0,0.38-0.21,0.72-0.55,0.89L13,10.62V11h-2v-1c0-0.38,0.21-0.72,0.55-0.89L13,8.38V7h-2v1.08H9V6c0-0.55,0.45-1,1-1h4 c0.55,0,1,0.45,1,1V9z" />
      </g>
    ),
  },
  presentation: {
    viewBox: '0 0 24 24',
    content: (
      <path d="M 2 2 L 2 4 L 22 4 L 22 2 Z M 2 5 L 2 17 C 2 18.105469 2.894531 19 4 19 L 11 19 L 11 22 L 13 22 L 13 19 L 20 19 C 21.105469 19 22 18.105469 22 17 L 22 5 Z M 4 7 L 20 7 L 20 16.5 C 20 16.777344 19.777344 17 19.5 17 L 4.5 17 C 4.222656 17 4 16.777344 4 16.5 Z M 13 8 L 13 10 L 19 10 L 19 8 Z M 8 9 C 6.34375 9 5 10.34375 5 12 C 5 13.65625 6.34375 15 8 15 C 9.65625 15 10.96875 13.640625 11 12 L 8 12 Z M 13 11 L 13 13 L 19 13 L 19 11 Z M 13 14 L 13 16 L 19 16 L 19 14 Z " />
    ),
  },
  recording: {
    viewBox: '0 0 24 24',
    content: (
      <g>
        <g>
          <defs>
            <rect id="SVGID_1_" x="-409" y="453" width="263" height="48" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g style={{ clipPath: 'url(#SVGID_2_)' }}>
            <image
              style={{ overflow: 'visible' }}
              width="263"
              height="48"
              transform="matrix(1 0 0 1 -409 453)"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_3_" x="-409" y="440" width="281" height="70" />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st0"
            d="M-1001,455h255c1.7,0,3,1.3,3,3v34c0,1.7-1.3,3-3,3h-255V455z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_5_"
              d="M-409,455h255c1.7,0,3,1.3,3,3v34c0,1.7-1.3,3-3,3h-255V455z"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st1">
            <defs>
              <rect
                id="SVGID_7_"
                x="-409"
                y="-514"
                width="1440"
                height="1024"
              />
            </defs>
            <clipPath id="SVGID_8_">
              <use xlinkHref="#SVGID_7_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect x="-414" y="450" className="st2" width="268" height="50" />
          </g>
        </g>
        <g>
          <defs>
            <circle id="SVGID_9_" cx="-373" cy="466.6" r="5.6" />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlinkHref="#SVGID_9_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st3">
            <defs>
              <rect id="SVGID_11_" x="-387" y="461" width="28" height="29" />
            </defs>
            <clipPath id="SVGID_12_">
              <use xlinkHref="#SVGID_11_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect
              x="-383.6"
              y="456"
              className="st4"
              width="21.2"
              height="21.2"
            />
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_13_"
              d="M-363.5,480.3c-0.9,0-1.7,0.3-2.2,1s-0.8,1.6-0.8,2.8c0,1.2,0.3,2.2,0.8,2.8c0.5,0.6,1.3,0.9,2.2,0.9
              c0.4,0,0.8,0,1.2-0.1s0.8-0.2,1.2-0.3v1.4c-0.8,0.3-1.7,0.4-2.7,0.4c-1.5,0-2.6-0.4-3.4-1.3s-1.2-2.2-1.2-3.8c0-1,0.2-1.9,0.6-2.7
              s0.9-1.4,1.7-1.8s1.6-0.6,2.5-0.6c1,0,2,0.2,2.8,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-1.1-0.4S-363.1,480.3-363.5,480.3z M-369.9,489
              h-5.7v-10h5.7v1.4h-4v2.7h3.8v1.4h-3.8v3.1h4V489z M-382.9,485v4h-1.6v-10h2.8c1.3,0,2.2,0.2,2.9,0.7c0.6,0.5,0.9,1.2,0.9,2.2
              c0,1.2-0.6,2.1-1.9,2.7l2.8,4.4h-1.9l-2.4-4H-382.9z M-382.9,483.7h1.1c0.8,0,1.3-0.1,1.7-0.4s0.5-0.7,0.5-1.3
              c0-0.6-0.2-1-0.6-1.2s-0.9-0.4-1.7-0.4h-1.1V483.7z"
            />
          </defs>
          <clipPath id="SVGID_14_">
            <use xlinkHref="#SVGID_13_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st5">
            <defs>
              <rect id="SVGID_15_" x="-387" y="461" width="28" height="29" />
            </defs>
            <clipPath id="SVGID_16_">
              <use xlinkHref="#SVGID_15_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect
              x="-389.5"
              y="473.9"
              className="st6"
              width="33.9"
              height="20.3"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_17_" x="-334" y="464" width="94" height="18" />
          </defs>
          <clipPath id="SVGID_18_">
            <use xlinkHref="#SVGID_17_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st7">
            <text transform="matrix(1 0 0 1 -334 481)" className="st8">
              REC
            </text>
          </g>
          <g className="st7">
            <text transform="matrix(1 0 0 1 -334 503)" className="st8">
              OR
            </text>
          </g>
          <g className="st7">
            <text transform="matrix(1 0 0 1 -334 525)" className="st8">
              DI
            </text>
          </g>
          <g className="st7">
            <text transform="matrix(1 0 0 1 -334 547)" className="st8">
              NG
            </text>
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_19_" x="-294" y="464" width="66" height="18" />
          </defs>
          <clipPath id="SVGID_20_">
            <use xlinkHref="#SVGID_19_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st9">
            <text transform="matrix(1 0 0 1 -294 481)" className="st8">
              00:12:21
            </text>
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_21_" x="-409" y="-514" width="1440" height="1024" />
          </defs>
          <clipPath id="SVGID_22_">
            <use xlinkHref="#SVGID_21_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st10">
            <text transform="matrix(1 0 0 1 -206.7031 481)">
              <tspan x="0" y="0" className="st8">
                S
              </tspan>
              <tspan x="8.8" y="0" className="st8">
                T
              </tspan>
              <tspan x="17.3" y="0" className="st8">
                OP
              </tspan>
            </text>
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_23_"
              d="M-214,463h53c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-53c-1.7,0-3-1.3-3-3v-18C-217,464.3-215.7,463-214,463z
              "
            />
          </defs>
          <clipPath id="SVGID_24_">
            <use xlinkHref="#SVGID_23_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st11">
            <defs>
              <rect
                id="SVGID_25_"
                x="-409"
                y="-514"
                width="1440"
                height="1024"
              />
            </defs>
            <clipPath id="SVGID_26_">
              <use xlinkHref="#SVGID_25_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path
              className="st12"
              d="M-214,463h53c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-53c-1.7,0-3-1.3-3-3v-18C-217,464.3-215.7,463-214,463z"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_27_" x="-408" y="453" width="263" height="48" />
          </defs>
          <clipPath id="SVGID_28_">
            <use xlinkHref="#SVGID_27_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g style={{ clipPath: 'url(#SVGID_28_)' }}>
            <image
              style={{ overflow: 'visible' }}
              width="263"
              height="48"
              transform="matrix(1 0 0 1 -408 453)"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_29_" x="-408" y="440" width="281" height="70" />
          </defs>
          <clipPath id="SVGID_30_">
            <use xlinkHref="#SVGID_29_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st13"
            d="M-1000,455h255c1.7,0,3,1.3,3,3v34c0,1.7-1.3,3-3,3h-255V455z"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_31_"
              d="M-408,455h255c1.7,0,3,1.3,3,3v34c0,1.7-1.3,3-3,3h-255V455z"
            />
          </defs>
          <clipPath id="SVGID_32_">
            <use xlinkHref="#SVGID_31_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st14">
            <defs>
              <rect
                id="SVGID_33_"
                x="-408"
                y="-514"
                width="1440"
                height="1024"
              />
            </defs>
            <clipPath id="SVGID_34_">
              <use xlinkHref="#SVGID_33_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect x="-413" y="450" className="st15" width="268" height="50" />
          </g>
        </g>
        <g>
          <defs>
            <circle id="SVGID_35_" cx="-372" cy="466.6" r="5.6" />
          </defs>
          <clipPath id="SVGID_36_">
            <use xlinkHref="#SVGID_35_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st16">
            <defs>
              <rect id="SVGID_37_" x="-386" y="461" width="28" height="29" />
            </defs>
            <clipPath id="SVGID_38_">
              <use xlinkHref="#SVGID_37_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect
              x="-382.6"
              y="456"
              className="st17"
              width="21.2"
              height="21.2"
            />
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_39_"
              d="M-362.5,480.3c-0.9,0-1.7,0.3-2.2,1s-0.8,1.6-0.8,2.8c0,1.2,0.3,2.2,0.8,2.8c0.5,0.6,1.3,0.9,2.2,0.9
              c0.4,0,0.8,0,1.2-0.1s0.8-0.2,1.2-0.3v1.4c-0.8,0.3-1.7,0.4-2.7,0.4c-1.5,0-2.6-0.4-3.4-1.3s-1.2-2.2-1.2-3.8c0-1,0.2-1.9,0.6-2.7
              s0.9-1.4,1.7-1.8s1.6-0.6,2.5-0.6c1,0,2,0.2,2.8,0.6l-0.6,1.4c-0.3-0.2-0.7-0.3-1.1-0.4S-362.1,480.3-362.5,480.3z M-368.9,489
              h-5.7v-10h5.7v1.4h-4v2.7h3.8v1.4h-3.8v3.1h4V489z M-381.9,485v4h-1.6v-10h2.8c1.3,0,2.2,0.2,2.9,0.7c0.6,0.5,0.9,1.2,0.9,2.2
              c0,1.2-0.6,2.1-1.9,2.7l2.8,4.4h-1.9l-2.4-4H-381.9z M-381.9,483.7h1.1c0.8,0,1.3-0.1,1.7-0.4s0.5-0.7,0.5-1.3
              c0-0.6-0.2-1-0.6-1.2s-0.9-0.4-1.7-0.4h-1.1V483.7z"
            />
          </defs>
          <clipPath id="SVGID_40_">
            <use xlinkHref="#SVGID_39_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st18">
            <defs>
              <rect id="SVGID_41_" x="-386" y="461" width="28" height="29" />
            </defs>
            <clipPath id="SVGID_42_">
              <use xlinkHref="#SVGID_41_" style={{ overflow: 'visible' }} />
            </clipPath>
            <rect
              x="-388.5"
              y="473.9"
              className="st19"
              width="33.9"
              height="20.3"
            />
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_43_" x="-333" y="464" width="94" height="18" />
          </defs>
          <clipPath id="SVGID_44_">
            <use xlinkHref="#SVGID_43_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st20">
            <text transform="matrix(1 0 0 1 -333 481)" className="st8">
              REC
            </text>
          </g>
          <g className="st20">
            <text transform="matrix(1 0 0 1 -333 503)" className="st8">
              OR
            </text>
          </g>
          <g className="st20">
            <text transform="matrix(1 0 0 1 -333 525)" className="st8">
              DI
            </text>
          </g>
          <g className="st20">
            <text transform="matrix(1 0 0 1 -333 547)" className="st8">
              NG
            </text>
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_45_" x="-293" y="464" width="66" height="18" />
          </defs>
          <clipPath id="SVGID_46_">
            <use xlinkHref="#SVGID_45_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st21">
            <text transform="matrix(1 0 0 1 -293 481)" className="st8">
              00:12:21
            </text>
          </g>
        </g>
        <g>
          <defs>
            <rect id="SVGID_47_" x="-408" y="-514" width="1440" height="1024" />
          </defs>
          <clipPath id="SVGID_48_">
            <use xlinkHref="#SVGID_47_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st22">
            <text transform="matrix(1 0 0 1 -205.7031 481)">
              <tspan x="0" y="0" className="st8">
                S
              </tspan>
              <tspan x="8.8" y="0" className="st8">
                T
              </tspan>
              <tspan x="17.3" y="0" className="st8">
                OP
              </tspan>
            </text>
          </g>
        </g>
        <g>
          <defs>
            <path
              id="SVGID_49_"
              d="M-213,463h53c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-53c-1.7,0-3-1.3-3-3v-18C-216,464.3-214.7,463-213,463z
              "
            />
          </defs>
          <clipPath id="SVGID_50_">
            <use xlinkHref="#SVGID_49_" style={{ overflow: 'visible' }} />
          </clipPath>
          <g className="st23">
            <defs>
              <rect
                id="SVGID_51_"
                x="-408"
                y="-514"
                width="1440"
                height="1024"
              />
            </defs>
            <clipPath id="SVGID_52_">
              <use xlinkHref="#SVGID_51_" style={{ overflow: 'visible' }} />
            </clipPath>
            <path
              className="st24"
              d="M-213,463h53c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3h-53c-1.7,0-3-1.3-3-3v-18C-216,464.3-214.7,463-213,463z"
            />
          </g>
        </g>
        <g>
          <defs>
            <circle id="SVGID_53_" cx="12" cy="5.7" r="3.8" />
          </defs>
          <clipPath id="SVGID_54_">
            <use xlinkHref="#SVGID_53_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect
            x="-1.5"
            y="-7.7"
            style={{ clipPath: 'url(#SVGID_54_)' }}
            width="26.8"
            height="26.8"
          />
        </g>
        <g>
          <defs>
            <path
              id="SVGID_55_"
              d="M18.5,15.1c-0.6,0-1.1,0.2-1.5,0.7c-0.4,0.5-0.6,1.1-0.6,1.9c0,0.8,0.2,1.5,0.5,1.9
              c0.4,0.4,0.9,0.6,1.5,0.6c0.3,0,0.6,0,0.8-0.1c0.3-0.1,0.6-0.1,0.8-0.2v1c-0.5,0.2-1.1,0.3-1.8,0.3c-1,0-1.8-0.3-2.3-0.9
              c-0.5-0.6-0.8-1.5-0.8-2.6c0-0.7,0.1-1.3,0.4-1.9c0.3-0.5,0.6-0.9,1.1-1.2c0.5-0.3,1.1-0.4,1.7-0.4c0.7,0,1.3,0.1,1.9,0.4L20,15.5
              c-0.2-0.1-0.5-0.2-0.7-0.3C19,15.1,18.8,15.1,18.5,15.1z M14.1,21h-3.9v-6.8h3.9v0.9h-2.7V17h2.6v0.9h-2.6v2.1h2.7V21z M5.2,18.3
              V21H4.1v-6.8H6c0.9,0,1.5,0.2,2,0.5c0.4,0.3,0.6,0.8,0.6,1.5c0,0.9-0.4,1.5-1.3,1.8l1.9,3H8l-1.6-2.7H5.2z M5.2,17.4H6
              c0.5,0,0.9-0.1,1.1-0.3c0.2-0.2,0.4-0.5,0.4-0.9c0-0.4-0.1-0.7-0.4-0.8c-0.3-0.2-0.6-0.3-1.1-0.3H5.2V17.4z"
            />
          </defs>
          <clipPath id="SVGID_56_">
            <use xlinkHref="#SVGID_55_" style={{ overflow: 'visible' }} />
          </clipPath>
          <rect
            x="-5.5"
            y="4.5"
            style={{ clipPath: 'url(#SVGID_56_)' }}
            width="35.5"
            height="26.2"
          />
        </g>
      </g>
    ),
  },
  record: {
    viewBox: '0 0 24 24',
    content: (
      <path d="M 12 5 C 8.1340068 5 5 8.1340068 5 12 C 5 15.865993 8.1340068 19 12 19 C 15.865993 19 19 15.865993 19 12 C 19 8.1340068 15.865993 5 12 5 z" />
    ),
  },
  pip_on: {
    viewBox: '0 0 24 24',
    content: (
      <g>
        <path
          d="M6.7,13.6c-0.6,0-1.1-0.5-1.1-1.1V7.4c0-0.6,0.5-1.1,1.1-1.1H13c0.6,0,1.1,0.5,1.1,1.1v5.1
          c0,0.6-0.5,1.1-1.1,1.1H6.7z"
        />
        <g>
          <path
            d="M9.6,17.5H3.5v-13h16v6h1.8c0.1,0,0.1,0,0.2,0V4.5c0-1.1-0.9-2-2-2h-16c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2
            h6h0.1V17.5z"
          />
        </g>
        <g id="surface1_1_">
          <path d="M21.5,20.5v-7h-2v3.6l-2.8-2.7l-1.4,1.4l2.8,2.7h-3.6v2H21.5z" />
        </g>
      </g>
    ),
  },
  pip_off: {
    viewBox: '0 0 24 24',
    content: (
      <g>
        <g>
          <path
            d="M9.6,17.5H3.5v-13h16v6h1.8c0.1,0,0.1,0,0.2,0V4.5c0-1.1-0.9-2-2-2h-16c-1.1,0-2,0.9-2,2v13c0,1.1,0.9,2,2,2h6
            h0.1V17.5z"
          />
        </g>
        <g id="surface1">
          <path d="M5.6,6.3v7h2V9.7l2.8,2.7l1.4-1.4L9,8.3h3.6v-2H5.6z" />
        </g>
        <path
          d="M13.2,21.5c-0.8,0-1.4-0.6-1.4-1.4v-6.4c0-0.8,0.6-1.4,1.4-1.4h7.9c0.8,0,1.4,0.6,1.4,1.4v6.4
          c0,0.8-0.6,1.4-1.4,1.4H13.2z"
        />
      </g>
    ),
  },
  fav: {
    viewBox: '0 0 20 19',
    content: (
      <path d="M10 18.35l-1.45-1.32C3.4 12.36 0 9.28 0 5.5 0 2.42 2.42 0 5.5 0 7.24 0 8.91.81 10 2.09 11.09.81 12.76 0 14.5 0 17.58 0 20 2.42 20 5.5c0 3.78-3.4 6.86-8.55 11.54L10 18.35z" />
    ),
  },
};

const useIconColors = makeStyles((theme) => {
  const iconColor =
    theme.palette.type === 'light'
      ? theme.palette.grey[900]
      : theme.palette.grey[50];

  return { root: { fill: iconColor, color: iconColor } };
});

const Icon = ({
  id,
  x,
  y,
  type,
  title,
  role,
  warning,
  fontSize,
  onClick,
  className,
  ...props
}) => {
  const icon = icons[type];
  const classes = useIconColors(props);

  if (!icon) {
    return (
      <UiIcon
        className={classNames(classes.root, className)}
        fontSize={fontSize}
      >
        {type}
      </UiIcon>
    );
  }

  return (
    <svg
      className={classNames('icon', 'icon-' + type, classes.root, className)}
      onClick={onClick}
      viewBox={icon.viewBox}
      x={x}
      y={y}
      id={id}
    >
      <title>{title}</title>
      {icon.content}
      {warning && (
        <Icon type="warning-tag" x="-5" title={I18n.t('alt:warning')} />
      )}
    </svg>
  );
};

Icon.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  warning: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = { warning: false };

export default Icon;
