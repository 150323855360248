import eyeson, { immediate, FeatureDetector } from 'eyeson';

let _stream = null;
let _instance = null;
let _accessKey = null;
let _storedLayout = null;
let _currentState = null;
let _onEvent = null;
let _constraintsInit = true;
let _isCurrentPresentationLayout = false;
const _state = {
  init: 'init',
  active: 'active',
  error: 'error',
};
const _options = {
  quality: null,
  screenshareUserPreventLayout: false,
};

const _gDMConstraints = {
  video: {
    height: { max: 1080 },
    frameRate: { max: 15 },
  },
  surfaceSwitching: 'include',
  selfBrowserSurface: 'include',
  monitorTypeSurfaces: 'include',
};

const initConstraints = () => {
  if (!_constraintsInit) return;
  _constraintsInit = false;
  if (
    navigator.mediaDevices.getSupportedConstraints()
      .suppressLocalAudioPlayback === true
  ) {
    _gDMConstraints.audio = { suppressLocalAudioPlayback: true };
    _gDMConstraints.systemAudio = 'exclude';
    if (FeatureDetector.canStereo()) {
      _gDMConstraints.audio.channelCount = 2;
      _gDMConstraints.audio.echoCancellation = false;
    }
  }
  if (FeatureDetector.canChooseDisplaySurface()) {
    _gDMConstraints.video.displaySurface = 'monitor';
  }
};

const requestScreenshare = async () => {
  initConstraints();
  _stream = await navigator.mediaDevices.getDisplayMedia(_gDMConstraints);
  _stream.getTracks().forEach((track) => {
    if (track.kind === 'video') {
      track.type = 'screen-track';
    }
    track.addEventListener('ended', () => {
      stop();
    });
  });
};

const changeScreenshare = async () => {
  let stream = null;
  try {
    stream = await navigator.mediaDevices.getDisplayMedia(_gDMConstraints);
  } catch (error) {
    emit({ type: 'warning', name: `error_Screen_${error.name}` });
  }
  if (!stream) {
    return false;
  }
  try {
    _instance.send({ type: 'replace_stream', stream });
    _stream.getTracks().forEach((track) => track.stop());
    _stream = stream;
    _stream.getTracks().forEach((track) => {
      if (track.kind === 'video') {
        track.type = 'screen-track';
      }
      track.addEventListener('ended', () => {
        stop();
      });
    });
  } catch (error) {
    emit({ type: 'warning', name: 'error:msg:unknown' });
    return false;
  }
  return true;
};

const stopScreenshare = () => {
  if (_stream) {
    _stream.getTracks().forEach((track) => track.stop());
    _stream = null;
  }
};

const registerUser = () => {
  return new Promise((resolve, reject) => {
    if (_accessKey) {
      resolve();
      return;
    }
    if (!_instance) {
      _instance = eyeson.createInstance();
    }
    const onEvent = (event) => {
      if (event.type === 'guest_user') {
        _accessKey = event.token;
        _instance.offEvent(onEvent);
        resolve();
      } else if (event.type === 'error') {
        _instance.offEvent(onEvent);
        reject(new Error(event.content));
      }
    };
    try {
      _instance.onEvent(onEvent);
      _instance.send({
        type: 'request_guest_user',
        api: eyeson.config.api,
        token: eyeson.room.guest_token,
        name: '[Screenshare] ' + eyeson.user.name,
      });
    } catch (error) {
      if (_instance) {
        _instance.offEvent(onEvent);
      }
      reject(error);
    }
  });
};

const onInstanceEvent = (event) => {
  const { type } = event;
  if (type === 'room_ready') {
    if (_currentState === _state.init) {
      setCurrentState(_state.active);
      if (!_instance.user.apiId) {
        _instance.user.apiId = event.content.user.id;
      }
      if (!_options.screenshareUserPreventLayout) {
        setPresentationLayout();
      }
      emit({ type: 'screen_addition_active' });
    }
  } else if (type === 'error') {
    setCurrentState(_state.error);
    emit({ type: 'warning', name: 'error:msg:unknown' });
    stop();
  } else if (type === 'exit') {
    stop();
  }
};

const start = async (options) => {
  setCurrentState(_state.init);
  const initPromise = await Promise.allSettled([
    requestScreenshare(),
    registerUser(),
  ]);
  if (initPromise.some((promise) => promise.status === 'rejected')) {
    setCurrentState(_state.error);
    stopScreenshare();
    if (initPromise[0].status === 'rejected') {
      emit({
        type: 'warning',
        name: `error_Screen_${initPromise[0].reason.name}`,
      });
    } else {
      emit({ type: 'warning', name: 'error:msg:unknown' });
    }
    return;
  }
  try {
    storeCurrentLayout(options);
    _instance.onEvent(onInstanceEvent);
    startSession();
  } catch (error) {
    setCurrentState(_state.error);
    emit({ type: 'warning', name: 'error:msg:unknown' });
    stop();
  }
};

const setPresentationLayout = () => {
  eyeson.send({
    type: 'set_layout',
    params: {
      layout: 'auto',
      name: 'present-upper-6-aspect-fit',
      users: [_instance.user.apiId, eyeson.user.apiId, '', '', '', '', ''],
      voice_activation: true,
      show_names: false,
    },
  });
};

const setCurrentState = (state) => {
  _currentState = state;
};

const getCurrentState = () => {
  return _currentState;
};

const startSession = () => {
  const options = { stream: _stream, sendOnly: true };
  if (_options.quality === 'low') {
    options.sendEncodings = {
      audio: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_AUDIO },
      video: { maxBitrate: +process.env.REACT_APP_DATASAVER_LOW_VIDEO },
    };
  }
  if (_options.quality === 'medium') {
    options.sendEncodings = {
      video: { maxBitrate: +process.env.REACT_APP_DATASAVER_MEDIUM_VIDEO },
    };
  }
  _instance.start(_accessKey, options);
};

const stop = () => {
  if (_instance) {
    _instance.offEvent(onInstanceEvent);
    if (_instance.core) {
      clearInterval(_instance.core.pollingFallbackInterval);
    }
    _instance.destroy();
  }
  stopScreenshare();
  if (_currentState) {
    setCurrentState(null);
    if (!_options.screenshareUserPreventLayout) {
      restorePreviousLayout();
    }
    emit({ type: 'screen_addition_ended' });
  }
};

const storeCurrentLayout = (options) => {
  if (options.layout_map) {
    try {
      _storedLayout = {
        layout: options.layout,
        name: options.layout_name,
        users: options.layout_users,
        map: JSON.stringify(options.layout_map),
        show_names: options.show_names,
        voice_activation: options.voice_activation,
      };
    } catch (error) {
      _storedLayout = {
        layout: 'auto',
        show_names: options.show_names,
        voice_activation: options.voice_activation,
      };
    }
  } else {
    _storedLayout = {
      layout: options.layout,
      name: options.layout_name,
      users: options.layout_users,
      show_names: options.show_names,
      voice_activation: options.voice_activation,
    };
  }
};

const restorePreviousLayout = () => {
  if (_storedLayout) {
    eyeson.send({ type: 'set_layout', params: _storedLayout });
    clearStoredLayout();
  }
};

const clearStoredLayout = () => {
  _storedLayout = null;
};

const onLayoutUpdate = (options) => {
  if (_currentState !== _state.active) {
    _isCurrentPresentationLayout = false;
    return;
  }
  const { layout, layout_name, layout_users } = options;
  _isCurrentPresentationLayout =
    layout === 'auto' &&
    layout_name === 'present-upper-6-aspect-fit' &&
    layout_users &&
    layout_users.length === 7 &&
    layout_users[0] === _instance.user.apiId &&
    layout_users[1] === eyeson.user.apiId;
};

const isCurrentPresentationLayout = () => {
  return _isCurrentPresentationLayout;
};

const onEvent = (fn) => {
  _onEvent = fn;
};

const offEvent = () => {
  _onEvent = null;
};

const emit = (msg) => {
  if (_onEvent) {
    immediate(() => _onEvent(msg));
  }
};

const setOptions = (options) => {
  Object.assign(_options, options);
};

const _helper = {
  start,
  stop,
  getCurrentState,
  onEvent,
  offEvent,
  changeScreenshare,
  setPresentationLayout,
  clearStoredLayout,
  onLayoutUpdate,
  isCurrentPresentationLayout,
  setOptions,
};

export default _helper;
