import Icon from '../generic/Icon.js';
import React, { Fragment } from 'react';
import NameInsert from './NameInsert.js';
import VoiceIndicator from './VoiceActivityIndicator.js';
import FloatingNameInsert from './FloatingNameInsert.js';

const NameInserts = (props) => {
  const {
    user,
    solo,
    active: { hasPresenter, isPresenter, presenter, sfu },
    options: { layout_users, sfu_mode },
    participants,
    canvasCollapsed = true,
    voiceActivity: voice,
    canvasPresentation: { files },
  } = props;

  // NOTE: As a presenter you'll still get the MCU stream, even if your client
  // is sfu capable.
  if (!sfu && !isPresenter && !solo) {
    return null;
  }

  if (hasPresenter) {
    const apiPresenter = participants.find((p) => p.id === presenter);
    if (!apiPresenter) {
      return null;
    }

    return (
      <Fragment>
        <NameInsert user={apiPresenter}>
          <span className="activity-indicator-group">
            <VoiceIndicator
              active={voice.on && voice.user.apiId === apiPresenter.apiId}
            />
            <Icon type="presentation" title="presentation" />
          </span>
        </NameInsert>

        <FloatingNameInsert
          visible={voice.on && voice.user.apiId !== apiPresenter.apiId}
          collapsed={canvasCollapsed}
          participants={participants}
          voiceActivity={voice}
        />
      </Fragment>
    );
  }

  const peer = solo ? user : participants.find((p) => p.apiId !== user.id);
  if (
    !peer ||
    (layout_users && files.length === 0) ||
    (solo && sfu_mode !== 'ptp')
  ) {
    return null;
  }
  return (
    <NameInsert user={peer}>
      <VoiceIndicator active={voice.on && voice.user.apiId === peer.apiId} />
    </NameInsert>
  );
};

export default NameInserts;
