import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '../../generic/TextField.js';
import GiphyLogo from './GiphyLogo.js';
import Gifs from './Gifs.js';
import ScrollContainer from '../../generic/ScrollContainer.js';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import { debounce, Logger } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';
import PlaybackHelper from '../../../utils/PlaybackHelper.js';

// Handle full-width for tabs.
const StyledTab = withStyles({
  fullWidth: { minWidth: 'auto' },
})(Tab);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0, 1, 0),
  },
}));

const GifChannel = ({ user, gifEndpoint, gifs, onEvent }) => {
  const helper = new PlaybackHelper(gifEndpoint);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [channel, setChannel] = useState('trending');
  const classes = useStyles();

  const search = (searchQuery) => {
    const timer = setTimeout(() => setLoading(true), 250);
    const endLoading = () => {
      clearTimeout(timer);
      setLoading(false);
    };
    try {
      const response =
        searchQuery.trim() !== ''
          ? helper.search(searchQuery)
          : helper.gifSelection('trending');

      response
        .then((gifs) => {
          endLoading();
          onEvent({ type: 'gifs_update', gifs });
          setChannel('trending');
        })
        .catch(() => endLoading());
    } catch (error) {
      endLoading();
    }
  };

  const bouncedSearch = useRef(debounce(search, 500));

  useEffect(() => {
    bouncedSearch.current(query);
  }, [query]);

  const switchChannel = (selection) => {
    helper.gifSelection(selection).then((gifs) => {
      onEvent({ type: 'gifs_update', gifs, selection });
    });
    helper.setSelection(selection);
    setChannel(selection);
  };

  const handleFav = (gif) => {
    helper.toggleFav(gif);

    if (channel === 'fav') {
      switchChannel(channel);
    }
    onEvent({
      type: 'gifs_update',
      gifs: helper.annotateFavs(gifs),
      details: 'toggle_fav',
    });
  };

  const handleClick = (gif) => {
    if (!user.clientId) {
      Logger.warn(`GiphyDialog: no sip id for ${user.id} ${user.apiId}`);
    }

    onEvent({
      type: 'playback_start',
      gif,
      playback: {
        url: gif.images.original_mp4.mp4,
        name: gif.slug,
      },
    });
  };

  return (
    <ScrollContainer>
      <Box display="flex" flexWrap="nowrap" flexDirection="column">
        <Paper elevation={2} className="eyeson-paper" square>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="nowrap"
            alignItems="center"
            px={2}
          >
            <TextField
              fullWidth
              id="search-gifs"
              value={query}
              onChange={setQuery}
              label={I18n.t('placeholder:search_gifs')}
              trailingIcon="search"
              className={classes.margin}
              variant="filled"
            />
            <Box ml={2}>
              <GiphyLogo />
            </Box>
          </Box>
          <Tabs
            value={channel}
            onChange={(_event, value) => switchChannel(value)}
            variant="fullWidth"
            textColor="secondary"
            indicatorColor="secondary"
            scrollButtons="off"
          >
            <StyledTab
              value="trending"
              icon={
                <Tooltip title={I18n.t('label:trending')} placement="top">
                  <Icon>trending_up</Icon>
                </Tooltip>
              }
              aria-label={I18n.t('label:trending', {
                defaultValue: 'Trending',
              })}
            />
            <StyledTab
              value="recent"
              icon={
                <Tooltip title={I18n.t('label:recent')} placement="top">
                  <Icon>access_time</Icon>
                </Tooltip>
              }
              aria-label={I18n.t('label:recent', { defaultValue: 'Recent' })}
            />
            <StyledTab
              value="fav"
              icon={
                <Tooltip title={I18n.t('label:favourites')} placement="top">
                  <Icon>favorite</Icon>
                </Tooltip>
              }
              aria-label={I18n.t('label:favourites', {
                defaultValue: 'Favourites',
              })}
            />
          </Tabs>
        </Paper>
        <section>
          {loading ? (
            <Box mt={4}>
              <Typography align="center">{I18n.t('label:loading')}</Typography>
            </Box>
          ) : gifs.length === 0 ? (
            <Box mt={4}>
              <Typography align="center">¯\_(ツ)_/¯</Typography>
              <Typography align="center">
                {I18n.t(`placeholder:no_results_${channel}`)}
              </Typography>
            </Box>
          ) : (
            <Gifs
              gifs={gifs}
              onFav={handleFav}
              onClick={handleClick}
              channel={channel}
            />
          )}
        </section>
      </Box>
    </ScrollContainer>
  );
};

GifChannel.propTypes = {
  user: PropTypes.object.isRequired,
  gifs: PropTypes.array.isRequired,
  onEvent: PropTypes.func.isRequired,
};

export default GifChannel;
