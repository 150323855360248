import I18n from 'i18n-js';
import update from 'immutability-helper';
import BaseAction from './BaseActions.js';
import PipControlsHelper from '../PipControlsHelper.js';

class PipCamEvent extends BaseAction {
  process(state, _props) {
    const { active } = this.message;
    if (active) {
      PipControlsHelper.hideHangup();
    } else {
      PipControlsHelper.showHangup();
    }
    return {
      pipCam: update(state.pipCam, {
        active: { $set: active },
        autoStart: { $set: false },
        request: { $set: false },
      }),
    };
  }
}

class PipCamErrorEvent extends BaseAction {
  process(state, props) {
    if (!state.pipCam.autoStart) {
      props.enqueueSnackbar(I18n.t('error:pip_cam:unable_start'), {
        variant: 'error',
      });
    }
    return {
      pipCam: update(state.pipCam, {
        active: { $set: false },
        autoStart: { $set: false },
        request: { $set: false },
      }),
    };
  }
}

class PipCamVideoEndedWarning extends BaseAction {
  process(state, props) {
    props.enqueueSnackbar(I18n.t('error:pip_cam:ended'), {
      variant: 'error',
    });
    return {
      pipCam: update(state.pipCam, {
        active: { $set: false },
        autoStart: { $set: false },
        request: { $set: false },
      }),
    };
  }
}

class PipCamUnsupportedWarning extends BaseAction {
  process(state, props) {
    props.enqueueSnackbar(I18n.t('error:pip_cam:not_supported'), {
      variant: 'error',
    });
    return {
      pipCam: update(state.pipCam, {
        active: { $set: false },
        autoStart: { $set: false },
        request: { $set: false },
      }),
    };
  }
}

class TogglePipCamEvent extends BaseAction {
  process(state, props) {
    if (state.pipCam.active) {
      props.eyeson.send({ type: 'stop_pip_cam' });
    } else {
      props.eyeson.send({ type: 'start_pip_cam' });
    }
    return {
      pipCam: update(state.pipCam, {
        request: { $set: true },
      }),
    };
  }
}

export {
  PipCamEvent,
  PipCamErrorEvent,
  PipCamVideoEndedWarning,
  PipCamUnsupportedWarning,
  TogglePipCamEvent,
};
