import I18n from 'i18n-js';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from './ErrorPage.js';
import BrowserSupportPage from './BrowserSupportPage.js';
import InterCommunicationHelper from '../../utils/InterCommunicationHelper.js';
import StreamdeckHelper from '../../utils/StreamdeckHelper.js';

/**
 * Selects which Error Page should be displayed.
 **/
const ErrorPageSelector = ({
  eyeson,
  status,
  exitCode,
  handleExit,
  environment,
  destinations,
  options,
  getRetryCounter,
  setRetryCounter,
}) => {
  // User saw an ErrorPage and clicked the primary button, we exit
  // to the inactivity destination.
  const handleErrorPageExit = () =>
    handleExit({ reason: 'error_page', destination: destinations.inactivity });

  // User saw an ErrorPage and clicked the button, we exit to
  // to the inactivity destination.
  const handleInactivityExit = () =>
    handleExit({ reason: 'inactive', destination: destinations.inactivity });

  const handleReload = () => window.location.reload();

  const commonProps = {
    eyeson: eyeson,
    status: status,
    exitCode: exitCode,
    hasExitURL: !!options.exit_url,
    getRetryCounter: getRetryCounter,
    setRetryCounter: setRetryCounter,
  };

  useEffect(() => {
    InterCommunicationHelper.onMeetingLeave(status);
    StreamdeckHelper.destroy();
  }, [status]);

  return [
    {
      condition: () => status === 'unsupported',
      render: () => (
        <BrowserSupportPage isIOSDevice={environment.isIOSDevice} />
      ),
    },
    {
      condition: () => status === 'inactive',
      render: () => (
        <ErrorPage
          {...commonProps}
          onClick={handleInactivityExit}
          primaryLabel={I18n.t('btn:start_over')}
        />
      ),
    },
    {
      condition: () =>
        ['offline', 'access_denied', 'permalink_unavailable'].includes(status),
      render: () => (
        <ErrorPage
          {...commonProps}
          onClick={handleReload}
          primaryLabel={I18n.t('btn:refresh')}
        />
      ),
    },
    {
      condition: () => status === 'session_in_use',
      render: () => {
        if (!eyeson.room.guest_token) {
          return (
            <ErrorPage
              {...commonProps}
              onClick={handleErrorPageExit}
              primaryLabel={I18n.t('btn:back_to_account')}
            />
          );
        }
        const token = eyeson.room.guest_token;
        let url = `${window.location.href.split('?')[0]}?guest=${token}`;
        if (options.suggest_guest_names === false) {
          url += '&suggest=false';
        }
        return (
          <ErrorPage
            {...commonProps}
            onClick={() => (window.location.href = url)}
            primaryLabel={I18n.t('btn:join_as_guest')}
            onSecondaryClick={handleErrorPageExit}
            secondaryLabel={I18n.t('btn:back_to_account')}
          />
        );
      },
    },
    {
      condition: () => ['meeting_locked', 'end_meeting'].includes(status),
      render: () => (
        <ErrorPage
          {...commonProps}
          onClick={handleErrorPageExit}
          primaryLabel={I18n.t('btn:back_to_account')}
        />
      ),
    },
    {
      condition: () => status !== 'exit_room',
      render: () => (
        <ErrorPage
          {...commonProps}
          onClick={handleReload}
          primaryLabel={
            [
              'permission',
              'not_readable',
              'request_too_large',
              'abrupt_disconnect',
            ].includes(status)
              ? I18n.t('label:try_again')
              : I18n.t('label:reload')
          }
          onSecondaryClick={handleErrorPageExit}
          secondaryLabel={I18n.t('btn:back_to_account')}
        />
      ),
    },
    {
      condition: () => true,
      render: () => null,
    },
  ]
    .find((component) => component.condition())
    .render();
};

ErrorPageSelector.propTypes = {
  eyeson: PropTypes.object.isRequired,
  status: PropTypes.string,
  exitCode: PropTypes.string,
  handleExit: PropTypes.func,
  environment: PropTypes.object,
  destinations: PropTypes.object,
};

ErrorPageSelector.defaultProps = {
  status: 'default-status',
};

export default ErrorPageSelector;
