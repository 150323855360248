import I18n from 'i18n-js';
import { Logger } from 'eyeson';
import React, { Component } from 'react';
import NameInserts from '../name_inserts/NameInserts.js';
import Status from '../status/Status.js';
import Typography from '@material-ui/core/Typography';
import MediaToolbar from './MediaToolbar.js';

class MediaPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      volume: 1,
      volumeBackup: 1,
      paused: true,
      duration: null,
      currentTime: 0,
    };
    this.canvasEl = null;
    this.actionCallback = (type) =>
      Logger.warn('MediaPresentation, missing actionCallback', type);
  }

  componentDidMount() {
    try {
      this.props.onEvent({
        type: 'start_media',
        canvas: this.canvasEl,
        file: this.props.file,
        onReady: this.onReady,
        onDuration: this.onDuration,
        onTimeUpdate: this.onTimeUpdate,
        onEnd: this.onEnd,
        performAction: this.onActionCallback,
      });
    } catch (error) {
      this.props.onEvent({
        type: 'capture_error',
        name: `error_${error.name}`,
      });
    }
  }

  componentWillUnmount() {
    this.props.onEvent({ type: 'set_presentation_stream', stream: null });
  }

  startStream = () => {
    this.props.onEvent({ type: 'start_presenting', name: 'media' });
    this.props.onStart();
  };

  stopStream = () => {
    this.props.onEvent({ type: 'presentation' });
    this.props.onStop();
  };

  setRef = (ref) => {
    if (ref) {
      this.canvasEl = ref;
    }
  };

  playPauseClick = () => {
    const paused = this.state.paused;
    const action = paused ? 'play' : 'pause';
    this.actionCallback(action).then((isPaused) => {
      this.setState({ paused: isPaused });
    });
  };

  toggleMute = () => {
    this.setVolume(this.state.volume === 0 ? this.state.volumeBackup : 0);
  };

  setVolume = (volume) => {
    const change = { volume };
    if (volume > 0) {
      change.volumeBackup = volume;
    }
    this.actionCallback('volume', volume);
    this.setState(change);
  };

  setCurrentTime = (time) => {
    // this.setState({ currentTime: time });
    this.actionCallback('time', time);
  };

  onReady = () => {
    this.setState({ ready: true });
    this.props.onEvent({ type: 'set_presentation_stream', stream: true });
  };

  onDuration = (duration) => {
    this.setState({ duration });
  };

  onTimeUpdate = (time) => {
    this.setState({ currentTime: time });
  };

  onEnd = () => {
    this.setState({ paused: true });
  };

  onActionCallback = (actionCallback) => {
    this.actionCallback = actionCallback;
  };

  render() {
    const customStatusCss = {
      width: '100%',
      position: 'absolute',
      background: 'rgba(0, 0, 0, 0.5)',
    };

    return (
      <>
        <div className="canvas">
          {this.props.options.show_names && <NameInserts {...this.props} />}
          <div className="canvas-wrapper canvas-media">
            {!this.props.active.isPresenting && (
              <Status message={I18n.t('label:preview')} style={customStatusCss}>
                <Typography variant="h5">
                  {I18n.t('presentation:preview-text')}
                </Typography>
              </Status>
            )}
            <canvas
              ref={this.setRef}
              width={1920}
              height={1080}
              aria-label={I18n.t('aria:label:presentation_canvas', {
                defaultValue: 'Presentation canvas',
              })}
            />
          </div>
          <MediaToolbar
            {...this.props}
            {...this.state}
            startStream={this.startStream}
            stopStream={this.stopStream}
            onPlayPause={this.playPauseClick}
            setVolume={this.setVolume}
            setCurrentTime={this.setCurrentTime}
            onMute={this.toggleMute}
          />
        </div>
      </>
    );
  }
}

export default MediaPresentation;
