import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
/**
 * Layout User Drag Preview
 * This component is needed for the CustomDragLayer. At first I'd make sense
 * to render the LayoutUser their as well, but this results in problems
 * since it is a DragSource. See:
 * https://github.com/react-dnd/react-dnd/issues/431#issuecomment-317219509
 * for more details.
 **/
const LayoutUserDragPreview = ({ user }) => {
  return (
    <Chip
      avatar={<Avatar src={user.avatar} alt={user.name} />}
      label={user.name}
      clickable
    />
  );
};

export default LayoutUserDragPreview;
