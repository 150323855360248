import React from 'react';
import PropTypes from 'prop-types';
import dateHelper from '../../../utils/dateHelper.js';

const DateDivider = ({ date }) => {
  return (
    <div className="eyeson-date-divider">
      <span className="date-break">{dateHelper.formatDate(date)}</span>
    </div>
  );
};

DateDivider.propTypes = {
  date: PropTypes.instanceOf(Date),
};

export default DateDivider;
