import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import { FeatureDetector } from 'eyeson';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Icon from '@material-ui/core/Icon';
import CustomIcon from '../generic/Icon.js';
import CounterIcon from '../generic/CounterIcon.js';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import MobileParticipants from '../mobile/MobileParticipants.js';
import MobileConnectionInfoIcon from '../mobile/MobileConnectionInfoIcon.js';
import MobileConnectionInfo from '../mobile/MobileConnectionInfo.js';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  rec: {
    fill: theme.palette.error.main,
  },
  customIcon: {
    '& svg': {
      width: '1em',
      height: '1em',
      fill: 'currentColor',
      color: 'inherit',
    },
  },
}));

const TopToolbar = ({
  me,
  isPip,
  canPip,
  active,
  visible,
  isGuest,
  isLocked,
  hidden,
  onEvent,
  quickJoin,
  pipDisabled,
  participants,
  kickAvailable,
  hideGuestLogin,
  connectionStatistics,
}) => {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <IconButton
          aria-label={I18n.t('label:exit', { defaultValue: 'Exit' })}
          onClick={() => onEvent({ type: 'exit_room' })}
        >
          <Icon>power_settings_new</Icon>
        </IconButton>
        <div className={classes.grow} />
        <div className="toolbarOptions">
          {FeatureDetector.isIOSDevice() && (
            <IconButton
              aria-label={I18n.t('label:revitalize_stream', {
                defaultValue: 'Revitalize Video',
              })}
              onClick={() => onEvent({ type: 'revitalize_stream_dialog' })}
            >
              <Icon>healing</Icon>
            </IconButton>
          )}
          {connectionStatistics && (
            <IconButton
              aria-label={I18n.t('label:connection', {
                defaultValue: 'Connection Info',
              })}
              onClick={() => onEvent({ type: 'toggle_mobile_connection_info' })}
            >
              <MobileConnectionInfoIcon data={connectionStatistics} />
            </IconButton>
          )}
          {canPip && (
            <IconButton
              className={classes.customIcon}
              aria-label={I18n.t('label:pip', {
                defaultValue: 'Picture in Picture',
              })}
              onClick={() => onEvent({ type: 'toggle_pip' })}
              disabled={pipDisabled}
            >
              <CustomIcon type={isPip ? 'pip_off' : 'pip_on'} />
            </IconButton>
          )}
          {Boolean(active.recording) && (
            <CustomIcon type="recording" className={classes.rec} />
          )}
          <IconButton
            aria-label={I18n.t('data_saving:title', {
              defaultValue: 'Data-Saving Mode',
            })}
            onClick={() => onEvent({ type: 'data_saver_dialog' })}
          >
            <Icon>network_check</Icon>
          </IconButton>
          <IconButton
            aria-label={I18n.t('label:quick_join', {
              defaultValue: 'Invite guest',
            })}
            onClick={() => onEvent({ type: 'toggle_mobile_participants' })}
          >
            <CounterIcon
              icon={!isLocked && quickJoin ? 'person_add' : 'people'}
              count={participants.length}
            />
          </IconButton>
        </div>
      </Toolbar>
      {visible.mobile_participants_list && (
        <MobileParticipants
          me={me}
          onEvent={onEvent}
          isGuest={isGuest}
          isLocked={isLocked}
          quickJoin={quickJoin}
          participants={participants}
          kickAvailable={kickAvailable}
          hideGuestLogin={hideGuestLogin}
        />
      )}
      {visible.mobile_connection_info && (
        <MobileConnectionInfo data={connectionStatistics} onEvent={onEvent} />
      )}
    </AppBar>
  );
};

TopToolbar.propTypes = {
  me: PropTypes.object,
  active: PropTypes.object.isRequired,
  visible: PropTypes.object,
  hidden: PropTypes.bool,
  isGuest: PropTypes.bool,
  onEvent: PropTypes.func,
  participants: PropTypes.array,
  kickAvailable: PropTypes.bool,
};

TopToolbar.defaultProps = {
  hidden: false,
  visible: { mobile_participants_list: false },
  participants: [],
  kickAvailable: true,
};

export default TopToolbar;
