import React, { Fragment, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'i18n-js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    overflowWrap: 'break-word',
  },
  dialogHeader: {
    paddingRight: '42px',
  },
}));

const DialogWindow = ({
  id,
  role,
  onClose,
  title,
  noClose,
  children,
  animation,
  useAriaDescription,
  ...rest
}) => {
  const classes = useStyles();
  const dialogEl = useRef(null);

  // https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html
  const setFocus = (element) => {
    const dialogElement = element.firstChild;
    dialogElement && dialogElement.focus();
  };

  const paperProps = {
    id: id,
    role: role,
    tabIndex: 0,
    'aria-describedby': useAriaDescription ? id + '-desc' : null,
    'aria-labelledby': id + '-title',
    'aria-modal': 'true',
  };

  useEffect(() => {
    // needed for focus after content change when already open
    if (dialogEl.current) {
      const dialogElement = document.getElementById(id);
      dialogElement && dialogElement.focus();
    }
  }, [id]);

  return (
    <Fragment>
      <Dialog
        ref={dialogEl}
        onClose={!noClose && onClose}
        TransitionProps={{ onEnter: setFocus }}
        PaperProps={paperProps}
        open
        scroll="body"
        {...rest}
      >
        {onClose ? (
          <IconButton
            aria-label={I18n.t('dialog:close', {
              defaultValue: 'Close dialog window',
            })}
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
        {title && (
          <DialogTitle className={classes.dialogHeader}>
            <span id={id + '-title'}>{title}</span>{' '}
          </DialogTitle>
        )}
        <DialogContent className={classes.content}>{children}</DialogContent>
      </Dialog>
      {animation}
    </Fragment>
  );
};

DialogWindow.propTypes = {
  id: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
  noClose: PropTypes.bool,
  useAriaDescription: PropTypes.bool,
};

DialogWindow.defaultProps = {
  role: 'dialog',
  title: '',
  noClose: false,
  useAriaDescription: false,
  onClose: () => {},
};

export default DialogWindow;
