import I18n from 'i18n-js';
import Video from '../streams/Video.js';
import Button from '../generic/Button.js';
import MicCheck from '../device_settings/MicCheck.js';
import PropTypes from 'prop-types';
import AudioLevel from './AudioLevel.js';
import AudioPreview from './AudioPreview.js';
import DevicePicker from './DevicePicker.js';
import DialogActions from '@material-ui/core/DialogActions';
import React, { Fragment, useState, useEffect } from 'react';
import { FeatureDetector, DeviceManager, LocalStorage } from 'eyeson';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpLink from '../generic/HelpLink.js';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import VirtualBackgroundTypes from '../../utils/VirtualBackgroundTypes.js';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import Tooltip from '@material-ui/core/Tooltip';
import supports from '../../utils/SupportHelper.js';
import WebHIDHelper from '../../utils/WebHIDHelper.js';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import PipControlsHelper from '../../utils/PipControlsHelper.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '20rem',
  },
  fiddy: {
    flex: '1 1 0%',
    display: 'flex',
    height: '100%',
    flexFlow: 'column',
    justifyContent: 'space-around',
    margin: theme.spacing(0, 4, 0, 0),
    '& .eyeson-video': {
      height: 'auto',
    },
  },
  row: {
    display: 'flex',
    flexFlow: 'row',
    '& button': {
      top: -theme.spacing(1),
    },
  },
  footerExtra: {
    marginRight: 'auto',
  },
  setting: {
    display: 'flex',
    flexFlow: 'row',
    margin: theme.spacing(0, 0, 2, 0),
    width: '100%',
    '& button': {
      top: -theme.spacing(1),
    },
  },
  hidSetting: {
    display: 'flex',
    flexFlow: 'row',
    gap: '1em',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2, 0),
    '& ul': {
      flex: '1',
      '& .MuiListItem-gutters': {
        padding: 0,
      },
    },
  },
  vbgSetting: {
    flexGrow: 1,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
}));

const useVBGLoadingStyles = makeStyles({
  root: {
    marginLeft: '0.5rem',
    verticalAlign: 'middle',
  },
});

const useListItemStyles = makeStyles({
  root: {
    opacity: ({ blocked }) => (blocked ? '0.5' : '1'),
  },
});

const isSafari = FeatureDetector.isSafari();
const canMultipleDifferentMicrophones =
  FeatureDetector.canMultipleDifferentMicrophones();
const canChangeMicrophone = FeatureDetector.canChangeMicrophone();
const deviceManager = new DeviceManager();

const DeviceSettings = ({ onChange, onSave, onClose, context }) => {
  const availableBackgrounds =
    VirtualBackgroundTypes.getVirtualBackgroundTypes();
  const { virtualBackground, vbgAvailable } = context;
  const [devices, setDevices] = useState({
    cameras: deviceManager.cameras,
    speakers: deviceManager.speakers,
    microphones: deviceManager.microphones,
  });
  let initVbgTypeId = availableBackgrounds.findIndex(
    (item) => item.type === virtualBackground && !item.hidden
  );
  if (initVbgTypeId === -1) {
    initVbgTypeId = '';
  }

  const hidBlocked = WebHIDHelper.isBlocked();
  const classes = useStyles();
  const vbgLoadingStyles = useVBGLoadingStyles();
  const listItemStyles = useListItemStyles({ blocked: hidBlocked });
  const [stream, setStream] = useState(null);
  const [sinkId, setSinkId] = useState(deviceManager.sinkId);
  const [muted, setMuted] = useState(true);
  const [applyDisabled, setApplyDisabled] = useState(false);
  const [vbgType, setVbgType] = useState(virtualBackground);
  const [vbgTypeId, setVbgTypeId] = useState(initVbgTypeId);
  const [vbgLoading, setVbgLoading] = useState(false);
  const [selectedMic, setSelectedMic] = useState(null);
  const [hidDevices, setHidDevices] = useState([]);
  const [autoPip, setAutoPip] = useState(false);

  useEffect(() => {
    const autoPipSetting = LocalStorage.load('autopip', { enabled: false });
    setAutoPip(autoPipSetting.enabled);
  }, []);

  useEffect(() => {
    const handleChange = (event) => {
      const { error, stream, sinkId } = event;
      if (error) {
        setApplyDisabled(true);
        onChange({
          type: 'warning',
          name: 'error_' + error.name,
          message: error.message,
        });
        return;
      }
      setApplyDisabled(false);
      if (stream) {
        if (isSafari) {
          onChange({ type: 'fix_safari_audio' });
        }
        setStream(event.stream);
        return;
      }
      if (sinkId) {
        setSinkId(sinkId);
        return;
      }
      setDevices(event);
    };

    const updateLoading = (loading) => {
      setVbgLoading(loading);
    };

    const webhidEvent = (event) => {
      if (event.type === 'devicelist') {
        setHidDevices(event.devices);
      }
    };

    if (virtualBackground === 'image:blob') {
      VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': true });
      setVbgTypeId(
        availableBackgrounds.findIndex(
          (item) => item.type === virtualBackground
        )
      );
    }
    deviceManager.setVirtualBackgroundType(virtualBackground);
    deviceManager.virtualBackground.storeLocalImageFile();
    deviceManager.virtualBackground.onLoading(updateLoading);
    deviceManager.onChange(handleChange);
    deviceManager.start();
    WebHIDHelper.onEvent(webhidEvent);
    WebHIDHelper.emitDeviceList();

    return () => {
      deviceManager.removeListener(handleChange);
      deviceManager.virtualBackground.offLoading(updateLoading);
      deviceManager.virtualBackground.resetLocalImageFile();
      deviceManager.stop();
      WebHIDHelper.offEvent(webhidEvent);
    };
  }, [onChange, virtualBackground, availableBackgrounds]);

  const handleError = ({ name, message }) =>
    onChange({ type: 'warning', name: 'error_' + name, message: message });

  const handleWarning = ({ name }) =>
    onChange({ type: 'warning', name: 'warning_' + name });

  const handlePlayAudio = (event) =>
    onChange({ type: 'audio_output_play_preview', details: event });

  const handleVideoInputChange = (event) => {
    if (!event.target.value) {
      return;
    }
    onChange({ type: 'video_input_change', details: event.deviceLabel });
    deviceManager.setVideoInput(event.target.value);
  };

  const handleAudioInputChange = (event) => {
    if (!event.target.value) {
      return;
    }
    onChange({ type: 'audio_input_change', details: event.deviceLabel });
    if (canMultipleDifferentMicrophones) {
      deviceManager.setAudioInput(event.target.value);
    } else {
      setSelectedMic(event.target.value);
    }
  };

  const handleAudioOutputChange = (event) => {
    if (!event.target.value) {
      return;
    }
    onChange({ type: 'audio_output_change', details: event.deviceLabel });
    deviceManager.setAudioOutput(event.target.value);
  };

  const handleSave = () => {
    deviceManager.virtualBackground.saveLocalImageFile();
    if (!canMultipleDifferentMicrophones && selectedMic) {
      deviceManager.setAudioInput(selectedMic, { preventUpdate: true });
    }
    deviceManager.storeConstraints();
    const vbgType = availableBackgrounds[vbgTypeId].type;
    onChange({
      type: 'device_update',
      sinkId: sinkId,
      virtualBackground: vbgType,
    });
    onSave();
  };

  const changeVbgBackground = (event) => {
    let typeId = event.target.value;
    let type = availableBackgrounds[typeId].type;
    deviceManager.setVirtualBackgroundType(type).then(
      () => {
        setVbgTypeId('');
        VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': false });
        typeId = availableBackgrounds.findIndex((entry) => entry.type === type);
        setVbgType(type);
        setVbgTypeId(typeId);
        onChange({ type: 'virtual_background_change', details: type });
      },
      () => {
        onChange({ type: 'warning', name: 'warning_vbg_ImageLoadingError' });
      }
    );
  };

  const handleVirtualBackgroundFile = () => {
    deviceManager.loadLocalImageForVirtualBackground((error) => {
      if (error) {
        onChange({ type: 'warning', name: 'warning_vbg_' + error.name });
        return;
      }
      VirtualBackgroundTypes.setTypeAvailable({ 'image:blob': true });
      const typeId = availableBackgrounds.findIndex(
        (entry) => entry.type === 'image:blob'
      );
      setVbgType('image:blob');
      setVbgTypeId(typeId);
      onChange({ type: 'virtual_background_change', details: 'image:blob' });
    });
  };

  const toggleAutoPip = (event) => {
    const enabled = event.target.value === 'enabled';
    if (enabled) {
      PipControlsHelper.enableAutoPip();
    } else {
      PipControlsHelper.disableAutoPip();
    }
    LocalStorage.store('autopip', { enabled });
    setAutoPip(enabled);
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.fiddy}>
          <Video
            stream={stream}
            muted={muted}
            sinkId={sinkId}
            pipEnabled={false}
            useCanvas={supports.playCanvasStreamBug && vbgType !== 'off'}
          />
          {stream && (
            <AudioLevel
              stream={stream}
              onError={handleError}
              onWarning={handleWarning}
            />
          )}
        </div>

        <div className={classes.fiddy}>
          <Typography variant="overline" className={classes.label}>
            {I18n.t('devices:camera')}
          </Typography>
          <DevicePicker
            label={I18n.t('label:select_camera')}
            stream={stream}
            devices={devices.cameras}
            onChange={handleVideoInputChange}
            disabled={devices.cameras.length === 0}
          />
          <Typography variant="overline" className={classes.label}>
            {I18n.t('devices:microphone')}
          </Typography>
          <div className={classes.row}>
            <DevicePicker
              label={I18n.t('label:select_microphone')}
              stream={stream}
              selected={selectedMic}
              devices={devices.microphones}
              onChange={handleAudioInputChange}
              disabled={!canChangeMicrophone}
            />
            {canMultipleDifferentMicrophones && (
              <MicCheck onClick={() => setMuted(!muted)} playerMuted={muted} />
            )}
          </div>
          <Typography variant="overline" className={classes.label}>
            {I18n.t('devices:speaker')}
          </Typography>
          <div className={classes.row}>
            <DevicePicker
              label={I18n.t('label:select_speaker')}
              sinkId={sinkId}
              devices={devices.speakers}
              onChange={handleAudioOutputChange}
            />
            <AudioPreview sinkId={sinkId} onClick={handlePlayAudio} />
          </div>
          {vbgAvailable && (
            <>
              <Typography variant="overline" className={classes.label}>
                {I18n.t('label:virtual_background')}
                {vbgLoading && (
                  <CircularProgress
                    color="secondary"
                    size="1.2rem"
                    classes={vbgLoadingStyles}
                  />
                )}
              </Typography>
              <div
                className={
                  supports.AutoPip || WebHIDHelper.isSupported()
                    ? classes.row
                    : classes.setting
                }
              >
                <FormControl className={classes.vbgSetting}>
                  <Select
                    value={vbgTypeId}
                    onChange={changeVbgBackground}
                    color="secondary"
                    SelectDisplayProps={{
                      'aria-label': I18n.t('label:virtual_background'),
                    }}
                  >
                    {availableBackgrounds.map((item, index) => {
                      return item.hidden ? null : (
                        <MenuItem
                          key={index}
                          value={index}
                          disabled={!item.enabled}
                        >
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Tooltip
                  title={I18n.t('label:vbg:local_image')}
                  placement="top"
                >
                  <IconButton
                    onClick={handleVirtualBackgroundFile}
                    aria-label={I18n.t('label:vbg:local_image', {
                      defaultValue: 'Custom image',
                    })}
                  >
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
          {supports.AutoPip && (
            <>
              <Typography variant="overline" className={classes.label}>
                Automatic Picture-in-picture
                <Tooltip
                  arrow
                  title={I18n.t('dialog:autopip:description')}
                  placement="top"
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Typography>
              <div className={classes.setting}>
                <FormControl className={classes.vbgSetting}>
                  <Select
                    value={autoPip ? 'enabled' : 'disabled'}
                    onChange={toggleAutoPip}
                    color="secondary"
                    SelectDisplayProps={{
                      'aria-label': I18n.t('dialog:autopip:description'),
                    }}
                  >
                    {['enabled', 'disabled'].map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {I18n.t(`label:${item}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          {WebHIDHelper.isSupported() && (
            <>
              <Typography variant="overline" className={classes.label}>
                {I18n.t('dialog:webhid:title')}
                <Tooltip
                  arrow
                  title={I18n.t('dialog:webhid:description')}
                  placement="top"
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </Typography>
              <div className={classes.hidSetting}>
                <List>
                  {hidDevices.length === 0 ? (
                    <ListItem key="none" className={listItemStyles.root}>
                      <ListItemText>
                        {I18n.t('label:webhid:no_device')}
                      </ListItemText>
                    </ListItem>
                  ) : (
                    hidDevices.map((device) => (
                      <ListItem key={device.id}>
                        <ListItemText>{device.productName}</ListItemText>
                        <ListItemSecondaryAction>
                          <Tooltip
                            title={I18n.t('label:webhid:remove_device')}
                            placement="top"
                          >
                            <IconButton
                              edge="end"
                              aria-label={I18n.t('label:webhid:remove_device')}
                              onClick={() =>
                                WebHIDHelper.removeDevice(device.id)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  )}
                </List>
                <Tooltip
                  title={
                    hidBlocked
                      ? I18n.t('error:webhid:prevent_multiple_instances')
                      : I18n.t('label:webhid:pair_device')
                  }
                  placement="top"
                >
                  <span>
                    <IconButton
                      disabled={hidBlocked}
                      onClick={() => WebHIDHelper.pairDeviceRequest()}
                      aria-label={I18n.t('label:webhid:pair_device')}
                    >
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
      <DialogActions>
        <HelpLink
          label={I18n.t('label:any_problems')}
          link="https://app.eyeson.team/quicktest/"
          color="default"
          className={classes.footerExtra}
        />
        <Button onClick={onClose} type="secondary">
          {I18n.t('dialog:secondaryLabel')}
        </Button>
        <Button onClick={handleSave} disabled={applyDisabled}>
          {I18n.t('btn:apply')}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

DeviceSettings.propTypes = {
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DeviceSettings;
