import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    animation: 'pulse 5s infinite',
  },
}));

const Status = ({ visible = true, message = null, children, ...rest }) => {
  const classes = useStyles();

  if (!visible) {
    return null;
  }

  return (
    <div className={classes.root} {...rest}>
      {message && <Typography variant="h1">{message}</Typography>}
      {children}
    </div>
  );
};

export default Status;
