import { GiphyApi } from 'eyeson';
import Storage from './PlaybackStore.js';

class PlaybackHelper {
  constructor(endpoint) {
    this.giphyApi = new GiphyApi(endpoint + '/gifs');
  }

  selection() {
    return Storage.selection();
  }

  setSelection(selection) {
    return Storage.setSelection(selection);
  }

  gifSelection(selection) {
    return new Promise(async (resolve) => {
      let gifs = [];

      if (selection === 'recent') {
        gifs = Storage.recent();
      }
      if (selection === 'fav') {
        gifs = Storage.favs();
      }
      if (selection === 'trending') {
        gifs = await this.giphyApi.trending();
      }

      resolve(this.annotateFavs(gifs));
    });
  }

  async search(query) {
    return await this.giphyApi.search(query);
  }

  async randomGifForQuery(query) {
    if (query === 'random') {
      return await this.giphyApi.random();
    }
    return await this.giphyApi.randomForQuery(query);
  }

  annotateFavs(gifs) {
    const favs = Storage.favs();
    const newGifs = [...gifs];
    newGifs.forEach((gif) => {
      gif['fav'] = Boolean(favs.find((fav) => fav.id === gif.id));
    });
    return newGifs;
  }

  toggleFav(gif) {
    Storage.toggleFav(gif);
  }

  addRecent(gif) {
    Storage.addRecent(gif);
  }
}

export default PlaybackHelper;
