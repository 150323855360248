import twemoji from 'twemoji';
import emojify from '../../utils/emojify.js';
import useInterval from '../../hooks/useInterval.js';
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import FunFacts from '../../assets/fun_facts_en.json';

const FunFact = ({ initialDelay = true }) => {
  let prevIdx = 0;
  const source = FunFacts;
  const pickRandom = () => {
    let randIdx = Math.floor(Math.random() * source.length);
    while (randIdx === prevIdx && source.length > 1) {
      randIdx = Math.floor(Math.random() * source.length);
    }
    prevIdx = randIdx;
    return source[randIdx];
  };

  const [funFact, setFunFact] = useState(initialDelay ? null : pickRandom);
  const duration = 7000;

  useInterval(() => setFunFact(pickRandom), duration);

  if (!funFact) {
    return <div className="empty-fun-fact">&nbsp;</div>;
  }

  return (
    <Typography variant="subtitle1">
      <div
        style={{ animation: `fadeInAndOut ${duration}ms infinite` }}
        className="fun-fact"
        dangerouslySetInnerHTML={{
          __html: twemoji.parse(emojify(funFact)),
        }}
      />
    </Typography>
  );
};

export default FunFact;
