import React, { Component } from 'react';
import emojify from './../../../utils/emojify.js';
import PropTypes from 'prop-types';
import MyMessage from './messages/MyMessage.js';
import LocationMessage from './messages/LocationMessage.js';
import PlainMessage from './messages/PlainMessage.js';
import NotifyMessage from './messages/NotifyMessage.js';
import FollowUpMessage from './messages/FollowUpMessage.js';
import QuestionMessage from './messages/QuestionMessage.js';
import AttributionMessage from './messages/AttributionMessage.js';
import ControlMessage from './messages/ControlMessage.js';
import StatusMessage from './messages/StatusMessage.js';

/**
 * Chat Message to display.
 **/
class ChatMessage extends Component {
  /**
   * Select a message by asking the messages if they fit to the props given.
   **/
  getMessageTemplate() {
    return [
      ControlMessage,
      NotifyMessage,
      LocationMessage,
      QuestionMessage,
      FollowUpMessage,
      AttributionMessage,
      StatusMessage,
      MyMessage,
      PlainMessage,
    ].find((msg) => msg.test(this.props));
  }

  /**
   * Select a message template and render it.
   **/
  render() {
    let Message = this.getMessageTemplate();
    return <Message {...this.props} content={emojify(this.props.content)} />;
  }
}

ChatMessage.propTypes = {
  me: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired,
  onEvent: PropTypes.func.isRequired,
  skipHead: PropTypes.bool,
  timestamp: PropTypes.instanceOf(Date).isRequired,
};

ChatMessage.defaultProps = {
  skipHead: false,
};

export default ChatMessage;
