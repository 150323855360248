import React from 'react';
import Lottie from '../Lottie.js';
import animationData from '../../assets/animations/soundwave.json';

const SoundWave = ({ active, width = 30, height = 30, ...rest }) => {
  return (
    <span className="sound-wave" {...rest}>
      <Lottie
        width={width}
        height={height}
        options={{ animationData }}
        isStopped={!active}
        ariaLabel="soundwave"
        ariaRole="presentation"
        tabIndex={null}
      />
    </span>
  );
};

export default SoundWave;
