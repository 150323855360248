import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import FeatureDetector from '../FeatureDetector.js';
import MediaStreamBuilder from '../MediaStreamBuilder.js';
import MicMixer from '../MicMixer.js';
import { getScreenAsVideoTracks } from '../utils/StreamHelpers.js';

const adjustMediaStreamTracks = (stream, audio, video) => {
  stream.getAudioTracks().forEach(track => (track.enabled = audio));
  stream.getVideoTracks().forEach(track => (track.enabled = video));
};

/**
 * ChangeLocalStream (optimization after PodiumEvent).
 **/
class ChangeLocalStreamEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle({ audio, video, screen = false, surface }) {
    Logger.debug('ChangeLocalStreamEvent::handle');
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    const { localStream, vbgMixer } = _session;

    if (
      FeatureDetector.isIOSDevice() ||
      FeatureDetector.stopsDeviceActivityIndicatorOnDisable()
    ) {
      if (vbgMixer) {
        if (video) {
          vbgMixer.resume();
        } else {
          vbgMixer.suspend();
        }
        if (
          vbgMixer.originalStream &&
          vbgMixer.originalStream !== localStream
        ) {
          adjustMediaStreamTracks(vbgMixer.originalStream, audio, video);
        }
      }
      adjustMediaStreamTracks(localStream, audio, video);
      localStream.locallyChanged = true;
      _session.emit({ type: 'stream_update', localStream });
      return;
    }

    if (_session.micMixer) {
      _session.micMixer.destroy();
      _session.micMixer = null;
    }
    if (vbgMixer) {
      vbgMixer.stopOriginalStream();
      vbgMixer.terminate();
    }

    const options = {
      audio: audio,
      video: video,
      virtualBackground: _session.options.virtualBackground,
      existingStream: localStream,
      vbgMixer: vbgMixer,
      deviceMonitor: _session.deviceMonitor
    };

    if (screen) {
      _session.micMixer = new MicMixer();
      options.video = false;
      options.screen = true;
      options.surface = surface;
      options.virtualBackground = false;
      options.micMixer = _session.micMixer;
    }

    new MediaStreamBuilder(options)
      .start()
      .then(stream => {
        const [screenTrack] = getScreenAsVideoTracks(stream);
        if (screenTrack) {
          screenTrack.addEventListener('ended', () => {
            _session.emit({ type: 'stop_screen_video' });
          });
        }
        return stream;
      })
      .then(stream => {
        stream.locallyChanged = true;
        _session.setStream(stream);
      })
      .catch(error => {
        Logger.error('ChangeLocalStreamEvent', error);
        if (screen) {
          let name = `error_Screen_${error.name}`;
          if (error.message.indexOf('not start audio') > -1) {
            name = `error_Screen_${error.name}_Audio`;
          }
          _session.emit({ type: 'screen_capture_error', name });
          _session.emit({ type: 'stop_screen_video' });
        }
      });
  }
}

export default ChangeLocalStreamEvent;
