const ErrorStateList = [
  'bye',
  'offline',
  'inactive',
  'exit_room',
  'permission',
  'ice_failed',
  'unsupported',
  'not_readable',
  'access_denied',
  'session_in_use',
  'session_failed',
  'transport_error',
  'request_too_large',
  'abrupt_disconnect',
  'end_meeting',
  'meeting_locked',
  'ice_error',
  'permalink_unavailable',
];

export default ErrorStateList;
